import { SnoozeButton } from "@/components/snooze-button";
import { Separator } from "@/components/ui/separator";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { BankanCard, BankanCardHeader, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-cards";
import { BankanScore } from "./bankan-score";

type Group = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number];

export const MarketingPlansCard = ({ group, cardClassName }: { group: Group; cardClassName: string }) => {
  const { insuredId, insured, marketingPlans } = group;
  return (
    <BankanCard className={cardClassName}>
      <BankanCardHeader insured={insured} />
      {marketingPlans.map((plan) => {
        const { id, desiredEffectiveDate, selectedLinesOfBusiness, lastTransitionAt, score, snoozeUntil } = plan;
        return (
          <div key={id} className="relative">
            <Separator />
            <SnoozeButton opportunityId={id} desiredEffectiveDate={desiredEffectiveDate} snoozeUntil={snoozeUntil} />
            <BankanLink to={`/insured/${insuredId}/plans/${id}`}>
              <BankanLinkText className="space-y-1">
                {selectedLinesOfBusiness[0]
                  ? selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                  : "No Coverage Selected"}
              </BankanLinkText>
              <BankanLinkFooter>
                <div className="mr-auto">{formatTimezoneDateString(desiredEffectiveDate, "MMM d, yyyy")}</div>
                <BankanScore bankanScore={score} />
                <DaysSince date={lastTransitionAt} />
              </BankanLinkFooter>
            </BankanLink>
          </div>
        );
      })}
    </BankanCard>
  );
};
