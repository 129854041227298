import { FC, useState } from "react";

import { Grid, GridCell, GridLoading, GridRow, GridRowHeader } from "@/components/grid";
import { Button } from "@/components/ui/button";
import { Card, CardHeader } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { ToastAction } from "@/components/ui/toast";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { SUBMISSION_STATE_METADATA } from "@/metadata";
import {
  AppetiteProductFragment,
  SubmissionStateTransitionFragment,
  useDismissSubmissionStateLogExclusionReasonsMutation,
  useSubmissionStateLogExclusionReasonsQuery,
  useUndismissSubmissionStateLogExclusionReasonsMutation,
  VerticalMarketingPlanTemplateFragment,
} from "src/generated/graphql";
import { cn } from "src/utils";

import { TemplateHeader } from "./components/template-header";

export const TemplateExclusionReasons = ({ template }: { template: VerticalMarketingPlanTemplateFragment }) => {
  const [selected, setSelected] = useState(template?.products[0]?.id);

  const { products } = template;
  const { appetiteProduct } = products.find((product) => product.id === selected) ?? {};

  return (
    <Card className="grid grid-cols-[2fr_1px_3fr]">
      <div className="flex flex-col">
        <TemplateHeader template={template} />

        {!!products?.length && (
          <Grid className="relative max-h-[480px] overflow-y-auto grid-cols-[1fr_2fr]">
            <GridRowHeader position="sticky" className="bg-card top-0">
              <GridCell>Carrier</GridCell>
              <GridCell>Product</GridCell>
            </GridRowHeader>

            {products.map((product) => (
              <GridRow
                key={product.id}
                onPointerDown={() => setSelected(product.id)}
                className={cn("cursor-pointer", product.id === selected && "!bg-secondary/35")}
              >
                <GridCell>{product.appetiteProduct.carrierName}</GridCell>
                <GridCell>{product.appetiteProduct.carrierProductName}</GridCell>
              </GridRow>
            ))}
          </Grid>
        )}
      </div>

      <Separator orientation="vertical" />

      {appetiteProduct && (
        <div className="flex flex-col">
          <CardHeader className="font-semibold flex-row gap-2 h-16 items-center text-sm">
            {appetiteProduct.carrierName}: {appetiteProduct.carrierProductName}
          </CardHeader>

          <AppetiteProductExclusionReasonsGrid appetiteProduct={appetiteProduct} />
        </div>
      )}
    </Card>
  );
};

export interface AppetiteProductExclusionReasonsGridProps {
  appetiteProduct: AppetiteProductFragment;
}

export const AppetiteProductExclusionReasonsGrid: FC<AppetiteProductExclusionReasonsGridProps> = ({
  appetiteProduct,
}) => {
  const { data, loading, refetch } = useSubmissionStateLogExclusionReasonsQuery({
    variables: { input: { appetiteProductId: appetiteProduct.id } },
  });

  const reasons = data?.submissionStateLogExclusionReasons;

  return (
    <Grid className="relative max-h-[480px] overflow-y-auto grid-cols-[2fr_1fr_2rem]">
      <GridRowHeader position="sticky" className="bg-card top-0">
        <GridCell>Reason</GridCell>
        <GridCell>Type</GridCell>
        <div />
      </GridRowHeader>

      {loading && !reasons?.length && <GridLoading columns={3} />}

      {reasons?.map((reason) => (
        <AppetiteProductExclusionReasonsGridRow
          key={reason.id}
          appetiteProduct={appetiteProduct}
          reason={reason}
          onDismiss={refetch}
          onUndismiss={refetch}
        />
      ))}
    </Grid>
  );
};

export interface AppetiteProductExclusionReasonsGridRowProps {
  appetiteProduct: AppetiteProductFragment;
  reason: SubmissionStateTransitionFragment;
  onDismiss?: () => void;
  onUndismiss?: () => void;
}

export const AppetiteProductExclusionReasonsGridRow: FC<AppetiteProductExclusionReasonsGridRowProps> = ({
  appetiteProduct,
  reason,
  onDismiss,
  onUndismiss,
}) => {
  const { toast } = useToast();
  const [dismissExclusionReasons, { loading: dismissExclusionReasonsLoading }] =
    useDismissSubmissionStateLogExclusionReasonsMutation();
  const [undismissExclusionReasons, { loading: undismissExclusionReasonsLoading }] =
    useUndismissSubmissionStateLogExclusionReasonsMutation();

  const loading = dismissExclusionReasonsLoading || undismissExclusionReasonsLoading;

  const handleUndismiss = () => {
    undismissExclusionReasons({
      variables: {
        input: {
          appetiteProductId: appetiteProduct.id,
          submissionStateLogIds: [reason.id],
        },
      },
      onCompleted: onUndismiss,
    });
  };

  const handleDismiss = () => {
    dismissExclusionReasons({
      variables: {
        input: {
          appetiteProductId: appetiteProduct.id,
          submissionStateLogIds: [reason.id],
        },
      },
      onCompleted: () => {
        toast({
          title: "Exclusion reason dismissed",
          description: `Exclusion reason "${reason.details}" has been dismissed.`,
          action: (
            <ToastAction onClick={handleUndismiss} altText="undo dismiss exclusion reason">
              Undo
            </ToastAction>
          ),
        });
        onDismiss?.();
      },
    });
  };

  return (
    <GridRow>
      <GridCell>{reason.details}</GridCell>
      <GridCell>{SUBMISSION_STATE_METADATA[reason.state].label}</GridCell>
      <div className="text-right -mr-2">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="sm" display="icon" onClick={handleDismiss} disabled={loading}>
              {!loading && <Icon icon="close" />}
              {loading && <Spinner />}
            </Button>
          </TooltipTrigger>
          <TooltipContent>Dismiss</TooltipContent>
        </Tooltip>
      </div>
    </GridRow>
  );
};
