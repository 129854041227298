import { useState } from "react";
import { DefaultValues } from "react-hook-form";
import { useNavigate } from "react-router";
import { input, z, ZodObject } from "zod";

import { SectionContent } from "@/components/section";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Badge } from "@/components/ui/badge";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Field, Input, TextArea } from "@/forms/default";
import { Reform } from "@/forms/reform";
import {
  BusinessClassFragment,
  BusinessClassSystem,
  useSearchBusinessClassesLazyQuery,
  VerticalFragment,
} from "src/generated/graphql";

interface VerticalFormProps<T extends ZodObject<any>> {
  vertical?: VerticalFragment;
  schema: T;
  onSubmit: (values: z.infer<T>, selectedCgls: Set<string>) => void;
  defaultValues?: DefaultValues<input<T>>;
}

export const VerticalForm = <T extends ZodObject<any>>({
  vertical,
  schema,
  onSubmit,
  defaultValues,
}: VerticalFormProps<T>) => {
  const [selectedCgls, setSelectedCgls] = useState<Set<string>>(new Set(vertical?.isoCglCodes.map((c) => c.classCode)));

  const navigate = useNavigate();
  return (
    <Reform
      schema={schema}
      onSubmit={(_, values) => {
        onSubmit(values, selectedCgls);
        navigate(-1);
      }}
      defaultValues={defaultValues}
      className="flex flex-auto flex-col"
    >
      <SectionContent className="flex-auto space-y-6">
        <Field label="Name" name="name">
          <Input name="name" />
        </Field>
        <Field
          label={
            <div className="flex flex-row justify-between">
              <span>Description</span>
              <span className="text-sm text-muted-foreground">Optional</span>
            </div>
          }
          name="description"
        >
          <TextArea name="description" />
        </Field>
        <Pills
          items={[...selectedCgls]}
          onDelete={(code) => {
            selectedCgls.delete(code);
            setSelectedCgls(new Set(selectedCgls));
          }}
        />
        <CglSelector
          onSelect={(option) => {
            selectedCgls.has(option.code) ? selectedCgls.delete(option.code) : selectedCgls.add(option.code);
            setSelectedCgls(new Set(selectedCgls));
          }}
        />
      </SectionContent>
      <Bar as="footer">
        <Button type="submit">Save</Button>
      </Bar>
    </Reform>
  );
};

const CglSelector: React.FC<{ onSelect: (option: BusinessClassFragment) => void }> = ({ onSelect }) => {
  const [load] = useSearchBusinessClassesLazyQuery();
  return (
    <Autocomplete
      options={(text) => {
        return load({ variables: { input: { term: text, classSystems: [BusinessClassSystem.IsoGl] } } }).then((res) => [
          ...(res.data?.searchBusinessClasses ?? []),
        ]);
      }}
      onSelect={onSelect}
      toValue={(option) => businessClassToLabel(option)}
      toLabel={(option) => businessClassToLabel(option)}
      placeholder="Search CGL Classifications"
    />
  );
};

const Pill: React.FC<{
  text: string;
  onClick?: () => void;
  onDelete?: (item: string) => void;
}> = ({ text, onClick, onDelete }) => {
  return (
    <Badge variant="outline" className="bg-slate-300" onClick={onClick}>
      <div className="flex flex-row justify-between gap-1">
        {text}
        {onDelete && (
          <div
            className="flex"
            onClick={(e) => {
              e.preventDefault();
              onDelete(text);
            }}
          >
            <Icon icon="close" />
          </div>
        )}
      </div>
    </Badge>
  );
};

const Pills: React.FC<{
  items: string[];
  onClick?: () => void;
  onDelete?: (item: string) => void;
}> = ({ items, onClick, onDelete }) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {items.map((item) => (
        <Pill key={item} text={item} onClick={onClick} onDelete={onDelete} />
      ))}
    </div>
  );
};

const businessClassToLabel = (c: BusinessClassFragment) =>
  `${c.code} ${c.code && c.description ? `-` : ""} ${c.description}`;
