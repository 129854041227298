import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** An arbitrary precision decimal value. Often used for dollar amounts */
  Decimal: string & { __scalar: "Decimal" };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: string | boolean | number | Record<string, any> | string[] | boolean[] | number[];
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: string & { __scalar: "UUID" };
};

export type AcordGenerationInput = {
  opportunityId: Scalars["String"];
  type: AcordType;
};

export enum AcordType {
  Acord125 = "Acord125",
  Acord126 = "Acord126",
  Acord140 = "Acord140",
}

export enum ActionExecutionOperation {
  Approved = "Approved",
  Dismissed = "Dismissed",
  None = "None",
  Rejected = "Rejected",
}

export type ActionFilterInput = {
  actionType?: InputMaybe<ActionType>;
  autoExecute?: InputMaybe<Scalars["Boolean"]>;
  executedOperations?: InputMaybe<Array<ActionExecutionOperation>>;
  opportunityId?: InputMaybe<Scalars["String"]>;
  submissionId?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export enum ActionType {
  ApproveCreatedOpportunity = "ApproveCreatedOpportunity",
  ApproveDeclinedByAi = "ApproveDeclinedByAI",
  ApproveMarketingPlan = "ApproveMarketingPlan",
  CloseOpportunity = "CloseOpportunity",
  CrossSellCpl = "CrossSellCPL",
  CrossSellCyber = "CrossSellCyber",
  FollowUpOnInformationRequest = "FollowUpOnInformationRequest",
  FollowUpOnQuotes1d = "FollowUpOnQuotes1d",
  GenericEmailReply = "GenericEmailReply",
  InHouseAuthorityQuote = "InHouseAuthorityQuote",
  InactiveOpportunity = "InactiveOpportunity",
  RequestExpeditedRequirements = "RequestExpeditedRequirements",
  RespondToAgentComment = "RespondToAgentComment",
  ReviewCarrierQuote = "ReviewCarrierQuote",
  SendQuoteToAgent = "SendQuoteToAgent",
  SendSubmissionPacketToCarrier = "SendSubmissionPacketToCarrier",
  VerifyInformationRequest = "VerifyInformationRequest",
}

export type AddProductToVerticalMarketingPlanTemplateInput = {
  appetiteProductIds: Array<Scalars["String"]>;
  verticalMarketingPlanTemplateId: Scalars["String"];
};

export type AddVerticalMarketingPlanTemplateProductRequirementInput = {
  description?: InputMaybe<Scalars["String"]>;
  groups: Scalars["String"];
  label?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
  verticalMarketingPlanTemplateProductIds: Array<Scalars["String"]>;
};

export type AgenciesFilterInput = {
  hasLeads?: InputMaybe<Scalars["Boolean"]>;
  term?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export enum AgentActionType {
  ClientData = "ClientData",
  Custom = "Custom",
  RespondToInformationRequest = "RespondToInformationRequest",
  UploadFile = "UploadFile",
}

export type AgentInput = {
  agencyId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
};

export type AppetiteNoteFilterInput = {
  isoCglCodes?: InputMaybe<Array<Scalars["String"]>>;
  lobs?: InputMaybe<Array<Coverages>>;
  states?: InputMaybe<Array<Scalars["String"]>>;
};

export enum AppetiteNoteTagType {
  CglCode = "CglCode",
  LineOfBusiness = "LineOfBusiness",
  State = "State",
}

export type BankanMarketingPlanColumnInput = {
  agencyId?: InputMaybe<Scalars["String"]>;
  brokerId?: InputMaybe<Scalars["String"]>;
  column: MarketingPlanBankanColumn;
};

export enum BankanPolicyColumn {
  AwaitingBinderFromCarrier = "AwaitingBinderFromCarrier",
  AwaitingPolicyFromCarrier = "AwaitingPolicyFromCarrier",
  BinderProcessed = "BinderProcessed",
  ExpiringSoon = "ExpiringSoon",
  IssuedEndorsementsRequested = "IssuedEndorsementsRequested",
  ReceivedBinderFromCarrier = "ReceivedBinderFromCarrier",
  ReceivedPolicyFromCarrier = "ReceivedPolicyFromCarrier",
}

export type BankanPolicyColumnInput = {
  agencyId?: InputMaybe<Scalars["String"]>;
  brokerId?: InputMaybe<Scalars["String"]>;
  column: BankanPolicyColumn;
};

export enum BankanQuoteColumn {
  BindRequestReceived = "BindRequestReceived",
  Delivered = "Delivered",
  ReadyToSend = "ReadyToSend",
}

export type BankanQuoteColumnInput = {
  agencyId?: InputMaybe<Scalars["String"]>;
  brokerId?: InputMaybe<Scalars["String"]>;
  column: BankanQuoteColumn;
};

export enum BindHqApplicationType {
  Extension = "Extension",
  New = "New",
  Renewal = "Renewal",
  Rewrite = "Rewrite",
}

export type BindHqFetchAgentsInput = {
  bindHqAgencyId?: InputMaybe<Scalars["String"]>;
};

export enum BindHqInsuredType {
  Corporation = "Corporation",
  Individual = "Individual",
  Individuals = "Individuals",
  JointVenture = "JointVenture",
  LimitedCorporation = "LimitedCorporation",
  LimitedLiabilityCompany = "LimitedLiabilityCompany",
  LimitedPartnership = "LimitedPartnership",
  NonProfit = "NonProfit",
  Other = "Other",
  Partnership = "Partnership",
  Proprietorship = "Proprietorship",
  SoleProprietor = "SoleProprietor",
  SubchapterSCorporation = "SubchapterSCorporation",
  Trust = "Trust",
}

export enum BindHqLineOfBusiness {
  Acct = "ACCT",
  Ache = "ACHE",
  ActiveShooter = "ACTIVE_SHOOTER",
  Agent = "AGENT",
  Aglia = "AGLIA",
  Agop = "AGOP",
  Agpp = "AGPP",
  Agpr = "AGPR",
  Airc = "AIRC",
  Airpfb = "AIRPFB",
  Apkge = "APKGE",
  Aprod = "APROD",
  Arch = "ARCH",
  Art = "ART",
  Auto = "AUTO",
  Autob = "AUTOB",
  Autop = "AUTOP",
  Avpkg = "AVPKG",
  Bail = "BAIL",
  Bandm = "BANDM",
  Blank = "BLANK",
  Bmisc = "BMISC",
  Boat = "BOAT",
  Bop = "BOP",
  Bopgl = "BOPGL",
  Boppr = "BOPPR",
  Bpp = "BPP",
  BuildersRisk = "BUILDERS_RISK",
  Cavn = "CAVN",
  Cfire = "CFIRE",
  Cfrm = "CFRM",
  Comr = "COMR",
  ContingentAuto = "CONTINGENT_AUTO",
  ContingentCargo = "CONTINGENT_CARGO",
  Contr = "CONTR",
  ContractorsEquipment = "CONTRACTORS_EQUIPMENT",
  Cop = "COP",
  Cp = "CP",
  Cpkge = "CPKGE",
  Cpl = "CPL",
  Cpmp = "CPMP",
  Crim = "CRIM",
  Crime = "CRIME",
  Cyber = "CYBER",
  Dfire = "DFIRE",
  Disab = "DISAB",
  Do = "DO",
  Eil = "EIL",
  El = "EL",
  Eo = "EO",
  Epkg = "EPKG",
  Epli = "EPLI",
  Eq = "EQ",
  Eqfltr = "EQFLTR",
  Eqlia = "EQLIA",
  Exlia = "EXLIA",
  Expl = "EXPL",
  Fidty = "FIDTY",
  Fiduc = "FIDUC",
  Flood = "FLOOD",
  Garag = "GARAG",
  Gl = "GL",
  Hang = "HANG",
  Hbb = "HBB",
  Hlth = "HLTH",
  Home = "HOME",
  Il = "IL",
  Inmar = "INMAR",
  Inmrc = "INMRC",
  Inmrp = "INMRP",
  InstallationFloater = "INSTALLATION_FLOATER",
  Judcl = "JUDCL",
  Kidra = "KIDRA",
  Law = "LAW",
  Licpt = "LICPT",
  Ll = "LL",
  Lmort = "LMORT",
  Logpckg = "LOGPCKG",
  Lstin = "LSTIN",
  Lvstk = "LVSTK",
  Media = "MEDIA",
  Mhome = "MHOME",
  Mmal = "MMAL",
  Mpl = "MPL",
  Mpp = "MPP",
  Mtc = "MTC",
  Mtrcr = "MTRCR",
  Motorcycle = "Motorcycle",
  Nwfgr = "NWFGR",
  Ocp = "OCP",
  Olib = "OLIB",
  Phys = "PHYS",
  Pkg = "PKG",
  Pl = "PL",
  Plib = "PLIB",
  Plmsc = "PLMSC",
  Ppkge = "PPKGE",
  Ppl = "PPL",
  ProductRecall = "PRODUCT_RECALL",
  Prsl = "PRSL",
  Pubof = "PUBOF",
  Recv = "RECV",
  Rrprl = "RRPRL",
  Scap = "SCAP",
  Sfrnc = "SFRNC",
  Shipintr = "SHIPINTR",
  Smp = "SMP",
  Stkth = "STKTH",
  Stock = "STOCK",
  Stpl = "STPL",
  Sure = "SURE",
  Tech = "TECH",
  TripTransit = "TRIP_TRANSIT",
  Truck = "TRUCK",
  Umbrc = "UMBRC",
  Umbrl = "UMBRL",
  Umbrp = "UMBRP",
  Un = "UN",
  Warel = "WAREL",
  Wcma = "WCMA",
  Wind = "WIND",
  Work = "WORK",
  Workp = "WORKP",
  Workv = "WORKV",
  Yacht = "YACHT",
}

export enum BindHqQuoteBillingType {
  AgencyBill = "AgencyBill",
  DirectBill = "DirectBill",
  Filing = "Filing",
  InsuredDirectBill = "InsuredDirectBill",
  Manual = "Manual",
}

export enum BindHqQuoteBusinessType {
  Binding = "Binding",
  Brokerage = "Brokerage",
}

export type BindHqQuoteLineItemInput = {
  amount: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  lineOfBusiness: BindHqLineOfBusiness;
  type: BindHqQuoteLineItemType;
};

export enum BindHqQuoteLineItemType {
  AgentFee = "AgentFee",
  CarrierFee = "CarrierFee",
  CarrierInspectionFee = "CarrierInspectionFee",
  Clearinghouse = "Clearinghouse",
  Empa = "Empa",
  FireMarshallTax = "FireMarshallTax",
  HealthyHomes = "HealthyHomes",
  InspectionFee = "InspectionFee",
  MgaFee = "MgaFee",
  MunicipalTax = "MunicipalTax",
  OptIns = "OptIns",
  Premium = "Premium",
  RiskManagementFee = "RiskManagementFee",
  StampingFee = "StampingFee",
  StateTax = "StateTax",
  Surcharge = "Surcharge",
  TechnologyFee = "TechnologyFee",
  WillComply = "WillComply",
  WindpoolFee = "WindpoolFee",
}

export enum BindHqQuoteTaxesCollectedBy {
  Agent = "Agent",
  MarketingCompany = "MarketingCompany",
  Mga = "Mga",
}

export type BindQuoteInput = {
  createApplication: CreateBindHqApplicationInput;
  createPolicy: CreatePolicyInputNew;
  createQuote: CreateBindHqQuoteInput;
  quoteId?: InputMaybe<Scalars["ID"]>;
};

export enum BindingAuthority {
  InAuthority = "InAuthority",
  Prohibited = "Prohibited",
  Submit = "Submit",
}

export type BrokerCreateAgentAction = {
  externalReason?: InputMaybe<Scalars["String"]>;
  opportunityId: Scalars["String"];
  reason?: InputMaybe<Scalars["String"]>;
  submissionId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export enum BusinessClassSystem {
  IsoGl = "ISO_GL",
  Naics = "NAICS",
}

export type BusinessClassificationsSearchInput = {
  classSystems?: InputMaybe<Array<BusinessClassSystem>>;
  term?: InputMaybe<Scalars["String"]>;
};

export type CarrierContactConnectorInput = {
  appetiteProductId: Scalars["String"];
  id: Scalars["ID"];
  type: CarrierContactType;
};

export enum CarrierContactType {
  Reference = "Reference",
  Submission = "Submission",
}

export type ClientDataFilterInput = {
  insuredId: Scalars["String"];
  keys?: InputMaybe<Array<Scalars["String"]>>;
  source?: InputMaybe<Scalars["String"]>;
};

export enum Code_Path {
  Policy = "POLICY",
  ProcessedQuote = "PROCESSED_QUOTE",
  RawQuote = "RAW_QUOTE",
}

export type CompleteAgentActionInput = {
  id: Scalars["String"];
};

export type ConnectOrDisconnectExtractedLabelInput = {
  extractedLabelId: Scalars["String"];
  id: Scalars["String"];
};

export enum Coverages {
  BuildersRisk = "BuildersRisk",
  Cargo = "Cargo",
  CommercialAuto = "CommercialAuto",
  ContingentCargo = "ContingentCargo",
  ContractorsEquipment = "ContractorsEquipment",
  ContractorsPollution = "ContractorsPollution",
  ControlOfWell = "ControlOfWell",
  Crime = "Crime",
  Cyber = "Cyber",
  DirectorsAndOfficers = "DirectorsAndOfficers",
  EoProfessional = "EOProfessional",
  Epli = "EPLI",
  Earthquake = "Earthquake",
  EmployeeBenefits = "EmployeeBenefits",
  EnvironmentalImpairment = "EnvironmentalImpairment",
  ExcessUmbrella = "ExcessUmbrella",
  Fiduciary = "Fiduciary",
  Flood = "Flood",
  Garage = "Garage",
  GeneralLiability = "GeneralLiability",
  Hnoa = "HNOA",
  InlandMarine = "InlandMarine",
  Intellectual = "Intellectual",
  LiquorLiability = "LiquorLiability",
  MarinePi = "MarinePI",
  MaritimeEmployers = "MaritimeEmployers",
  NonDealersGarage = "NonDealersGarage",
  NonOwnedDisposalSites = "NonOwnedDisposalSites",
  OceanMarine = "OceanMarine",
  ProductRecall = "ProductRecall",
  ProductsPollution = "ProductsPollution",
  Property = "Property",
  SpecialEvents = "SpecialEvents",
  TechEo = "TechEO",
  WorkersCompensation = "WorkersCompensation",
}

export type CreateActionInput = {
  actionType: ActionType;
  autoExecuteAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  opportunityId: Scalars["String"];
  submissionId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type CreateAppetiteNoteInput = {
  isoCglCodes: Array<Scalars["String"]>;
  linesOfBusiness: Array<Coverages>;
  note: Scalars["String"];
  states: Array<Scalars["String"]>;
};

export type CreateBindHqApplicationInput = {
  agentBindHqId: Scalars["String"];
  linesOfBusiness: Array<BindHqLineOfBusiness>;
  type: BindHqApplicationType;
};

export type CreateBindHqQuoteInput = {
  agentCommissionPercentage: Scalars["String"];
  billingType: BindHqQuoteBillingType;
  businessType: BindHqQuoteBusinessType;
  carrierId: Scalars["String"];
  grossCommissionPercentage: Scalars["String"];
  lineItems: Array<BindHqQuoteLineItemInput>;
  marketingCompanyId: Scalars["String"];
  minimumEarnedPremiumPercentage: Scalars["String"];
  taxationHomeState: Scalars["String"];
  taxesCollectedBy: BindHqQuoteTaxesCollectedBy;
};

export type CreateBindingExclusionInput = {
  limit?: InputMaybe<Scalars["String"]>;
  operations?: InputMaybe<Scalars["String"]>;
  opportunityId: Scalars["String"];
  other?: InputMaybe<Scalars["String"]>;
  revenue?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  tiv?: InputMaybe<Scalars["String"]>;
};

export type CreateCarrierContactInput = {
  appetiteProductId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  type: CarrierContactType;
};

export type CreateClientDataInput = {
  clientDataInputs: Array<CreateClientDatumInput>;
  insuredId: Scalars["String"];
  source?: InputMaybe<Scalars["String"]>;
};

export type CreateClientDataPdfInput = {
  css: Scalars["String"];
  filename: Scalars["String"];
  html: Scalars["String"];
  opportunityId: Scalars["String"];
};

export type CreateClientDatumInput = {
  index?: InputMaybe<Scalars["Int"]>;
  key: Scalars["String"];
  value: Scalars["JSON"];
};

export type CreateCommentInput = {
  isPublic?: Scalars["Boolean"];
  opportunityId: Scalars["String"];
  text: Scalars["String"];
};

export type CreateEmailDraftInput = {
  cc?: InputMaybe<Array<Scalars["String"]>>;
  opportunityId: Scalars["String"];
  subject: Scalars["String"];
  text: Scalars["String"];
  to: Array<Scalars["String"]>;
};

export type CreateEmailTemplateDraftInput = {
  fileIds?: Array<Scalars["String"]>;
  submissionId: Scalars["String"];
  template: OpportunityEmailTemplate;
};

export type CreateInsuredAndAgentInput = {
  agentInput: AgentInput;
  cglCode?: InputMaybe<Scalars["String"]>;
  description: Scalars["String"];
  exposures?: InputMaybe<Scalars["String"]>;
  naicsCode?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  primaryState?: InputMaybe<Scalars["String"]>;
  revenue?: InputMaybe<Scalars["Int"]>;
};

export type CreateInsuredHazardInput = {
  cgl: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  exposure: Scalars["String"];
  hazardNumber: Scalars["Int"];
  insuredId: Scalars["String"];
  locationId: Scalars["String"];
  premiumBasis: PremiumBasis;
};

export type CreateInsuredInput = {
  cglCode?: InputMaybe<Scalars["String"]>;
  description: Scalars["String"];
  exposures?: InputMaybe<Scalars["String"]>;
  naicsCode?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  primaryState?: InputMaybe<Scalars["String"]>;
  revenue?: InputMaybe<Scalars["Int"]>;
};

export type CreateInsuredPremiseBuildingInput = {
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  annualRevenue: Scalars["String"];
  anyAreaLeasedToOthers?: InputMaybe<Scalars["Boolean"]>;
  buildingNumber: Scalars["Int"];
  cityLimits?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  fullTimeEmployeeCount?: InputMaybe<Scalars["Int"]>;
  interest?: InputMaybe<Scalars["String"]>;
  locationId: Scalars["String"];
  occupiedArea?: InputMaybe<Scalars["Int"]>;
  openToPublicArea?: InputMaybe<Scalars["Int"]>;
  partTimeEmployeeCount?: InputMaybe<Scalars["Int"]>;
  totalBuildingArea?: InputMaybe<Scalars["Int"]>;
};

export type CreateInsuredPremiseLocationInput = {
  city: Scalars["String"];
  county: Scalars["String"];
  insuredId: Scalars["String"];
  locationNumber: Scalars["Int"];
  state: Scalars["String"];
  zip: Scalars["String"];
};

export type CreateOpportunityInput = {
  desiredEffectiveDate: Scalars["DateTime"];
  insuredId: Scalars["String"];
  selectedLinesOfBusiness?: InputMaybe<Array<Scalars["String"]>>;
};

export type CreateOpportunityNoteInput = {
  opportunityId: Scalars["String"];
  text: Scalars["String"];
};

export type CreatePipelineVersionInput = {
  name: Scalars["String"];
  pipeline: FileProcessingPipelineInput;
};

export type CreatePolicyInputNew = {
  effectiveDate: Scalars["DateTime"];
  expirationDate: Scalars["DateTime"];
  invoiceDueDate: Scalars["DateTime"];
  policyNumber: Scalars["String"];
};

export type CreatePrimaryLabelInput = {
  dataType?: InputMaybe<PrimaryLabelDataType>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  extractedLabels?: InputMaybe<Array<Scalars["String"]>>;
  primaryKey: Scalars["String"];
};

export type CreateProcessedBinderInput = {
  outstandingSubjectivities: Scalars["String"];
  policyId: Scalars["String"];
};

export type CreateProcessedQuoteInput = {
  agentCommission: Scalars["Float"];
  brokerFee: Scalars["Float"];
  carrierFee?: InputMaybe<Scalars["Float"]>;
  carrierName: Scalars["String"];
  effectiveDate: Scalars["DateTime"];
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  grossCommission?: InputMaybe<Scalars["Float"]>;
  homeState: Scalars["String"];
  inspectionFee?: InputMaybe<Scalars["Float"]>;
  insuredName: Scalars["String"];
  mep: Scalars["Float"];
  premium: Scalars["Float"];
  quoteId: Scalars["String"];
  subjectivities?: Array<Scalars["String"]>;
  submissionId: Scalars["String"];
};

export type CreateRenewalInput = {
  policyId: Scalars["UUID"];
};

export type CreateTagInput = {
  label: Scalars["String"];
};

export type CreateVerticalInput = {
  description?: InputMaybe<Scalars["String"]>;
  isoCglCodes?: InputMaybe<Array<Scalars["String"]>>;
  name: Scalars["String"];
};

export type CreateVerticalMarketingPlanTemplateInput = {
  lobs: Array<Scalars["String"]>;
  verticalId: Scalars["String"];
};

export type DeleteClientDataInput = {
  index?: InputMaybe<Scalars["Int"]>;
  insuredId: Scalars["String"];
  key: Scalars["String"];
  source: Scalars["String"];
};

export type DeleteFileUploadInput = {
  id: Scalars["String"];
};

export type DeleteVerticalMarketingPlanTemplateProductRequirementInput = {
  ids: Array<Scalars["String"]>;
};

export enum DesireToRemarket {
  High = "High",
  Low = "Low",
  Medium = "Medium",
}

export type DismissSubmissionStateLogExclusionReasonsInput = {
  appetiteProductId: Scalars["ID"];
  submissionStateLogIds: Array<Scalars["ID"]>;
};

export type EditInsuredHazardInput = {
  cgl: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  exposure: Scalars["String"];
  hazardId: Scalars["String"];
  hazardNumber: Scalars["Int"];
  locationId: Scalars["String"];
  premiumBasis: PremiumBasis;
};

export type EditInsuredPremiseBuildingInput = {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  annualRevenue: Scalars["String"];
  anyAreaLeasedToOthers?: InputMaybe<Scalars["Boolean"]>;
  buildingId: Scalars["String"];
  buildingNumber: Scalars["Int"];
  burglarAlarmType?: InputMaybe<Scalars["String"]>;
  cityLimits?: InputMaybe<Scalars["String"]>;
  constructionType?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  fullTimeEmployeeCount?: InputMaybe<Scalars["Int"]>;
  heatingYear?: InputMaybe<Scalars["String"]>;
  interest?: InputMaybe<Scalars["String"]>;
  locationId: Scalars["String"];
  occupiedArea?: InputMaybe<Scalars["Int"]>;
  openToPublicArea?: InputMaybe<Scalars["Int"]>;
  partTimeEmployeeCount?: InputMaybe<Scalars["Int"]>;
  plumbingYear?: InputMaybe<Scalars["String"]>;
  roofType?: InputMaybe<Scalars["String"]>;
  roofingYear?: InputMaybe<Scalars["String"]>;
  sprinklerPercent?: InputMaybe<Scalars["String"]>;
  totalBuildingArea?: InputMaybe<Scalars["Int"]>;
  wiringYear?: InputMaybe<Scalars["String"]>;
  yearBuilt?: InputMaybe<Scalars["String"]>;
};

export type EditInsuredPremiseLocationInput = {
  city: Scalars["String"];
  county: Scalars["String"];
  locationId: Scalars["String"];
  locationNumber: Scalars["Int"];
  state: Scalars["String"];
  zip: Scalars["String"];
};

export type ExtractedLabelFiltersInput = {
  count?: InputMaybe<Scalars["Int"]>;
  key?: InputMaybe<Scalars["String"]>;
  primaryLabels?: InputMaybe<ExtractedLabelsPrimaryLabelsFilter>;
  sortBy?: InputMaybe<ExtractedLabelsSortBy>;
  sortOrder?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
};

export enum ExtractedLabelsPrimaryLabelsFilter {
  All = "all",
  None = "none",
  Some = "some",
}

export enum ExtractedLabelsSortBy {
  Count = "count",
  Key = "key",
  Source = "source",
}

export type FilePipelineEdgeInput = {
  destinationNodes: Array<FilePipelineNodeInput>;
  label: Scalars["String"];
  sourceNodeName: Scalars["String"];
};

export type FilePipelineNodeInput = {
  category: Scalars["String"];
  endPage?: InputMaybe<Scalars["Int"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  startPage?: InputMaybe<Scalars["Int"]>;
};

export type FileProcessingPipelineInput = {
  initial: FilePipelineNodeInput;
  transitions: Array<FilePipelineEdgeInput>;
};

export enum FileProcessorCategory {
  Classifier = "Classifier",
  Extractor = "Extractor",
  PromptExtractor = "PromptExtractor",
  Redactor = "Redactor",
  Splitter = "Splitter",
}

export enum FileProcessorIssueType {
  DocumentLabelNeedsFileProcessor = "DOCUMENT_LABEL_NEEDS_FILE_PROCESSOR",
  FileProcessorHasMultipleDeployedVersions = "FILE_PROCESSOR_HAS_MULTIPLE_DEPLOYED_VERSIONS",
  FileProcessorNeedsDefaultVersion = "FILE_PROCESSOR_NEEDS_DEFAULT_VERSION",
  FileProcessorNeedsDeployedVersion = "FILE_PROCESSOR_NEEDS_DEPLOYED_VERSION",
}

export enum FileProcessorNodeType {
  DocumentLabel = "DocumentLabel",
  FileProcessor = "FileProcessor",
}

export enum FileProcessorVersionState {
  Creating = "CREATING",
  Deleting = "DELETING",
  Deployed = "DEPLOYED",
  Deploying = "DEPLOYING",
  Failed = "FAILED",
  Importing = "IMPORTING",
  StateUnspecified = "STATE_UNSPECIFIED",
  Undeployed = "UNDEPLOYED",
  Undeploying = "UNDEPLOYING",
}

export enum File_Audience {
  External = "EXTERNAL",
  Internal = "INTERNAL",
}

export enum File_Source {
  CorrespondencePdf = "CORRESPONDENCE_PDF",
  EmailAttachment = "EMAIL_ATTACHMENT",
  GenerateAcord = "GENERATE_ACORD",
  ManualUpload = "MANUAL_UPLOAD",
  QuotePdf = "QUOTE_PDF",
  RawQuotePdf = "RAW_QUOTE_PDF",
}

export type FindExtractedLabelsInput = {
  filter?: InputMaybe<ExtractedLabelFiltersInput>;
  pagination?: InputMaybe<PaginationInput>;
  query?: InputMaybe<Scalars["String"]>;
};

export type FindFileInput = {
  id: Scalars["String"];
};

export type FindFilesByLabelInput = {
  deletedAt?: Scalars["Boolean"];
  includeCorrespondence?: Scalars["Boolean"];
  includeGmailAttachments?: Scalars["Boolean"];
  insuredId: Scalars["String"];
  labels: Array<Scalars["String"]>;
};

export type FindJobsInput = {
  fileId: Scalars["String"];
};

export type FindManyFileUploadInput = {
  insuredId: Scalars["UUID"];
};

export type FindPrimaryLabelsArgs = {
  filter?: InputMaybe<PrimaryLabelFiltersInput>;
  pagination?: InputMaybe<PaginationInput>;
  query?: InputMaybe<Scalars["String"]>;
};

export type FindTagsInput = {
  label?: InputMaybe<Scalars["String"]>;
};

export type FindVerticalByCglInput = {
  isoCglCode: Scalars["String"];
};

export type GmailThreadFilterInput = {
  insuredId?: InputMaybe<Scalars["String"]>;
};

export type GroupedMarketingPlansInput = {
  agencyId?: InputMaybe<Scalars["String"]>;
  brokerId?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Array<MarketingPlanState>>;
};

export type GroupedQuotesInput = {
  agencyId?: InputMaybe<Scalars["String"]>;
  brokerId?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Array<QuoteState>>;
};

export type InsuredFilterInput = {
  agencyId?: InputMaybe<Scalars["String"]>;
  agentId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<Scalars["String"]>>;
  isTest?: Scalars["Boolean"];
  /** Has no marketing plans */
  orphaned?: Scalars["Boolean"];
  term?: InputMaybe<Scalars["String"]>;
  /** Has marketing plans, but none are assigned */
  unassigned?: Scalars["Boolean"];
};

export type InviteUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type LeadUpdateInput = {
  carrierOverride?: InputMaybe<Scalars["String"]>;
  currentAgentCommission?: InputMaybe<Scalars["String"]>;
  desireToRemarket?: InputMaybe<DesireToRemarket>;
  id: Scalars["String"];
  qwAgentCommission?: InputMaybe<Scalars["String"]>;
  qwCarrierMatch?: InputMaybe<QwCarrierMatch>;
};

export type LeadsFilterInput = {
  agencyId?: InputMaybe<Scalars["String"]>;
  agentFirstName?: InputMaybe<Scalars["String"]>;
  agentLastName?: InputMaybe<Scalars["String"]>;
  excludeArchived?: InputMaybe<Scalars["Boolean"]>;
  expirationDateAfter?: InputMaybe<Scalars["String"]>;
  expirationDateBefore?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<Scalars["String"]>>;
  opportunityId?: InputMaybe<Scalars["String"]>;
  sortByHighlighted?: InputMaybe<Scalars["Boolean"]>;
};

export type LeadsUpdateManyInput = {
  archived?: InputMaybe<Scalars["Boolean"]>;
  currentAgentCommission?: InputMaybe<Scalars["String"]>;
  desireToRemarket?: InputMaybe<DesireToRemarket>;
  highlighted?: InputMaybe<Scalars["Boolean"]>;
  ids: Array<Scalars["ID"]>;
  notes?: InputMaybe<Scalars["String"]>;
  qwAgentCommission?: InputMaybe<Scalars["String"]>;
  qwCarrierMatch?: InputMaybe<QwCarrierMatch>;
};

export enum MarketTypes {
  BindingAuthority = "bindingAuthority",
  Brokerage = "brokerage",
}

export enum MarketingPlanAction {
  CompleteMarketing = "CompleteMarketing",
  GatherMoreInformation = "GatherMoreInformation",
  Reopen = "Reopen",
  ReviewInformation = "ReviewInformation",
  SelectCoverages = "SelectCoverages",
  StartMarketing = "StartMarketing",
}

export enum MarketingPlanBankanColumn {
  InformationGathering = "InformationGathering",
  InformationReview = "InformationReview",
  MarketingInfoRequired = "MarketingInfoRequired",
  MarketingReady = "MarketingReady",
  UnassignedInformationGathering = "UnassignedInformationGathering",
  UnassignedOther = "UnassignedOther",
  UnassignedWaitingForCoverages = "UnassignedWaitingForCoverages",
  WaitingForCoverages = "WaitingForCoverages",
}

export enum MarketingPlanState {
  Complete = "Complete",
  InformationGathering = "InformationGathering",
  InformationReview = "InformationReview",
  Marketing = "Marketing",
  WaitingForCoverages = "WaitingForCoverages",
}

export type MergeInsuredInput = {
  deprecatedId: Scalars["UUID"];
  survivorId: Scalars["UUID"];
};

export enum OpportunityEmailTemplate {
  PolicyCheck = "PolicyCheck",
  RequestBinder = "RequestBinder",
  RequestQuote = "RequestQuote",
  SendBinder = "SendBinder",
  SendPolicy = "SendPolicy",
  SendQuote = "SendQuote",
  SubmitToCarrier = "SubmitToCarrier",
  SubmitToInHouseAuthority = "SubmitToInHouseAuthority",
}

export enum OpportunityEventType {
  ActionApproved = "ActionApproved",
  ActionDismissed = "ActionDismissed",
  ActionRejected = "ActionRejected",
  ActionRequested = "ActionRequested",
  AlbyUpdate = "AlbyUpdate",
  ApplicationSubmitted = "ApplicationSubmitted",
  AssignmentChange = "AssignmentChange",
  AutomatedFollowUp = "AutomatedFollowUp",
  BotComment = "BotComment",
  BusinessClassCodesChanged = "BusinessClassCodesChanged",
  CoveragesChanged = "CoveragesChanged",
  Created = "Created",
  DesiredEffectiveDateChange = "DesiredEffectiveDateChange",
  Email = "Email",
  FileUpload = "FileUpload",
  GmailMessage = "GmailMessage",
  GmailRelay = "GmailRelay",
  OutcomeChange = "OutcomeChange",
  ProductsAdded = "ProductsAdded",
  ProductsRemoved = "ProductsRemoved",
  RequirementAdded = "RequirementAdded",
  StatusChange = "StatusChange",
  SubmissionStatusChange = "SubmissionStatusChange",
  TriageAssignment = "TriageAssignment",
  TriageReassignment = "TriageReassignment",
  UserComment = "UserComment",
}

export type OpportunityEventsFilterInput = {
  opportunityId: Scalars["String"];
  types?: InputMaybe<Array<OpportunityEventType>>;
};

/** The initial select coverages step */
export type OpportunitySelectCoveragesInput = {
  id: Scalars["ID"];
  propertyTIV?: InputMaybe<Scalars["Int"]>;
  selectedLinesOfBusiness: Array<Scalars["String"]>;
};

export type OpportunityUpdateInput = {
  brokerId?: InputMaybe<Scalars["UUID"]>;
  desiredEffectiveDate?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  selectedLinesOfBusiness?: InputMaybe<Array<Scalars["String"]>>;
};

export type PaginationInput = {
  page?: InputMaybe<Scalars["Int"]>;
  rowsPerPage?: InputMaybe<Scalars["Int"]>;
};

export enum PolicyAction {
  Cancel = "Cancel",
  DeliverBinder = "DeliverBinder",
  GenerateBinderCover = "GenerateBinderCover",
  Issue = "Issue",
  ReceiveBinder = "ReceiveBinder",
  ReceivePolicy = "ReceivePolicy",
  SkipBinder = "SkipBinder",
  UploadNewBinder = "UploadNewBinder",
}

export enum PolicyFileType {
  Policy = "Policy",
  ProcessedBinder = "ProcessedBinder",
  RedactedBinder = "RedactedBinder",
}

export type PolicyFilterInput = {
  /** Filter for active policies (currently within the [effectiveDate-30d]->[expirationDate+30d] window). */
  active?: InputMaybe<Scalars["Boolean"]>;
  insuredId?: InputMaybe<Scalars["String"]>;
  marketingPlanId?: InputMaybe<Scalars["String"]>;
  submissionId?: InputMaybe<Scalars["String"]>;
};

export enum PolicyState {
  AwaitingBinderFromCarrier = "AwaitingBinderFromCarrier",
  AwaitingPolicyFromCarrier = "AwaitingPolicyFromCarrier",
  BinderProcessed = "BinderProcessed",
  Canceled = "Canceled",
  Issued = "Issued",
  ReceivedBinderFromCarrier = "ReceivedBinderFromCarrier",
  ReceivedPolicyFromCarrier = "ReceivedPolicyFromCarrier",
}

export enum PremiumBasis {
  Acres = "Acres",
  Admissions = "Admissions",
  Dwellings = "Dwellings",
  Gallons = "Gallons",
  GrossSales = "GrossSales",
  Other = "Other",
  ParksPlaygrounds = "ParksPlaygrounds",
  Payroll = "Payroll",
  SquareFeet = "SquareFeet",
  SubCost = "SubCost",
  SwimmingPools = "SwimmingPools",
  TotalCost = "TotalCost",
  Units = "Units",
}

export enum PrimaryLabelDataType {
  Boolean = "boolean",
  Date = "date",
  Number = "number",
  Percent = "percent",
  String = "string",
}

export type PrimaryLabelFiltersInput = {
  dataType?: InputMaybe<PrimaryLabelDataType>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  primaryKey?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<PrimaryLabelsSortBy>;
  sortOrder?: InputMaybe<Scalars["String"]>;
};

export enum PrimaryLabelsSortBy {
  CreatedAt = "createdAt",
  DisplayName = "displayName",
  PrimaryKey = "primaryKey",
  UpdatedAt = "updatedAt",
}

export enum ProductExclusionStatus {
  Approved = "Approved",
  PendingReview = "PendingReview",
  Rejected = "Rejected",
}

export type ProductExclusionUpdateInput = {
  approvedReason?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  naics?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<ProductExclusionStatus>;
};

export type ProductExclusionWhere = {
  statuses?: InputMaybe<Array<ProductExclusionStatus>>;
};

export type PublicAgentInput = {
  agencyName: Scalars["String"];
  agencyState: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
};

export type PublicCreateOpportunityInput = {
  agentInput: PublicAgentInput;
  clientInput: CreateInsuredInput;
  desiredEffectiveDate: Scalars["DateTime"];
};

export enum QuoteAction {
  Bind = "Bind",
  Deliver = "Deliver",
  Invalidate = "Invalidate",
  ManuallyProcess = "ManuallyProcess",
  ReceiveBindRequest = "ReceiveBindRequest",
  ReceiveNotTaken = "ReceiveNotTaken",
  UploadRawQuote = "UploadRawQuote",
  UploadRedactedQuote = "UploadRedactedQuote",
}

export type QuoteProposalValuesInput = {
  quoteId?: InputMaybe<Scalars["ID"]>;
  submissionId?: InputMaybe<Scalars["ID"]>;
};

export enum QuoteState {
  BindRequestReceived = "BindRequestReceived",
  Bound = "Bound",
  Created = "Created",
  Delivered = "Delivered",
  Invalid = "Invalid",
  NotTaken = "NotTaken",
  Processed = "Processed",
  Redacted = "Redacted",
  Unredacted = "Unredacted",
}

export type QuotesInput = {
  insuredId?: InputMaybe<Scalars["String"]>;
  marketingPlanId?: InputMaybe<Scalars["String"]>;
  submissionId?: InputMaybe<Scalars["String"]>;
};

export enum QwCarrierMatch {
  Competitive = "Competitive",
  None = "None",
  Possible = "Possible",
  Same = "Same",
}

export type ReplaceMarketingPlanSubmissionsInput = {
  appetiteProductIds: Array<Scalars["String"]>;
  marketingPlanId: Scalars["ID"];
};

export type RerunProcessorInput = {
  category: FileProcessorCategory;
  endDate?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  label: Scalars["String"];
  maxFiles?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  parentProcessorCategory: FileProcessorCategory;
  parentProcessorId: Scalars["String"];
  startDate?: InputMaybe<Scalars["DateTime"]>;
};

export enum Roles {
  Arm = "ARM",
  Admin = "Admin",
  Agent = "Agent",
  AlbyEnabled = "AlbyEnabled",
  BindingAuthority = "BindingAuthority",
  Broker = "Broker",
  Brokerage = "Brokerage",
  Dj = "DJ",
  PreferredBroker = "PreferredBroker",
  UnderwritingAssistant = "UnderwritingAssistant",
}

export type SendBindRequestInput = {
  carrierContactInformation?: InputMaybe<Scalars["String"]>;
  outstandingSubjectivities: Scalars["String"];
  quoteId: Scalars["ID"];
  tria: Scalars["String"];
};

export type SendPolicyFileInput = {
  emailComment?: InputMaybe<Scalars["String"]>;
  fileIds?: InputMaybe<Array<Scalars["String"]>>;
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type SendQuoteInput = {
  emailComment?: InputMaybe<Scalars["String"]>;
  fileIds?: InputMaybe<Array<Scalars["String"]>>;
  quoteId?: InputMaybe<Scalars["ID"]>;
};

export type SendSubmissionToCarrierInput = {
  emailComment?: InputMaybe<Scalars["String"]>;
  fileIds?: InputMaybe<Array<Scalars["String"]>>;
  submissionId: Scalars["ID"];
};

export type SnoozeOpportunityInput = {
  id: Scalars["String"];
  snoozeUntil: Scalars["DateTime"];
};

export enum SubmissionAction {
  BlockMarket = "BlockMarket",
  Decline = "Decline",
  Quote = "Quote",
  ReadyToSubmit = "ReadyToSubmit",
  Refer = "Refer",
  Revert = "Revert",
  RuleOut = "RuleOut",
  Submit = "Submit",
}

export enum SubmissionState {
  Declined = "Declined",
  MarketBlocked = "MarketBlocked",
  Pending = "Pending",
  Quoted = "Quoted",
  ReadyToSubmit = "ReadyToSubmit",
  Referred = "Referred",
  Revert = "Revert",
  RuledOut = "RuledOut",
  Submitted = "Submitted",
}

export type SubmissionStateLogExclusionReasonsFilter = {
  appetiteProductId: Scalars["ID"];
};

export type SubmissionsFilterInput = {
  appetiteProductIds?: InputMaybe<Array<Scalars["String"]>>;
  appetiteProductMarketTypes?: InputMaybe<Array<Scalars["String"]>>;
  ids?: InputMaybe<Array<Scalars["String"]>>;
  opportunityId?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<SubmissionsSortBy>;
  states?: InputMaybe<Array<SubmissionState>>;
};

export enum SubmissionsSortBy {
  CreatedAt = "createdAt",
  DesiredEffectiveDate = "desiredEffectiveDate",
  EffectiveDate = "effectiveDate",
  UpdatedAt = "updatedAt",
}

export enum SupplementalForm {
  BuildersRisk = "BuildersRisk",
  ContractorsGeneralLiability = "ContractorsGeneralLiability",
  ContractorsPollutionLiability = "ContractorsPollutionLiability",
  Cyber = "Cyber",
  Daycare = "Daycare",
  Garage = "Garage",
  ResidentialLro = "ResidentialLro",
  SubmissionSupplemental = "SubmissionSupplemental",
}

export type TestAlbyInput = {
  insuredId: Scalars["String"];
  opportunityId: Scalars["String"];
};

export type TransitionMarketingPlanInput = {
  action: MarketingPlanAction;
  expectedState: MarketingPlanState;
  id: Scalars["UUID"];
};

export type TransitionPolicyInput = {
  action: PolicyAction;
  expectedState: PolicyState;
  id: Scalars["UUID"];
};

export type TransitionQuoteInput = {
  action: QuoteAction;
  expectedState: QuoteState;
  id: Scalars["UUID"];
};

export type TransitionSubmissionInput = {
  action: SubmissionAction;
  details?: InputMaybe<Scalars["String"]>;
  expectedState: SubmissionState;
  id: Scalars["UUID"];
};

export type UpdateActionInput = {
  autoExecuteAt?: InputMaybe<Scalars["DateTime"]>;
  executedOperation?: InputMaybe<ActionExecutionOperation>;
  id: Scalars["String"];
  metadata?: InputMaybe<Scalars["String"]>;
};

export type UpdateAgencyInput = {
  agencyId: Scalars["String"];
  bindHqId?: InputMaybe<Scalars["String"]>;
  isPrivate?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["String"]>;
};

export type UpdateAppetiteNoteInput = {
  id: Scalars["String"];
  isoCglCodes: Array<Scalars["String"]>;
  linesOfBusiness: Array<Coverages>;
  note: Scalars["String"];
  states: Array<Scalars["String"]>;
};

export type UpdateAppetiteProductInput = {
  id: Scalars["ID"];
  portalLink?: InputMaybe<Scalars["String"]>;
  submissionInstructions?: InputMaybe<Scalars["String"]>;
};

export type UpdateBindHqAgencyInput = {
  billingContactFirstName?: InputMaybe<Scalars["String"]>;
  billingContactLastName?: InputMaybe<Scalars["String"]>;
  bindHqId: Scalars["String"];
  city: Scalars["String"];
  email: Scalars["String"];
  id: Scalars["String"];
  line1: Scalars["String"];
  line2?: InputMaybe<Scalars["String"]>;
  phone: Scalars["String"];
  state: Scalars["String"];
  zip: Scalars["String"];
};

export type UpdateCarrierContactInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateCommentInput = {
  id: Scalars["UUID"];
  isPublic?: InputMaybe<Scalars["Boolean"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type UpdateFileProcessorVersionInput = {
  processorId: Scalars["String"];
  processorVersionId: Scalars["String"];
};

export type UpdateFileUploadInput = {
  audience?: InputMaybe<File_Audience>;
  filename?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  sha256Checksum?: InputMaybe<Scalars["String"]>;
};

export type UpdateFileUploadTagInput = {
  id: Scalars["String"];
  label: Scalars["String"];
};

export type UpdateInsuredInput = {
  agentId?: InputMaybe<Scalars["UUID"]>;
  bindHqId?: InputMaybe<Scalars["String"]>;
  cglCode?: InputMaybe<Scalars["String"]>;
  dba?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  exposures?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  isoCglCodes?: InputMaybe<Array<Scalars["String"]>>;
  legalEntityType?: InputMaybe<BindHqInsuredType>;
  naicsCode?: InputMaybe<Scalars["String"]>;
  naicsCodes?: InputMaybe<Array<Scalars["String"]>>;
  name?: InputMaybe<Scalars["String"]>;
  primaryState?: InputMaybe<Scalars["String"]>;
  revenue?: InputMaybe<Scalars["Int"]>;
  sicCodes?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdatePolicyInput = {
  awaitingSubjectivities?: InputMaybe<Scalars["Boolean"]>;
  binderFileId?: InputMaybe<Scalars["String"]>;
  endorsementsRequested?: InputMaybe<Scalars["Boolean"]>;
  policyFileId?: InputMaybe<Scalars["String"]>;
  policyId: Scalars["String"];
};

export type UpdatePrimaryLabelInput = {
  dataType?: InputMaybe<PrimaryLabelDataType>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  extractedLabels?: InputMaybe<Array<Scalars["String"]>>;
  id: Scalars["String"];
  primaryKey?: InputMaybe<Scalars["String"]>;
};

export type UpdateSubmissionInput = {
  bindHqUrl?: InputMaybe<Scalars["String"]>;
  cancellationDate?: InputMaybe<Scalars["DateTime"]>;
  coverage?: InputMaybe<Scalars["String"]>;
  declinationReason?: InputMaybe<Scalars["String"]>;
  declinedDueToNaics?: InputMaybe<Scalars["Boolean"]>;
  declinedDueToState?: InputMaybe<Scalars["Boolean"]>;
  effectiveDate?: InputMaybe<Scalars["DateTime"]>;
  expirationDate?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  personalizedEmailSubmissionBody?: InputMaybe<Scalars["String"]>;
  referralReason?: InputMaybe<Scalars["String"]>;
  submissionEmailAttachmentFileIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdateTagInput = {
  childTag?: InputMaybe<Scalars["String"]>;
  label: Scalars["String"];
  parentTag?: InputMaybe<Scalars["String"]>;
};

export type UpdateUserAssignmentsEnabledInput = {
  brokerId: Scalars["String"];
  enabled: Scalars["Boolean"];
};

export type UpdateVerticalInput = {
  description?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  isoCglCodes?: InputMaybe<Array<Scalars["String"]>>;
  name: Scalars["String"];
};

export type UpdateVerticalMarketingPlanTemplateProductRankInput = {
  id: Scalars["String"];
  rank: Scalars["Int"];
};

export type UpdateVerticalMarketingPlanTemplateProductRequirementInput = {
  description?: InputMaybe<Scalars["String"]>;
  groups: Scalars["String"];
  ids: Array<Scalars["String"]>;
  title: Scalars["String"];
};

export type UpdateVerticalMarketingPlanTemplateProductRulesInput = {
  id: Scalars["String"];
  rules: Scalars["String"];
};

export type UpdateVerticalMarketingPlanTemplateProductSubmissionEmailInput = {
  id: Scalars["String"];
  submissionEmail: Scalars["String"];
};

export type UserAccountFindManyFilter = {
  agencyId?: InputMaybe<Scalars["String"]>;
  emailDomainVerified?: InputMaybe<Scalars["Boolean"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  roles?: InputMaybe<Array<Roles>>;
};

export type UserAccountUpdateInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  verifyEmailDomain?: InputMaybe<Scalars["Boolean"]>;
};

export type ActionFragment = {
  __typename?: "Action";
  id: string;
  title: string;
  description?: string | null;
  actionType: ActionType;
  executedOperation: ActionExecutionOperation;
  createdAt: any;
  updatedAt?: any | null;
  opportunityId: string;
  submissionId?: string | null;
  executedAt?: any | null;
  autoExecuteAt?: any | null;
  metadata?: string | null;
  executedBy?: {
    __typename?: "UserAccount";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  appetiteProduct?: {
    __typename?: "AppetiteProduct";
    carrierName: string;
    carrierProductName?: string | null;
    portalLink?: string | null;
  } | null;
};

export type ActionApprovedEventFragment = {
  __typename?: "ActionApprovedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type ActionDismissedEventFragment = {
  __typename?: "ActionDismissedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type ActionRejectedEventFragment = {
  __typename?: "ActionRejectedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type ActionRequestedEventFragment = {
  __typename?: "ActionRequestedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type AgencyFragment = {
  __typename?: "Agency";
  id: string;
  name: string;
  bindHqId?: string | null;
  isPrivate: boolean;
};

export type AgencyAffiliationFragment = {
  __typename?: "AgencyAffiliation";
  name: string;
  shortName?: string | null;
  logoUrl?: string | null;
  bindingCommission?: (string & { __scalar: "Decimal" }) | null;
  brokerageCommission?: (string & { __scalar: "Decimal" }) | null;
};

export type AppetiteOpportunityFragment = {
  __typename?: "Opportunity";
  id: string & { __scalar: "UUID" };
  state: MarketingPlanState;
  selectedLinesOfBusiness: Array<string>;
  desiredEffectiveDate: any;
  renewalOf?: string | null;
  insured: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    name: string;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    description?: string | null;
    agent: {
      __typename?: "UserAccount";
      agencyId: string & { __scalar: "UUID" };
      email: string;
      firstName: string;
      lastName: string;
    };
  };
  broker?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
};

export type AppetiteNoteFragment = {
  __typename?: "AppetiteNote";
  id: string;
  isoCglCodes: Array<string>;
  lobs: Array<Coverages>;
  states: Array<string>;
  note: string;
};

export type AppetiteProductFragment = {
  __typename?: "AppetiteProduct";
  id: string;
  carrierName: string;
  carrierProductName?: string | null;
  marketType?: MarketTypes | null;
  coverages: string;
  endorsements: string;
  coreLines: Array<string>;
  productDescription?: string | null;
  portalLink?: string | null;
  submissionInstructions?: string | null;
  bindHqCarrierIds: Array<string>;
  bindHqMarketingCompanyId?: string | null;
  submissionCarrierContacts?: Array<{
    __typename?: "CarrierContact";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    title?: string | null;
  }> | null;
  referenceCarrierContacts?: Array<{
    __typename?: "CarrierContact";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    title?: string | null;
  }> | null;
};

export type ApplicationSubmittedEventFragment = {
  __typename?: "ApplicationSubmittedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type AssignmentChangeEventFragment = {
  __typename?: "AssignmentChangeEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type AutomatedFollowUpEventFragment = {
  __typename?: "AutomatedFollowUpEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type BindHqAgencyFragment = {
  __typename?: "BindHqAgency";
  id: string;
  name: string;
  phone?: string | null;
  email?: string | null;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  okToBind: boolean;
};

export type BotCommentEventFragment = {
  __typename?: "BotCommentEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type BusinessClassFragment = {
  __typename?: "BusinessClass";
  id?: string | null;
  description?: string | null;
  system: BusinessClassSystem;
  code: string;
};

export type BusinessClassCodesChangedEventFragment = {
  __typename?: "BusinessClassCodesChangedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type CarrierFragment = {
  __typename: "Carrier";
  id: string;
  name: string;
  slug: string;
  pdf: boolean;
  api: boolean;
  portalUrl?: string | null;
  url?: string | null;
  foregroundColor: string;
  backgroundColor: string;
};

export type CarrierContactFragment = {
  __typename?: "CarrierContact";
  id: string & { __scalar: "UUID" };
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  title?: string | null;
};

export type ClassCodeFragment = {
  __typename?: "ClassCode";
  id: string;
  description: string;
  classCode: string;
  classSystem: string;
};

export type ClientDataFragment = {
  __typename?: "ClientData";
  id: string;
  insuredId: string;
  index?: number | null;
  key: string;
  value: string;
  source?: string | null;
};

export type CoveragesChangedEventFragment = {
  __typename?: "CoveragesChangedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type CreatedEventFragment = {
  __typename?: "CreatedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type DesiredEffectiveDateChangeEventFragment = {
  __typename?: "DesiredEffectiveDateChangeEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type EmailEventFragment = {
  __typename?: "EmailEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  email: {
    __typename?: "Email";
    id: string & { __scalar: "UUID" };
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    body: string;
    extractedBody: string;
    dateReceived: any;
    linkToEmailInGoogleGroups?: string | null;
  };
};

export type ExtractedLabelFragment = {
  __typename?: "ExtractedLabel";
  id: string;
  key: string;
  source: string;
  count: string;
  primaryLabels: Array<{ __typename?: "PrimaryLabel"; id: string; primaryKey: string; displayName?: string | null }>;
};

export type FilePipelineVersionFragment = {
  __typename?: "FilePipelineVersion";
  id: string;
  pipeline: {
    __typename?: "FileProcessingPipeline";
    initial: {
      __typename?: "FilePipelineNode";
      id: string;
      category: string;
      name: string;
      startPage?: number | null;
      endPage?: number | null;
    };
    transitions: Array<{
      __typename?: "FilePipelineEdge";
      sourceNodeName: string;
      label: string;
      destinationNodes: Array<{
        __typename?: "FilePipelineNode";
        id: string;
        category: string;
        name: string;
        startPage?: number | null;
        endPage?: number | null;
      }>;
    }>;
  };
};

export type FileProcessingJobFragment = {
  __typename?: "FileProcessingJob";
  id: string;
  status: string;
  pipeline: string;
  fileId: string;
  parentId?: string | null;
  createdAt: any;
  updatedAt?: any | null;
};

export type FileUploadFragment = {
  __typename?: "FileUpload";
  id: string;
  filename: string;
  mimeType: string;
  fileSource?: File_Source | null;
  audience?: string | null;
};

export type FileUploadEventFragment = {
  __typename?: "FileUploadEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type GmailMessageFragment = {
  __typename?: "GmailMessage";
  id: string & { __scalar: "UUID" };
  headerMessageId: string;
  to: string;
  from: string;
  subject: string;
  cc?: string | null;
  replyTo?: string | null;
  textAsHtml: string;
  dateReceived: any;
  attachments: Array<{
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  }>;
};

export type GmailMessageEventFragment = {
  __typename?: "GmailMessageEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  gmailMessage: {
    __typename?: "GmailMessage";
    id: string & { __scalar: "UUID" };
    headerMessageId: string;
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    replyTo?: string | null;
    textAsHtml: string;
    dateReceived: any;
    attachments: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
  };
};

export type GmailRelayEventFragment = {
  __typename?: "GmailRelayEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  gmailRelay: {
    __typename?: "GmailRelay";
    recipientEmail: string;
    initiator: { __typename?: "UserAccount"; email: string };
  };
  gmailMessage: {
    __typename?: "GmailMessage";
    html: string;
    id: string & { __scalar: "UUID" };
    headerMessageId: string;
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    replyTo?: string | null;
    textAsHtml: string;
    dateReceived: any;
    attachments: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
  };
};

export type InsuredFragment = {
  __typename?: "Insured";
  id: string & { __scalar: "UUID" };
  createdAt: any;
  bindHqId?: string | null;
  name: string;
  dba?: string | null;
  legalEntityType?: string | null;
  description?: string | null;
  primaryState?: string | null;
  naicsCodes: Array<string>;
  isoCglCodes: Array<string>;
  sicCodes: Array<string>;
  exposures?: string | null;
  mergedIntoId?: (string & { __scalar: "UUID" }) | null;
  revenue?: number | null;
  tiv?: number | null;
  agentId: string & { __scalar: "UUID" };
  businessClasses: Array<{
    __typename?: "BusinessClass";
    id?: string | null;
    description?: string | null;
    system: BusinessClassSystem;
    code: string;
  }>;
  agent: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
  agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
};

export type InsuredDeepFragment = {
  __typename?: "Insured";
  id: string & { __scalar: "UUID" };
  createdAt: any;
  bindHqId?: string | null;
  name: string;
  dba?: string | null;
  legalEntityType?: string | null;
  description?: string | null;
  primaryState?: string | null;
  naicsCodes: Array<string>;
  isoCglCodes: Array<string>;
  sicCodes: Array<string>;
  exposures?: string | null;
  mergedIntoId?: (string & { __scalar: "UUID" }) | null;
  revenue?: number | null;
  tiv?: number | null;
  agentId: string & { __scalar: "UUID" };
  opportunities: Array<{
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    updatedAt?: any | null;
    selectedLinesOfBusiness: Array<string>;
    desiredEffectiveDate: any;
    state: MarketingPlanState;
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  }>;
  businessClasses: Array<{
    __typename?: "BusinessClass";
    id?: string | null;
    description?: string | null;
    system: BusinessClassSystem;
    code: string;
  }>;
  agent: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
  agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
};

export type InsuredHazardFragment = {
  __typename?: "InsuredHazard";
  id: string;
  hazardNumber: number;
  locationId?: string | null;
  locationNumber: number;
  cgl?: string | null;
  premiumBasis?: PremiumBasis | null;
  exposure?: string | null;
  description?: string | null;
  indications: Array<{
    __typename?: "InsuredHazardIndication";
    carrierName: string;
    lob: string;
    minRate?: (string & { __scalar: "Decimal" }) | null;
    maxRate?: (string & { __scalar: "Decimal" }) | null;
    minPremium?: (string & { __scalar: "Decimal" }) | null;
    maxPremium?: (string & { __scalar: "Decimal" }) | null;
  }>;
};

export type InsuredPremiseBuildingFragment = {
  __typename?: "InsuredPremiseBuilding";
  id: string;
  locationId: string;
  buildingNumber: number;
  description?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  fullTimeEmployeeCount?: number | null;
  partTimeEmployeeCount?: number | null;
  annualRevenue?: (string & { __scalar: "Decimal" }) | null;
  occupiedArea?: number | null;
  totalBuildingArea?: number | null;
  wiringYear?: string | null;
  roofingYear?: string | null;
  heatingYear?: string | null;
  plumbingYear?: string | null;
  tiv?: number | null;
  roofType?: string | null;
  constructionType?: string | null;
  yearBuilt?: string | null;
  burglarAlarmType?: string | null;
  sprinklerPercent?: string | null;
};

export type InsuredPremiseLocationFragment = {
  __typename?: "InsuredPremiseLocation";
  id: string;
  locationNumber: number;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  county?: string | null;
  violentCrimeScore?: (string & { __scalar: "Decimal" }) | null;
  propertyCrimeScore?: (string & { __scalar: "Decimal" }) | null;
  buildings: Array<{
    __typename?: "InsuredPremiseBuilding";
    id: string;
    locationId: string;
    buildingNumber: number;
    description?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    fullTimeEmployeeCount?: number | null;
    partTimeEmployeeCount?: number | null;
    annualRevenue?: (string & { __scalar: "Decimal" }) | null;
    occupiedArea?: number | null;
    totalBuildingArea?: number | null;
    wiringYear?: string | null;
    roofingYear?: string | null;
    heatingYear?: string | null;
    plumbingYear?: string | null;
    tiv?: number | null;
    roofType?: string | null;
    constructionType?: string | null;
    yearBuilt?: string | null;
    burglarAlarmType?: string | null;
    sprinklerPercent?: string | null;
  }>;
};

export type LeadFragment = {
  __typename?: "Lead";
  id: string & { __scalar: "UUID" };
  accountName: string;
  effectiveDate?: any | null;
  expirationDate: any;
  rawLines: Array<string>;
  coreLines: Array<string>;
  carriers: Array<string>;
  carrierOverride?: string | null;
  isQwCarrier?: boolean | null;
  qwCarrierMatch?: QwCarrierMatch | null;
  premium?: (string & { __scalar: "Decimal" }) | null;
  description?: string | null;
  naicsCode?: string | null;
  agentFirstName?: string | null;
  agentLastName?: string | null;
  archived: boolean;
  highlighted: boolean;
  notes?: string | null;
  desireToRemarket?: DesireToRemarket | null;
  currentAgentCommission?: (string & { __scalar: "Decimal" }) | null;
  qwAgentCommission?: (string & { __scalar: "Decimal" }) | null;
  agencyId: string & { __scalar: "UUID" };
  opportunityId?: (string & { __scalar: "UUID" }) | null;
  topAppetiteProductScore?: (string & { __scalar: "Decimal" }) | null;
};

export type LineOfBusinessFragment = { __typename?: "LineOfBusiness"; id: string; displayName: string };

export type MarketingPlanStateTransitionFragment = {
  __typename?: "MarketingPlanStateTransition";
  id: string;
  marketingPlanId: string;
  previousState: MarketingPlanState;
  state: MarketingPlanState;
  createdAt: any;
  originatorId: string;
};

export type NotificationFragment = {
  __typename?: "Notification";
  id: string;
  createdAt: any;
  readAt?: any | null;
  event:
    | {
        __typename: "ActionApprovedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "ActionDismissedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "ActionRejectedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "ActionRequestedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "AlbyUpdateEvent";
        type: OpportunityEventType;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "ApplicationSubmittedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "AssignmentChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "AutomatedFollowUpEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "BotCommentEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "BusinessClassCodesChangedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "CoveragesChangedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "CreatedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "DesiredEffectiveDateChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "EmailEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
        email: {
          __typename?: "Email";
          id: string & { __scalar: "UUID" };
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          body: string;
          extractedBody: string;
          dateReceived: any;
          linkToEmailInGoogleGroups?: string | null;
        };
      }
    | {
        __typename: "FileUploadEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "GmailMessageEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
        gmailMessage: {
          __typename?: "GmailMessage";
          id: string & { __scalar: "UUID" };
          headerMessageId: string;
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          replyTo?: string | null;
          textAsHtml: string;
          dateReceived: any;
          attachments: Array<{
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          }>;
        };
      }
    | {
        __typename: "GmailRelayEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
        gmailRelay: {
          __typename?: "GmailRelay";
          recipientEmail: string;
          initiator: { __typename?: "UserAccount"; email: string };
        };
        gmailMessage: {
          __typename?: "GmailMessage";
          html: string;
          id: string & { __scalar: "UUID" };
          headerMessageId: string;
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          replyTo?: string | null;
          textAsHtml: string;
          dateReceived: any;
          attachments: Array<{
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          }>;
        };
      }
    | {
        __typename: "OutcomeChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "ProductsAddedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "ProductsRemovedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "RequirementAddedEvent";
        type: OpportunityEventType;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "StatusChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "SubmissionStatusChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "TriageAssignmentEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
      }
    | {
        __typename: "UserComment";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        createdAt: any;
        updatedAt?: any | null;
        isPublic: boolean;
        userAccountId: string & { __scalar: "UUID" };
        opportunityId: string & { __scalar: "UUID" };
        opportunity: {
          __typename?: "Opportunity";
          id: string & { __scalar: "UUID" };
          insuredId: string & { __scalar: "UUID" };
          insured: {
            __typename?: "Insured";
            name: string;
            agent: {
              __typename: "UserAccount";
              id: string & { __scalar: "UUID" };
              createdAt: any;
              bindHqId?: string | null;
              firstName: string;
              lastName: string;
              email: string;
              roles: Array<Roles>;
              internal: boolean;
              agencyId: string & { __scalar: "UUID" };
              assignmentsEnabled: boolean;
              avatarUrl?: string | null;
              phone?: string | null;
              agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
            };
          };
          broker?: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          } | null;
        };
        userAccount: {
          __typename: "UserAccount";
          id: string & { __scalar: "UUID" };
          createdAt: any;
          bindHqId?: string | null;
          firstName: string;
          lastName: string;
          email: string;
          roles: Array<Roles>;
          internal: boolean;
          agencyId: string & { __scalar: "UUID" };
          assignmentsEnabled: boolean;
          avatarUrl?: string | null;
          phone?: string | null;
          agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
        };
      };
};

type NotificationOpportunityEvent_ActionApprovedEvent_Fragment = {
  __typename: "ActionApprovedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_ActionDismissedEvent_Fragment = {
  __typename: "ActionDismissedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_ActionRejectedEvent_Fragment = {
  __typename: "ActionRejectedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_ActionRequestedEvent_Fragment = {
  __typename: "ActionRequestedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_AlbyUpdateEvent_Fragment = {
  __typename: "AlbyUpdateEvent";
  type: OpportunityEventType;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_ApplicationSubmittedEvent_Fragment = {
  __typename: "ApplicationSubmittedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_AssignmentChangeEvent_Fragment = {
  __typename: "AssignmentChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_AutomatedFollowUpEvent_Fragment = {
  __typename: "AutomatedFollowUpEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_BotCommentEvent_Fragment = {
  __typename: "BotCommentEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_BusinessClassCodesChangedEvent_Fragment = {
  __typename: "BusinessClassCodesChangedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_CoveragesChangedEvent_Fragment = {
  __typename: "CoveragesChangedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_CreatedEvent_Fragment = {
  __typename: "CreatedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_DesiredEffectiveDateChangeEvent_Fragment = {
  __typename: "DesiredEffectiveDateChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_EmailEvent_Fragment = {
  __typename: "EmailEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
  email: {
    __typename?: "Email";
    id: string & { __scalar: "UUID" };
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    body: string;
    extractedBody: string;
    dateReceived: any;
    linkToEmailInGoogleGroups?: string | null;
  };
};

type NotificationOpportunityEvent_FileUploadEvent_Fragment = {
  __typename: "FileUploadEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_GmailMessageEvent_Fragment = {
  __typename: "GmailMessageEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
  gmailMessage: {
    __typename?: "GmailMessage";
    id: string & { __scalar: "UUID" };
    headerMessageId: string;
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    replyTo?: string | null;
    textAsHtml: string;
    dateReceived: any;
    attachments: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
  };
};

type NotificationOpportunityEvent_GmailRelayEvent_Fragment = {
  __typename: "GmailRelayEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
  gmailRelay: {
    __typename?: "GmailRelay";
    recipientEmail: string;
    initiator: { __typename?: "UserAccount"; email: string };
  };
  gmailMessage: {
    __typename?: "GmailMessage";
    html: string;
    id: string & { __scalar: "UUID" };
    headerMessageId: string;
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    replyTo?: string | null;
    textAsHtml: string;
    dateReceived: any;
    attachments: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
  };
};

type NotificationOpportunityEvent_OutcomeChangeEvent_Fragment = {
  __typename: "OutcomeChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_ProductsAddedEvent_Fragment = {
  __typename: "ProductsAddedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_ProductsRemovedEvent_Fragment = {
  __typename: "ProductsRemovedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_RequirementAddedEvent_Fragment = {
  __typename: "RequirementAddedEvent";
  type: OpportunityEventType;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_StatusChangeEvent_Fragment = {
  __typename: "StatusChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_SubmissionStatusChangeEvent_Fragment = {
  __typename: "SubmissionStatusChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_TriageAssignmentEvent_Fragment = {
  __typename: "TriageAssignmentEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

type NotificationOpportunityEvent_UserComment_Fragment = {
  __typename: "UserComment";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  createdAt: any;
  updatedAt?: any | null;
  isPublic: boolean;
  userAccountId: string & { __scalar: "UUID" };
  opportunityId: string & { __scalar: "UUID" };
  opportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
    insured: {
      __typename?: "Insured";
      name: string;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    };
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
  userAccount: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
};

export type NotificationOpportunityEventFragment =
  | NotificationOpportunityEvent_ActionApprovedEvent_Fragment
  | NotificationOpportunityEvent_ActionDismissedEvent_Fragment
  | NotificationOpportunityEvent_ActionRejectedEvent_Fragment
  | NotificationOpportunityEvent_ActionRequestedEvent_Fragment
  | NotificationOpportunityEvent_AlbyUpdateEvent_Fragment
  | NotificationOpportunityEvent_ApplicationSubmittedEvent_Fragment
  | NotificationOpportunityEvent_AssignmentChangeEvent_Fragment
  | NotificationOpportunityEvent_AutomatedFollowUpEvent_Fragment
  | NotificationOpportunityEvent_BotCommentEvent_Fragment
  | NotificationOpportunityEvent_BusinessClassCodesChangedEvent_Fragment
  | NotificationOpportunityEvent_CoveragesChangedEvent_Fragment
  | NotificationOpportunityEvent_CreatedEvent_Fragment
  | NotificationOpportunityEvent_DesiredEffectiveDateChangeEvent_Fragment
  | NotificationOpportunityEvent_EmailEvent_Fragment
  | NotificationOpportunityEvent_FileUploadEvent_Fragment
  | NotificationOpportunityEvent_GmailMessageEvent_Fragment
  | NotificationOpportunityEvent_GmailRelayEvent_Fragment
  | NotificationOpportunityEvent_OutcomeChangeEvent_Fragment
  | NotificationOpportunityEvent_ProductsAddedEvent_Fragment
  | NotificationOpportunityEvent_ProductsRemovedEvent_Fragment
  | NotificationOpportunityEvent_RequirementAddedEvent_Fragment
  | NotificationOpportunityEvent_StatusChangeEvent_Fragment
  | NotificationOpportunityEvent_SubmissionStatusChangeEvent_Fragment
  | NotificationOpportunityEvent_TriageAssignmentEvent_Fragment
  | NotificationOpportunityEvent_UserComment_Fragment;

export type OpportunityDetailsFragment = {
  __typename?: "Opportunity";
  id: string & { __scalar: "UUID" };
  isTest: boolean;
  state: MarketingPlanState;
  createdAt: any;
  insuredId: string & { __scalar: "UUID" };
  updatedAt?: any | null;
  selectedLinesOfBusiness: Array<string>;
  desiredEffectiveDate: any;
  brokerageFolderId?: string | null;
  renewalOf?: string | null;
  createdBy: { __typename?: "UserAccount"; id: string & { __scalar: "UUID" }; firstName: string; lastName: string };
  updatedBy?: {
    __typename?: "UserAccount";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
  } | null;
  broker?: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  } | null;
  submissions: Array<{
    __typename?: "Submission";
    id: string & { __scalar: "UUID" };
    opportunityId: string;
    appetiteProductId: string;
    bindHqApplicationId?: string | null;
    state: SubmissionState;
    createdAt: any;
    updatedAt?: any | null;
    lastTransitionAt: any;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
    quotes: Array<{
      __typename?: "Quote";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      submissionId: string;
      state: QuoteState;
      premium?: number | null;
      totalPremium?: number | null;
      mep?: number | null;
      agentCommission?: number | null;
      grossCommission?: number | null;
      carrierFee?: number | null;
      brokerFee?: number | null;
      inspectionFee?: number | null;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      outstandingSubjectivities?: string | null;
      redactedQuoteId?: string | null;
      processedQuoteId?: string | null;
      redactedQuote?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedQuote?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      policy?: {
        __typename?: "Policy";
        id: string & { __scalar: "UUID" };
        insuredId: string;
        opportunityId: string;
        quoteId: string;
        state: PolicyState;
        policyNumber: string;
        effectiveDate?: any | null;
        expirationDate?: any | null;
        createdAt: any;
        lastTransitionAt: any;
        awaitingSubjectivities: boolean;
        endorsementsRequested: boolean;
        policyFileId?: string | null;
        redactedBinderFileId?: string | null;
        processedBinderFileId?: string | null;
        bindHqPolicyId?: string | null;
        policyFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        redactedBinderFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        processedBinderFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        stateTransitions: Array<{
          __typename?: "PolicyStateTransition";
          id: string;
          policyId: string;
          previousState: PolicyState;
          state: PolicyState;
          createdAt: any;
          originatorId: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      } | null;
      stateTransitions: Array<{
        __typename?: "QuoteStateTransition";
        id: string;
        quoteId: string;
        previousState: QuoteState;
        state: QuoteState;
        createdAt: any;
        originatorId: string;
      }>;
      submission: {
        __typename?: "Submission";
        id: string & { __scalar: "UUID" };
        opportunityId: string;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      };
    }>;
    stateTransitions: Array<{
      __typename?: "SubmissionStateTransition";
      id: string;
      submissionId: string;
      previousState: SubmissionState;
      details?: string | null;
      state: SubmissionState;
      createdAt: any;
      originatorId: string;
    }>;
    exclusionReason?: {
      __typename?: "ProductExclusion";
      id: string;
      appetiteProductId: string;
      reason?: string | null;
      state?: string | null;
      naicsCode?: string | null;
    } | null;
  }>;
  appetiteProducts: Array<{
    __typename?: "AppetiteProduct";
    id: string;
    marketType?: MarketTypes | null;
    carrierName: string;
    carrierProductName?: string | null;
  }>;
  renewal?: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } } | null;
  insured: {
    __typename?: "Insured";
    brokerageFolderId?: string | null;
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  };
  files: Array<{
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  }>;
  leads: Array<{
    __typename?: "Lead";
    id: string & { __scalar: "UUID" };
    accountName: string;
    effectiveDate?: any | null;
    expirationDate: any;
    rawLines: Array<string>;
    coreLines: Array<string>;
    carriers: Array<string>;
    carrierOverride?: string | null;
    isQwCarrier?: boolean | null;
    qwCarrierMatch?: QwCarrierMatch | null;
    premium?: (string & { __scalar: "Decimal" }) | null;
    description?: string | null;
    naicsCode?: string | null;
    agentFirstName?: string | null;
    agentLastName?: string | null;
    archived: boolean;
    highlighted: boolean;
    notes?: string | null;
    desireToRemarket?: DesireToRemarket | null;
    currentAgentCommission?: (string & { __scalar: "Decimal" }) | null;
    qwAgentCommission?: (string & { __scalar: "Decimal" }) | null;
    agencyId: string & { __scalar: "UUID" };
    opportunityId?: (string & { __scalar: "UUID" }) | null;
    topAppetiteProductScore?: (string & { __scalar: "Decimal" }) | null;
  }>;
  notes: Array<{
    __typename?: "OpportunityNote";
    id: string;
    createdAt: any;
    text: string;
    creator: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
  }>;
  stateTransitions: Array<{
    __typename?: "MarketingPlanStateTransition";
    id: string;
    marketingPlanId: string;
    previousState: MarketingPlanState;
    state: MarketingPlanState;
    createdAt: any;
    originatorId: string;
  }>;
  verticalMarketingPlanTemplate?: {
    __typename?: "VerticalMarketingPlanTemplate";
    products: Array<{
      __typename?: "VerticalMarketingPlanTemplateProduct";
      rank: number;
      rules: string;
      appetiteProduct: { __typename?: "AppetiteProduct"; id: string };
    }>;
  } | null;
};

type OpportunityEvent_ActionApprovedEvent_Fragment = {
  __typename: "ActionApprovedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_ActionDismissedEvent_Fragment = {
  __typename: "ActionDismissedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_ActionRejectedEvent_Fragment = {
  __typename: "ActionRejectedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_ActionRequestedEvent_Fragment = {
  __typename: "ActionRequestedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_AlbyUpdateEvent_Fragment = {
  __typename: "AlbyUpdateEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_ApplicationSubmittedEvent_Fragment = {
  __typename: "ApplicationSubmittedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_AssignmentChangeEvent_Fragment = {
  __typename: "AssignmentChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_AutomatedFollowUpEvent_Fragment = {
  __typename: "AutomatedFollowUpEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_BotCommentEvent_Fragment = {
  __typename: "BotCommentEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_BusinessClassCodesChangedEvent_Fragment = {
  __typename: "BusinessClassCodesChangedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_CoveragesChangedEvent_Fragment = {
  __typename: "CoveragesChangedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_CreatedEvent_Fragment = {
  __typename: "CreatedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_DesiredEffectiveDateChangeEvent_Fragment = {
  __typename: "DesiredEffectiveDateChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_EmailEvent_Fragment = {
  __typename: "EmailEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  email: {
    __typename?: "Email";
    id: string & { __scalar: "UUID" };
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    body: string;
    extractedBody: string;
    dateReceived: any;
    linkToEmailInGoogleGroups?: string | null;
  };
};

type OpportunityEvent_FileUploadEvent_Fragment = {
  __typename: "FileUploadEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_GmailMessageEvent_Fragment = {
  __typename: "GmailMessageEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  gmailMessage: {
    __typename?: "GmailMessage";
    id: string & { __scalar: "UUID" };
    headerMessageId: string;
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    replyTo?: string | null;
    textAsHtml: string;
    dateReceived: any;
    attachments: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
  };
};

type OpportunityEvent_GmailRelayEvent_Fragment = {
  __typename: "GmailRelayEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
  gmailRelay: {
    __typename?: "GmailRelay";
    recipientEmail: string;
    initiator: { __typename?: "UserAccount"; email: string };
  };
  gmailMessage: {
    __typename?: "GmailMessage";
    html: string;
    id: string & { __scalar: "UUID" };
    headerMessageId: string;
    to: string;
    from: string;
    subject: string;
    cc?: string | null;
    replyTo?: string | null;
    textAsHtml: string;
    dateReceived: any;
    attachments: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
  };
};

type OpportunityEvent_OutcomeChangeEvent_Fragment = {
  __typename: "OutcomeChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_ProductsAddedEvent_Fragment = {
  __typename: "ProductsAddedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_ProductsRemovedEvent_Fragment = {
  __typename: "ProductsRemovedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_RequirementAddedEvent_Fragment = {
  __typename: "RequirementAddedEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_StatusChangeEvent_Fragment = {
  __typename: "StatusChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_SubmissionStatusChangeEvent_Fragment = {
  __typename: "SubmissionStatusChangeEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_TriageAssignmentEvent_Fragment = {
  __typename: "TriageAssignmentEvent";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

type OpportunityEvent_UserComment_Fragment = {
  __typename: "UserComment";
  type: OpportunityEventType;
  id: string & { __scalar: "UUID" };
  text: string;
  createdAt: any;
  updatedAt?: any | null;
  isPublic: boolean;
  userAccountId: string & { __scalar: "UUID" };
  opportunityId: string & { __scalar: "UUID" };
  userAccount: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
};

export type OpportunityEventFragment =
  | OpportunityEvent_ActionApprovedEvent_Fragment
  | OpportunityEvent_ActionDismissedEvent_Fragment
  | OpportunityEvent_ActionRejectedEvent_Fragment
  | OpportunityEvent_ActionRequestedEvent_Fragment
  | OpportunityEvent_AlbyUpdateEvent_Fragment
  | OpportunityEvent_ApplicationSubmittedEvent_Fragment
  | OpportunityEvent_AssignmentChangeEvent_Fragment
  | OpportunityEvent_AutomatedFollowUpEvent_Fragment
  | OpportunityEvent_BotCommentEvent_Fragment
  | OpportunityEvent_BusinessClassCodesChangedEvent_Fragment
  | OpportunityEvent_CoveragesChangedEvent_Fragment
  | OpportunityEvent_CreatedEvent_Fragment
  | OpportunityEvent_DesiredEffectiveDateChangeEvent_Fragment
  | OpportunityEvent_EmailEvent_Fragment
  | OpportunityEvent_FileUploadEvent_Fragment
  | OpportunityEvent_GmailMessageEvent_Fragment
  | OpportunityEvent_GmailRelayEvent_Fragment
  | OpportunityEvent_OutcomeChangeEvent_Fragment
  | OpportunityEvent_ProductsAddedEvent_Fragment
  | OpportunityEvent_ProductsRemovedEvent_Fragment
  | OpportunityEvent_RequirementAddedEvent_Fragment
  | OpportunityEvent_StatusChangeEvent_Fragment
  | OpportunityEvent_SubmissionStatusChangeEvent_Fragment
  | OpportunityEvent_TriageAssignmentEvent_Fragment
  | OpportunityEvent_UserComment_Fragment;

export type OutcomeChangeEventFragment = {
  __typename?: "OutcomeChangeEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type PolicyFragment = {
  __typename?: "Policy";
  id: string & { __scalar: "UUID" };
  insuredId: string;
  opportunityId: string;
  quoteId: string;
  state: PolicyState;
  policyNumber: string;
  effectiveDate?: any | null;
  expirationDate?: any | null;
  createdAt: any;
  lastTransitionAt: any;
  awaitingSubjectivities: boolean;
  endorsementsRequested: boolean;
  policyFileId?: string | null;
  redactedBinderFileId?: string | null;
  processedBinderFileId?: string | null;
  bindHqPolicyId?: string | null;
  policyFile?: {
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  } | null;
  redactedBinderFile?: {
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  } | null;
  processedBinderFile?: {
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  } | null;
  stateTransitions: Array<{
    __typename?: "PolicyStateTransition";
    id: string;
    policyId: string;
    previousState: PolicyState;
    state: PolicyState;
    createdAt: any;
    originatorId: string;
  }>;
  appetiteProduct: {
    __typename?: "AppetiteProduct";
    id: string;
    carrierName: string;
    carrierProductName?: string | null;
    marketType?: MarketTypes | null;
    coverages: string;
    endorsements: string;
    coreLines: Array<string>;
    productDescription?: string | null;
    portalLink?: string | null;
    submissionInstructions?: string | null;
    bindHqCarrierIds: Array<string>;
    bindHqMarketingCompanyId?: string | null;
    submissionCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
    referenceCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
  };
};

export type PolicyStateTransitionFragment = {
  __typename?: "PolicyStateTransition";
  id: string;
  policyId: string;
  previousState: PolicyState;
  state: PolicyState;
  createdAt: any;
  originatorId: string;
};

export type PrimaryLabelFragment = {
  __typename?: "PrimaryLabel";
  id: string;
  primaryKey: string;
  displayName?: string | null;
  dataType?: PrimaryLabelDataType | null;
  description?: string | null;
  createdAt: any;
  updatedAt?: any | null;
  createdById?: string | null;
  updatedById?: string | null;
  extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
  createdBy?: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  } | null;
  updatedBy?: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  } | null;
};

export type ProductExclusionFragment = {
  __typename?: "ProductExclusion";
  id: string;
  appetiteProductId: string;
  reason?: string | null;
  state?: string | null;
  naicsCode?: string | null;
};

export type ProductsAddedEventFragment = {
  __typename?: "ProductsAddedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type ProductsRemovedEventFragment = {
  __typename?: "ProductsRemovedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type QuoteFragment = {
  __typename?: "Quote";
  id: string & { __scalar: "UUID" };
  insuredId: string;
  submissionId: string;
  state: QuoteState;
  premium?: number | null;
  totalPremium?: number | null;
  mep?: number | null;
  agentCommission?: number | null;
  grossCommission?: number | null;
  carrierFee?: number | null;
  brokerFee?: number | null;
  inspectionFee?: number | null;
  effectiveDate?: any | null;
  expirationDate?: any | null;
  createdAt: any;
  lastTransitionAt: any;
  outstandingSubjectivities?: string | null;
  redactedQuoteId?: string | null;
  processedQuoteId?: string | null;
  redactedQuote?: {
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  } | null;
  processedQuote?: {
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  } | null;
  policy?: {
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  } | null;
  stateTransitions: Array<{
    __typename?: "QuoteStateTransition";
    id: string;
    quoteId: string;
    previousState: QuoteState;
    state: QuoteState;
    createdAt: any;
    originatorId: string;
  }>;
  submission: {
    __typename?: "Submission";
    id: string & { __scalar: "UUID" };
    opportunityId: string;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  };
};

export type QuoteKitchenSinkFragment = {
  __typename?: "Quote";
  id: string & { __scalar: "UUID" };
  insuredId: string;
  submissionId: string;
  state: QuoteState;
  premium?: number | null;
  mep?: number | null;
  agentCommission?: number | null;
  grossCommission?: number | null;
  carrierFee?: number | null;
  brokerFee?: number | null;
  inspectionFee?: number | null;
  effectiveDate?: any | null;
  expirationDate?: any | null;
  createdAt: any;
  lastTransitionAt: any;
  redactedQuoteId?: string | null;
  processedQuoteId?: string | null;
  redactedQuote?: {
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  } | null;
  processedQuote?: {
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  } | null;
  policy?: {
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  } | null;
  stateTransitions: Array<{
    __typename?: "QuoteStateTransition";
    id: string;
    quoteId: string;
    previousState: QuoteState;
    state: QuoteState;
    createdAt: any;
    originatorId: string;
  }>;
  submission: {
    __typename?: "Submission";
    id: string & { __scalar: "UUID" };
    state: SubmissionState;
    createdAt: any;
    opportunityId: string;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
    opportunity: {
      __typename?: "Opportunity";
      id: string & { __scalar: "UUID" };
      isTest: boolean;
      state: MarketingPlanState;
      createdAt: any;
      selectedLinesOfBusiness: Array<string>;
      desiredEffectiveDate: any;
      difficulties?: string | null;
      brokerageFolderId?: string | null;
      renewalOf?: string | null;
      insured: {
        __typename?: "Insured";
        agent: {
          __typename: "UserAccount";
          id: string & { __scalar: "UUID" };
          createdAt: any;
          bindHqId?: string | null;
          firstName: string;
          lastName: string;
          email: string;
          roles: Array<Roles>;
          internal: boolean;
          agencyId: string & { __scalar: "UUID" };
          assignmentsEnabled: boolean;
          avatarUrl?: string | null;
          phone?: string | null;
          agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
        };
      };
      broker?: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      } | null;
    };
  };
};

export type QuoteStateTransitionFragment = {
  __typename?: "QuoteStateTransition";
  id: string;
  quoteId: string;
  previousState: QuoteState;
  state: QuoteState;
  createdAt: any;
  originatorId: string;
};

export type QuoteSubmissionFragment = {
  __typename?: "Submission";
  id: string & { __scalar: "UUID" };
  state: SubmissionState;
  exclusionReason?: {
    __typename?: "ProductExclusion";
    id: string;
    appetiteProductId: string;
    reason?: string | null;
    state?: string | null;
    naicsCode?: string | null;
  } | null;
  appetiteProduct: {
    __typename?: "AppetiteProduct";
    id: string;
    carrierName: string;
    carrierProductName?: string | null;
  };
};

export type RequirementAddedEventFragment = {
  __typename?: "RequirementAddedEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type ScoredAppetiteProductFragment = {
  __typename?: "ScoredAppetiteProduct";
  score: number;
  reasons?: Array<string> | null;
  previouslyMarketedCount: number;
  previouslyQuotedCount: number;
  product: {
    __typename?: "AppetiteProduct";
    id: string;
    carrierName: string;
    carrierSlug?: string | null;
    carrierProductName?: string | null;
    productDescription?: string | null;
    coverages: string;
    coreLines: Array<string>;
  };
};

export type MarketingPlanStateTransitionFragmentFragment = {
  __typename?: "MarketingPlanStateTransition";
  id: string;
  marketingPlanId: string;
  originatorId: string;
  previousState: MarketingPlanState;
  state: MarketingPlanState;
  createdAt: any;
};

export type SubmissionStateTransitionFragmentFragment = {
  __typename?: "SubmissionStateTransition";
  id: string;
  submissionId: string;
  originatorId: string;
  previousState: SubmissionState;
  state: SubmissionState;
  createdAt: any;
};

export type QuoteStateTransitionFragmentFragment = {
  __typename?: "QuoteStateTransition";
  id: string;
  quoteId: string;
  originatorId: string;
  previousState: QuoteState;
  state: QuoteState;
  createdAt: any;
};

export type PolicyStateTransitionFragmentFragment = {
  __typename?: "PolicyStateTransition";
  id: string;
  policyId: string;
  originatorId: string;
  previousState: PolicyState;
  state: PolicyState;
  createdAt: any;
};

export type SlimAppetiteProductFragmentFragment = {
  __typename?: "AppetiteProduct";
  id: string;
  carrierName: string;
  carrierProductName?: string | null;
  marketType?: MarketTypes | null;
};

export type StatusChangeEventFragment = {
  __typename?: "StatusChangeEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type SubmissionFragment = {
  __typename?: "Submission";
  id: string & { __scalar: "UUID" };
  opportunityId: string;
  appetiteProductId: string;
  bindHqApplicationId?: string | null;
  state: SubmissionState;
  createdAt: any;
  updatedAt?: any | null;
  lastTransitionAt: any;
  appetiteProduct: {
    __typename?: "AppetiteProduct";
    id: string;
    carrierName: string;
    carrierProductName?: string | null;
    marketType?: MarketTypes | null;
    coverages: string;
    endorsements: string;
    coreLines: Array<string>;
    productDescription?: string | null;
    portalLink?: string | null;
    submissionInstructions?: string | null;
    bindHqCarrierIds: Array<string>;
    bindHqMarketingCompanyId?: string | null;
    submissionCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
    referenceCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
  };
  quotes: Array<{
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    totalPremium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    outstandingSubjectivities?: string | null;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    };
  }>;
  stateTransitions: Array<{
    __typename?: "SubmissionStateTransition";
    id: string;
    submissionId: string;
    previousState: SubmissionState;
    details?: string | null;
    state: SubmissionState;
    createdAt: any;
    originatorId: string;
  }>;
};

export type SubmissionDetailsFragment = {
  __typename?: "Submission";
  id: string & { __scalar: "UUID" };
  opportunityId: string;
  appetiteProductId: string;
  bindHqApplicationId?: string | null;
  state: SubmissionState;
  createdAt: any;
  updatedAt?: any | null;
  lastTransitionAt: any;
  quotes: Array<{
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    totalPremium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    outstandingSubjectivities?: string | null;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    };
  }>;
  appetiteProduct: {
    __typename?: "AppetiteProduct";
    id: string;
    carrierName: string;
    carrierProductName?: string | null;
    marketType?: MarketTypes | null;
    coverages: string;
    endorsements: string;
    coreLines: Array<string>;
    productDescription?: string | null;
    portalLink?: string | null;
    submissionInstructions?: string | null;
    bindHqCarrierIds: Array<string>;
    bindHqMarketingCompanyId?: string | null;
    submissionCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
    referenceCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
  };
  stateTransitions: Array<{
    __typename?: "SubmissionStateTransition";
    id: string;
    submissionId: string;
    previousState: SubmissionState;
    details?: string | null;
    state: SubmissionState;
    createdAt: any;
    originatorId: string;
  }>;
};

export type SubmissionStateTransitionFragment = {
  __typename?: "SubmissionStateTransition";
  id: string;
  submissionId: string;
  previousState: SubmissionState;
  details?: string | null;
  state: SubmissionState;
  createdAt: any;
  originatorId: string;
};

export type SubmissionStatusChangeEventFragment = {
  __typename?: "SubmissionStatusChangeEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type AlbyUpdateEventFragment = {
  __typename?: "AlbyUpdateEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type SubmissionResponseFragment = {
  __typename?: "SubmissionResponse";
  submissions: Array<{
    __typename?: "Submission";
    id: string & { __scalar: "UUID" };
    state: SubmissionState;
    personalizedEmailSubmissionBody?: string | null;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      carrierName: string;
      carrierProductName?: string | null;
      submissionCarrierContacts?: Array<{ __typename?: "CarrierContact"; email: string }> | null;
    };
    opportunity: {
      __typename?: "Opportunity";
      id: string & { __scalar: "UUID" };
      state: MarketingPlanState;
      desiredEffectiveDate: any;
      insured: { __typename?: "Insured"; name: string };
      broker?: { __typename?: "UserAccount"; firstName: string; lastName: string; email: string } | null;
    };
  }>;
};

export type TriageAssignmentEventFragment = {
  __typename?: "TriageAssignmentEvent";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  opportunityId: string & { __scalar: "UUID" };
  createdAt: any;
  isPublic: boolean;
};

export type UserAccountFragment = {
  __typename: "UserAccount";
  id: string & { __scalar: "UUID" };
  createdAt: any;
  bindHqId?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  roles: Array<Roles>;
  internal: boolean;
  agencyId: string & { __scalar: "UUID" };
  assignmentsEnabled: boolean;
  avatarUrl?: string | null;
  phone?: string | null;
  agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
};

export type UserCommentFragment = {
  __typename?: "UserComment";
  id: string & { __scalar: "UUID" };
  type: OpportunityEventType;
  text: string;
  createdAt: any;
  updatedAt?: any | null;
  isPublic: boolean;
  userAccountId: string & { __scalar: "UUID" };
  opportunityId: string & { __scalar: "UUID" };
  userAccount: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
};

export type VerticalFragment = {
  __typename?: "Vertical";
  id: string;
  name: string;
  description?: string | null;
  enabled: boolean;
  isoCglCodes: Array<{
    __typename?: "ClassCode";
    id: string;
    description: string;
    classCode: string;
    classSystem: string;
  }>;
  marketingPlanTemplates: Array<{
    __typename?: "VerticalMarketingPlanTemplate";
    id: string;
    lobs: Array<string>;
    products: Array<{
      __typename?: "VerticalMarketingPlanTemplateProduct";
      id: string;
      rank: number;
      submissionEmail?: string | null;
      rules: string;
      requirements: Array<{
        __typename?: "VerticalMarketingPlanTemplateProductRequirement";
        id: string;
        title: string;
        description?: string | null;
        label?: string | null;
        groups: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    }>;
  }>;
};

export type VerticalMarketingPlanTemplateFragment = {
  __typename?: "VerticalMarketingPlanTemplate";
  id: string;
  lobs: Array<string>;
  products: Array<{
    __typename?: "VerticalMarketingPlanTemplateProduct";
    id: string;
    rank: number;
    submissionEmail?: string | null;
    rules: string;
    requirements: Array<{
      __typename?: "VerticalMarketingPlanTemplateProductRequirement";
      id: string;
      title: string;
      description?: string | null;
      label?: string | null;
      groups: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  }>;
};

export type VerticalMarketingPlanTemplateProductFragment = {
  __typename?: "VerticalMarketingPlanTemplateProduct";
  id: string;
  rank: number;
  submissionEmail?: string | null;
  rules: string;
  requirements: Array<{
    __typename?: "VerticalMarketingPlanTemplateProductRequirement";
    id: string;
    title: string;
    description?: string | null;
    label?: string | null;
    groups: string;
  }>;
  appetiteProduct: {
    __typename?: "AppetiteProduct";
    id: string;
    carrierName: string;
    carrierProductName?: string | null;
    marketType?: MarketTypes | null;
    coverages: string;
    endorsements: string;
    coreLines: Array<string>;
    productDescription?: string | null;
    portalLink?: string | null;
    submissionInstructions?: string | null;
    bindHqCarrierIds: Array<string>;
    bindHqMarketingCompanyId?: string | null;
    submissionCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
    referenceCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
  };
};

export type CreateAgentActionLogMutationVariables = Exact<{
  agentActionId: Scalars["String"];
  type: Scalars["String"];
  fileId?: InputMaybe<Scalars["String"]>;
  response?: InputMaybe<Scalars["String"]>;
}>;

export type CreateAgentActionLogMutation = {
  __typename?: "Mutation";
  createAgentActionLog: {
    __typename?: "AgentActionLog";
    id: string;
    agentActionId: string;
    type: string;
    fileId?: string | null;
    response?: string | null;
  };
};

export type AddCarrierContactToAppetiteProductMutationVariables = Exact<{
  input: CarrierContactConnectorInput;
}>;

export type AddCarrierContactToAppetiteProductMutation = {
  __typename?: "Mutation";
  addCarrierContactToAppetiteProduct: {
    __typename?: "CarrierContact";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    title?: string | null;
  };
};

export type AddProductToVerticalMarketingPlanTemplateMutationVariables = Exact<{
  input: AddProductToVerticalMarketingPlanTemplateInput;
}>;

export type AddProductToVerticalMarketingPlanTemplateMutation = {
  __typename?: "Mutation";
  addProductToVerticalMarketingPlanTemplate: { __typename?: "VerticalMarketingPlanTemplate"; id: string };
};

export type AddVerticalMarketingPlanTemplateProductRequirementMutationVariables = Exact<{
  input: AddVerticalMarketingPlanTemplateProductRequirementInput;
}>;

export type AddVerticalMarketingPlanTemplateProductRequirementMutation = {
  __typename?: "Mutation";
  addVerticalMarketingPlanTemplateProductRequirement: { __typename?: "VerticalMarketingPlanTemplate"; id: string };
};

export type BindQuoteMutationVariables = Exact<{
  input: BindQuoteInput;
}>;

export type BindQuoteMutation = {
  __typename?: "Mutation";
  bindQuote: {
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    totalPremium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    outstandingSubjectivities?: string | null;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    };
  };
};

export type BrokerCreateAgentActionMutationVariables = Exact<{
  input: BrokerCreateAgentAction;
}>;

export type BrokerCreateAgentActionMutation = {
  __typename?: "Mutation";
  brokerCreateAgentAction: { __typename?: "AgentAction"; id: string & { __scalar: "UUID" } };
};

export type CheckUpstreamFileProcessorMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CheckUpstreamFileProcessorMutation = {
  __typename?: "Mutation";
  checkUpstreamFileProcessor?: { __typename?: "CheckUpstreamFileProcessor"; exists: boolean } | null;
};

export type CompleteAgentActionMutationVariables = Exact<{
  input: CompleteAgentActionInput;
}>;

export type CompleteAgentActionMutation = {
  __typename?: "Mutation";
  completeAgentAction: { __typename?: "AgentAction"; id: string & { __scalar: "UUID" } };
};

export type ConnectExtractedLabelToPrimaryLabelMutationVariables = Exact<{
  input: ConnectOrDisconnectExtractedLabelInput;
}>;

export type ConnectExtractedLabelToPrimaryLabelMutation = {
  __typename?: "Mutation";
  connectExtractedLabelToPrimaryLabel: {
    __typename?: "PrimaryLabel";
    id: string;
    primaryKey: string;
    displayName?: string | null;
    dataType?: PrimaryLabelDataType | null;
    description?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    createdById?: string | null;
    updatedById?: string | null;
    extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
    createdBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    updatedBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

export type CreateActionMutationVariables = Exact<{
  input: CreateActionInput;
}>;

export type CreateActionMutation = { __typename?: "Mutation"; createAction: { __typename?: "Action"; id: string } };

export type CreateAppetiteNoteMutationVariables = Exact<{
  input: CreateAppetiteNoteInput;
}>;

export type CreateAppetiteNoteMutation = {
  __typename?: "Mutation";
  createAppetiteNote: {
    __typename?: "AppetiteNote";
    id: string;
    isoCglCodes: Array<string>;
    lobs: Array<Coverages>;
    states: Array<string>;
    note: string;
  };
};

export type CreateBindingAuthorityExclusionMutationVariables = Exact<{
  input: CreateBindingExclusionInput;
}>;

export type CreateBindingAuthorityExclusionMutation = {
  __typename?: "Mutation";
  createBindingExclusion: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } };
};

export type CreateCarrierContactMutationVariables = Exact<{
  input: CreateCarrierContactInput;
}>;

export type CreateCarrierContactMutation = {
  __typename?: "Mutation";
  createCarrierContact: {
    __typename?: "CarrierContact";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    title?: string | null;
  };
};

export type CreateClientDataMutationVariables = Exact<{
  input: CreateClientDataInput;
}>;

export type CreateClientDataMutation = {
  __typename?: "Mutation";
  createClientData: Array<{
    __typename?: "ClientData";
    id: string;
    insuredId: string;
    index?: number | null;
    key: string;
    value: string;
    source?: string | null;
  }>;
};

export type CreateClientDataPdfMutationVariables = Exact<{
  input: CreateClientDataPdfInput;
}>;

export type CreateClientDataPdfMutation = {
  __typename?: "Mutation";
  createClientDataPDF: {
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  };
};

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: "Mutation";
  createComment: {
    __typename: "UserComment";
    type: OpportunityEventType;
    id: string & { __scalar: "UUID" };
    text: string;
    createdAt: any;
    updatedAt?: any | null;
    isPublic: boolean;
    userAccountId: string & { __scalar: "UUID" };
    opportunityId: string & { __scalar: "UUID" };
    userAccount: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
  };
};

export type CreateEmailDraftMutationVariables = Exact<{
  input: CreateEmailDraftInput;
}>;

export type CreateEmailDraftMutation = { __typename?: "Mutation"; createEmailDraft: string };

export type CreateEmailTemplateDraftMutationVariables = Exact<{
  input: CreateEmailTemplateDraftInput;
}>;

export type CreateEmailTemplateDraftMutation = { __typename?: "Mutation"; createEmailTemplateDraft: string };

export type CreateFilePipelineVersionMutationVariables = Exact<{
  input: CreatePipelineVersionInput;
}>;

export type CreateFilePipelineVersionMutation = {
  __typename?: "Mutation";
  createPipelineVersion: {
    __typename?: "FilePipelineVersion";
    id: string;
    pipeline: {
      __typename?: "FileProcessingPipeline";
      initial: {
        __typename?: "FilePipelineNode";
        id: string;
        category: string;
        name: string;
        startPage?: number | null;
        endPage?: number | null;
      };
      transitions: Array<{
        __typename?: "FilePipelineEdge";
        sourceNodeName: string;
        label: string;
        destinationNodes: Array<{
          __typename?: "FilePipelineNode";
          id: string;
          category: string;
          name: string;
          startPage?: number | null;
          endPage?: number | null;
        }>;
      }>;
    };
  };
};

export type CreateInsuredMutationVariables = Exact<{
  input: CreateInsuredInput;
}>;

export type CreateInsuredMutation = {
  __typename?: "Mutation";
  createInsured: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  };
};

export type CreateInsuredAndAgentMutationVariables = Exact<{
  input: CreateInsuredAndAgentInput;
}>;

export type CreateInsuredAndAgentMutation = {
  __typename?: "Mutation";
  createInsuredAndAgent: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  };
};

export type CreateInsuredHazardMutationVariables = Exact<{
  input: CreateInsuredHazardInput;
}>;

export type CreateInsuredHazardMutation = {
  __typename?: "Mutation";
  createHazard: { __typename?: "InsuredHazard"; id: string };
};

export type CreateInsuredPremiseBuildingMutationVariables = Exact<{
  input: CreateInsuredPremiseBuildingInput;
}>;

export type CreateInsuredPremiseBuildingMutation = {
  __typename?: "Mutation";
  createBuilding: { __typename?: "InsuredPremiseBuilding"; id: string };
};

export type CreateInsuredPremiseLocationMutationVariables = Exact<{
  input: CreateInsuredPremiseLocationInput;
}>;

export type CreateInsuredPremiseLocationMutation = {
  __typename?: "Mutation";
  createLocation: { __typename?: "InsuredPremiseLocation"; id: string };
};

export type CreateOpportunityMutationVariables = Exact<{
  input: CreateOpportunityInput;
}>;

export type CreateOpportunityMutation = {
  __typename?: "Mutation";
  createOpportunity: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } };
};

export type CreateOpportunityNoteMutationVariables = Exact<{
  input: CreateOpportunityNoteInput;
}>;

export type CreateOpportunityNoteMutation = {
  __typename?: "Mutation";
  createNote: { __typename?: "OpportunityNote"; id: string };
};

export type CreatePrimaryLabelMutationVariables = Exact<{
  input: CreatePrimaryLabelInput;
}>;

export type CreatePrimaryLabelMutation = {
  __typename?: "Mutation";
  createPrimaryLabel: {
    __typename?: "PrimaryLabel";
    id: string;
    primaryKey: string;
    displayName?: string | null;
    dataType?: PrimaryLabelDataType | null;
    description?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    createdById?: string | null;
    updatedById?: string | null;
    extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
    createdBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    updatedBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

export type CreateProcessedBinderMutationVariables = Exact<{
  input: CreateProcessedBinderInput;
}>;

export type CreateProcessedBinderMutation = {
  __typename?: "Mutation";
  createProcessedBinder: {
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  };
};

export type CreateProcessedQuotePdfMutationVariables = Exact<{
  input: CreateProcessedQuoteInput;
}>;

export type CreateProcessedQuotePdfMutation = {
  __typename?: "Mutation";
  createProcessedQuotePDF: {
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    totalPremium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    outstandingSubjectivities?: string | null;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    };
  };
};

export type CreateRenewalMutationVariables = Exact<{
  input: CreateRenewalInput;
}>;

export type CreateRenewalMutation = {
  __typename?: "Mutation";
  createRenewal: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } };
};

export type CreateTagMutationVariables = Exact<{
  input: CreateTagInput;
}>;

export type CreateTagMutation = { __typename?: "Mutation"; createFileTag: { __typename?: "FileTag"; label: string } };

export type CreateVerticalMutationVariables = Exact<{
  input: CreateVerticalInput;
}>;

export type CreateVerticalMutation = {
  __typename?: "Mutation";
  createVertical: {
    __typename?: "Vertical";
    id: string;
    name: string;
    description?: string | null;
    enabled: boolean;
    isoCglCodes: Array<{
      __typename?: "ClassCode";
      id: string;
      description: string;
      classCode: string;
      classSystem: string;
    }>;
    marketingPlanTemplates: Array<{
      __typename?: "VerticalMarketingPlanTemplate";
      id: string;
      lobs: Array<string>;
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        id: string;
        rank: number;
        submissionEmail?: string | null;
        rules: string;
        requirements: Array<{
          __typename?: "VerticalMarketingPlanTemplateProductRequirement";
          id: string;
          title: string;
          description?: string | null;
          label?: string | null;
          groups: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      }>;
    }>;
  };
};

export type CreateVerticalMarketingPlanTemplateMutationVariables = Exact<{
  input: CreateVerticalMarketingPlanTemplateInput;
}>;

export type CreateVerticalMarketingPlanTemplateMutation = {
  __typename?: "Mutation";
  createVerticalMarketingPlanTemplate: { __typename?: "VerticalMarketingPlanTemplate"; id: string };
};

export type DeactivateUserAccountMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeactivateUserAccountMutation = {
  __typename?: "Mutation";
  deactivateUser: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
};

export type DeleteActionMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteActionMutation = { __typename?: "Mutation"; deleteAction: { __typename?: "Action"; id: string } };

export type DeleteAgentActionMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteAgentActionMutation = {
  __typename?: "Mutation";
  deleteAgentAction: { __typename?: "AgentAction"; id: string & { __scalar: "UUID" } };
};

export type DeleteClientDataRowMutationVariables = Exact<{
  input: DeleteClientDataInput;
}>;

export type DeleteClientDataRowMutation = {
  __typename?: "Mutation";
  deleteClientDataRow: {
    __typename?: "ClientData";
    id: string;
    insuredId: string;
    index?: number | null;
    key: string;
    value: string;
    source?: string | null;
  };
};

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteCommentMutation = {
  __typename?: "Mutation";
  deleteComment: {
    __typename: "UserComment";
    type: OpportunityEventType;
    id: string & { __scalar: "UUID" };
    text: string;
    createdAt: any;
    updatedAt?: any | null;
    isPublic: boolean;
    userAccountId: string & { __scalar: "UUID" };
    opportunityId: string & { __scalar: "UUID" };
    userAccount: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
  };
};

export type DeleteFileProcessorVersionMutationVariables = Exact<{
  input: UpdateFileProcessorVersionInput;
}>;

export type DeleteFileProcessorVersionMutation = {
  __typename?: "Mutation";
  deleteProcessorVersion: { __typename?: "OperationSuccessResponse"; success: boolean };
};

export type DeleteFileUploadMutationVariables = Exact<{
  input: DeleteFileUploadInput;
}>;

export type DeleteFileUploadMutation = {
  __typename?: "Mutation";
  deleteFileUpload: { __typename?: "FileUpload"; id: string };
};

export type DeleteInsuredMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteInsuredMutation = {
  __typename?: "Mutation";
  deleteInsured?: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  } | null;
};

export type DeleteInsuredHazardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteInsuredHazardMutation = {
  __typename?: "Mutation";
  deleteHazard: { __typename?: "InsuredHazard"; id: string };
};

export type DeleteInsuredPremiseBuildingMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteInsuredPremiseBuildingMutation = {
  __typename?: "Mutation";
  deleteBuilding: { __typename?: "InsuredPremiseBuilding"; id: string };
};

export type DeleteInsuredPremiseLocationMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteInsuredPremiseLocationMutation = {
  __typename?: "Mutation";
  deleteLocation: { __typename?: "InsuredPremiseLocation"; id: string };
};

export type DeleteOpportunityMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteOpportunityMutation = {
  __typename?: "Mutation";
  deleteOpportunity: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } };
};

export type DeletePrimaryLabelMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeletePrimaryLabelMutation = {
  __typename?: "Mutation";
  deletePrimaryLabel: {
    __typename?: "PrimaryLabel";
    id: string;
    primaryKey: string;
    displayName?: string | null;
    dataType?: PrimaryLabelDataType | null;
    description?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    createdById?: string | null;
    updatedById?: string | null;
    extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
    createdBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    updatedBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

export type DeleteVerticalMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type DeleteVerticalMutation = {
  __typename?: "Mutation";
  deleteVertical: {
    __typename?: "Vertical";
    id: string;
    name: string;
    description?: string | null;
    enabled: boolean;
    isoCglCodes: Array<{
      __typename?: "ClassCode";
      id: string;
      description: string;
      classCode: string;
      classSystem: string;
    }>;
    marketingPlanTemplates: Array<{
      __typename?: "VerticalMarketingPlanTemplate";
      id: string;
      lobs: Array<string>;
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        id: string;
        rank: number;
        submissionEmail?: string | null;
        rules: string;
        requirements: Array<{
          __typename?: "VerticalMarketingPlanTemplateProductRequirement";
          id: string;
          title: string;
          description?: string | null;
          label?: string | null;
          groups: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      }>;
    }>;
  };
};

export type DeleteVerticalMarketingPlanTemplateProductRequirementMutationVariables = Exact<{
  input: DeleteVerticalMarketingPlanTemplateProductRequirementInput;
}>;

export type DeleteVerticalMarketingPlanTemplateProductRequirementMutation = {
  __typename?: "Mutation";
  deleteVerticalMarketingPlanTemplateProductRequirement: { __typename?: "VerticalMarketingPlanTemplate"; id: string };
};

export type DeliverBinderMutationVariables = Exact<{
  input: SendPolicyFileInput;
}>;

export type DeliverBinderMutation = {
  __typename?: "Mutation";
  deliverBinder: {
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  };
};

export type DeployFileProcessorVersionMutationVariables = Exact<{
  input: UpdateFileProcessorVersionInput;
}>;

export type DeployFileProcessorVersionMutation = {
  __typename?: "Mutation";
  deployProcessorVersion: { __typename?: "OperationSuccessResponse"; success: boolean };
};

export type DisconnectExtractedLabelFromPrimaryLabelMutationVariables = Exact<{
  input: ConnectOrDisconnectExtractedLabelInput;
}>;

export type DisconnectExtractedLabelFromPrimaryLabelMutation = {
  __typename?: "Mutation";
  disconnectExtractedLabelFromPrimaryLabel: {
    __typename?: "PrimaryLabel";
    id: string;
    primaryKey: string;
    displayName?: string | null;
    dataType?: PrimaryLabelDataType | null;
    description?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    createdById?: string | null;
    updatedById?: string | null;
    extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
    createdBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    updatedBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

export type DismissSubmissionStateLogExclusionReasonsMutationVariables = Exact<{
  input: DismissSubmissionStateLogExclusionReasonsInput;
}>;

export type DismissSubmissionStateLogExclusionReasonsMutation = {
  __typename?: "Mutation";
  dismissSubmissionStateLogExclusionReasons: Array<{
    __typename?: "SubmissionStateTransition";
    id: string;
    submissionId: string;
    previousState: SubmissionState;
    details?: string | null;
    state: SubmissionState;
    createdAt: any;
    originatorId: string;
  }>;
};

export type EditInsuredHazardMutationVariables = Exact<{
  input: EditInsuredHazardInput;
}>;

export type EditInsuredHazardMutation = {
  __typename?: "Mutation";
  editHazard: { __typename?: "InsuredHazard"; id: string };
};

export type EditInsuredPremiseBuildingMutationVariables = Exact<{
  input: EditInsuredPremiseBuildingInput;
}>;

export type EditInsuredPremiseBuildingMutation = {
  __typename?: "Mutation";
  editBuilding: { __typename?: "InsuredPremiseBuilding"; id: string };
};

export type EditInsuredPremiseLocationMutationVariables = Exact<{
  input: EditInsuredPremiseLocationInput;
}>;

export type EditInsuredPremiseLocationMutation = {
  __typename?: "Mutation";
  editLocation: { __typename?: "InsuredPremiseLocation"; id: string };
};

export type GenerateAcordMutationVariables = Exact<{
  input: AcordGenerationInput;
}>;

export type GenerateAcordMutation = {
  __typename?: "Mutation";
  generateAcord: { __typename?: "FileUpload"; id: string };
};

export type IssuePolicyMutationVariables = Exact<{
  input: SendPolicyFileInput;
}>;

export type IssuePolicyMutation = {
  __typename?: "Mutation";
  issuePolicy: {
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  };
};

export type MergeInsuredMutationVariables = Exact<{
  input: MergeInsuredInput;
}>;

export type MergeInsuredMutation = {
  __typename?: "Mutation";
  mergeInsured: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  };
};

export type PublicCreateOpportunityMutationVariables = Exact<{
  input: PublicCreateOpportunityInput;
}>;

export type PublicCreateOpportunityMutation = {
  __typename?: "Mutation";
  publicCreateOpportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    createdById: string & { __scalar: "UUID" };
  };
};

export type RemoveCarrierContactFromAppetiteProductMutationVariables = Exact<{
  input: CarrierContactConnectorInput;
}>;

export type RemoveCarrierContactFromAppetiteProductMutation = {
  __typename?: "Mutation";
  removeCarrierContactFromAppetiteProduct: {
    __typename?: "CarrierContact";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    title?: string | null;
  };
};

export type RemoveFIleTagMutationVariables = Exact<{
  input: UpdateTagInput;
}>;

export type RemoveFIleTagMutation = {
  __typename?: "Mutation";
  removeFileTag: { __typename?: "FileTag"; label: string };
};

export type ReplaceMarketingPlanSubmissionsMutationVariables = Exact<{
  input: ReplaceMarketingPlanSubmissionsInput;
}>;

export type ReplaceMarketingPlanSubmissionsMutation = {
  __typename?: "Mutation";
  replaceMarketingPlanSubmissions: Array<{ __typename?: "Submission"; id: string & { __scalar: "UUID" } }>;
};

export type RerunProcessorMutationVariables = Exact<{
  input: RerunProcessorInput;
}>;

export type RerunProcessorMutation = {
  __typename?: "Mutation";
  rerunProcessor: { __typename?: "OperationSuccessResponse"; success: boolean };
};

export type SelectOpportunityCoveragesMutationVariables = Exact<{
  input: OpportunitySelectCoveragesInput;
}>;

export type SelectOpportunityCoveragesMutation = {
  __typename?: "Mutation";
  selectOpportunityCoverages: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    insuredId: string & { __scalar: "UUID" };
  };
};

export type SendBindRequestMutationVariables = Exact<{
  input: SendBindRequestInput;
}>;

export type SendBindRequestMutation = {
  __typename?: "Mutation";
  sendBindRequest: {
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    totalPremium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    outstandingSubjectivities?: string | null;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    };
  };
};

export type SendQuoteEmailMutationVariables = Exact<{
  input: SendQuoteInput;
}>;

export type SendQuoteEmailMutation = {
  __typename?: "Mutation";
  sendQuote: {
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    totalPremium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    outstandingSubjectivities?: string | null;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    };
  };
};

export type SendSubmissionToCarrierMutationVariables = Exact<{
  input: SendSubmissionToCarrierInput;
}>;

export type SendSubmissionToCarrierMutation = {
  __typename?: "Mutation";
  sendSubmissionToCarrier: {
    __typename?: "Submission";
    id: string & { __scalar: "UUID" };
    opportunityId: string;
    appetiteProductId: string;
    bindHqApplicationId?: string | null;
    state: SubmissionState;
    createdAt: any;
    updatedAt?: any | null;
    lastTransitionAt: any;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
    quotes: Array<{
      __typename?: "Quote";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      submissionId: string;
      state: QuoteState;
      premium?: number | null;
      totalPremium?: number | null;
      mep?: number | null;
      agentCommission?: number | null;
      grossCommission?: number | null;
      carrierFee?: number | null;
      brokerFee?: number | null;
      inspectionFee?: number | null;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      outstandingSubjectivities?: string | null;
      redactedQuoteId?: string | null;
      processedQuoteId?: string | null;
      redactedQuote?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedQuote?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      policy?: {
        __typename?: "Policy";
        id: string & { __scalar: "UUID" };
        insuredId: string;
        opportunityId: string;
        quoteId: string;
        state: PolicyState;
        policyNumber: string;
        effectiveDate?: any | null;
        expirationDate?: any | null;
        createdAt: any;
        lastTransitionAt: any;
        awaitingSubjectivities: boolean;
        endorsementsRequested: boolean;
        policyFileId?: string | null;
        redactedBinderFileId?: string | null;
        processedBinderFileId?: string | null;
        bindHqPolicyId?: string | null;
        policyFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        redactedBinderFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        processedBinderFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        stateTransitions: Array<{
          __typename?: "PolicyStateTransition";
          id: string;
          policyId: string;
          previousState: PolicyState;
          state: PolicyState;
          createdAt: any;
          originatorId: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      } | null;
      stateTransitions: Array<{
        __typename?: "QuoteStateTransition";
        id: string;
        quoteId: string;
        previousState: QuoteState;
        state: QuoteState;
        createdAt: any;
        originatorId: string;
      }>;
      submission: {
        __typename?: "Submission";
        id: string & { __scalar: "UUID" };
        opportunityId: string;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      };
    }>;
    stateTransitions: Array<{
      __typename?: "SubmissionStateTransition";
      id: string;
      submissionId: string;
      previousState: SubmissionState;
      details?: string | null;
      state: SubmissionState;
      createdAt: any;
      originatorId: string;
    }>;
  };
};

export type SetDefaultFileProcessorVersionMutationVariables = Exact<{
  input: UpdateFileProcessorVersionInput;
}>;

export type SetDefaultFileProcessorVersionMutation = {
  __typename?: "Mutation";
  setDefaultProcessorVersion: { __typename?: "OperationSuccessResponse"; success: boolean };
};

export type SnoozeOpportunityMutationVariables = Exact<{
  input: SnoozeOpportunityInput;
}>;

export type SnoozeOpportunityMutation = {
  __typename?: "Mutation";
  snoozeOpportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    state: MarketingPlanState;
    snoozeUntil?: any | null;
  };
};

export type TestAlbyMutationVariables = Exact<{
  input: TestAlbyInput;
}>;

export type TestAlbyMutation = { __typename?: "Mutation"; testAlby: string };

export type ToggleExtractedLabelToPrimaryLabelConnectionMutationVariables = Exact<{
  input: ConnectOrDisconnectExtractedLabelInput;
}>;

export type ToggleExtractedLabelToPrimaryLabelConnectionMutation = {
  __typename?: "Mutation";
  toggleExtractedLabelToPrimaryLabelConnection: {
    __typename?: "PrimaryLabel";
    id: string;
    primaryKey: string;
    displayName?: string | null;
    dataType?: PrimaryLabelDataType | null;
    description?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    createdById?: string | null;
    updatedById?: string | null;
    extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
    createdBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    updatedBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

export type TransitionMarketingPlanMutationVariables = Exact<{
  input: TransitionMarketingPlanInput;
}>;

export type TransitionMarketingPlanMutation = {
  __typename?: "Mutation";
  transitionMarketingPlan: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } };
};

export type TransitionPolicyMutationVariables = Exact<{
  input: TransitionPolicyInput;
}>;

export type TransitionPolicyMutation = {
  __typename?: "Mutation";
  transitionPolicy: {
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  };
};

export type TransitionQuoteMutationVariables = Exact<{
  input: TransitionQuoteInput;
}>;

export type TransitionQuoteMutation = {
  __typename?: "Mutation";
  transitionQuote: {
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    totalPremium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    outstandingSubjectivities?: string | null;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    };
  };
};

export type TransitionSubmissionMutationVariables = Exact<{
  input: TransitionSubmissionInput;
}>;

export type TransitionSubmissionMutation = {
  __typename?: "Mutation";
  transitionSubmission: { __typename?: "Submission"; id: string & { __scalar: "UUID" } };
};

export type UncompleteAgentActionMutationVariables = Exact<{
  input: CompleteAgentActionInput;
}>;

export type UncompleteAgentActionMutation = {
  __typename?: "Mutation";
  uncompleteAgentAction: { __typename?: "AgentAction"; id: string & { __scalar: "UUID" } };
};

export type UndeleteOpportunityMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UndeleteOpportunityMutation = {
  __typename?: "Mutation";
  undeleteOpportunity: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } };
};

export type UndeployFileProcessorVersionMutationVariables = Exact<{
  input: UpdateFileProcessorVersionInput;
}>;

export type UndeployFileProcessorVersionMutation = {
  __typename?: "Mutation";
  undeployProcessorVersion: { __typename?: "OperationSuccessResponse"; success: boolean };
};

export type UndismissSubmissionStateLogExclusionReasonsMutationVariables = Exact<{
  input: DismissSubmissionStateLogExclusionReasonsInput;
}>;

export type UndismissSubmissionStateLogExclusionReasonsMutation = {
  __typename?: "Mutation";
  undismissSubmissionStateLogExclusionReasons: Array<{
    __typename?: "SubmissionStateTransition";
    id: string;
    submissionId: string;
    previousState: SubmissionState;
    details?: string | null;
    state: SubmissionState;
    createdAt: any;
    originatorId: string;
  }>;
};

export type UpdateActionMutationVariables = Exact<{
  input: UpdateActionInput;
}>;

export type UpdateActionMutation = {
  __typename?: "Mutation";
  updateAction: {
    __typename?: "Action";
    id: string;
    autoExecuteAt?: any | null;
    executedOperation: ActionExecutionOperation;
    executedBy?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
  };
};

export type UpdateAgencyMutationVariables = Exact<{
  input: UpdateAgencyInput;
}>;

export type UpdateAgencyMutation = {
  __typename?: "Mutation";
  updateAgency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
};

export type UpdateAgencyOwnerMutationVariables = Exact<{
  input: UpdateAgencyInput;
}>;

export type UpdateAgencyOwnerMutation = {
  __typename?: "Mutation";
  updateAgency: {
    __typename?: "Agency";
    id: string;
    name: string;
    isTest: boolean;
    isPrivate: boolean;
    owner?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

export type UpdateAppetiteNoteMutationVariables = Exact<{
  input: UpdateAppetiteNoteInput;
}>;

export type UpdateAppetiteNoteMutation = {
  __typename?: "Mutation";
  updateAppetiteNote: {
    __typename?: "AppetiteNote";
    id: string;
    isoCglCodes: Array<string>;
    lobs: Array<Coverages>;
    states: Array<string>;
    note: string;
  };
};

export type UpdateAppetiteProductMutationVariables = Exact<{
  input: UpdateAppetiteProductInput;
}>;

export type UpdateAppetiteProductMutation = {
  __typename?: "Mutation";
  updateAppetiteProduct: {
    __typename?: "AppetiteProduct";
    id: string;
    carrierName: string;
    carrierProductName?: string | null;
    marketType?: MarketTypes | null;
    coverages: string;
    endorsements: string;
    coreLines: Array<string>;
    productDescription?: string | null;
    portalLink?: string | null;
    submissionInstructions?: string | null;
    bindHqCarrierIds: Array<string>;
    bindHqMarketingCompanyId?: string | null;
    submissionCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
    referenceCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
  };
};

export type UpdateBindHqAgencyMutationVariables = Exact<{
  input: UpdateBindHqAgencyInput;
}>;

export type UpdateBindHqAgencyMutation = {
  __typename?: "Mutation";
  updateBindHqAgency: {
    __typename?: "BindHqAgency";
    id: string;
    name: string;
    phone?: string | null;
    email?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    okToBind: boolean;
  };
};

export type UpdateCarrierContactMutationVariables = Exact<{
  input: UpdateCarrierContactInput;
}>;

export type UpdateCarrierContactMutation = {
  __typename?: "Mutation";
  updateCarrierContact: {
    __typename?: "CarrierContact";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    title?: string | null;
  };
};

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;

export type UpdateCommentMutation = {
  __typename?: "Mutation";
  updateComment: {
    __typename: "UserComment";
    type: OpportunityEventType;
    id: string & { __scalar: "UUID" };
    text: string;
    createdAt: any;
    updatedAt?: any | null;
    isPublic: boolean;
    userAccountId: string & { __scalar: "UUID" };
    opportunityId: string & { __scalar: "UUID" };
    userAccount: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
  };
};

export type UpdateFileUploadTagsMutationVariables = Exact<{
  input: UpdateFileUploadTagInput;
}>;

export type UpdateFileUploadTagsMutation = {
  __typename?: "Mutation";
  updateFileUploadTags: { __typename?: "FileTag"; label: string };
};

export type UpdateFileUploadMutationVariables = Exact<{
  input: UpdateFileUploadInput;
}>;

export type UpdateFileUploadMutation = {
  __typename?: "Mutation";
  updateFileUpload: { __typename?: "FileUpload"; id: string };
};

export type UpdateInsuredMutationVariables = Exact<{
  input: UpdateInsuredInput;
}>;

export type UpdateInsuredMutation = {
  __typename?: "Mutation";
  updateInsured: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  };
};

export type UpdateLeadMutationVariables = Exact<{
  input: LeadUpdateInput;
}>;

export type UpdateLeadMutation = {
  __typename?: "Mutation";
  updateLead: {
    __typename?: "Lead";
    id: string & { __scalar: "UUID" };
    accountName: string;
    effectiveDate?: any | null;
    expirationDate: any;
    rawLines: Array<string>;
    coreLines: Array<string>;
    carriers: Array<string>;
    carrierOverride?: string | null;
    isQwCarrier?: boolean | null;
    qwCarrierMatch?: QwCarrierMatch | null;
    premium?: (string & { __scalar: "Decimal" }) | null;
    description?: string | null;
    naicsCode?: string | null;
    agentFirstName?: string | null;
    agentLastName?: string | null;
    archived: boolean;
    highlighted: boolean;
    notes?: string | null;
    desireToRemarket?: DesireToRemarket | null;
    currentAgentCommission?: (string & { __scalar: "Decimal" }) | null;
    qwAgentCommission?: (string & { __scalar: "Decimal" }) | null;
    agencyId: string & { __scalar: "UUID" };
    opportunityId?: (string & { __scalar: "UUID" }) | null;
    topAppetiteProductScore?: (string & { __scalar: "Decimal" }) | null;
  };
};

export type UpdateManyLeadsMutationVariables = Exact<{
  input: LeadsUpdateManyInput;
}>;

export type UpdateManyLeadsMutation = {
  __typename?: "Mutation";
  updateManyLeads: Array<{
    __typename?: "Lead";
    id: string & { __scalar: "UUID" };
    accountName: string;
    effectiveDate?: any | null;
    expirationDate: any;
    rawLines: Array<string>;
    coreLines: Array<string>;
    carriers: Array<string>;
    carrierOverride?: string | null;
    isQwCarrier?: boolean | null;
    qwCarrierMatch?: QwCarrierMatch | null;
    premium?: (string & { __scalar: "Decimal" }) | null;
    description?: string | null;
    naicsCode?: string | null;
    agentFirstName?: string | null;
    agentLastName?: string | null;
    archived: boolean;
    highlighted: boolean;
    notes?: string | null;
    desireToRemarket?: DesireToRemarket | null;
    currentAgentCommission?: (string & { __scalar: "Decimal" }) | null;
    qwAgentCommission?: (string & { __scalar: "Decimal" }) | null;
    agencyId: string & { __scalar: "UUID" };
    opportunityId?: (string & { __scalar: "UUID" }) | null;
    topAppetiteProductScore?: (string & { __scalar: "Decimal" }) | null;
  }>;
};

export type UpdateOpportunityMutationVariables = Exact<{
  input: OpportunityUpdateInput;
}>;

export type UpdateOpportunityMutation = {
  __typename?: "Mutation";
  updateOpportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    state: MarketingPlanState;
    selectedLinesOfBusiness: Array<string>;
    desiredEffectiveDate: any;
    renewalOf?: string | null;
    insured: {
      __typename?: "Insured";
      id: string & { __scalar: "UUID" };
      name: string;
      primaryState?: string | null;
      naicsCodes: Array<string>;
      description?: string | null;
      agent: {
        __typename?: "UserAccount";
        agencyId: string & { __scalar: "UUID" };
        email: string;
        firstName: string;
        lastName: string;
      };
    };
    broker?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
  };
};

export type UpdatePolicyMutationVariables = Exact<{
  input: UpdatePolicyInput;
}>;

export type UpdatePolicyMutation = {
  __typename?: "Mutation";
  updatePolicy: {
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  };
};

export type UpdatePrimaryLabelMutationVariables = Exact<{
  input: UpdatePrimaryLabelInput;
}>;

export type UpdatePrimaryLabelMutation = {
  __typename?: "Mutation";
  updatePrimaryLabel: {
    __typename?: "PrimaryLabel";
    id: string;
    primaryKey: string;
    displayName?: string | null;
    dataType?: PrimaryLabelDataType | null;
    description?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    createdById?: string | null;
    updatedById?: string | null;
    extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
    createdBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    updatedBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

export type UpdateProductExclusionMutationVariables = Exact<{
  input: ProductExclusionUpdateInput;
}>;

export type UpdateProductExclusionMutation = {
  __typename?: "Mutation";
  productExclusionUpdate: { __typename?: "ProductExclusion"; id: string };
};

export type UpdateSubmissionMutationVariables = Exact<{
  input: UpdateSubmissionInput;
}>;

export type UpdateSubmissionMutation = {
  __typename?: "Mutation";
  updateSubmission: { __typename?: "Submission"; id: string & { __scalar: "UUID" } };
};

export type UpdateFileTagMutationVariables = Exact<{
  input: UpdateTagInput;
}>;

export type UpdateFileTagMutation = {
  __typename?: "Mutation";
  updateFileTag: { __typename?: "FileTag"; label: string };
};

export type UpdateUserAccountMutationVariables = Exact<{
  id: Scalars["String"];
  input: UserAccountUpdateInput;
}>;

export type UpdateUserAccountMutation = {
  __typename?: "Mutation";
  updateUserAccount: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
};

export type UpdateUserAssignmentsEnabledMutationVariables = Exact<{
  input: UpdateUserAssignmentsEnabledInput;
}>;

export type UpdateUserAssignmentsEnabledMutation = {
  __typename?: "Mutation";
  updateUserAssignmentsEnabled: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
};

export type UpdateVerticalMutationVariables = Exact<{
  input: UpdateVerticalInput;
}>;

export type UpdateVerticalMutation = {
  __typename?: "Mutation";
  updateVertical: {
    __typename?: "Vertical";
    id: string;
    name: string;
    description?: string | null;
    enabled: boolean;
    isoCglCodes: Array<{
      __typename?: "ClassCode";
      id: string;
      description: string;
      classCode: string;
      classSystem: string;
    }>;
    marketingPlanTemplates: Array<{
      __typename?: "VerticalMarketingPlanTemplate";
      id: string;
      lobs: Array<string>;
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        id: string;
        rank: number;
        submissionEmail?: string | null;
        rules: string;
        requirements: Array<{
          __typename?: "VerticalMarketingPlanTemplateProductRequirement";
          id: string;
          title: string;
          description?: string | null;
          label?: string | null;
          groups: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      }>;
    }>;
  };
};

export type UpdateVerticalMarketingPlanTemplateProductRankMutationVariables = Exact<{
  input: UpdateVerticalMarketingPlanTemplateProductRankInput;
}>;

export type UpdateVerticalMarketingPlanTemplateProductRankMutation = {
  __typename?: "Mutation";
  updateVerticalMarketingPlanTemplateProductRank: { __typename?: "VerticalMarketingPlanTemplate"; id: string };
};

export type UpdateVerticalMarketingPlanTemplateProductRequirementMutationVariables = Exact<{
  input: UpdateVerticalMarketingPlanTemplateProductRequirementInput;
}>;

export type UpdateVerticalMarketingPlanTemplateProductRequirementMutation = {
  __typename?: "Mutation";
  updateVerticalMarketingPlanTemplateProductRequirement: { __typename?: "VerticalMarketingPlanTemplate"; id: string };
};

export type UpdateVerticalMarketingPlanTemplateProductRulesMutationVariables = Exact<{
  input: UpdateVerticalMarketingPlanTemplateProductRulesInput;
}>;

export type UpdateVerticalMarketingPlanTemplateProductRulesMutation = {
  __typename?: "Mutation";
  updateVerticalMarketingPlanTemplateProductRules: { __typename?: "VerticalMarketingPlanTemplate"; id: string };
};

export type UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationVariables = Exact<{
  input: UpdateVerticalMarketingPlanTemplateProductSubmissionEmailInput;
}>;

export type UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation = {
  __typename?: "Mutation";
  updateVerticalMarketingPlanTemplateProductSubmissionEmail: {
    __typename?: "VerticalMarketingPlanTemplate";
    id: string;
  };
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: "Mutation";
  inviteUserToAgency: {
    __typename?: "RegisterUserResponse";
    userId: string & { __scalar: "UUID" };
    email: string;
    agencyId: string & { __scalar: "UUID" };
  };
};

export type ReassignOpportunityMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ReassignOpportunityMutation = {
  __typename?: "Mutation";
  reassignOpportunity: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    isTest: boolean;
    state: MarketingPlanState;
    createdAt: any;
    insuredId: string & { __scalar: "UUID" };
    updatedAt?: any | null;
    selectedLinesOfBusiness: Array<string>;
    desiredEffectiveDate: any;
    brokerageFolderId?: string | null;
    renewalOf?: string | null;
    createdBy: { __typename?: "UserAccount"; id: string & { __scalar: "UUID" }; firstName: string; lastName: string };
    updatedBy?: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
    } | null;
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    submissions: Array<{
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProductId: string;
      bindHqApplicationId?: string | null;
      state: SubmissionState;
      createdAt: any;
      updatedAt?: any | null;
      lastTransitionAt: any;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
      quotes: Array<{
        __typename?: "Quote";
        id: string & { __scalar: "UUID" };
        insuredId: string;
        submissionId: string;
        state: QuoteState;
        premium?: number | null;
        totalPremium?: number | null;
        mep?: number | null;
        agentCommission?: number | null;
        grossCommission?: number | null;
        carrierFee?: number | null;
        brokerFee?: number | null;
        inspectionFee?: number | null;
        effectiveDate?: any | null;
        expirationDate?: any | null;
        createdAt: any;
        lastTransitionAt: any;
        outstandingSubjectivities?: string | null;
        redactedQuoteId?: string | null;
        processedQuoteId?: string | null;
        redactedQuote?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        processedQuote?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        policy?: {
          __typename?: "Policy";
          id: string & { __scalar: "UUID" };
          insuredId: string;
          opportunityId: string;
          quoteId: string;
          state: PolicyState;
          policyNumber: string;
          effectiveDate?: any | null;
          expirationDate?: any | null;
          createdAt: any;
          lastTransitionAt: any;
          awaitingSubjectivities: boolean;
          endorsementsRequested: boolean;
          policyFileId?: string | null;
          redactedBinderFileId?: string | null;
          processedBinderFileId?: string | null;
          bindHqPolicyId?: string | null;
          policyFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          redactedBinderFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          processedBinderFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          stateTransitions: Array<{
            __typename?: "PolicyStateTransition";
            id: string;
            policyId: string;
            previousState: PolicyState;
            state: PolicyState;
            createdAt: any;
            originatorId: string;
          }>;
          appetiteProduct: {
            __typename?: "AppetiteProduct";
            id: string;
            carrierName: string;
            carrierProductName?: string | null;
            marketType?: MarketTypes | null;
            coverages: string;
            endorsements: string;
            coreLines: Array<string>;
            productDescription?: string | null;
            portalLink?: string | null;
            submissionInstructions?: string | null;
            bindHqCarrierIds: Array<string>;
            bindHqMarketingCompanyId?: string | null;
            submissionCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
            referenceCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
          };
        } | null;
        stateTransitions: Array<{
          __typename?: "QuoteStateTransition";
          id: string;
          quoteId: string;
          previousState: QuoteState;
          state: QuoteState;
          createdAt: any;
          originatorId: string;
        }>;
        submission: {
          __typename?: "Submission";
          id: string & { __scalar: "UUID" };
          opportunityId: string;
          appetiteProduct: {
            __typename?: "AppetiteProduct";
            id: string;
            carrierName: string;
            carrierProductName?: string | null;
            marketType?: MarketTypes | null;
            coverages: string;
            endorsements: string;
            coreLines: Array<string>;
            productDescription?: string | null;
            portalLink?: string | null;
            submissionInstructions?: string | null;
            bindHqCarrierIds: Array<string>;
            bindHqMarketingCompanyId?: string | null;
            submissionCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
            referenceCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
          };
        };
      }>;
      stateTransitions: Array<{
        __typename?: "SubmissionStateTransition";
        id: string;
        submissionId: string;
        previousState: SubmissionState;
        details?: string | null;
        state: SubmissionState;
        createdAt: any;
        originatorId: string;
      }>;
      exclusionReason?: {
        __typename?: "ProductExclusion";
        id: string;
        appetiteProductId: string;
        reason?: string | null;
        state?: string | null;
        naicsCode?: string | null;
      } | null;
    }>;
    appetiteProducts: Array<{
      __typename?: "AppetiteProduct";
      id: string;
      marketType?: MarketTypes | null;
      carrierName: string;
      carrierProductName?: string | null;
    }>;
    renewal?: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } } | null;
    insured: {
      __typename?: "Insured";
      brokerageFolderId?: string | null;
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      name: string;
      dba?: string | null;
      legalEntityType?: string | null;
      description?: string | null;
      primaryState?: string | null;
      naicsCodes: Array<string>;
      isoCglCodes: Array<string>;
      sicCodes: Array<string>;
      exposures?: string | null;
      mergedIntoId?: (string & { __scalar: "UUID" }) | null;
      revenue?: number | null;
      tiv?: number | null;
      agentId: string & { __scalar: "UUID" };
      businessClasses: Array<{
        __typename?: "BusinessClass";
        id?: string | null;
        description?: string | null;
        system: BusinessClassSystem;
        code: string;
      }>;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
    };
    files: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
    leads: Array<{
      __typename?: "Lead";
      id: string & { __scalar: "UUID" };
      accountName: string;
      effectiveDate?: any | null;
      expirationDate: any;
      rawLines: Array<string>;
      coreLines: Array<string>;
      carriers: Array<string>;
      carrierOverride?: string | null;
      isQwCarrier?: boolean | null;
      qwCarrierMatch?: QwCarrierMatch | null;
      premium?: (string & { __scalar: "Decimal" }) | null;
      description?: string | null;
      naicsCode?: string | null;
      agentFirstName?: string | null;
      agentLastName?: string | null;
      archived: boolean;
      highlighted: boolean;
      notes?: string | null;
      desireToRemarket?: DesireToRemarket | null;
      currentAgentCommission?: (string & { __scalar: "Decimal" }) | null;
      qwAgentCommission?: (string & { __scalar: "Decimal" }) | null;
      agencyId: string & { __scalar: "UUID" };
      opportunityId?: (string & { __scalar: "UUID" }) | null;
      topAppetiteProductScore?: (string & { __scalar: "Decimal" }) | null;
    }>;
    notes: Array<{
      __typename?: "OpportunityNote";
      id: string;
      createdAt: any;
      text: string;
      creator: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    }>;
    stateTransitions: Array<{
      __typename?: "MarketingPlanStateTransition";
      id: string;
      marketingPlanId: string;
      previousState: MarketingPlanState;
      state: MarketingPlanState;
      createdAt: any;
      originatorId: string;
    }>;
    verticalMarketingPlanTemplate?: {
      __typename?: "VerticalMarketingPlanTemplate";
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        rank: number;
        rules: string;
        appetiteProduct: { __typename?: "AppetiteProduct"; id: string };
      }>;
    } | null;
  };
};

export type ActionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ActionQuery = {
  __typename?: "Query";
  action: {
    __typename?: "Action";
    id: string;
    title: string;
    description?: string | null;
    actionType: ActionType;
    executedOperation: ActionExecutionOperation;
    createdAt: any;
    updatedAt?: any | null;
    opportunityId: string;
    submissionId?: string | null;
    executedAt?: any | null;
    autoExecuteAt?: any | null;
    metadata?: string | null;
    executedBy?: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
    } | null;
    appetiteProduct?: {
      __typename?: "AppetiteProduct";
      carrierName: string;
      carrierProductName?: string | null;
      portalLink?: string | null;
    } | null;
  };
};

export type ActionsQueryVariables = Exact<{
  input: ActionFilterInput;
}>;

export type ActionsQuery = {
  __typename?: "Query";
  actions: Array<{
    __typename?: "Action";
    id: string;
    title: string;
    description?: string | null;
    actionType: ActionType;
    executedOperation: ActionExecutionOperation;
    createdAt: any;
    updatedAt?: any | null;
    opportunityId: string;
    submissionId?: string | null;
    executedAt?: any | null;
    autoExecuteAt?: any | null;
    metadata?: string | null;
    executedBy?: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
    } | null;
    appetiteProduct?: {
      __typename?: "AppetiteProduct";
      carrierName: string;
      carrierProductName?: string | null;
      portalLink?: string | null;
    } | null;
  }>;
};

export type AdminAccountsQueryVariables = Exact<{
  input?: UserAccountFindManyFilter;
}>;

export type AdminAccountsQuery = {
  __typename?: "Query";
  userAccounts: Array<{
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  }>;
};

export type AgenciesQueryVariables = Exact<{
  input?: InputMaybe<AgenciesFilterInput>;
}>;

export type AgenciesQuery = {
  __typename?: "Query";
  agencies: Array<{ __typename?: "Agency"; id: string; name: string; isTest: boolean }>;
};

export type AgenciesWithOwnerQueryVariables = Exact<{ [key: string]: never }>;

export type AgenciesWithOwnerQuery = {
  __typename?: "Query";
  agencies: Array<{
    __typename?: "Agency";
    id: string;
    name: string;
    isTest: boolean;
    isPrivate: boolean;
    owner?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  }>;
};

export type AgencyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AgencyQuery = {
  __typename?: "Query";
  agency: {
    __typename?: "Agency";
    id: string;
    name: string;
    isTest: boolean;
    isPrivate: boolean;
    owner?: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
    } | null;
  };
};

export type AgencyAccountsQueryVariables = Exact<{
  input: UserAccountFindManyFilter;
}>;

export type AgencyAccountsQuery = {
  __typename?: "Query";
  agencyAccounts: Array<{
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  }>;
};

export type AgencyAffiliationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AgencyAffiliationQuery = {
  __typename?: "Query";
  agency: {
    __typename?: "Agency";
    affiliations?: Array<{
      __typename?: "AgencyAffiliation";
      name: string;
      shortName?: string | null;
      logoUrl?: string | null;
      bindingCommission?: (string & { __scalar: "Decimal" }) | null;
      brokerageCommission?: (string & { __scalar: "Decimal" }) | null;
    }> | null;
  };
};

export type AgencyAppointmentsQueryVariables = Exact<{
  agencyId: Scalars["String"];
}>;

export type AgencyAppointmentsQuery = {
  __typename?: "Query";
  agencyAppointments: Array<{ __typename?: "AgencyAppointment"; id: string; agencyId: string; carrierSlug: string }>;
};

export type AgentAccountsQueryVariables = Exact<{
  input?: UserAccountFindManyFilter;
}>;

export type AgentAccountsQuery = {
  __typename?: "Query";
  userAccounts: Array<{
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  }>;
};

export type AgentActionTitlesQueryVariables = Exact<{
  actionType: Scalars["String"];
}>;

export type AgentActionTitlesQuery = { __typename?: "Query"; agentActionTitles: Array<string> };

export type AiGuessBusinessClassesQueryVariables = Exact<{
  description: Scalars["String"];
}>;

export type AiGuessBusinessClassesQuery = {
  __typename?: "Query";
  aiGuessBusinessClasses: Array<{
    __typename?: "BusinessClass";
    id?: string | null;
    description?: string | null;
    system: BusinessClassSystem;
    code: string;
  }>;
};

export type AppetiteFilterOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type AppetiteFilterOptionsQuery = {
  __typename?: "Query";
  appetiteFilterOptions: {
    __typename?: "AppetiteFilterOptions";
    verticals: Array<{
      __typename?: "AppetiteVertical";
      id: string;
      name: string;
      linesOfBusiness: Array<{ __typename?: "AppetiteLineOfBusiness"; name: string }>;
    }>;
  };
};

export type AppetiteGuidesForOpportunityQueryVariables = Exact<{
  opportunityId: Scalars["ID"];
}>;

export type AppetiteGuidesForOpportunityQuery = {
  __typename?: "Query";
  appetiteGuidesForOpportunity: Array<{
    __typename?: "AppetiteGuide";
    carrierName: string;
    authority: BindingAuthority;
    code?: string | null;
    lob?: string | null;
    state?: string | null;
    parsedText?: string | null;
    googleDriveUrl?: string | null;
    fileType?: string | null;
  }>;
};

export type AppetiteNotesQueryVariables = Exact<{
  input?: InputMaybe<AppetiteNoteFilterInput>;
}>;

export type AppetiteNotesQuery = {
  __typename?: "Query";
  appetiteNotes: Array<{
    __typename?: "AppetiteNote";
    id: string;
    isoCglCodes: Array<string>;
    lobs: Array<Coverages>;
    states: Array<string>;
    note: string;
  }>;
};

export type AppetiteNotesForOpportunityQueryVariables = Exact<{
  opportunityId: Scalars["String"];
}>;

export type AppetiteNotesForOpportunityQuery = {
  __typename?: "Query";
  appetiteNotesForOpportunity: Array<{
    __typename?: "AppetiteNoteResult";
    appetiteNote: { __typename?: "AppetiteNote"; id: string; note: string };
    matchedTags: Array<{ __typename?: "AppetiteNoteTag"; value: string; type: AppetiteNoteTagType }>;
  }>;
};

export type AppetiteProductLinesQueryVariables = Exact<{ [key: string]: never }>;

export type AppetiteProductLinesQuery = { __typename?: "Query"; appetiteProductLines: Array<string> };

export type AppetiteProductsQueryVariables = Exact<{ [key: string]: never }>;

export type AppetiteProductsQuery = {
  __typename?: "Query";
  appetiteProducts: Array<{
    __typename?: "AppetiteProduct";
    id: string;
    carrierName: string;
    carrierProductName?: string | null;
    marketType?: MarketTypes | null;
    coverages: string;
    endorsements: string;
    coreLines: Array<string>;
    productDescription?: string | null;
    portalLink?: string | null;
    submissionInstructions?: string | null;
    bindHqCarrierIds: Array<string>;
    bindHqMarketingCompanyId?: string | null;
    submissionCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
    referenceCarrierContacts?: Array<{
      __typename?: "CarrierContact";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      title?: string | null;
    }> | null;
  }>;
};

export type AppetiteProductsCountQueryVariables = Exact<{ [key: string]: never }>;

export type AppetiteProductsCountQuery = { __typename?: "Query"; productsCount: number };

export type BankanMarketingPlanColumnQueryVariables = Exact<{
  input: BankanMarketingPlanColumnInput;
}>;

export type BankanMarketingPlanColumnQuery = {
  __typename?: "Query";
  bankanMarketingPlanColumn: {
    __typename?: "BankanMarketingPlansResponse";
    groupedMarketingPlans: Array<{
      __typename?: "GroupedMarketingPlans";
      insuredId: string;
      insuredName: string;
      insured: {
        __typename?: "Insured";
        id: string & { __scalar: "UUID" };
        name: string;
        createdAt: any;
        agent: {
          __typename?: "UserAccount";
          firstName: string;
          lastName: string;
          agency: { __typename?: "Agency"; name: string };
        };
      };
      marketingPlans: Array<{
        __typename?: "Opportunity";
        id: string & { __scalar: "UUID" };
        state: MarketingPlanState;
        desiredEffectiveDate: any;
        lastTransitionAt: any;
        selectedLinesOfBusiness: Array<string>;
        snoozeUntil?: any | null;
        broker?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
        score?: {
          __typename?: "OpportunityScore";
          score: number;
          verticalScore: number;
          affiliationScore: number;
          effectiveDateScore: number;
          bindScore: number;
          bindRatioScore: number;
        } | null;
      }>;
    }>;
  };
};

export type BankanPolicyColumnQueryVariables = Exact<{
  input: BankanPolicyColumnInput;
}>;

export type BankanPolicyColumnQuery = {
  __typename?: "Query";
  bankanPolicyColumn: {
    __typename?: "BankanPoliciesResponse";
    groupedPolicies: Array<{
      __typename?: "GroupedPolicies";
      insuredId: string;
      insuredName: string;
      insured: {
        __typename?: "Insured";
        id: string & { __scalar: "UUID" };
        name: string;
        createdAt: any;
        agent: {
          __typename?: "UserAccount";
          firstName: string;
          lastName: string;
          agency: { __typename?: "Agency"; name: string };
        };
      };
      policies: Array<{
        __typename?: "Policy";
        id: string & { __scalar: "UUID" };
        state: PolicyState;
        policyNumber: string;
        expirationDate?: any | null;
        lastTransitionAt: any;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
        };
      }>;
    }>;
  };
};

export type BankanQuoteColumnQueryVariables = Exact<{
  input: BankanQuoteColumnInput;
}>;

export type BankanQuoteColumnQuery = {
  __typename?: "Query";
  bankanQuoteColumn: {
    __typename?: "BankanQuotesResponse";
    groupedQuotes: Array<{
      __typename?: "BankanGroupedQuotes";
      insuredId: string;
      insuredName: string;
      insured: {
        __typename?: "Insured";
        id: string & { __scalar: "UUID" };
        name: string;
        createdAt: any;
        agent: {
          __typename?: "UserAccount";
          firstName: string;
          lastName: string;
          agency: { __typename?: "Agency"; name: string };
        };
      };
      quotes: Array<{
        __typename?: "Quote";
        id: string & { __scalar: "UUID" };
        state: QuoteState;
        premium?: number | null;
        effectiveDate?: any | null;
        lastTransitionAt: any;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
        };
      }>;
    }>;
  };
};

export type BindHqAgenciesQueryVariables = Exact<{ [key: string]: never }>;

export type BindHqAgenciesQuery = {
  __typename?: "Query";
  bindHqAgencies: Array<{
    __typename?: "BindHqAgency";
    id: string;
    name: string;
    phone?: string | null;
    email?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    okToBind: boolean;
  }>;
};

export type BindHqAgentsQueryVariables = Exact<{
  input: BindHqFetchAgentsInput;
}>;

export type BindHqAgentsQuery = {
  __typename?: "Query";
  bindHqAgents: Array<{ __typename?: "BindHqAgent"; id: string; firstName: string; lastName: string }>;
};

export type BindHqCarriersQueryVariables = Exact<{ [key: string]: never }>;

export type BindHqCarriersQuery = {
  __typename?: "Query";
  bindHqCarriers: Array<{ __typename?: "BindHqCarrier"; id: string; name: string }>;
};

export type BindHqInsuredsQueryVariables = Exact<{ [key: string]: never }>;

export type BindHqInsuredsQuery = {
  __typename?: "Query";
  bindHqInsureds: Array<{ __typename?: "BindHqInsured"; id: string; name: string; type: string }>;
};

export type BindHqMarketingCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type BindHqMarketingCompaniesQuery = {
  __typename?: "Query";
  bindHqMarketingCompanies: Array<{ __typename?: "BindHqMarketingCompany"; id: string; name: string }>;
};

export type BrokerAccountsQueryVariables = Exact<{
  input?: UserAccountFindManyFilter;
}>;

export type BrokerAccountsQuery = {
  __typename?: "Query";
  userAccounts: Array<{
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  }>;
};

export type AssignemntQueueQueryVariables = Exact<{ [key: string]: never }>;

export type AssignemntQueueQuery = {
  __typename?: "Query";
  brokerAssignmentQueue: {
    __typename?: "BrokerAssignmentQueue";
    brokers: Array<{
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    }>;
  };
};

export type CarrierContactsQueryVariables = Exact<{ [key: string]: never }>;

export type CarrierContactsQuery = {
  __typename?: "Query";
  carrierContacts: Array<{
    __typename?: "CarrierContact";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    title?: string | null;
  }>;
};

export type ClassCodesQueryVariables = Exact<{ [key: string]: never }>;

export type ClassCodesQuery = {
  __typename?: "Query";
  classCodes: Array<{
    __typename?: "ClassCode";
    id: string;
    description: string;
    classCode: string;
    classSystem: string;
  }>;
};

export type ClientDataQueryVariables = Exact<{
  input: ClientDataFilterInput;
}>;

export type ClientDataQuery = {
  __typename?: "Query";
  clientData: Array<{
    __typename?: "ClientData";
    id: string;
    insuredId: string;
    index?: number | null;
    key: string;
    value: string;
    source?: string | null;
  }>;
};

export type GmailThreadsQueryVariables = Exact<{
  input: GmailThreadFilterInput;
}>;

export type GmailThreadsQuery = {
  __typename?: "Query";
  gmailThreads: Array<{
    __typename?: "GmailThread";
    id: string;
    gmailMessages: Array<{
      __typename?: "GmailMessage";
      id: string & { __scalar: "UUID" };
      to: string;
      from: string;
      subject: string;
      text: string;
      textAsHtml: string;
      html: string;
    }>;
  }>;
};

export type ExtractedLabelsQueryVariables = Exact<{ [key: string]: never }>;

export type ExtractedLabelsQuery = {
  __typename?: "Query";
  extractedLabels: Array<{
    __typename?: "ExtractedLabel";
    id: string;
    key: string;
    source: string;
    count: string;
    primaryLabels: Array<{ __typename?: "PrimaryLabel"; id: string; primaryKey: string; displayName?: string | null }>;
  }>;
};

export type FileJobsQueryVariables = Exact<{
  input: FindJobsInput;
}>;

export type FileJobsQuery = {
  __typename?: "Query";
  fileJobs: Array<{ __typename?: "FileProcessingJob"; id: string; status: string; pipeline: string; createdAt: any }>;
};

export type FileProcessingPipelineQueryVariables = Exact<{ [key: string]: never }>;

export type FileProcessingPipelineQuery = {
  __typename?: "Query";
  fileProcessingPipeline: {
    __typename?: "FilePipelineVersion";
    id: string;
    pipeline: {
      __typename?: "FileProcessingPipeline";
      initial: {
        __typename?: "FilePipelineNode";
        id: string;
        category: string;
        name: string;
        startPage?: number | null;
        endPage?: number | null;
      };
      transitions: Array<{
        __typename?: "FilePipelineEdge";
        sourceNodeName: string;
        label: string;
        destinationNodes: Array<{
          __typename?: "FilePipelineNode";
          id: string;
          category: string;
          name: string;
          startPage?: number | null;
          endPage?: number | null;
        }>;
      }>;
    };
  };
};

export type FileProcessingPipelineIssuesQueryVariables = Exact<{ [key: string]: never }>;

export type FileProcessingPipelineIssuesQuery = {
  __typename?: "Query";
  pipelineIssues?: Array<{
    __typename?: "FileProcessorIssue";
    nodeId: string;
    nodeType: FileProcessorNodeType;
    type: FileProcessorIssueType;
  }> | null;
};

export type FileProcessorQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FileProcessorQuery = {
  __typename?: "Query";
  fileProcessor?: {
    __typename?: "FileProcessingPipelineProcessor";
    id: string;
    type: FileProcessorNodeType;
    category: FileProcessorCategory;
    name: string;
    processorId: string;
    totalProcessedFiles: number;
    startPage?: number | null;
    endPage?: number | null;
    defaultProcessorVersion?: string | null;
    processorVersions?: Array<{
      __typename?: "FileProcessorVersion";
      id: string;
      displayName: string;
      state: FileProcessorVersionState;
      f1Score?: number | null;
      createdAt?: any | null;
    }> | null;
    files: Array<{
      __typename?: "FileUpload";
      labels: Array<string>;
      insuredId?: string | null;
      createdAt: any;
      deletedAt?: any | null;
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
    issues?: Array<{
      __typename?: "FileProcessorIssue";
      nodeId: string;
      nodeType: FileProcessorNodeType;
      type: FileProcessorIssueType;
    }> | null;
  } | null;
};

export type FileTagsQueryVariables = Exact<{
  input: FindTagsInput;
}>;

export type FileTagsQuery = {
  __typename?: "Query";
  fileTags: Array<{
    __typename?: "FileTag";
    label: string;
    parentTags?: Array<{ __typename?: "FileTag"; label: string }> | null;
    childTags?: Array<{ __typename?: "FileTag"; label: string }> | null;
  }>;
};

export type FilePathsQueryVariables = Exact<{ [key: string]: never }>;

export type FilePathsQuery = { __typename?: "Query"; paths: Code_Path };

export type FilesQueryVariables = Exact<{
  input: FindManyFileUploadInput;
}>;

export type FilesQuery = {
  __typename?: "Query";
  files: Array<{
    __typename?: "FileUpload";
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
  }>;
};

export type FilesByLabelQueryVariables = Exact<{
  input: FindFilesByLabelInput;
}>;

export type FilesByLabelQuery = {
  __typename?: "Query";
  filesByLabel: Array<{
    __typename?: "FileUpload";
    deletedAt?: any | null;
    createdAt: any;
    labels: Array<string>;
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
    audience?: string | null;
    uploader?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
  }>;
};

export type FindFileUploadQueryVariables = Exact<{
  input: FindFileInput;
}>;

export type FindFileUploadQuery = {
  __typename?: "Query";
  findFile: {
    __typename?: "FileUpload";
    labels: Array<string>;
    audience?: string | null;
    id: string;
    filename: string;
    mimeType: string;
    fileSource?: File_Source | null;
  };
};

export type FindProductExclusionsQueryVariables = Exact<{
  where?: InputMaybe<ProductExclusionWhere>;
}>;

export type FindProductExclusionsQuery = {
  __typename?: "Query";
  productExclusionsFind: Array<{
    __typename?: "ProductExclusion";
    id: string;
    reason?: string | null;
    state?: string | null;
    naicsCode?: string | null;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
    };
    submission: { __typename?: "Submission"; id: string & { __scalar: "UUID" }; opportunityId: string };
  }>;
};

export type GroupedMarketingPlansQueryVariables = Exact<{
  input: GroupedMarketingPlansInput;
}>;

export type GroupedMarketingPlansQuery = {
  __typename?: "Query";
  groupedMarketingPlans: {
    __typename?: "BankanMarketingPlansResponse";
    groupedMarketingPlans: Array<{
      __typename?: "GroupedMarketingPlans";
      insuredId: string;
      insuredName: string;
      insured: {
        __typename?: "Insured";
        id: string & { __scalar: "UUID" };
        name: string;
        createdAt: any;
        agent: {
          __typename?: "UserAccount";
          firstName: string;
          lastName: string;
          agency: { __typename?: "Agency"; name: string };
        };
      };
      marketingPlans: Array<{
        __typename?: "Opportunity";
        id: string & { __scalar: "UUID" };
        state: MarketingPlanState;
        desiredEffectiveDate: any;
        lastTransitionAt: any;
        selectedLinesOfBusiness: Array<string>;
        broker?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
      }>;
    }>;
  };
};

export type GroupedQuotesQueryVariables = Exact<{
  input: GroupedQuotesInput;
}>;

export type GroupedQuotesQuery = {
  __typename?: "Query";
  groupedQuotes: {
    __typename?: "BankanQuotesResponse";
    groupedQuotes: Array<{
      __typename?: "BankanGroupedQuotes";
      insuredId: string;
      insuredName: string;
      quotes: Array<{
        __typename?: "Quote";
        id: string & { __scalar: "UUID" };
        state: QuoteState;
        premium?: number | null;
        effectiveDate?: any | null;
        lastTransitionAt: any;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
        };
      }>;
    }>;
  };
};

export type InsuredQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InsuredQuery = {
  __typename?: "Query";
  insured?: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  } | null;
};

export type InsuredFileTagsQueryVariables = Exact<{
  input: FindTagsInput;
  insuredId: Scalars["ID"];
}>;

export type InsuredFileTagsQuery = {
  __typename?: "Query";
  fileTags: Array<{
    __typename?: "FileTag";
    label: string;
    hasFile: boolean;
    parentTags?: Array<{ __typename?: "FileTag"; label: string }> | null;
    childTags?: Array<{ __typename?: "FileTag"; label: string }> | null;
  }>;
};

export type InsuredHazardsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InsuredHazardsQuery = {
  __typename?: "Query";
  hazards: Array<{
    __typename?: "InsuredHazard";
    id: string;
    hazardNumber: number;
    locationId?: string | null;
    locationNumber: number;
    cgl?: string | null;
    premiumBasis?: PremiumBasis | null;
    exposure?: string | null;
    description?: string | null;
    indications: Array<{
      __typename?: "InsuredHazardIndication";
      carrierName: string;
      lob: string;
      minRate?: (string & { __scalar: "Decimal" }) | null;
      maxRate?: (string & { __scalar: "Decimal" }) | null;
      minPremium?: (string & { __scalar: "Decimal" }) | null;
      maxPremium?: (string & { __scalar: "Decimal" }) | null;
    }>;
  }>;
};

export type InsuredOpportunitiesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InsuredOpportunitiesQuery = {
  __typename?: "Query";
  insuredOpportunities: Array<{
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    state: MarketingPlanState;
    selectedLinesOfBusiness: Array<string>;
    desiredEffectiveDate: any;
    renewalOf?: string | null;
    insured: {
      __typename?: "Insured";
      id: string & { __scalar: "UUID" };
      name: string;
      primaryState?: string | null;
      naicsCodes: Array<string>;
      description?: string | null;
      agent: {
        __typename?: "UserAccount";
        agencyId: string & { __scalar: "UUID" };
        email: string;
        firstName: string;
        lastName: string;
      };
    };
    broker?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
  }>;
};

export type InsuredPoliciesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InsuredPoliciesQuery = {
  __typename?: "Query";
  insured?: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    policies: Array<{
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      state: PolicyState;
      policyNumber: string;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      createdAt: any;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
    }>;
  } | null;
};

export type InsuredPremiseLocationsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InsuredPremiseLocationsQuery = {
  __typename?: "Query";
  locations: Array<{
    __typename?: "InsuredPremiseLocation";
    id: string;
    locationNumber: number;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    county?: string | null;
    violentCrimeScore?: (string & { __scalar: "Decimal" }) | null;
    propertyCrimeScore?: (string & { __scalar: "Decimal" }) | null;
    buildings: Array<{
      __typename?: "InsuredPremiseBuilding";
      id: string;
      locationId: string;
      buildingNumber: number;
      description?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      fullTimeEmployeeCount?: number | null;
      partTimeEmployeeCount?: number | null;
      annualRevenue?: (string & { __scalar: "Decimal" }) | null;
      occupiedArea?: number | null;
      totalBuildingArea?: number | null;
      wiringYear?: string | null;
      roofingYear?: string | null;
      heatingYear?: string | null;
      plumbingYear?: string | null;
      tiv?: number | null;
      roofType?: string | null;
      constructionType?: string | null;
      yearBuilt?: string | null;
      burglarAlarmType?: string | null;
      sprinklerPercent?: string | null;
    }>;
  }>;
};

export type InsuredSubmissionsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InsuredSubmissionsQuery = {
  __typename?: "Query";
  insured?: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    submissions: Array<{
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      state: SubmissionState;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        carrierName: string;
        carrierProductName?: string | null;
        submissionCarrierContacts?: Array<{ __typename?: "CarrierContact"; email: string }> | null;
      };
      quotes: Array<{
        __typename?: "Quote";
        id: string & { __scalar: "UUID" };
        insuredId: string;
        submissionId: string;
        state: QuoteState;
        premium?: number | null;
        totalPremium?: number | null;
        mep?: number | null;
        agentCommission?: number | null;
        grossCommission?: number | null;
        carrierFee?: number | null;
        brokerFee?: number | null;
        inspectionFee?: number | null;
        effectiveDate?: any | null;
        expirationDate?: any | null;
        createdAt: any;
        lastTransitionAt: any;
        outstandingSubjectivities?: string | null;
        redactedQuoteId?: string | null;
        processedQuoteId?: string | null;
        redactedQuote?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        processedQuote?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        policy?: {
          __typename?: "Policy";
          id: string & { __scalar: "UUID" };
          insuredId: string;
          opportunityId: string;
          quoteId: string;
          state: PolicyState;
          policyNumber: string;
          effectiveDate?: any | null;
          expirationDate?: any | null;
          createdAt: any;
          lastTransitionAt: any;
          awaitingSubjectivities: boolean;
          endorsementsRequested: boolean;
          policyFileId?: string | null;
          redactedBinderFileId?: string | null;
          processedBinderFileId?: string | null;
          bindHqPolicyId?: string | null;
          policyFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          redactedBinderFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          processedBinderFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          stateTransitions: Array<{
            __typename?: "PolicyStateTransition";
            id: string;
            policyId: string;
            previousState: PolicyState;
            state: PolicyState;
            createdAt: any;
            originatorId: string;
          }>;
          appetiteProduct: {
            __typename?: "AppetiteProduct";
            id: string;
            carrierName: string;
            carrierProductName?: string | null;
            marketType?: MarketTypes | null;
            coverages: string;
            endorsements: string;
            coreLines: Array<string>;
            productDescription?: string | null;
            portalLink?: string | null;
            submissionInstructions?: string | null;
            bindHqCarrierIds: Array<string>;
            bindHqMarketingCompanyId?: string | null;
            submissionCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
            referenceCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
          };
        } | null;
        stateTransitions: Array<{
          __typename?: "QuoteStateTransition";
          id: string;
          quoteId: string;
          previousState: QuoteState;
          state: QuoteState;
          createdAt: any;
          originatorId: string;
        }>;
        submission: {
          __typename?: "Submission";
          id: string & { __scalar: "UUID" };
          opportunityId: string;
          appetiteProduct: {
            __typename?: "AppetiteProduct";
            id: string;
            carrierName: string;
            carrierProductName?: string | null;
            marketType?: MarketTypes | null;
            coverages: string;
            endorsements: string;
            coreLines: Array<string>;
            productDescription?: string | null;
            portalLink?: string | null;
            submissionInstructions?: string | null;
            bindHqCarrierIds: Array<string>;
            bindHqMarketingCompanyId?: string | null;
            submissionCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
            referenceCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
          };
        };
      }>;
    }>;
  } | null;
};

export type InsuredWithDuplicateFlagQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InsuredWithDuplicateFlagQuery = {
  __typename?: "Query";
  insured?: {
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    possibleDuplicates: Array<{
      __typename?: "Insured";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      name: string;
    }>;
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  } | null;
};

export type InsuredsQueryVariables = Exact<{
  input: InsuredFilterInput;
}>;

export type InsuredsQuery = {
  __typename?: "Query";
  insureds: Array<{
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    name: string;
    dba?: string | null;
    legalEntityType?: string | null;
    description?: string | null;
    primaryState?: string | null;
    naicsCodes: Array<string>;
    isoCglCodes: Array<string>;
    sicCodes: Array<string>;
    exposures?: string | null;
    mergedIntoId?: (string & { __scalar: "UUID" }) | null;
    revenue?: number | null;
    tiv?: number | null;
    agentId: string & { __scalar: "UUID" };
    businessClasses: Array<{
      __typename?: "BusinessClass";
      id?: string | null;
      description?: string | null;
      system: BusinessClassSystem;
      code: string;
    }>;
    agent: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    };
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
  }>;
};

export type InsuredsDeepQueryVariables = Exact<{
  input: InsuredFilterInput;
}>;

export type InsuredsDeepQuery = {
  __typename?: "Query";
  insureds: Array<{
    __typename?: "Insured";
    id: string & { __scalar: "UUID" };
    name: string;
    agent: {
      __typename?: "UserAccount";
      firstName: string;
      lastName: string;
      agency: { __typename?: "Agency"; name: string };
    };
    opportunities: Array<{
      __typename?: "Opportunity";
      id: string & { __scalar: "UUID" };
      deletedAt?: any | null;
      desiredEffectiveDate: any;
      selectedLinesOfBusiness: Array<string>;
      state: MarketingPlanState;
    }>;
  }>;
};

export type LeadAgentNamesQueryVariables = Exact<{
  agencyId?: InputMaybe<Scalars["String"]>;
}>;

export type LeadAgentNamesQuery = {
  __typename?: "Query";
  leadAgentNames: Array<{ __typename?: "LeadAgentName"; firstName?: string | null; lastName?: string | null }>;
};

export type LeadsQueryVariables = Exact<{
  input: LeadsFilterInput;
}>;

export type LeadsQuery = {
  __typename?: "Query";
  leads: Array<{
    __typename?: "Lead";
    id: string & { __scalar: "UUID" };
    accountName: string;
    effectiveDate?: any | null;
    expirationDate: any;
    rawLines: Array<string>;
    coreLines: Array<string>;
    carriers: Array<string>;
    carrierOverride?: string | null;
    isQwCarrier?: boolean | null;
    qwCarrierMatch?: QwCarrierMatch | null;
    premium?: (string & { __scalar: "Decimal" }) | null;
    description?: string | null;
    naicsCode?: string | null;
    agentFirstName?: string | null;
    agentLastName?: string | null;
    archived: boolean;
    highlighted: boolean;
    notes?: string | null;
    desireToRemarket?: DesireToRemarket | null;
    currentAgentCommission?: (string & { __scalar: "Decimal" }) | null;
    qwAgentCommission?: (string & { __scalar: "Decimal" }) | null;
    agencyId: string & { __scalar: "UUID" };
    opportunityId?: (string & { __scalar: "UUID" }) | null;
    topAppetiteProductScore?: (string & { __scalar: "Decimal" }) | null;
  }>;
};

export type LinesOfBusinessQueryVariables = Exact<{ [key: string]: never }>;

export type LinesOfBusinessQuery = {
  __typename?: "Query";
  linesOfBusiness: Array<{ __typename?: "LineOfBusiness"; id: string; displayName: string }>;
};

export type MyAccountQueryVariables = Exact<{ [key: string]: never }>;

export type MyAccountQuery = {
  __typename?: "Query";
  myAccount: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  };
};

export type OpportunityQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OpportunityQuery = {
  __typename?: "Query";
  opportunity?: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    state: MarketingPlanState;
    selectedLinesOfBusiness: Array<string>;
    desiredEffectiveDate: any;
    renewalOf?: string | null;
    insured: {
      __typename?: "Insured";
      id: string & { __scalar: "UUID" };
      name: string;
      primaryState?: string | null;
      naicsCodes: Array<string>;
      description?: string | null;
      agent: {
        __typename?: "UserAccount";
        agencyId: string & { __scalar: "UUID" };
        email: string;
        firstName: string;
        lastName: string;
      };
    };
    broker?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
  } | null;
};

export type OpportunityDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OpportunityDetailsQuery = {
  __typename?: "Query";
  opportunity?: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    isTest: boolean;
    state: MarketingPlanState;
    createdAt: any;
    insuredId: string & { __scalar: "UUID" };
    updatedAt?: any | null;
    selectedLinesOfBusiness: Array<string>;
    desiredEffectiveDate: any;
    brokerageFolderId?: string | null;
    renewalOf?: string | null;
    createdBy: { __typename?: "UserAccount"; id: string & { __scalar: "UUID" }; firstName: string; lastName: string };
    updatedBy?: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
    } | null;
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    submissions: Array<{
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProductId: string;
      bindHqApplicationId?: string | null;
      state: SubmissionState;
      createdAt: any;
      updatedAt?: any | null;
      lastTransitionAt: any;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
      quotes: Array<{
        __typename?: "Quote";
        id: string & { __scalar: "UUID" };
        insuredId: string;
        submissionId: string;
        state: QuoteState;
        premium?: number | null;
        totalPremium?: number | null;
        mep?: number | null;
        agentCommission?: number | null;
        grossCommission?: number | null;
        carrierFee?: number | null;
        brokerFee?: number | null;
        inspectionFee?: number | null;
        effectiveDate?: any | null;
        expirationDate?: any | null;
        createdAt: any;
        lastTransitionAt: any;
        outstandingSubjectivities?: string | null;
        redactedQuoteId?: string | null;
        processedQuoteId?: string | null;
        redactedQuote?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        processedQuote?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        policy?: {
          __typename?: "Policy";
          id: string & { __scalar: "UUID" };
          insuredId: string;
          opportunityId: string;
          quoteId: string;
          state: PolicyState;
          policyNumber: string;
          effectiveDate?: any | null;
          expirationDate?: any | null;
          createdAt: any;
          lastTransitionAt: any;
          awaitingSubjectivities: boolean;
          endorsementsRequested: boolean;
          policyFileId?: string | null;
          redactedBinderFileId?: string | null;
          processedBinderFileId?: string | null;
          bindHqPolicyId?: string | null;
          policyFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          redactedBinderFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          processedBinderFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          stateTransitions: Array<{
            __typename?: "PolicyStateTransition";
            id: string;
            policyId: string;
            previousState: PolicyState;
            state: PolicyState;
            createdAt: any;
            originatorId: string;
          }>;
          appetiteProduct: {
            __typename?: "AppetiteProduct";
            id: string;
            carrierName: string;
            carrierProductName?: string | null;
            marketType?: MarketTypes | null;
            coverages: string;
            endorsements: string;
            coreLines: Array<string>;
            productDescription?: string | null;
            portalLink?: string | null;
            submissionInstructions?: string | null;
            bindHqCarrierIds: Array<string>;
            bindHqMarketingCompanyId?: string | null;
            submissionCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
            referenceCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
          };
        } | null;
        stateTransitions: Array<{
          __typename?: "QuoteStateTransition";
          id: string;
          quoteId: string;
          previousState: QuoteState;
          state: QuoteState;
          createdAt: any;
          originatorId: string;
        }>;
        submission: {
          __typename?: "Submission";
          id: string & { __scalar: "UUID" };
          opportunityId: string;
          appetiteProduct: {
            __typename?: "AppetiteProduct";
            id: string;
            carrierName: string;
            carrierProductName?: string | null;
            marketType?: MarketTypes | null;
            coverages: string;
            endorsements: string;
            coreLines: Array<string>;
            productDescription?: string | null;
            portalLink?: string | null;
            submissionInstructions?: string | null;
            bindHqCarrierIds: Array<string>;
            bindHqMarketingCompanyId?: string | null;
            submissionCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
            referenceCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
          };
        };
      }>;
      stateTransitions: Array<{
        __typename?: "SubmissionStateTransition";
        id: string;
        submissionId: string;
        previousState: SubmissionState;
        details?: string | null;
        state: SubmissionState;
        createdAt: any;
        originatorId: string;
      }>;
      exclusionReason?: {
        __typename?: "ProductExclusion";
        id: string;
        appetiteProductId: string;
        reason?: string | null;
        state?: string | null;
        naicsCode?: string | null;
      } | null;
    }>;
    appetiteProducts: Array<{
      __typename?: "AppetiteProduct";
      id: string;
      marketType?: MarketTypes | null;
      carrierName: string;
      carrierProductName?: string | null;
    }>;
    renewal?: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } } | null;
    insured: {
      __typename?: "Insured";
      brokerageFolderId?: string | null;
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      name: string;
      dba?: string | null;
      legalEntityType?: string | null;
      description?: string | null;
      primaryState?: string | null;
      naicsCodes: Array<string>;
      isoCglCodes: Array<string>;
      sicCodes: Array<string>;
      exposures?: string | null;
      mergedIntoId?: (string & { __scalar: "UUID" }) | null;
      revenue?: number | null;
      tiv?: number | null;
      agentId: string & { __scalar: "UUID" };
      businessClasses: Array<{
        __typename?: "BusinessClass";
        id?: string | null;
        description?: string | null;
        system: BusinessClassSystem;
        code: string;
      }>;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
    };
    files: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
    leads: Array<{
      __typename?: "Lead";
      id: string & { __scalar: "UUID" };
      accountName: string;
      effectiveDate?: any | null;
      expirationDate: any;
      rawLines: Array<string>;
      coreLines: Array<string>;
      carriers: Array<string>;
      carrierOverride?: string | null;
      isQwCarrier?: boolean | null;
      qwCarrierMatch?: QwCarrierMatch | null;
      premium?: (string & { __scalar: "Decimal" }) | null;
      description?: string | null;
      naicsCode?: string | null;
      agentFirstName?: string | null;
      agentLastName?: string | null;
      archived: boolean;
      highlighted: boolean;
      notes?: string | null;
      desireToRemarket?: DesireToRemarket | null;
      currentAgentCommission?: (string & { __scalar: "Decimal" }) | null;
      qwAgentCommission?: (string & { __scalar: "Decimal" }) | null;
      agencyId: string & { __scalar: "UUID" };
      opportunityId?: (string & { __scalar: "UUID" }) | null;
      topAppetiteProductScore?: (string & { __scalar: "Decimal" }) | null;
    }>;
    notes: Array<{
      __typename?: "OpportunityNote";
      id: string;
      createdAt: any;
      text: string;
      creator: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    }>;
    stateTransitions: Array<{
      __typename?: "MarketingPlanStateTransition";
      id: string;
      marketingPlanId: string;
      previousState: MarketingPlanState;
      state: MarketingPlanState;
      createdAt: any;
      originatorId: string;
    }>;
    verticalMarketingPlanTemplate?: {
      __typename?: "VerticalMarketingPlanTemplate";
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        rank: number;
        rules: string;
        appetiteProduct: { __typename?: "AppetiteProduct"; id: string };
      }>;
    } | null;
  } | null;
};

export type OpportunityEventQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OpportunityEventQuery = {
  __typename?: "Query";
  opportunityEvent:
    | {
        __typename: "ActionApprovedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionDismissedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionRejectedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionRequestedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AlbyUpdateEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ApplicationSubmittedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AssignmentChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AutomatedFollowUpEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "BotCommentEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "BusinessClassCodesChangedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "CoveragesChangedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "CreatedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "DesiredEffectiveDateChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "EmailEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        email: {
          __typename?: "Email";
          id: string & { __scalar: "UUID" };
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          body: string;
          extractedBody: string;
          dateReceived: any;
          linkToEmailInGoogleGroups?: string | null;
        };
      }
    | {
        __typename: "FileUploadEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "GmailMessageEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        gmailMessage: {
          __typename?: "GmailMessage";
          id: string & { __scalar: "UUID" };
          headerMessageId: string;
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          replyTo?: string | null;
          textAsHtml: string;
          dateReceived: any;
          attachments: Array<{
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          }>;
        };
      }
    | {
        __typename: "GmailRelayEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        gmailRelay: {
          __typename?: "GmailRelay";
          recipientEmail: string;
          initiator: { __typename?: "UserAccount"; email: string };
        };
        gmailMessage: {
          __typename?: "GmailMessage";
          html: string;
          id: string & { __scalar: "UUID" };
          headerMessageId: string;
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          replyTo?: string | null;
          textAsHtml: string;
          dateReceived: any;
          attachments: Array<{
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          }>;
        };
      }
    | {
        __typename: "OutcomeChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ProductsAddedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ProductsRemovedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "RequirementAddedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "StatusChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "SubmissionStatusChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "TriageAssignmentEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "UserComment";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        createdAt: any;
        updatedAt?: any | null;
        isPublic: boolean;
        userAccountId: string & { __scalar: "UUID" };
        opportunityId: string & { __scalar: "UUID" };
        userAccount: {
          __typename: "UserAccount";
          id: string & { __scalar: "UUID" };
          createdAt: any;
          bindHqId?: string | null;
          firstName: string;
          lastName: string;
          email: string;
          roles: Array<Roles>;
          internal: boolean;
          agencyId: string & { __scalar: "UUID" };
          assignmentsEnabled: boolean;
          avatarUrl?: string | null;
          phone?: string | null;
          agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
        };
      };
};

export type OpportunityEventsQueryVariables = Exact<{
  input: OpportunityEventsFilterInput;
}>;

export type OpportunityEventsQuery = {
  __typename?: "Query";
  opportunityEvents: Array<
    | {
        __typename: "ActionApprovedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionDismissedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionRejectedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionRequestedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AlbyUpdateEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ApplicationSubmittedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AssignmentChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AutomatedFollowUpEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "BotCommentEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "BusinessClassCodesChangedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "CoveragesChangedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "CreatedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "DesiredEffectiveDateChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "EmailEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        email: {
          __typename?: "Email";
          id: string & { __scalar: "UUID" };
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          body: string;
          extractedBody: string;
          dateReceived: any;
          linkToEmailInGoogleGroups?: string | null;
        };
      }
    | {
        __typename: "FileUploadEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "GmailMessageEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        gmailMessage: {
          __typename?: "GmailMessage";
          id: string & { __scalar: "UUID" };
          headerMessageId: string;
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          replyTo?: string | null;
          textAsHtml: string;
          dateReceived: any;
          attachments: Array<{
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          }>;
        };
      }
    | {
        __typename: "GmailRelayEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        gmailRelay: {
          __typename?: "GmailRelay";
          recipientEmail: string;
          initiator: { __typename?: "UserAccount"; email: string };
        };
        gmailMessage: {
          __typename?: "GmailMessage";
          html: string;
          id: string & { __scalar: "UUID" };
          headerMessageId: string;
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          replyTo?: string | null;
          textAsHtml: string;
          dateReceived: any;
          attachments: Array<{
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          }>;
        };
      }
    | {
        __typename: "OutcomeChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ProductsAddedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ProductsRemovedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "RequirementAddedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "StatusChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "SubmissionStatusChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "TriageAssignmentEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "UserComment";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        createdAt: any;
        updatedAt?: any | null;
        isPublic: boolean;
        userAccountId: string & { __scalar: "UUID" };
        opportunityId: string & { __scalar: "UUID" };
        userAccount: {
          __typename: "UserAccount";
          id: string & { __scalar: "UUID" };
          createdAt: any;
          bindHqId?: string | null;
          firstName: string;
          lastName: string;
          email: string;
          roles: Array<Roles>;
          internal: boolean;
          agencyId: string & { __scalar: "UUID" };
          assignmentsEnabled: boolean;
          avatarUrl?: string | null;
          phone?: string | null;
          agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
        };
      }
  >;
};

export type OpportunityRequirementQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OpportunityRequirementQuery = {
  __typename?: "Query";
  agentAction: {
    __typename?: "AgentAction";
    id: string & { __scalar: "UUID" };
    title: string;
    description?: string | null;
    actionType: AgentActionType;
    supplementalForm?: SupplementalForm | null;
    complete: boolean;
    expeditedRequirement: boolean;
  };
};

export type OpportunityRequirementsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OpportunityRequirementsQuery = {
  __typename?: "Query";
  opportunity?: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    state: MarketingPlanState;
    filesProcessing: boolean;
    insured: {
      __typename?: "Insured";
      id: string & { __scalar: "UUID" };
      name: string;
      agent: { __typename?: "UserAccount"; firstName: string };
    };
    agentActions: Array<{
      __typename?: "AgentAction";
      id: string & { __scalar: "UUID" };
      title: string;
      description?: string | null;
      actionType: AgentActionType;
      supplementalForm?: SupplementalForm | null;
      complete: boolean;
      expeditedRequirement: boolean;
      ui?: {
        __typename?: "AgentActionUI";
        title: string;
        description: string;
        steps: Array<{
          __typename?: "AgentActionUIStep";
          title: string;
          description: string;
          elements: Array<{
            __typename?: "AgentActionUIElement";
            element: string;
            clientDataKey: string;
            array: boolean;
            props: string;
          }>;
        }>;
      } | null;
      logs: Array<{
        __typename?: "AgentActionLog";
        id: string;
        response?: string | null;
        file?: {
          __typename?: "FileUpload";
          deletedAt?: any | null;
          createdAt: any;
          labels: Array<string>;
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
          uploader?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type PaginatedAgenciesQueryVariables = Exact<{
  args?: InputMaybe<AgenciesFilterInput>;
  pagination: PaginationInput;
}>;

export type PaginatedAgenciesQuery = {
  __typename?: "Query";
  paginatedAgencies: {
    __typename?: "PaginatedAgencies";
    numRecords: number;
    numPages: number;
    agencies: Array<{
      __typename?: "Agency";
      id: string;
      name: string;
      isTest: boolean;
      createdAt: any;
      owner?: { __typename?: "UserAccount"; firstName: string; lastName: string } | null;
    }>;
  };
};

export type PaginatedExtractedLabelsQueryVariables = Exact<{
  input?: InputMaybe<FindExtractedLabelsInput>;
  pagination: PaginationInput;
}>;

export type PaginatedExtractedLabelsQuery = {
  __typename?: "Query";
  paginatedExtractedLabels: {
    __typename?: "PaginatedExtractedLabels";
    numPages: number;
    numRecords: number;
    extractedLabels: Array<{
      __typename?: "ExtractedLabel";
      id: string;
      key: string;
      source: string;
      count: string;
      primaryLabels: Array<{
        __typename?: "PrimaryLabel";
        id: string;
        primaryKey: string;
        displayName?: string | null;
      }>;
    }>;
  };
};

export type PaginatedInsuredsQueryVariables = Exact<{
  args?: InputMaybe<InsuredFilterInput>;
  pagination: PaginationInput;
}>;

export type PaginatedInsuredsQuery = {
  __typename?: "Query";
  paginatedInsureds: {
    __typename?: "PaginatedInsureds";
    numRecords: number;
    numPages: number;
    insureds: Array<{ __typename?: "Insured"; id: string & { __scalar: "UUID" }; name: string }>;
  };
};

export type PaginatedPrimaryLabelsQueryVariables = Exact<{
  input?: InputMaybe<FindPrimaryLabelsArgs>;
  pagination: PaginationInput;
}>;

export type PaginatedPrimaryLabelsQuery = {
  __typename?: "Query";
  paginatedPrimaryLabels: {
    __typename?: "PaginatedPrimaryLabels";
    numPages: number;
    numRecords: number;
    PrimaryLabels: Array<{
      __typename?: "PrimaryLabel";
      id: string;
      primaryKey: string;
      displayName?: string | null;
      dataType?: PrimaryLabelDataType | null;
      description?: string | null;
      createdAt: any;
      updatedAt?: any | null;
      createdById?: string | null;
      updatedById?: string | null;
      extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
      createdBy?: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      } | null;
      updatedBy?: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      } | null;
    }>;
  };
};

export type PoliciesQueryVariables = Exact<{
  input: PolicyFilterInput;
}>;

export type PoliciesQuery = {
  __typename?: "Query";
  policies: Array<{
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  }>;
};

export type PolicyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PolicyQuery = {
  __typename?: "Query";
  policy: {
    __typename?: "Policy";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    opportunityId: string;
    quoteId: string;
    state: PolicyState;
    policyNumber: string;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    awaitingSubjectivities: boolean;
    endorsementsRequested: boolean;
    policyFileId?: string | null;
    redactedBinderFileId?: string | null;
    processedBinderFileId?: string | null;
    bindHqPolicyId?: string | null;
    policyFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    redactedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedBinderFile?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    stateTransitions: Array<{
      __typename?: "PolicyStateTransition";
      id: string;
      policyId: string;
      previousState: PolicyState;
      state: PolicyState;
      createdAt: any;
      originatorId: string;
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
  };
};

export type PolicyFileTypesQueryVariables = Exact<{ [key: string]: never }>;

export type PolicyFileTypesQuery = { __typename?: "Query"; policyFileTypes: PolicyFileType };

export type PrimaryLabelQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PrimaryLabelQuery = {
  __typename?: "Query";
  primaryLabel: {
    __typename?: "PrimaryLabel";
    id: string;
    primaryKey: string;
    displayName?: string | null;
    dataType?: PrimaryLabelDataType | null;
    description?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    createdById?: string | null;
    updatedById?: string | null;
    extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
    createdBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    updatedBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  };
};

export type PrimaryLabelsQueryVariables = Exact<{
  input?: InputMaybe<FindPrimaryLabelsArgs>;
}>;

export type PrimaryLabelsQuery = {
  __typename?: "Query";
  primaryLabels: Array<{
    __typename?: "PrimaryLabel";
    id: string;
    primaryKey: string;
    displayName?: string | null;
    dataType?: PrimaryLabelDataType | null;
    description?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    createdById?: string | null;
    updatedById?: string | null;
    extractedLabels: Array<{ __typename?: "ExtractedLabel"; id: string; key: string; source: string; count: string }>;
    createdBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    updatedBy?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
  }>;
};

export type ProposalValuesQueryVariables = Exact<{
  input: QuoteProposalValuesInput;
}>;

export type ProposalValuesQuery = {
  __typename?: "Query";
  proposalValues?: {
    __typename?: "QuoteProposalValues";
    stampingFee?: number | null;
    mep?: number | null;
    carrierName?: string | null;
    premium?: number | null;
    subjectivities?: Array<string> | null;
    insuredName?: string | null;
    effectiveDate?: string | null;
    lines?: Array<string> | null;
    homeState?: string | null;
    agentFirstName?: string | null;
    agencyName?: string | null;
    agentCommission?: number | null;
    surplusLinesTaxes?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    totalPremium?: number | null;
  } | null;
};

export type QuoteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type QuoteQuery = {
  __typename?: "Query";
  quote: {
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    totalPremium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    outstandingSubjectivities?: string | null;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    };
  };
};

export type QuoteKitchenSinkQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type QuoteKitchenSinkQuery = {
  __typename?: "Query";
  quote: {
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      state: SubmissionState;
      createdAt: any;
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
      opportunity: {
        __typename?: "Opportunity";
        id: string & { __scalar: "UUID" };
        isTest: boolean;
        state: MarketingPlanState;
        createdAt: any;
        selectedLinesOfBusiness: Array<string>;
        desiredEffectiveDate: any;
        difficulties?: string | null;
        brokerageFolderId?: string | null;
        renewalOf?: string | null;
        insured: {
          __typename?: "Insured";
          agent: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          };
        };
        broker?: {
          __typename: "UserAccount";
          id: string & { __scalar: "UUID" };
          createdAt: any;
          bindHqId?: string | null;
          firstName: string;
          lastName: string;
          email: string;
          roles: Array<Roles>;
          internal: boolean;
          agencyId: string & { __scalar: "UUID" };
          assignmentsEnabled: boolean;
          avatarUrl?: string | null;
          phone?: string | null;
          agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
        } | null;
      };
    };
  };
};

export type QuotesQueryVariables = Exact<{
  input: QuotesInput;
}>;

export type QuotesQuery = {
  __typename?: "Query";
  quotes: Array<{
    __typename?: "Quote";
    id: string & { __scalar: "UUID" };
    insuredId: string;
    submissionId: string;
    state: QuoteState;
    premium?: number | null;
    mep?: number | null;
    agentCommission?: number | null;
    grossCommission?: number | null;
    carrierFee?: number | null;
    brokerFee?: number | null;
    inspectionFee?: number | null;
    effectiveDate?: any | null;
    expirationDate?: any | null;
    createdAt: any;
    lastTransitionAt: any;
    redactedQuoteId?: string | null;
    processedQuoteId?: string | null;
    redactedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    processedQuote?: {
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    } | null;
    policy?: {
      __typename?: "Policy";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      opportunityId: string;
      quoteId: string;
      state: PolicyState;
      policyNumber: string;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      awaitingSubjectivities: boolean;
      endorsementsRequested: boolean;
      policyFileId?: string | null;
      redactedBinderFileId?: string | null;
      processedBinderFileId?: string | null;
      bindHqPolicyId?: string | null;
      policyFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      redactedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedBinderFile?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      stateTransitions: Array<{
        __typename?: "PolicyStateTransition";
        id: string;
        policyId: string;
        previousState: PolicyState;
        state: PolicyState;
        createdAt: any;
        originatorId: string;
      }>;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
    } | null;
    stateTransitions: Array<{
      __typename?: "QuoteStateTransition";
      id: string;
      quoteId: string;
      previousState: QuoteState;
      state: QuoteState;
      createdAt: any;
      originatorId: string;
    }>;
    submission: {
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      state: SubmissionState;
      createdAt: any;
      opportunityId: string;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
      opportunity: {
        __typename?: "Opportunity";
        id: string & { __scalar: "UUID" };
        isTest: boolean;
        state: MarketingPlanState;
        createdAt: any;
        selectedLinesOfBusiness: Array<string>;
        desiredEffectiveDate: any;
        difficulties?: string | null;
        brokerageFolderId?: string | null;
        renewalOf?: string | null;
        insured: {
          __typename?: "Insured";
          agent: {
            __typename: "UserAccount";
            id: string & { __scalar: "UUID" };
            createdAt: any;
            bindHqId?: string | null;
            firstName: string;
            lastName: string;
            email: string;
            roles: Array<Roles>;
            internal: boolean;
            agencyId: string & { __scalar: "UUID" };
            assignmentsEnabled: boolean;
            avatarUrl?: string | null;
            phone?: string | null;
            agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
          };
        };
        broker?: {
          __typename: "UserAccount";
          id: string & { __scalar: "UUID" };
          createdAt: any;
          bindHqId?: string | null;
          firstName: string;
          lastName: string;
          email: string;
          roles: Array<Roles>;
          internal: boolean;
          agencyId: string & { __scalar: "UUID" };
          assignmentsEnabled: boolean;
          avatarUrl?: string | null;
          phone?: string | null;
          agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
        } | null;
      };
    };
  }>;
};

export type SearchAppetiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SearchAppetiteQuery = {
  __typename?: "Query";
  appetiteSearchForOpportunity: {
    __typename?: "AppetiteSearchResult";
    scoredProducts: Array<{
      __typename?: "ScoredAppetiteProduct";
      product: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        authority: Array<string>;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        bindingGuideResults: Array<{
          __typename?: "BindingGuideResult";
          id: string;
          cgl: string;
          lob: string;
          result: BindingAuthority;
          explanation?: string | null;
        }>;
      };
    }>;
  };
};

export type SearchAppetiteForOpportunityQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SearchAppetiteForOpportunityQuery = {
  __typename?: "Query";
  appetiteSearchForOpportunity: {
    __typename?: "AppetiteSearchResult";
    scoredProducts: Array<{
      __typename?: "ScoredAppetiteProduct";
      score: number;
      reasons?: Array<string> | null;
      previouslyMarketedCount: number;
      previouslyQuotedCount: number;
      product: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierSlug?: string | null;
        carrierProductName?: string | null;
        productDescription?: string | null;
        coverages: string;
        coreLines: Array<string>;
      };
    }>;
  };
};

export type SearchBusinessClassesQueryVariables = Exact<{
  input: BusinessClassificationsSearchInput;
}>;

export type SearchBusinessClassesQuery = {
  __typename?: "Query";
  searchBusinessClasses: Array<{
    __typename?: "BusinessClass";
    id?: string | null;
    description?: string | null;
    system: BusinessClassSystem;
    code: string;
  }>;
};

export type SelectProductsOpportunityQueryVariables = Exact<{ [key: string]: never }>;

export type SelectProductsOpportunityQuery = { __typename?: "Query"; appetiteProductLines: Array<string> };

export type StateTransitionsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type StateTransitionsQuery = {
  __typename?: "Query";
  opportunity?: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    stateTransitions: Array<{
      __typename?: "MarketingPlanStateTransition";
      id: string;
      marketingPlanId: string;
      originatorId: string;
      previousState: MarketingPlanState;
      state: MarketingPlanState;
      createdAt: any;
    }>;
    submissions: Array<{
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
      };
      stateTransitions: Array<{
        __typename?: "SubmissionStateTransition";
        id: string;
        submissionId: string;
        originatorId: string;
        previousState: SubmissionState;
        state: SubmissionState;
        createdAt: any;
      }>;
      quotes: Array<{
        __typename?: "Quote";
        id: string & { __scalar: "UUID" };
        stateTransitions: Array<{
          __typename?: "QuoteStateTransition";
          id: string;
          quoteId: string;
          originatorId: string;
          previousState: QuoteState;
          state: QuoteState;
          createdAt: any;
        }>;
        policy?: {
          __typename?: "Policy";
          id: string & { __scalar: "UUID" };
          stateTransitions: Array<{
            __typename?: "PolicyStateTransition";
            id: string;
            policyId: string;
            originatorId: string;
            previousState: PolicyState;
            state: PolicyState;
            createdAt: any;
          }>;
        } | null;
      }>;
    }>;
  } | null;
};

export type SubmissionQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SubmissionQuery = {
  __typename?: "Query";
  submission: {
    __typename?: "Submission";
    id: string & { __scalar: "UUID" };
    opportunityId: string;
    appetiteProductId: string;
    bindHqApplicationId?: string | null;
    state: SubmissionState;
    createdAt: any;
    updatedAt?: any | null;
    lastTransitionAt: any;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
    quotes: Array<{
      __typename?: "Quote";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      submissionId: string;
      state: QuoteState;
      premium?: number | null;
      totalPremium?: number | null;
      mep?: number | null;
      agentCommission?: number | null;
      grossCommission?: number | null;
      carrierFee?: number | null;
      brokerFee?: number | null;
      inspectionFee?: number | null;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      outstandingSubjectivities?: string | null;
      redactedQuoteId?: string | null;
      processedQuoteId?: string | null;
      redactedQuote?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedQuote?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      policy?: {
        __typename?: "Policy";
        id: string & { __scalar: "UUID" };
        insuredId: string;
        opportunityId: string;
        quoteId: string;
        state: PolicyState;
        policyNumber: string;
        effectiveDate?: any | null;
        expirationDate?: any | null;
        createdAt: any;
        lastTransitionAt: any;
        awaitingSubjectivities: boolean;
        endorsementsRequested: boolean;
        policyFileId?: string | null;
        redactedBinderFileId?: string | null;
        processedBinderFileId?: string | null;
        bindHqPolicyId?: string | null;
        policyFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        redactedBinderFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        processedBinderFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        stateTransitions: Array<{
          __typename?: "PolicyStateTransition";
          id: string;
          policyId: string;
          previousState: PolicyState;
          state: PolicyState;
          createdAt: any;
          originatorId: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      } | null;
      stateTransitions: Array<{
        __typename?: "QuoteStateTransition";
        id: string;
        quoteId: string;
        previousState: QuoteState;
        state: QuoteState;
        createdAt: any;
        originatorId: string;
      }>;
      submission: {
        __typename?: "Submission";
        id: string & { __scalar: "UUID" };
        opportunityId: string;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      };
    }>;
    stateTransitions: Array<{
      __typename?: "SubmissionStateTransition";
      id: string;
      submissionId: string;
      previousState: SubmissionState;
      details?: string | null;
      state: SubmissionState;
      createdAt: any;
      originatorId: string;
    }>;
  };
};

export type SubmissionDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SubmissionDetailsQuery = {
  __typename?: "Query";
  submission: {
    __typename?: "Submission";
    id: string & { __scalar: "UUID" };
    opportunityId: string;
    appetiteProductId: string;
    bindHqApplicationId?: string | null;
    state: SubmissionState;
    createdAt: any;
    updatedAt?: any | null;
    lastTransitionAt: any;
    quotes: Array<{
      __typename?: "Quote";
      id: string & { __scalar: "UUID" };
      insuredId: string;
      submissionId: string;
      state: QuoteState;
      premium?: number | null;
      totalPremium?: number | null;
      mep?: number | null;
      agentCommission?: number | null;
      grossCommission?: number | null;
      carrierFee?: number | null;
      brokerFee?: number | null;
      inspectionFee?: number | null;
      effectiveDate?: any | null;
      expirationDate?: any | null;
      createdAt: any;
      lastTransitionAt: any;
      outstandingSubjectivities?: string | null;
      redactedQuoteId?: string | null;
      processedQuoteId?: string | null;
      redactedQuote?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      processedQuote?: {
        __typename?: "FileUpload";
        id: string;
        filename: string;
        mimeType: string;
        fileSource?: File_Source | null;
        audience?: string | null;
      } | null;
      policy?: {
        __typename?: "Policy";
        id: string & { __scalar: "UUID" };
        insuredId: string;
        opportunityId: string;
        quoteId: string;
        state: PolicyState;
        policyNumber: string;
        effectiveDate?: any | null;
        expirationDate?: any | null;
        createdAt: any;
        lastTransitionAt: any;
        awaitingSubjectivities: boolean;
        endorsementsRequested: boolean;
        policyFileId?: string | null;
        redactedBinderFileId?: string | null;
        processedBinderFileId?: string | null;
        bindHqPolicyId?: string | null;
        policyFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        redactedBinderFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        processedBinderFile?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        stateTransitions: Array<{
          __typename?: "PolicyStateTransition";
          id: string;
          policyId: string;
          previousState: PolicyState;
          state: PolicyState;
          createdAt: any;
          originatorId: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      } | null;
      stateTransitions: Array<{
        __typename?: "QuoteStateTransition";
        id: string;
        quoteId: string;
        previousState: QuoteState;
        state: QuoteState;
        createdAt: any;
        originatorId: string;
      }>;
      submission: {
        __typename?: "Submission";
        id: string & { __scalar: "UUID" };
        opportunityId: string;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      };
    }>;
    appetiteProduct: {
      __typename?: "AppetiteProduct";
      id: string;
      carrierName: string;
      carrierProductName?: string | null;
      marketType?: MarketTypes | null;
      coverages: string;
      endorsements: string;
      coreLines: Array<string>;
      productDescription?: string | null;
      portalLink?: string | null;
      submissionInstructions?: string | null;
      bindHqCarrierIds: Array<string>;
      bindHqMarketingCompanyId?: string | null;
      submissionCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
      referenceCarrierContacts?: Array<{
        __typename?: "CarrierContact";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        title?: string | null;
      }> | null;
    };
    stateTransitions: Array<{
      __typename?: "SubmissionStateTransition";
      id: string;
      submissionId: string;
      previousState: SubmissionState;
      details?: string | null;
      state: SubmissionState;
      createdAt: any;
      originatorId: string;
    }>;
  };
};

export type SubmissionStateLogExclusionReasonsQueryVariables = Exact<{
  input: SubmissionStateLogExclusionReasonsFilter;
}>;

export type SubmissionStateLogExclusionReasonsQuery = {
  __typename?: "Query";
  submissionStateLogExclusionReasons: Array<{
    __typename?: "SubmissionStateTransition";
    id: string;
    submissionId: string;
    previousState: SubmissionState;
    details?: string | null;
    state: SubmissionState;
    createdAt: any;
    originatorId: string;
  }>;
};

export type SubmissionsQueryVariables = Exact<{
  args: SubmissionsFilterInput;
}>;

export type SubmissionsQuery = {
  __typename?: "Query";
  submissions: {
    __typename?: "SubmissionResponse";
    submissions: Array<{
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      state: SubmissionState;
      personalizedEmailSubmissionBody?: string | null;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        carrierName: string;
        carrierProductName?: string | null;
        submissionCarrierContacts?: Array<{ __typename?: "CarrierContact"; email: string }> | null;
      };
      opportunity: {
        __typename?: "Opportunity";
        id: string & { __scalar: "UUID" };
        state: MarketingPlanState;
        desiredEffectiveDate: any;
        insured: { __typename?: "Insured"; name: string };
        broker?: { __typename?: "UserAccount"; firstName: string; lastName: string; email: string } | null;
      };
    }>;
  };
};

export type UnderwritingAssistantAccountsQueryVariables = Exact<{
  input?: UserAccountFindManyFilter;
}>;

export type UnderwritingAssistantAccountsQuery = {
  __typename?: "Query";
  userAccounts: Array<{
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  }>;
};

export type UserAccountsQueryVariables = Exact<{
  input: UserAccountFindManyFilter;
}>;

export type UserAccountsQuery = {
  __typename?: "Query";
  userAccounts: Array<{
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    createdAt: any;
    bindHqId?: string | null;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    assignmentsEnabled: boolean;
    avatarUrl?: string | null;
    phone?: string | null;
    agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
  }>;
};

export type VerticalQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type VerticalQuery = {
  __typename?: "Query";
  vertical: {
    __typename?: "Vertical";
    id: string;
    name: string;
    description?: string | null;
    enabled: boolean;
    isoCglCodes: Array<{
      __typename?: "ClassCode";
      id: string;
      description: string;
      classCode: string;
      classSystem: string;
    }>;
    marketingPlanTemplates: Array<{
      __typename?: "VerticalMarketingPlanTemplate";
      id: string;
      lobs: Array<string>;
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        id: string;
        rank: number;
        submissionEmail?: string | null;
        rules: string;
        requirements: Array<{
          __typename?: "VerticalMarketingPlanTemplateProductRequirement";
          id: string;
          title: string;
          description?: string | null;
          label?: string | null;
          groups: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      }>;
    }>;
  };
};

export type VerticalByCglQueryVariables = Exact<{
  input: FindVerticalByCglInput;
}>;

export type VerticalByCglQuery = {
  __typename?: "Query";
  verticalByCGL?: {
    __typename?: "Vertical";
    id: string;
    name: string;
    description?: string | null;
    enabled: boolean;
    isoCglCodes: Array<{
      __typename?: "ClassCode";
      id: string;
      description: string;
      classCode: string;
      classSystem: string;
    }>;
    marketingPlanTemplates: Array<{
      __typename?: "VerticalMarketingPlanTemplate";
      id: string;
      lobs: Array<string>;
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        id: string;
        rank: number;
        submissionEmail?: string | null;
        rules: string;
        requirements: Array<{
          __typename?: "VerticalMarketingPlanTemplateProductRequirement";
          id: string;
          title: string;
          description?: string | null;
          label?: string | null;
          groups: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      }>;
    }>;
  } | null;
};

export type VerticalsQueryVariables = Exact<{ [key: string]: never }>;

export type VerticalsQuery = {
  __typename?: "Query";
  verticals: Array<{
    __typename?: "Vertical";
    id: string;
    name: string;
    description?: string | null;
    enabled: boolean;
    isoCglCodes: Array<{
      __typename?: "ClassCode";
      id: string;
      description: string;
      classCode: string;
      classSystem: string;
    }>;
    marketingPlanTemplates: Array<{
      __typename?: "VerticalMarketingPlanTemplate";
      id: string;
      lobs: Array<string>;
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        id: string;
        rank: number;
        submissionEmail?: string | null;
        rules: string;
        requirements: Array<{
          __typename?: "VerticalMarketingPlanTemplateProductRequirement";
          id: string;
          title: string;
          description?: string | null;
          label?: string | null;
          groups: string;
        }>;
        appetiteProduct: {
          __typename?: "AppetiteProduct";
          id: string;
          carrierName: string;
          carrierProductName?: string | null;
          marketType?: MarketTypes | null;
          coverages: string;
          endorsements: string;
          coreLines: Array<string>;
          productDescription?: string | null;
          portalLink?: string | null;
          submissionInstructions?: string | null;
          bindHqCarrierIds: Array<string>;
          bindHqMarketingCompanyId?: string | null;
          submissionCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
          referenceCarrierContacts?: Array<{
            __typename?: "CarrierContact";
            id: string & { __scalar: "UUID" };
            firstName: string;
            lastName: string;
            email: string;
            phone?: string | null;
            title?: string | null;
          }> | null;
        };
      }>;
    }>;
  }>;
};

export type OnBrokerAssignmentQueueUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnBrokerAssignmentQueueUpdatedSubscription = {
  __typename?: "Subscription";
  brokerAssignmentQueueUpdated: {
    __typename?: "BrokerAssignmentQueue";
    brokers: Array<{
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    }>;
  };
};

export type OnOpportunityEventAddedSubscriptionVariables = Exact<{
  id: Scalars["String"];
}>;

export type OnOpportunityEventAddedSubscription = {
  __typename?: "Subscription";
  opportunityEventAdded:
    | {
        __typename: "ActionApprovedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionDismissedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionRejectedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ActionRequestedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AlbyUpdateEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ApplicationSubmittedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AssignmentChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "AutomatedFollowUpEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "BotCommentEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "BusinessClassCodesChangedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "CoveragesChangedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "CreatedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "DesiredEffectiveDateChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "EmailEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        email: {
          __typename?: "Email";
          id: string & { __scalar: "UUID" };
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          body: string;
          extractedBody: string;
          dateReceived: any;
          linkToEmailInGoogleGroups?: string | null;
        };
      }
    | {
        __typename: "FileUploadEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "GmailMessageEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        gmailMessage: {
          __typename?: "GmailMessage";
          id: string & { __scalar: "UUID" };
          headerMessageId: string;
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          replyTo?: string | null;
          textAsHtml: string;
          dateReceived: any;
          attachments: Array<{
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          }>;
        };
      }
    | {
        __typename: "GmailRelayEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
        gmailRelay: {
          __typename?: "GmailRelay";
          recipientEmail: string;
          initiator: { __typename?: "UserAccount"; email: string };
        };
        gmailMessage: {
          __typename?: "GmailMessage";
          html: string;
          id: string & { __scalar: "UUID" };
          headerMessageId: string;
          to: string;
          from: string;
          subject: string;
          cc?: string | null;
          replyTo?: string | null;
          textAsHtml: string;
          dateReceived: any;
          attachments: Array<{
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          }>;
        };
      }
    | {
        __typename: "OutcomeChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ProductsAddedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "ProductsRemovedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "RequirementAddedEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "StatusChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "SubmissionStatusChangeEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "TriageAssignmentEvent";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        opportunityId: string & { __scalar: "UUID" };
        createdAt: any;
        isPublic: boolean;
      }
    | {
        __typename: "UserComment";
        type: OpportunityEventType;
        id: string & { __scalar: "UUID" };
        text: string;
        createdAt: any;
        updatedAt?: any | null;
        isPublic: boolean;
        userAccountId: string & { __scalar: "UUID" };
        opportunityId: string & { __scalar: "UUID" };
        userAccount: {
          __typename: "UserAccount";
          id: string & { __scalar: "UUID" };
          createdAt: any;
          bindHqId?: string | null;
          firstName: string;
          lastName: string;
          email: string;
          roles: Array<Roles>;
          internal: boolean;
          agencyId: string & { __scalar: "UUID" };
          assignmentsEnabled: boolean;
          avatarUrl?: string | null;
          phone?: string | null;
          agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
        };
      };
};

export type OnOpportunityStateChangedSubscriptionVariables = Exact<{
  id: Scalars["String"];
}>;

export type OnOpportunityStateChangedSubscription = {
  __typename?: "Subscription";
  opportunityStateChanged: {
    __typename?: "Opportunity";
    id: string & { __scalar: "UUID" };
    isTest: boolean;
    state: MarketingPlanState;
    createdAt: any;
    insuredId: string & { __scalar: "UUID" };
    updatedAt?: any | null;
    selectedLinesOfBusiness: Array<string>;
    desiredEffectiveDate: any;
    brokerageFolderId?: string | null;
    renewalOf?: string | null;
    createdBy: { __typename?: "UserAccount"; id: string & { __scalar: "UUID" }; firstName: string; lastName: string };
    updatedBy?: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
    } | null;
    broker?: {
      __typename: "UserAccount";
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      internal: boolean;
      agencyId: string & { __scalar: "UUID" };
      assignmentsEnabled: boolean;
      avatarUrl?: string | null;
      phone?: string | null;
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
    } | null;
    submissions: Array<{
      __typename?: "Submission";
      id: string & { __scalar: "UUID" };
      opportunityId: string;
      appetiteProductId: string;
      bindHqApplicationId?: string | null;
      state: SubmissionState;
      createdAt: any;
      updatedAt?: any | null;
      lastTransitionAt: any;
      appetiteProduct: {
        __typename?: "AppetiteProduct";
        id: string;
        carrierName: string;
        carrierProductName?: string | null;
        marketType?: MarketTypes | null;
        coverages: string;
        endorsements: string;
        coreLines: Array<string>;
        productDescription?: string | null;
        portalLink?: string | null;
        submissionInstructions?: string | null;
        bindHqCarrierIds: Array<string>;
        bindHqMarketingCompanyId?: string | null;
        submissionCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
        referenceCarrierContacts?: Array<{
          __typename?: "CarrierContact";
          id: string & { __scalar: "UUID" };
          firstName: string;
          lastName: string;
          email: string;
          phone?: string | null;
          title?: string | null;
        }> | null;
      };
      quotes: Array<{
        __typename?: "Quote";
        id: string & { __scalar: "UUID" };
        insuredId: string;
        submissionId: string;
        state: QuoteState;
        premium?: number | null;
        totalPremium?: number | null;
        mep?: number | null;
        agentCommission?: number | null;
        grossCommission?: number | null;
        carrierFee?: number | null;
        brokerFee?: number | null;
        inspectionFee?: number | null;
        effectiveDate?: any | null;
        expirationDate?: any | null;
        createdAt: any;
        lastTransitionAt: any;
        outstandingSubjectivities?: string | null;
        redactedQuoteId?: string | null;
        processedQuoteId?: string | null;
        redactedQuote?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        processedQuote?: {
          __typename?: "FileUpload";
          id: string;
          filename: string;
          mimeType: string;
          fileSource?: File_Source | null;
          audience?: string | null;
        } | null;
        policy?: {
          __typename?: "Policy";
          id: string & { __scalar: "UUID" };
          insuredId: string;
          opportunityId: string;
          quoteId: string;
          state: PolicyState;
          policyNumber: string;
          effectiveDate?: any | null;
          expirationDate?: any | null;
          createdAt: any;
          lastTransitionAt: any;
          awaitingSubjectivities: boolean;
          endorsementsRequested: boolean;
          policyFileId?: string | null;
          redactedBinderFileId?: string | null;
          processedBinderFileId?: string | null;
          bindHqPolicyId?: string | null;
          policyFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          redactedBinderFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          processedBinderFile?: {
            __typename?: "FileUpload";
            id: string;
            filename: string;
            mimeType: string;
            fileSource?: File_Source | null;
            audience?: string | null;
          } | null;
          stateTransitions: Array<{
            __typename?: "PolicyStateTransition";
            id: string;
            policyId: string;
            previousState: PolicyState;
            state: PolicyState;
            createdAt: any;
            originatorId: string;
          }>;
          appetiteProduct: {
            __typename?: "AppetiteProduct";
            id: string;
            carrierName: string;
            carrierProductName?: string | null;
            marketType?: MarketTypes | null;
            coverages: string;
            endorsements: string;
            coreLines: Array<string>;
            productDescription?: string | null;
            portalLink?: string | null;
            submissionInstructions?: string | null;
            bindHqCarrierIds: Array<string>;
            bindHqMarketingCompanyId?: string | null;
            submissionCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
            referenceCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
          };
        } | null;
        stateTransitions: Array<{
          __typename?: "QuoteStateTransition";
          id: string;
          quoteId: string;
          previousState: QuoteState;
          state: QuoteState;
          createdAt: any;
          originatorId: string;
        }>;
        submission: {
          __typename?: "Submission";
          id: string & { __scalar: "UUID" };
          opportunityId: string;
          appetiteProduct: {
            __typename?: "AppetiteProduct";
            id: string;
            carrierName: string;
            carrierProductName?: string | null;
            marketType?: MarketTypes | null;
            coverages: string;
            endorsements: string;
            coreLines: Array<string>;
            productDescription?: string | null;
            portalLink?: string | null;
            submissionInstructions?: string | null;
            bindHqCarrierIds: Array<string>;
            bindHqMarketingCompanyId?: string | null;
            submissionCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
            referenceCarrierContacts?: Array<{
              __typename?: "CarrierContact";
              id: string & { __scalar: "UUID" };
              firstName: string;
              lastName: string;
              email: string;
              phone?: string | null;
              title?: string | null;
            }> | null;
          };
        };
      }>;
      stateTransitions: Array<{
        __typename?: "SubmissionStateTransition";
        id: string;
        submissionId: string;
        previousState: SubmissionState;
        details?: string | null;
        state: SubmissionState;
        createdAt: any;
        originatorId: string;
      }>;
      exclusionReason?: {
        __typename?: "ProductExclusion";
        id: string;
        appetiteProductId: string;
        reason?: string | null;
        state?: string | null;
        naicsCode?: string | null;
      } | null;
    }>;
    appetiteProducts: Array<{
      __typename?: "AppetiteProduct";
      id: string;
      marketType?: MarketTypes | null;
      carrierName: string;
      carrierProductName?: string | null;
    }>;
    renewal?: { __typename?: "Opportunity"; id: string & { __scalar: "UUID" } } | null;
    insured: {
      __typename?: "Insured";
      brokerageFolderId?: string | null;
      id: string & { __scalar: "UUID" };
      createdAt: any;
      bindHqId?: string | null;
      name: string;
      dba?: string | null;
      legalEntityType?: string | null;
      description?: string | null;
      primaryState?: string | null;
      naicsCodes: Array<string>;
      isoCglCodes: Array<string>;
      sicCodes: Array<string>;
      exposures?: string | null;
      mergedIntoId?: (string & { __scalar: "UUID" }) | null;
      revenue?: number | null;
      tiv?: number | null;
      agentId: string & { __scalar: "UUID" };
      businessClasses: Array<{
        __typename?: "BusinessClass";
        id?: string | null;
        description?: string | null;
        system: BusinessClassSystem;
        code: string;
      }>;
      agent: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
      agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isPrivate: boolean };
    };
    files: Array<{
      __typename?: "FileUpload";
      id: string;
      filename: string;
      mimeType: string;
      fileSource?: File_Source | null;
      audience?: string | null;
    }>;
    leads: Array<{
      __typename?: "Lead";
      id: string & { __scalar: "UUID" };
      accountName: string;
      effectiveDate?: any | null;
      expirationDate: any;
      rawLines: Array<string>;
      coreLines: Array<string>;
      carriers: Array<string>;
      carrierOverride?: string | null;
      isQwCarrier?: boolean | null;
      qwCarrierMatch?: QwCarrierMatch | null;
      premium?: (string & { __scalar: "Decimal" }) | null;
      description?: string | null;
      naicsCode?: string | null;
      agentFirstName?: string | null;
      agentLastName?: string | null;
      archived: boolean;
      highlighted: boolean;
      notes?: string | null;
      desireToRemarket?: DesireToRemarket | null;
      currentAgentCommission?: (string & { __scalar: "Decimal" }) | null;
      qwAgentCommission?: (string & { __scalar: "Decimal" }) | null;
      agencyId: string & { __scalar: "UUID" };
      opportunityId?: (string & { __scalar: "UUID" }) | null;
      topAppetiteProductScore?: (string & { __scalar: "Decimal" }) | null;
    }>;
    notes: Array<{
      __typename?: "OpportunityNote";
      id: string;
      createdAt: any;
      text: string;
      creator: {
        __typename: "UserAccount";
        id: string & { __scalar: "UUID" };
        createdAt: any;
        bindHqId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        roles: Array<Roles>;
        internal: boolean;
        agencyId: string & { __scalar: "UUID" };
        assignmentsEnabled: boolean;
        avatarUrl?: string | null;
        phone?: string | null;
        agency: { __typename?: "Agency"; id: string; name: string; bindHqId?: string | null; isTest: boolean };
      };
    }>;
    stateTransitions: Array<{
      __typename?: "MarketingPlanStateTransition";
      id: string;
      marketingPlanId: string;
      previousState: MarketingPlanState;
      state: MarketingPlanState;
      createdAt: any;
      originatorId: string;
    }>;
    verticalMarketingPlanTemplate?: {
      __typename?: "VerticalMarketingPlanTemplate";
      products: Array<{
        __typename?: "VerticalMarketingPlanTemplateProduct";
        rank: number;
        rules: string;
        appetiteProduct: { __typename?: "AppetiteProduct"; id: string };
      }>;
    } | null;
  };
};

export const ActionFragmentDoc = gql`
  fragment Action on Action {
    id
    title
    description
    actionType
    executedOperation
    createdAt
    updatedAt
    opportunityId
    submissionId
    executedAt
    autoExecuteAt
    executedBy {
      id
      firstName
      lastName
      email
    }
    appetiteProduct {
      carrierName
      carrierProductName
      portalLink
    }
    metadata
  }
`;
export const AgencyAffiliationFragmentDoc = gql`
  fragment AgencyAffiliation on AgencyAffiliation {
    name
    shortName
    logoUrl
    bindingCommission
    brokerageCommission
  }
`;
export const AppetiteOpportunityFragmentDoc = gql`
  fragment AppetiteOpportunity on Opportunity {
    id
    state
    selectedLinesOfBusiness
    desiredEffectiveDate
    renewalOf
    insured {
      id
      name
      primaryState
      naicsCodes
      description
      agent {
        agencyId
        email
        firstName
        lastName
      }
    }
    broker {
      firstName
      lastName
    }
  }
`;
export const AppetiteNoteFragmentDoc = gql`
  fragment AppetiteNote on AppetiteNote {
    id
    isoCglCodes
    lobs
    states
    note
  }
`;
export const BindHqAgencyFragmentDoc = gql`
  fragment BindHqAgency on BindHqAgency {
    id
    name
    phone
    email
    line1
    line2
    city
    state
    zip
    okToBind
    okToBind
  }
`;
export const CarrierFragmentDoc = gql`
  fragment Carrier on Carrier {
    __typename
    id
    name
    slug
    pdf
    api
    portalUrl
    url
    foregroundColor
    backgroundColor
  }
`;
export const ClientDataFragmentDoc = gql`
  fragment ClientData on ClientData {
    id
    insuredId
    index
    key
    value
    source
  }
`;
export const ExtractedLabelFragmentDoc = gql`
  fragment ExtractedLabel on ExtractedLabel {
    id
    key
    source
    count
    primaryLabels {
      id
      primaryKey
      displayName
    }
  }
`;
export const FilePipelineVersionFragmentDoc = gql`
  fragment FilePipelineVersion on FilePipelineVersion {
    id
    pipeline {
      initial {
        id
        category
        name
        startPage
        endPage
      }
      transitions {
        sourceNodeName
        label
        destinationNodes {
          id
          category
          name
          startPage
          endPage
        }
      }
    }
  }
`;
export const FileProcessingJobFragmentDoc = gql`
  fragment FileProcessingJob on FileProcessingJob {
    id
    status
    pipeline
    fileId
    parentId
    createdAt
    updatedAt
  }
`;
export const BusinessClassFragmentDoc = gql`
  fragment BusinessClass on BusinessClass {
    id
    description
    system
    code
  }
`;
export const UserAccountFragmentDoc = gql`
  fragment UserAccount on UserAccount {
    __typename
    id
    createdAt
    bindHqId
    firstName
    lastName
    email
    roles
    internal
    agencyId
    assignmentsEnabled
    avatarUrl
    phone
    agency {
      id
      name
      bindHqId
      isTest
    }
  }
`;
export const AgencyFragmentDoc = gql`
  fragment Agency on Agency {
    id
    name
    bindHqId
    isPrivate
  }
`;
export const InsuredFragmentDoc = gql`
  fragment Insured on Insured {
    id
    createdAt
    bindHqId
    name
    dba
    legalEntityType
    description
    primaryState
    naicsCodes
    isoCglCodes
    sicCodes
    exposures
    mergedIntoId
    businessClasses {
      ...BusinessClass
    }
    revenue
    tiv
    agentId
    agent {
      ...UserAccount
    }
    agency {
      ...Agency
    }
  }
`;
export const InsuredDeepFragmentDoc = gql`
  fragment InsuredDeep on Insured {
    ...Insured
    opportunities {
      id
      createdAt
      updatedAt
      selectedLinesOfBusiness
      desiredEffectiveDate
      state
      broker {
        ...UserAccount
      }
    }
  }
`;
export const InsuredHazardFragmentDoc = gql`
  fragment InsuredHazard on InsuredHazard {
    id
    hazardNumber
    locationId
    locationNumber
    cgl
    premiumBasis
    exposure
    description
    indications {
      carrierName
      lob
      minRate
      maxRate
      minPremium
      maxPremium
    }
  }
`;
export const InsuredPremiseBuildingFragmentDoc = gql`
  fragment InsuredPremiseBuilding on InsuredPremiseBuilding {
    id
    locationId
    buildingNumber
    description
    addressLine1
    addressLine2
    fullTimeEmployeeCount
    partTimeEmployeeCount
    annualRevenue
    occupiedArea
    totalBuildingArea
    wiringYear
    roofingYear
    heatingYear
    plumbingYear
    tiv
    roofType
    constructionType
    yearBuilt
    burglarAlarmType
    sprinklerPercent
  }
`;
export const InsuredPremiseLocationFragmentDoc = gql`
  fragment InsuredPremiseLocation on InsuredPremiseLocation {
    id
    locationNumber
    city
    state
    zip
    county
    violentCrimeScore
    propertyCrimeScore
    buildings {
      ...InsuredPremiseBuilding
    }
  }
`;
export const LineOfBusinessFragmentDoc = gql`
  fragment LineOfBusiness on LineOfBusiness {
    id
    displayName
  }
`;
export const UserCommentFragmentDoc = gql`
  fragment UserComment on UserComment {
    id
    type
    text
    createdAt
    updatedAt
    isPublic
    userAccountId
    userAccount {
      ...UserAccount
    }
    opportunityId
  }
`;
export const AssignmentChangeEventFragmentDoc = gql`
  fragment AssignmentChangeEvent on AssignmentChangeEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const TriageAssignmentEventFragmentDoc = gql`
  fragment TriageAssignmentEvent on TriageAssignmentEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const EmailEventFragmentDoc = gql`
  fragment EmailEvent on EmailEvent {
    id
    type
    text
    opportunityId
    createdAt
    email {
      id
      to
      from
      subject
      cc
      body
      extractedBody
      dateReceived
      linkToEmailInGoogleGroups
    }
    isPublic
  }
`;
export const FileUploadFragmentDoc = gql`
  fragment FileUpload on FileUpload {
    id
    filename
    mimeType
    fileSource
    audience
  }
`;
export const GmailMessageFragmentDoc = gql`
  fragment GmailMessage on GmailMessage {
    id
    headerMessageId
    to
    from
    subject
    cc
    replyTo
    textAsHtml
    dateReceived
    attachments {
      ...FileUpload
    }
  }
`;
export const GmailMessageEventFragmentDoc = gql`
  fragment GmailMessageEvent on GmailMessageEvent {
    id
    type
    text
    opportunityId
    createdAt
    gmailMessage {
      ...GmailMessage
    }
    isPublic
  }
`;
export const GmailRelayEventFragmentDoc = gql`
  fragment GmailRelayEvent on GmailRelayEvent {
    id
    type
    text
    opportunityId
    createdAt
    gmailRelay {
      recipientEmail
      initiator {
        email
      }
    }
    gmailMessage {
      ...GmailMessage
      html
    }
    isPublic
  }
`;
export const FileUploadEventFragmentDoc = gql`
  fragment FileUploadEvent on FileUploadEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const StatusChangeEventFragmentDoc = gql`
  fragment StatusChangeEvent on StatusChangeEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const SubmissionStatusChangeEventFragmentDoc = gql`
  fragment SubmissionStatusChangeEvent on SubmissionStatusChangeEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const CreatedEventFragmentDoc = gql`
  fragment CreatedEvent on CreatedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const OutcomeChangeEventFragmentDoc = gql`
  fragment OutcomeChangeEvent on OutcomeChangeEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const DesiredEffectiveDateChangeEventFragmentDoc = gql`
  fragment DesiredEffectiveDateChangeEvent on DesiredEffectiveDateChangeEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const ApplicationSubmittedEventFragmentDoc = gql`
  fragment ApplicationSubmittedEvent on ApplicationSubmittedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const ProductsAddedEventFragmentDoc = gql`
  fragment ProductsAddedEvent on ProductsAddedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const ProductsRemovedEventFragmentDoc = gql`
  fragment ProductsRemovedEvent on ProductsRemovedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const BotCommentEventFragmentDoc = gql`
  fragment BotCommentEvent on BotCommentEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const AutomatedFollowUpEventFragmentDoc = gql`
  fragment AutomatedFollowUpEvent on AutomatedFollowUpEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const ActionRequestedEventFragmentDoc = gql`
  fragment ActionRequestedEvent on ActionRequestedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const ActionApprovedEventFragmentDoc = gql`
  fragment ActionApprovedEvent on ActionApprovedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const ActionRejectedEventFragmentDoc = gql`
  fragment ActionRejectedEvent on ActionRejectedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const ActionDismissedEventFragmentDoc = gql`
  fragment ActionDismissedEvent on ActionDismissedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const CoveragesChangedEventFragmentDoc = gql`
  fragment CoveragesChangedEvent on CoveragesChangedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const BusinessClassCodesChangedEventFragmentDoc = gql`
  fragment BusinessClassCodesChangedEvent on BusinessClassCodesChangedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const NotificationOpportunityEventFragmentDoc = gql`
  fragment NotificationOpportunityEvent on OpportunityEvent {
    __typename
    ... on UserComment {
      ...UserComment
    }
    ... on AssignmentChangeEvent {
      ...AssignmentChangeEvent
    }
    ... on TriageAssignmentEvent {
      ...TriageAssignmentEvent
    }
    ... on EmailEvent {
      ...EmailEvent
    }
    ... on GmailMessageEvent {
      ...GmailMessageEvent
    }
    ... on GmailRelayEvent {
      ...GmailRelayEvent
    }
    ... on FileUploadEvent {
      ...FileUploadEvent
    }
    ... on StatusChangeEvent {
      ...StatusChangeEvent
    }
    ... on SubmissionStatusChangeEvent {
      ...SubmissionStatusChangeEvent
    }
    ... on CreatedEvent {
      ...CreatedEvent
    }
    ... on OutcomeChangeEvent {
      ...OutcomeChangeEvent
    }
    ... on DesiredEffectiveDateChangeEvent {
      ...DesiredEffectiveDateChangeEvent
    }
    ... on ApplicationSubmittedEvent {
      ...ApplicationSubmittedEvent
    }
    ... on ProductsAddedEvent {
      ...ProductsAddedEvent
    }
    ... on ProductsRemovedEvent {
      ...ProductsRemovedEvent
    }
    ... on BotCommentEvent {
      ...BotCommentEvent
    }
    ... on AutomatedFollowUpEvent {
      ...AutomatedFollowUpEvent
    }
    ... on ActionRequestedEvent {
      ...ActionRequestedEvent
    }
    ... on ActionApprovedEvent {
      ...ActionApprovedEvent
    }
    ... on ActionRejectedEvent {
      ...ActionRejectedEvent
    }
    ... on ActionDismissedEvent {
      ...ActionDismissedEvent
    }
    ... on CoveragesChangedEvent {
      ...CoveragesChangedEvent
    }
    ... on BusinessClassCodesChangedEvent {
      ...BusinessClassCodesChangedEvent
    }
    ... on OpportunityEventBase {
      type
      opportunity {
        id
        insuredId
        insured {
          name
          agent {
            ...UserAccount
          }
        }
        broker {
          ...UserAccount
        }
      }
    }
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    createdAt
    readAt
    event {
      ...NotificationOpportunityEvent
    }
  }
`;
export const CarrierContactFragmentDoc = gql`
  fragment CarrierContact on CarrierContact {
    id
    firstName
    lastName
    email
    phone
    title
  }
`;
export const AppetiteProductFragmentDoc = gql`
  fragment AppetiteProduct on AppetiteProduct {
    id
    carrierName
    carrierProductName
    marketType
    coverages
    endorsements
    coreLines
    productDescription
    portalLink
    submissionInstructions
    bindHqCarrierIds
    bindHqMarketingCompanyId
    submissionCarrierContacts {
      ...CarrierContact
    }
    referenceCarrierContacts {
      ...CarrierContact
    }
  }
`;
export const PolicyStateTransitionFragmentDoc = gql`
  fragment PolicyStateTransition on PolicyStateTransition {
    id
    policyId
    previousState
    state
    createdAt
    originatorId
  }
`;
export const PolicyFragmentDoc = gql`
  fragment Policy on Policy {
    id
    insuredId
    opportunityId
    quoteId
    state
    policyNumber
    effectiveDate
    expirationDate
    createdAt
    lastTransitionAt
    awaitingSubjectivities
    endorsementsRequested
    policyFileId
    redactedBinderFileId
    processedBinderFileId
    bindHqPolicyId
    policyFile {
      ...FileUpload
    }
    redactedBinderFile {
      ...FileUpload
    }
    processedBinderFile {
      ...FileUpload
    }
    stateTransitions {
      ...PolicyStateTransition
    }
    appetiteProduct {
      ...AppetiteProduct
    }
    opportunityId
  }
`;
export const QuoteStateTransitionFragmentDoc = gql`
  fragment QuoteStateTransition on QuoteStateTransition {
    id
    quoteId
    previousState
    state
    createdAt
    originatorId
  }
`;
export const QuoteFragmentDoc = gql`
  fragment Quote on Quote {
    id
    insuredId
    submissionId
    state
    premium
    totalPremium
    mep
    agentCommission
    grossCommission
    carrierFee
    brokerFee
    inspectionFee
    effectiveDate
    expirationDate
    createdAt
    lastTransitionAt
    outstandingSubjectivities
    redactedQuoteId
    redactedQuote {
      ...FileUpload
    }
    processedQuoteId
    processedQuote {
      ...FileUpload
    }
    policy {
      ...Policy
    }
    stateTransitions {
      ...QuoteStateTransition
    }
    submission {
      id
      appetiteProduct {
        ...AppetiteProduct
      }
      opportunityId
    }
  }
`;
export const SubmissionStateTransitionFragmentDoc = gql`
  fragment SubmissionStateTransition on SubmissionStateTransition {
    id
    submissionId
    previousState
    details
    state
    createdAt
    originatorId
  }
`;
export const SubmissionFragmentDoc = gql`
  fragment Submission on Submission {
    id
    opportunityId
    appetiteProductId
    bindHqApplicationId
    state
    createdAt
    updatedAt
    lastTransitionAt
    appetiteProduct {
      ...AppetiteProduct
    }
    quotes {
      ...Quote
    }
    stateTransitions {
      ...SubmissionStateTransition
    }
  }
`;
export const ProductExclusionFragmentDoc = gql`
  fragment ProductExclusion on ProductExclusion {
    id
    appetiteProductId
    reason
    state
    naicsCode
  }
`;
export const QuoteSubmissionFragmentDoc = gql`
  fragment QuoteSubmission on Submission {
    id
    state
    exclusionReason {
      ...ProductExclusion
    }
    appetiteProduct {
      id
      carrierName
      carrierProductName
    }
  }
`;
export const LeadFragmentDoc = gql`
  fragment Lead on Lead {
    id
    accountName
    effectiveDate
    expirationDate
    rawLines
    coreLines
    carriers
    carrierOverride
    isQwCarrier
    qwCarrierMatch
    premium
    description
    naicsCode
    agentFirstName
    agentLastName
    archived
    highlighted
    notes
    desireToRemarket
    currentAgentCommission
    qwAgentCommission
    agencyId
    opportunityId
    topAppetiteProductScore
  }
`;
export const MarketingPlanStateTransitionFragmentDoc = gql`
  fragment MarketingPlanStateTransition on MarketingPlanStateTransition {
    id
    marketingPlanId
    previousState
    state
    createdAt
    originatorId
  }
`;
export const OpportunityDetailsFragmentDoc = gql`
  fragment OpportunityDetails on Opportunity {
    id
    isTest
    state
    createdAt
    insuredId
    createdBy {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
    }
    broker {
      ...UserAccount
    }
    submissions {
      ...Submission
      ...QuoteSubmission
    }
    appetiteProducts {
      id
      marketType
      carrierName
      carrierProductName
    }
    selectedLinesOfBusiness
    desiredEffectiveDate
    brokerageFolderId
    renewalOf
    renewal {
      id
    }
    insured {
      ...Insured
      brokerageFolderId
    }
    files {
      ...FileUpload
    }
    leads {
      ...Lead
    }
    notes {
      id
      createdAt
      creator {
        ...UserAccount
      }
      text
    }
    stateTransitions {
      ...MarketingPlanStateTransition
    }
    verticalMarketingPlanTemplate {
      products {
        rank
        rules
        appetiteProduct {
          id
        }
      }
    }
  }
`;
export const AlbyUpdateEventFragmentDoc = gql`
  fragment AlbyUpdateEvent on AlbyUpdateEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const RequirementAddedEventFragmentDoc = gql`
  fragment RequirementAddedEvent on RequirementAddedEvent {
    id
    type
    text
    opportunityId
    createdAt
    isPublic
  }
`;
export const OpportunityEventFragmentDoc = gql`
  fragment OpportunityEvent on OpportunityEvent {
    __typename
    ... on UserComment {
      ...UserComment
    }
    ... on AssignmentChangeEvent {
      ...AssignmentChangeEvent
    }
    ... on TriageAssignmentEvent {
      ...TriageAssignmentEvent
    }
    ... on EmailEvent {
      ...EmailEvent
    }
    ... on GmailMessageEvent {
      ...GmailMessageEvent
    }
    ... on GmailRelayEvent {
      ...GmailRelayEvent
    }
    ... on FileUploadEvent {
      ...FileUploadEvent
    }
    ... on StatusChangeEvent {
      ...StatusChangeEvent
    }
    ... on SubmissionStatusChangeEvent {
      ...SubmissionStatusChangeEvent
    }
    ... on AlbyUpdateEvent {
      ...AlbyUpdateEvent
    }
    ... on CreatedEvent {
      ...CreatedEvent
    }
    ... on OutcomeChangeEvent {
      ...OutcomeChangeEvent
    }
    ... on DesiredEffectiveDateChangeEvent {
      ...DesiredEffectiveDateChangeEvent
    }
    ... on ApplicationSubmittedEvent {
      ...ApplicationSubmittedEvent
    }
    ... on ProductsAddedEvent {
      ...ProductsAddedEvent
    }
    ... on ProductsRemovedEvent {
      ...ProductsRemovedEvent
    }
    ... on BotCommentEvent {
      ...BotCommentEvent
    }
    ... on AutomatedFollowUpEvent {
      ...AutomatedFollowUpEvent
    }
    ... on ActionRequestedEvent {
      ...ActionRequestedEvent
    }
    ... on ActionApprovedEvent {
      ...ActionApprovedEvent
    }
    ... on ActionRejectedEvent {
      ...ActionRejectedEvent
    }
    ... on ActionDismissedEvent {
      ...ActionDismissedEvent
    }
    ... on CoveragesChangedEvent {
      ...CoveragesChangedEvent
    }
    ... on BusinessClassCodesChangedEvent {
      ...BusinessClassCodesChangedEvent
    }
    ... on OpportunityEventBase {
      type
    }
    ... on RequirementAddedEvent {
      ...RequirementAddedEvent
    }
  }
`;
export const PrimaryLabelFragmentDoc = gql`
  fragment PrimaryLabel on PrimaryLabel {
    id
    primaryKey
    displayName
    dataType
    description
    extractedLabels {
      id
      key
      source
      count
    }
    createdAt
    updatedAt
    createdById
    createdBy {
      ...UserAccount
    }
    updatedById
    updatedBy {
      ...UserAccount
    }
  }
`;
export const QuoteKitchenSinkFragmentDoc = gql`
  fragment QuoteKitchenSink on Quote {
    id
    insuredId
    submissionId
    state
    premium
    mep
    agentCommission
    grossCommission
    carrierFee
    brokerFee
    inspectionFee
    effectiveDate
    expirationDate
    createdAt
    lastTransitionAt
    redactedQuoteId
    redactedQuote {
      ...FileUpload
    }
    processedQuoteId
    processedQuote {
      ...FileUpload
    }
    policy {
      ...Policy
    }
    stateTransitions {
      ...QuoteStateTransition
    }
    submission {
      id
      state
      createdAt
      appetiteProduct {
        ...AppetiteProduct
      }
      opportunityId
      opportunity {
        id
        isTest
        state
        createdAt
        selectedLinesOfBusiness
        desiredEffectiveDate
        difficulties
        brokerageFolderId
        renewalOf
        insured {
          agent {
            ...UserAccount
          }
        }
        broker {
          ...UserAccount
        }
      }
    }
  }
`;
export const ScoredAppetiteProductFragmentDoc = gql`
  fragment ScoredAppetiteProduct on ScoredAppetiteProduct {
    score
    reasons
    previouslyMarketedCount
    previouslyQuotedCount
    product {
      id
      carrierName
      carrierSlug
      carrierProductName
      productDescription
      coverages
      coreLines
    }
  }
`;
export const MarketingPlanStateTransitionFragmentFragmentDoc = gql`
  fragment MarketingPlanStateTransitionFragment on MarketingPlanStateTransition {
    id
    marketingPlanId
    originatorId
    previousState
    state
    createdAt
  }
`;
export const SubmissionStateTransitionFragmentFragmentDoc = gql`
  fragment SubmissionStateTransitionFragment on SubmissionStateTransition {
    id
    submissionId
    originatorId
    previousState
    state
    createdAt
  }
`;
export const QuoteStateTransitionFragmentFragmentDoc = gql`
  fragment QuoteStateTransitionFragment on QuoteStateTransition {
    id
    quoteId
    originatorId
    previousState
    state
    createdAt
  }
`;
export const PolicyStateTransitionFragmentFragmentDoc = gql`
  fragment PolicyStateTransitionFragment on PolicyStateTransition {
    id
    policyId
    originatorId
    previousState
    state
    createdAt
  }
`;
export const SlimAppetiteProductFragmentFragmentDoc = gql`
  fragment SlimAppetiteProductFragment on AppetiteProduct {
    id
    carrierName
    carrierProductName
    marketType
  }
`;
export const SubmissionDetailsFragmentDoc = gql`
  fragment SubmissionDetails on Submission {
    ...Submission
    quotes {
      ...Quote
    }
  }
`;
export const SubmissionResponseFragmentDoc = gql`
  fragment SubmissionResponse on SubmissionResponse {
    submissions {
      id
      state
      personalizedEmailSubmissionBody
      appetiteProduct {
        carrierName
        carrierProductName
        submissionCarrierContacts {
          email
        }
      }
      opportunity {
        id
        state
        desiredEffectiveDate
        insured {
          name
        }
        broker {
          firstName
          lastName
          email
        }
      }
    }
  }
`;
export const ClassCodeFragmentDoc = gql`
  fragment ClassCode on ClassCode {
    id
    description
    classCode
    classSystem
  }
`;
export const VerticalMarketingPlanTemplateProductFragmentDoc = gql`
  fragment VerticalMarketingPlanTemplateProduct on VerticalMarketingPlanTemplateProduct {
    id
    rank
    submissionEmail
    rules
    requirements {
      id
      title
      description
      label
      groups
    }
    appetiteProduct {
      ...AppetiteProduct
    }
  }
`;
export const VerticalMarketingPlanTemplateFragmentDoc = gql`
  fragment VerticalMarketingPlanTemplate on VerticalMarketingPlanTemplate {
    id
    lobs
    products {
      ...VerticalMarketingPlanTemplateProduct
    }
  }
`;
export const VerticalFragmentDoc = gql`
  fragment Vertical on Vertical {
    id
    name
    description
    enabled
    isoCglCodes {
      ...ClassCode
    }
    marketingPlanTemplates {
      ...VerticalMarketingPlanTemplate
    }
  }
`;
export const CreateAgentActionLogDocument = gql`
  mutation CreateAgentActionLog($agentActionId: String!, $type: String!, $fileId: String, $response: String) {
    createAgentActionLog(agentActionId: $agentActionId, type: $type, fileId: $fileId, response: $response) {
      id
      agentActionId
      type
      fileId
      response
    }
  }
`;
export type CreateAgentActionLogMutationFn = Apollo.MutationFunction<
  CreateAgentActionLogMutation,
  CreateAgentActionLogMutationVariables
>;

/**
 * __useCreateAgentActionLogMutation__
 *
 * To run a mutation, you first call `useCreateAgentActionLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgentActionLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgentActionLogMutation, { data, loading, error }] = useCreateAgentActionLogMutation({
 *   variables: {
 *      agentActionId: // value for 'agentActionId'
 *      type: // value for 'type'
 *      fileId: // value for 'fileId'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useCreateAgentActionLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAgentActionLogMutation, CreateAgentActionLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAgentActionLogMutation, CreateAgentActionLogMutationVariables>(
    CreateAgentActionLogDocument,
    options
  );
}
export type CreateAgentActionLogMutationHookResult = ReturnType<typeof useCreateAgentActionLogMutation>;
export type CreateAgentActionLogMutationResult = Apollo.MutationResult<CreateAgentActionLogMutation>;
export type CreateAgentActionLogMutationOptions = Apollo.BaseMutationOptions<
  CreateAgentActionLogMutation,
  CreateAgentActionLogMutationVariables
>;
export const AddCarrierContactToAppetiteProductDocument = gql`
  mutation AddCarrierContactToAppetiteProduct($input: CarrierContactConnectorInput!) {
    addCarrierContactToAppetiteProduct(input: $input) {
      ...CarrierContact
    }
  }
  ${CarrierContactFragmentDoc}
`;
export type AddCarrierContactToAppetiteProductMutationFn = Apollo.MutationFunction<
  AddCarrierContactToAppetiteProductMutation,
  AddCarrierContactToAppetiteProductMutationVariables
>;

/**
 * __useAddCarrierContactToAppetiteProductMutation__
 *
 * To run a mutation, you first call `useAddCarrierContactToAppetiteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCarrierContactToAppetiteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCarrierContactToAppetiteProductMutation, { data, loading, error }] = useAddCarrierContactToAppetiteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCarrierContactToAppetiteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCarrierContactToAppetiteProductMutation,
    AddCarrierContactToAppetiteProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCarrierContactToAppetiteProductMutation,
    AddCarrierContactToAppetiteProductMutationVariables
  >(AddCarrierContactToAppetiteProductDocument, options);
}
export type AddCarrierContactToAppetiteProductMutationHookResult = ReturnType<
  typeof useAddCarrierContactToAppetiteProductMutation
>;
export type AddCarrierContactToAppetiteProductMutationResult =
  Apollo.MutationResult<AddCarrierContactToAppetiteProductMutation>;
export type AddCarrierContactToAppetiteProductMutationOptions = Apollo.BaseMutationOptions<
  AddCarrierContactToAppetiteProductMutation,
  AddCarrierContactToAppetiteProductMutationVariables
>;
export const AddProductToVerticalMarketingPlanTemplateDocument = gql`
  mutation AddProductToVerticalMarketingPlanTemplate($input: AddProductToVerticalMarketingPlanTemplateInput!) {
    addProductToVerticalMarketingPlanTemplate(input: $input) {
      id
    }
  }
`;
export type AddProductToVerticalMarketingPlanTemplateMutationFn = Apollo.MutationFunction<
  AddProductToVerticalMarketingPlanTemplateMutation,
  AddProductToVerticalMarketingPlanTemplateMutationVariables
>;

/**
 * __useAddProductToVerticalMarketingPlanTemplateMutation__
 *
 * To run a mutation, you first call `useAddProductToVerticalMarketingPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductToVerticalMarketingPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductToVerticalMarketingPlanTemplateMutation, { data, loading, error }] = useAddProductToVerticalMarketingPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductToVerticalMarketingPlanTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProductToVerticalMarketingPlanTemplateMutation,
    AddProductToVerticalMarketingPlanTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProductToVerticalMarketingPlanTemplateMutation,
    AddProductToVerticalMarketingPlanTemplateMutationVariables
  >(AddProductToVerticalMarketingPlanTemplateDocument, options);
}
export type AddProductToVerticalMarketingPlanTemplateMutationHookResult = ReturnType<
  typeof useAddProductToVerticalMarketingPlanTemplateMutation
>;
export type AddProductToVerticalMarketingPlanTemplateMutationResult =
  Apollo.MutationResult<AddProductToVerticalMarketingPlanTemplateMutation>;
export type AddProductToVerticalMarketingPlanTemplateMutationOptions = Apollo.BaseMutationOptions<
  AddProductToVerticalMarketingPlanTemplateMutation,
  AddProductToVerticalMarketingPlanTemplateMutationVariables
>;
export const AddVerticalMarketingPlanTemplateProductRequirementDocument = gql`
  mutation AddVerticalMarketingPlanTemplateProductRequirement(
    $input: AddVerticalMarketingPlanTemplateProductRequirementInput!
  ) {
    addVerticalMarketingPlanTemplateProductRequirement(input: $input) {
      id
    }
  }
`;
export type AddVerticalMarketingPlanTemplateProductRequirementMutationFn = Apollo.MutationFunction<
  AddVerticalMarketingPlanTemplateProductRequirementMutation,
  AddVerticalMarketingPlanTemplateProductRequirementMutationVariables
>;

/**
 * __useAddVerticalMarketingPlanTemplateProductRequirementMutation__
 *
 * To run a mutation, you first call `useAddVerticalMarketingPlanTemplateProductRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVerticalMarketingPlanTemplateProductRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVerticalMarketingPlanTemplateProductRequirementMutation, { data, loading, error }] = useAddVerticalMarketingPlanTemplateProductRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVerticalMarketingPlanTemplateProductRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVerticalMarketingPlanTemplateProductRequirementMutation,
    AddVerticalMarketingPlanTemplateProductRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddVerticalMarketingPlanTemplateProductRequirementMutation,
    AddVerticalMarketingPlanTemplateProductRequirementMutationVariables
  >(AddVerticalMarketingPlanTemplateProductRequirementDocument, options);
}
export type AddVerticalMarketingPlanTemplateProductRequirementMutationHookResult = ReturnType<
  typeof useAddVerticalMarketingPlanTemplateProductRequirementMutation
>;
export type AddVerticalMarketingPlanTemplateProductRequirementMutationResult =
  Apollo.MutationResult<AddVerticalMarketingPlanTemplateProductRequirementMutation>;
export type AddVerticalMarketingPlanTemplateProductRequirementMutationOptions = Apollo.BaseMutationOptions<
  AddVerticalMarketingPlanTemplateProductRequirementMutation,
  AddVerticalMarketingPlanTemplateProductRequirementMutationVariables
>;
export const BindQuoteDocument = gql`
  mutation BindQuote($input: BindQuoteInput!) {
    bindQuote(input: $input) {
      ...Quote
    }
  }
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
`;
export type BindQuoteMutationFn = Apollo.MutationFunction<BindQuoteMutation, BindQuoteMutationVariables>;

/**
 * __useBindQuoteMutation__
 *
 * To run a mutation, you first call `useBindQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBindQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bindQuoteMutation, { data, loading, error }] = useBindQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBindQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<BindQuoteMutation, BindQuoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BindQuoteMutation, BindQuoteMutationVariables>(BindQuoteDocument, options);
}
export type BindQuoteMutationHookResult = ReturnType<typeof useBindQuoteMutation>;
export type BindQuoteMutationResult = Apollo.MutationResult<BindQuoteMutation>;
export type BindQuoteMutationOptions = Apollo.BaseMutationOptions<BindQuoteMutation, BindQuoteMutationVariables>;
export const BrokerCreateAgentActionDocument = gql`
  mutation BrokerCreateAgentAction($input: BrokerCreateAgentAction!) {
    brokerCreateAgentAction(input: $input) {
      id
    }
  }
`;
export type BrokerCreateAgentActionMutationFn = Apollo.MutationFunction<
  BrokerCreateAgentActionMutation,
  BrokerCreateAgentActionMutationVariables
>;

/**
 * __useBrokerCreateAgentActionMutation__
 *
 * To run a mutation, you first call `useBrokerCreateAgentActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrokerCreateAgentActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brokerCreateAgentActionMutation, { data, loading, error }] = useBrokerCreateAgentActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrokerCreateAgentActionMutation(
  baseOptions?: Apollo.MutationHookOptions<BrokerCreateAgentActionMutation, BrokerCreateAgentActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BrokerCreateAgentActionMutation, BrokerCreateAgentActionMutationVariables>(
    BrokerCreateAgentActionDocument,
    options
  );
}
export type BrokerCreateAgentActionMutationHookResult = ReturnType<typeof useBrokerCreateAgentActionMutation>;
export type BrokerCreateAgentActionMutationResult = Apollo.MutationResult<BrokerCreateAgentActionMutation>;
export type BrokerCreateAgentActionMutationOptions = Apollo.BaseMutationOptions<
  BrokerCreateAgentActionMutation,
  BrokerCreateAgentActionMutationVariables
>;
export const CheckUpstreamFileProcessorDocument = gql`
  mutation CheckUpstreamFileProcessor($id: ID!) {
    checkUpstreamFileProcessor(id: $id) {
      exists
    }
  }
`;
export type CheckUpstreamFileProcessorMutationFn = Apollo.MutationFunction<
  CheckUpstreamFileProcessorMutation,
  CheckUpstreamFileProcessorMutationVariables
>;

/**
 * __useCheckUpstreamFileProcessorMutation__
 *
 * To run a mutation, you first call `useCheckUpstreamFileProcessorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckUpstreamFileProcessorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkUpstreamFileProcessorMutation, { data, loading, error }] = useCheckUpstreamFileProcessorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckUpstreamFileProcessorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckUpstreamFileProcessorMutation,
    CheckUpstreamFileProcessorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CheckUpstreamFileProcessorMutation, CheckUpstreamFileProcessorMutationVariables>(
    CheckUpstreamFileProcessorDocument,
    options
  );
}
export type CheckUpstreamFileProcessorMutationHookResult = ReturnType<typeof useCheckUpstreamFileProcessorMutation>;
export type CheckUpstreamFileProcessorMutationResult = Apollo.MutationResult<CheckUpstreamFileProcessorMutation>;
export type CheckUpstreamFileProcessorMutationOptions = Apollo.BaseMutationOptions<
  CheckUpstreamFileProcessorMutation,
  CheckUpstreamFileProcessorMutationVariables
>;
export const CompleteAgentActionDocument = gql`
  mutation CompleteAgentAction($input: CompleteAgentActionInput!) {
    completeAgentAction(input: $input) {
      id
    }
  }
`;
export type CompleteAgentActionMutationFn = Apollo.MutationFunction<
  CompleteAgentActionMutation,
  CompleteAgentActionMutationVariables
>;

/**
 * __useCompleteAgentActionMutation__
 *
 * To run a mutation, you first call `useCompleteAgentActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAgentActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAgentActionMutation, { data, loading, error }] = useCompleteAgentActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteAgentActionMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteAgentActionMutation, CompleteAgentActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteAgentActionMutation, CompleteAgentActionMutationVariables>(
    CompleteAgentActionDocument,
    options
  );
}
export type CompleteAgentActionMutationHookResult = ReturnType<typeof useCompleteAgentActionMutation>;
export type CompleteAgentActionMutationResult = Apollo.MutationResult<CompleteAgentActionMutation>;
export type CompleteAgentActionMutationOptions = Apollo.BaseMutationOptions<
  CompleteAgentActionMutation,
  CompleteAgentActionMutationVariables
>;
export const ConnectExtractedLabelToPrimaryLabelDocument = gql`
  mutation ConnectExtractedLabelToPrimaryLabel($input: ConnectOrDisconnectExtractedLabelInput!) {
    connectExtractedLabelToPrimaryLabel(input: $input) {
      ...PrimaryLabel
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;
export type ConnectExtractedLabelToPrimaryLabelMutationFn = Apollo.MutationFunction<
  ConnectExtractedLabelToPrimaryLabelMutation,
  ConnectExtractedLabelToPrimaryLabelMutationVariables
>;

/**
 * __useConnectExtractedLabelToPrimaryLabelMutation__
 *
 * To run a mutation, you first call `useConnectExtractedLabelToPrimaryLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectExtractedLabelToPrimaryLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectExtractedLabelToPrimaryLabelMutation, { data, loading, error }] = useConnectExtractedLabelToPrimaryLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectExtractedLabelToPrimaryLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectExtractedLabelToPrimaryLabelMutation,
    ConnectExtractedLabelToPrimaryLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectExtractedLabelToPrimaryLabelMutation,
    ConnectExtractedLabelToPrimaryLabelMutationVariables
  >(ConnectExtractedLabelToPrimaryLabelDocument, options);
}
export type ConnectExtractedLabelToPrimaryLabelMutationHookResult = ReturnType<
  typeof useConnectExtractedLabelToPrimaryLabelMutation
>;
export type ConnectExtractedLabelToPrimaryLabelMutationResult =
  Apollo.MutationResult<ConnectExtractedLabelToPrimaryLabelMutation>;
export type ConnectExtractedLabelToPrimaryLabelMutationOptions = Apollo.BaseMutationOptions<
  ConnectExtractedLabelToPrimaryLabelMutation,
  ConnectExtractedLabelToPrimaryLabelMutationVariables
>;
export const CreateActionDocument = gql`
  mutation CreateAction($input: CreateActionInput!) {
    createAction(input: $input) {
      id
    }
  }
`;
export type CreateActionMutationFn = Apollo.MutationFunction<CreateActionMutation, CreateActionMutationVariables>;

/**
 * __useCreateActionMutation__
 *
 * To run a mutation, you first call `useCreateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionMutation, { data, loading, error }] = useCreateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateActionMutation, CreateActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateActionMutation, CreateActionMutationVariables>(CreateActionDocument, options);
}
export type CreateActionMutationHookResult = ReturnType<typeof useCreateActionMutation>;
export type CreateActionMutationResult = Apollo.MutationResult<CreateActionMutation>;
export type CreateActionMutationOptions = Apollo.BaseMutationOptions<
  CreateActionMutation,
  CreateActionMutationVariables
>;
export const CreateAppetiteNoteDocument = gql`
  mutation CreateAppetiteNote($input: CreateAppetiteNoteInput!) {
    createAppetiteNote(input: $input) {
      ...AppetiteNote
    }
  }
  ${AppetiteNoteFragmentDoc}
`;
export type CreateAppetiteNoteMutationFn = Apollo.MutationFunction<
  CreateAppetiteNoteMutation,
  CreateAppetiteNoteMutationVariables
>;

/**
 * __useCreateAppetiteNoteMutation__
 *
 * To run a mutation, you first call `useCreateAppetiteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppetiteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppetiteNoteMutation, { data, loading, error }] = useCreateAppetiteNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppetiteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAppetiteNoteMutation, CreateAppetiteNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAppetiteNoteMutation, CreateAppetiteNoteMutationVariables>(
    CreateAppetiteNoteDocument,
    options
  );
}
export type CreateAppetiteNoteMutationHookResult = ReturnType<typeof useCreateAppetiteNoteMutation>;
export type CreateAppetiteNoteMutationResult = Apollo.MutationResult<CreateAppetiteNoteMutation>;
export type CreateAppetiteNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateAppetiteNoteMutation,
  CreateAppetiteNoteMutationVariables
>;
export const CreateBindingAuthorityExclusionDocument = gql`
  mutation CreateBindingAuthorityExclusion($input: CreateBindingExclusionInput!) {
    createBindingExclusion(input: $input) {
      id
    }
  }
`;
export type CreateBindingAuthorityExclusionMutationFn = Apollo.MutationFunction<
  CreateBindingAuthorityExclusionMutation,
  CreateBindingAuthorityExclusionMutationVariables
>;

/**
 * __useCreateBindingAuthorityExclusionMutation__
 *
 * To run a mutation, you first call `useCreateBindingAuthorityExclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBindingAuthorityExclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBindingAuthorityExclusionMutation, { data, loading, error }] = useCreateBindingAuthorityExclusionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBindingAuthorityExclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBindingAuthorityExclusionMutation,
    CreateBindingAuthorityExclusionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBindingAuthorityExclusionMutation, CreateBindingAuthorityExclusionMutationVariables>(
    CreateBindingAuthorityExclusionDocument,
    options
  );
}
export type CreateBindingAuthorityExclusionMutationHookResult = ReturnType<
  typeof useCreateBindingAuthorityExclusionMutation
>;
export type CreateBindingAuthorityExclusionMutationResult =
  Apollo.MutationResult<CreateBindingAuthorityExclusionMutation>;
export type CreateBindingAuthorityExclusionMutationOptions = Apollo.BaseMutationOptions<
  CreateBindingAuthorityExclusionMutation,
  CreateBindingAuthorityExclusionMutationVariables
>;
export const CreateCarrierContactDocument = gql`
  mutation CreateCarrierContact($input: CreateCarrierContactInput!) {
    createCarrierContact(input: $input) {
      ...CarrierContact
    }
  }
  ${CarrierContactFragmentDoc}
`;
export type CreateCarrierContactMutationFn = Apollo.MutationFunction<
  CreateCarrierContactMutation,
  CreateCarrierContactMutationVariables
>;

/**
 * __useCreateCarrierContactMutation__
 *
 * To run a mutation, you first call `useCreateCarrierContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrierContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarrierContactMutation, { data, loading, error }] = useCreateCarrierContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarrierContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCarrierContactMutation, CreateCarrierContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCarrierContactMutation, CreateCarrierContactMutationVariables>(
    CreateCarrierContactDocument,
    options
  );
}
export type CreateCarrierContactMutationHookResult = ReturnType<typeof useCreateCarrierContactMutation>;
export type CreateCarrierContactMutationResult = Apollo.MutationResult<CreateCarrierContactMutation>;
export type CreateCarrierContactMutationOptions = Apollo.BaseMutationOptions<
  CreateCarrierContactMutation,
  CreateCarrierContactMutationVariables
>;
export const CreateClientDataDocument = gql`
  mutation CreateClientData($input: CreateClientDataInput!) {
    createClientData(input: $input) {
      ...ClientData
    }
  }
  ${ClientDataFragmentDoc}
`;
export type CreateClientDataMutationFn = Apollo.MutationFunction<
  CreateClientDataMutation,
  CreateClientDataMutationVariables
>;

/**
 * __useCreateClientDataMutation__
 *
 * To run a mutation, you first call `useCreateClientDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientDataMutation, { data, loading, error }] = useCreateClientDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientDataMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateClientDataMutation, CreateClientDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateClientDataMutation, CreateClientDataMutationVariables>(
    CreateClientDataDocument,
    options
  );
}
export type CreateClientDataMutationHookResult = ReturnType<typeof useCreateClientDataMutation>;
export type CreateClientDataMutationResult = Apollo.MutationResult<CreateClientDataMutation>;
export type CreateClientDataMutationOptions = Apollo.BaseMutationOptions<
  CreateClientDataMutation,
  CreateClientDataMutationVariables
>;
export const CreateClientDataPdfDocument = gql`
  mutation CreateClientDataPDF($input: CreateClientDataPDFInput!) {
    createClientDataPDF(input: $input) {
      ...FileUpload
    }
  }
  ${FileUploadFragmentDoc}
`;
export type CreateClientDataPdfMutationFn = Apollo.MutationFunction<
  CreateClientDataPdfMutation,
  CreateClientDataPdfMutationVariables
>;

/**
 * __useCreateClientDataPdfMutation__
 *
 * To run a mutation, you first call `useCreateClientDataPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientDataPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientDataPdfMutation, { data, loading, error }] = useCreateClientDataPdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientDataPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateClientDataPdfMutation, CreateClientDataPdfMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateClientDataPdfMutation, CreateClientDataPdfMutationVariables>(
    CreateClientDataPdfDocument,
    options
  );
}
export type CreateClientDataPdfMutationHookResult = ReturnType<typeof useCreateClientDataPdfMutation>;
export type CreateClientDataPdfMutationResult = Apollo.MutationResult<CreateClientDataPdfMutation>;
export type CreateClientDataPdfMutationOptions = Apollo.BaseMutationOptions<
  CreateClientDataPdfMutation,
  CreateClientDataPdfMutationVariables
>;
export const CreateCommentDocument = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      ...OpportunityEvent
    }
  }
  ${OpportunityEventFragmentDoc}
  ${UserCommentFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AssignmentChangeEventFragmentDoc}
  ${TriageAssignmentEventFragmentDoc}
  ${EmailEventFragmentDoc}
  ${GmailMessageEventFragmentDoc}
  ${GmailMessageFragmentDoc}
  ${FileUploadFragmentDoc}
  ${GmailRelayEventFragmentDoc}
  ${FileUploadEventFragmentDoc}
  ${StatusChangeEventFragmentDoc}
  ${SubmissionStatusChangeEventFragmentDoc}
  ${AlbyUpdateEventFragmentDoc}
  ${CreatedEventFragmentDoc}
  ${OutcomeChangeEventFragmentDoc}
  ${DesiredEffectiveDateChangeEventFragmentDoc}
  ${ApplicationSubmittedEventFragmentDoc}
  ${ProductsAddedEventFragmentDoc}
  ${ProductsRemovedEventFragmentDoc}
  ${BotCommentEventFragmentDoc}
  ${AutomatedFollowUpEventFragmentDoc}
  ${ActionRequestedEventFragmentDoc}
  ${ActionApprovedEventFragmentDoc}
  ${ActionRejectedEventFragmentDoc}
  ${ActionDismissedEventFragmentDoc}
  ${CoveragesChangedEventFragmentDoc}
  ${BusinessClassCodesChangedEventFragmentDoc}
  ${RequirementAddedEventFragmentDoc}
`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
}
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const CreateEmailDraftDocument = gql`
  mutation CreateEmailDraft($input: CreateEmailDraftInput!) {
    createEmailDraft(input: $input)
  }
`;
export type CreateEmailDraftMutationFn = Apollo.MutationFunction<
  CreateEmailDraftMutation,
  CreateEmailDraftMutationVariables
>;

/**
 * __useCreateEmailDraftMutation__
 *
 * To run a mutation, you first call `useCreateEmailDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailDraftMutation, { data, loading, error }] = useCreateEmailDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEmailDraftMutation, CreateEmailDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEmailDraftMutation, CreateEmailDraftMutationVariables>(
    CreateEmailDraftDocument,
    options
  );
}
export type CreateEmailDraftMutationHookResult = ReturnType<typeof useCreateEmailDraftMutation>;
export type CreateEmailDraftMutationResult = Apollo.MutationResult<CreateEmailDraftMutation>;
export type CreateEmailDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateEmailDraftMutation,
  CreateEmailDraftMutationVariables
>;
export const CreateEmailTemplateDraftDocument = gql`
  mutation CreateEmailTemplateDraft($input: CreateEmailTemplateDraftInput!) {
    createEmailTemplateDraft(input: $input)
  }
`;
export type CreateEmailTemplateDraftMutationFn = Apollo.MutationFunction<
  CreateEmailTemplateDraftMutation,
  CreateEmailTemplateDraftMutationVariables
>;

/**
 * __useCreateEmailTemplateDraftMutation__
 *
 * To run a mutation, you first call `useCreateEmailTemplateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailTemplateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailTemplateDraftMutation, { data, loading, error }] = useCreateEmailTemplateDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailTemplateDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEmailTemplateDraftMutation, CreateEmailTemplateDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEmailTemplateDraftMutation, CreateEmailTemplateDraftMutationVariables>(
    CreateEmailTemplateDraftDocument,
    options
  );
}
export type CreateEmailTemplateDraftMutationHookResult = ReturnType<typeof useCreateEmailTemplateDraftMutation>;
export type CreateEmailTemplateDraftMutationResult = Apollo.MutationResult<CreateEmailTemplateDraftMutation>;
export type CreateEmailTemplateDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateEmailTemplateDraftMutation,
  CreateEmailTemplateDraftMutationVariables
>;
export const CreateFilePipelineVersionDocument = gql`
  mutation CreateFilePipelineVersion($input: CreatePipelineVersionInput!) {
    createPipelineVersion(input: $input) {
      ...FilePipelineVersion
    }
  }
  ${FilePipelineVersionFragmentDoc}
`;
export type CreateFilePipelineVersionMutationFn = Apollo.MutationFunction<
  CreateFilePipelineVersionMutation,
  CreateFilePipelineVersionMutationVariables
>;

/**
 * __useCreateFilePipelineVersionMutation__
 *
 * To run a mutation, you first call `useCreateFilePipelineVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilePipelineVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilePipelineVersionMutation, { data, loading, error }] = useCreateFilePipelineVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFilePipelineVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFilePipelineVersionMutation,
    CreateFilePipelineVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFilePipelineVersionMutation, CreateFilePipelineVersionMutationVariables>(
    CreateFilePipelineVersionDocument,
    options
  );
}
export type CreateFilePipelineVersionMutationHookResult = ReturnType<typeof useCreateFilePipelineVersionMutation>;
export type CreateFilePipelineVersionMutationResult = Apollo.MutationResult<CreateFilePipelineVersionMutation>;
export type CreateFilePipelineVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateFilePipelineVersionMutation,
  CreateFilePipelineVersionMutationVariables
>;
export const CreateInsuredDocument = gql`
  mutation CreateInsured($input: CreateInsuredInput!) {
    createInsured(input: $input) {
      ...Insured
    }
  }
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AgencyFragmentDoc}
`;
export type CreateInsuredMutationFn = Apollo.MutationFunction<CreateInsuredMutation, CreateInsuredMutationVariables>;

/**
 * __useCreateInsuredMutation__
 *
 * To run a mutation, you first call `useCreateInsuredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuredMutation, { data, loading, error }] = useCreateInsuredMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsuredMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInsuredMutation, CreateInsuredMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInsuredMutation, CreateInsuredMutationVariables>(CreateInsuredDocument, options);
}
export type CreateInsuredMutationHookResult = ReturnType<typeof useCreateInsuredMutation>;
export type CreateInsuredMutationResult = Apollo.MutationResult<CreateInsuredMutation>;
export type CreateInsuredMutationOptions = Apollo.BaseMutationOptions<
  CreateInsuredMutation,
  CreateInsuredMutationVariables
>;
export const CreateInsuredAndAgentDocument = gql`
  mutation CreateInsuredAndAgent($input: CreateInsuredAndAgentInput!) {
    createInsuredAndAgent(input: $input) {
      ...Insured
    }
  }
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AgencyFragmentDoc}
`;
export type CreateInsuredAndAgentMutationFn = Apollo.MutationFunction<
  CreateInsuredAndAgentMutation,
  CreateInsuredAndAgentMutationVariables
>;

/**
 * __useCreateInsuredAndAgentMutation__
 *
 * To run a mutation, you first call `useCreateInsuredAndAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuredAndAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuredAndAgentMutation, { data, loading, error }] = useCreateInsuredAndAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsuredAndAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInsuredAndAgentMutation, CreateInsuredAndAgentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInsuredAndAgentMutation, CreateInsuredAndAgentMutationVariables>(
    CreateInsuredAndAgentDocument,
    options
  );
}
export type CreateInsuredAndAgentMutationHookResult = ReturnType<typeof useCreateInsuredAndAgentMutation>;
export type CreateInsuredAndAgentMutationResult = Apollo.MutationResult<CreateInsuredAndAgentMutation>;
export type CreateInsuredAndAgentMutationOptions = Apollo.BaseMutationOptions<
  CreateInsuredAndAgentMutation,
  CreateInsuredAndAgentMutationVariables
>;
export const CreateInsuredHazardDocument = gql`
  mutation CreateInsuredHazard($input: CreateInsuredHazardInput!) {
    createHazard(input: $input) {
      id
    }
  }
`;
export type CreateInsuredHazardMutationFn = Apollo.MutationFunction<
  CreateInsuredHazardMutation,
  CreateInsuredHazardMutationVariables
>;

/**
 * __useCreateInsuredHazardMutation__
 *
 * To run a mutation, you first call `useCreateInsuredHazardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuredHazardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuredHazardMutation, { data, loading, error }] = useCreateInsuredHazardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsuredHazardMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInsuredHazardMutation, CreateInsuredHazardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInsuredHazardMutation, CreateInsuredHazardMutationVariables>(
    CreateInsuredHazardDocument,
    options
  );
}
export type CreateInsuredHazardMutationHookResult = ReturnType<typeof useCreateInsuredHazardMutation>;
export type CreateInsuredHazardMutationResult = Apollo.MutationResult<CreateInsuredHazardMutation>;
export type CreateInsuredHazardMutationOptions = Apollo.BaseMutationOptions<
  CreateInsuredHazardMutation,
  CreateInsuredHazardMutationVariables
>;
export const CreateInsuredPremiseBuildingDocument = gql`
  mutation CreateInsuredPremiseBuilding($input: CreateInsuredPremiseBuildingInput!) {
    createBuilding(input: $input) {
      id
    }
  }
`;
export type CreateInsuredPremiseBuildingMutationFn = Apollo.MutationFunction<
  CreateInsuredPremiseBuildingMutation,
  CreateInsuredPremiseBuildingMutationVariables
>;

/**
 * __useCreateInsuredPremiseBuildingMutation__
 *
 * To run a mutation, you first call `useCreateInsuredPremiseBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuredPremiseBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuredPremiseBuildingMutation, { data, loading, error }] = useCreateInsuredPremiseBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsuredPremiseBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInsuredPremiseBuildingMutation,
    CreateInsuredPremiseBuildingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInsuredPremiseBuildingMutation, CreateInsuredPremiseBuildingMutationVariables>(
    CreateInsuredPremiseBuildingDocument,
    options
  );
}
export type CreateInsuredPremiseBuildingMutationHookResult = ReturnType<typeof useCreateInsuredPremiseBuildingMutation>;
export type CreateInsuredPremiseBuildingMutationResult = Apollo.MutationResult<CreateInsuredPremiseBuildingMutation>;
export type CreateInsuredPremiseBuildingMutationOptions = Apollo.BaseMutationOptions<
  CreateInsuredPremiseBuildingMutation,
  CreateInsuredPremiseBuildingMutationVariables
>;
export const CreateInsuredPremiseLocationDocument = gql`
  mutation CreateInsuredPremiseLocation($input: CreateInsuredPremiseLocationInput!) {
    createLocation(input: $input) {
      id
    }
  }
`;
export type CreateInsuredPremiseLocationMutationFn = Apollo.MutationFunction<
  CreateInsuredPremiseLocationMutation,
  CreateInsuredPremiseLocationMutationVariables
>;

/**
 * __useCreateInsuredPremiseLocationMutation__
 *
 * To run a mutation, you first call `useCreateInsuredPremiseLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuredPremiseLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuredPremiseLocationMutation, { data, loading, error }] = useCreateInsuredPremiseLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsuredPremiseLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInsuredPremiseLocationMutation,
    CreateInsuredPremiseLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInsuredPremiseLocationMutation, CreateInsuredPremiseLocationMutationVariables>(
    CreateInsuredPremiseLocationDocument,
    options
  );
}
export type CreateInsuredPremiseLocationMutationHookResult = ReturnType<typeof useCreateInsuredPremiseLocationMutation>;
export type CreateInsuredPremiseLocationMutationResult = Apollo.MutationResult<CreateInsuredPremiseLocationMutation>;
export type CreateInsuredPremiseLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateInsuredPremiseLocationMutation,
  CreateInsuredPremiseLocationMutationVariables
>;
export const CreateOpportunityDocument = gql`
  mutation CreateOpportunity($input: CreateOpportunityInput!) {
    createOpportunity(input: $input) {
      id
    }
  }
`;
export type CreateOpportunityMutationFn = Apollo.MutationFunction<
  CreateOpportunityMutation,
  CreateOpportunityMutationVariables
>;

/**
 * __useCreateOpportunityMutation__
 *
 * To run a mutation, you first call `useCreateOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpportunityMutation, { data, loading, error }] = useCreateOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOpportunityMutation, CreateOpportunityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOpportunityMutation, CreateOpportunityMutationVariables>(
    CreateOpportunityDocument,
    options
  );
}
export type CreateOpportunityMutationHookResult = ReturnType<typeof useCreateOpportunityMutation>;
export type CreateOpportunityMutationResult = Apollo.MutationResult<CreateOpportunityMutation>;
export type CreateOpportunityMutationOptions = Apollo.BaseMutationOptions<
  CreateOpportunityMutation,
  CreateOpportunityMutationVariables
>;
export const CreateOpportunityNoteDocument = gql`
  mutation CreateOpportunityNote($input: CreateOpportunityNoteInput!) {
    createNote(input: $input) {
      id
    }
  }
`;
export type CreateOpportunityNoteMutationFn = Apollo.MutationFunction<
  CreateOpportunityNoteMutation,
  CreateOpportunityNoteMutationVariables
>;

/**
 * __useCreateOpportunityNoteMutation__
 *
 * To run a mutation, you first call `useCreateOpportunityNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpportunityNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpportunityNoteMutation, { data, loading, error }] = useCreateOpportunityNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOpportunityNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOpportunityNoteMutation, CreateOpportunityNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOpportunityNoteMutation, CreateOpportunityNoteMutationVariables>(
    CreateOpportunityNoteDocument,
    options
  );
}
export type CreateOpportunityNoteMutationHookResult = ReturnType<typeof useCreateOpportunityNoteMutation>;
export type CreateOpportunityNoteMutationResult = Apollo.MutationResult<CreateOpportunityNoteMutation>;
export type CreateOpportunityNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateOpportunityNoteMutation,
  CreateOpportunityNoteMutationVariables
>;
export const CreatePrimaryLabelDocument = gql`
  mutation CreatePrimaryLabel($input: CreatePrimaryLabelInput!) {
    createPrimaryLabel(input: $input) {
      ...PrimaryLabel
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;
export type CreatePrimaryLabelMutationFn = Apollo.MutationFunction<
  CreatePrimaryLabelMutation,
  CreatePrimaryLabelMutationVariables
>;

/**
 * __useCreatePrimaryLabelMutation__
 *
 * To run a mutation, you first call `useCreatePrimaryLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrimaryLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrimaryLabelMutation, { data, loading, error }] = useCreatePrimaryLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrimaryLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePrimaryLabelMutation, CreatePrimaryLabelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePrimaryLabelMutation, CreatePrimaryLabelMutationVariables>(
    CreatePrimaryLabelDocument,
    options
  );
}
export type CreatePrimaryLabelMutationHookResult = ReturnType<typeof useCreatePrimaryLabelMutation>;
export type CreatePrimaryLabelMutationResult = Apollo.MutationResult<CreatePrimaryLabelMutation>;
export type CreatePrimaryLabelMutationOptions = Apollo.BaseMutationOptions<
  CreatePrimaryLabelMutation,
  CreatePrimaryLabelMutationVariables
>;
export const CreateProcessedBinderDocument = gql`
  mutation CreateProcessedBinder($input: CreateProcessedBinderInput!) {
    createProcessedBinder(input: $input) {
      ...Policy
    }
  }
  ${PolicyFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type CreateProcessedBinderMutationFn = Apollo.MutationFunction<
  CreateProcessedBinderMutation,
  CreateProcessedBinderMutationVariables
>;

/**
 * __useCreateProcessedBinderMutation__
 *
 * To run a mutation, you first call `useCreateProcessedBinderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessedBinderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessedBinderMutation, { data, loading, error }] = useCreateProcessedBinderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProcessedBinderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProcessedBinderMutation, CreateProcessedBinderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProcessedBinderMutation, CreateProcessedBinderMutationVariables>(
    CreateProcessedBinderDocument,
    options
  );
}
export type CreateProcessedBinderMutationHookResult = ReturnType<typeof useCreateProcessedBinderMutation>;
export type CreateProcessedBinderMutationResult = Apollo.MutationResult<CreateProcessedBinderMutation>;
export type CreateProcessedBinderMutationOptions = Apollo.BaseMutationOptions<
  CreateProcessedBinderMutation,
  CreateProcessedBinderMutationVariables
>;
export const CreateProcessedQuotePdfDocument = gql`
  mutation CreateProcessedQuotePDF($input: CreateProcessedQuoteInput!) {
    createProcessedQuotePDF(input: $input) {
      ...Quote
    }
  }
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
`;
export type CreateProcessedQuotePdfMutationFn = Apollo.MutationFunction<
  CreateProcessedQuotePdfMutation,
  CreateProcessedQuotePdfMutationVariables
>;

/**
 * __useCreateProcessedQuotePdfMutation__
 *
 * To run a mutation, you first call `useCreateProcessedQuotePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessedQuotePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessedQuotePdfMutation, { data, loading, error }] = useCreateProcessedQuotePdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProcessedQuotePdfMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProcessedQuotePdfMutation, CreateProcessedQuotePdfMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProcessedQuotePdfMutation, CreateProcessedQuotePdfMutationVariables>(
    CreateProcessedQuotePdfDocument,
    options
  );
}
export type CreateProcessedQuotePdfMutationHookResult = ReturnType<typeof useCreateProcessedQuotePdfMutation>;
export type CreateProcessedQuotePdfMutationResult = Apollo.MutationResult<CreateProcessedQuotePdfMutation>;
export type CreateProcessedQuotePdfMutationOptions = Apollo.BaseMutationOptions<
  CreateProcessedQuotePdfMutation,
  CreateProcessedQuotePdfMutationVariables
>;
export const CreateRenewalDocument = gql`
  mutation CreateRenewal($input: CreateRenewalInput!) {
    createRenewal(input: $input) {
      id
    }
  }
`;
export type CreateRenewalMutationFn = Apollo.MutationFunction<CreateRenewalMutation, CreateRenewalMutationVariables>;

/**
 * __useCreateRenewalMutation__
 *
 * To run a mutation, you first call `useCreateRenewalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRenewalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRenewalMutation, { data, loading, error }] = useCreateRenewalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRenewalMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRenewalMutation, CreateRenewalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRenewalMutation, CreateRenewalMutationVariables>(CreateRenewalDocument, options);
}
export type CreateRenewalMutationHookResult = ReturnType<typeof useCreateRenewalMutation>;
export type CreateRenewalMutationResult = Apollo.MutationResult<CreateRenewalMutation>;
export type CreateRenewalMutationOptions = Apollo.BaseMutationOptions<
  CreateRenewalMutation,
  CreateRenewalMutationVariables
>;
export const CreateTagDocument = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createFileTag(input: $input) {
      label
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const CreateVerticalDocument = gql`
  mutation CreateVertical($input: CreateVerticalInput!) {
    createVertical(input: $input) {
      ...Vertical
    }
  }
  ${VerticalFragmentDoc}
  ${ClassCodeFragmentDoc}
  ${VerticalMarketingPlanTemplateFragmentDoc}
  ${VerticalMarketingPlanTemplateProductFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type CreateVerticalMutationFn = Apollo.MutationFunction<CreateVerticalMutation, CreateVerticalMutationVariables>;

/**
 * __useCreateVerticalMutation__
 *
 * To run a mutation, you first call `useCreateVerticalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVerticalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVerticalMutation, { data, loading, error }] = useCreateVerticalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVerticalMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVerticalMutation, CreateVerticalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVerticalMutation, CreateVerticalMutationVariables>(CreateVerticalDocument, options);
}
export type CreateVerticalMutationHookResult = ReturnType<typeof useCreateVerticalMutation>;
export type CreateVerticalMutationResult = Apollo.MutationResult<CreateVerticalMutation>;
export type CreateVerticalMutationOptions = Apollo.BaseMutationOptions<
  CreateVerticalMutation,
  CreateVerticalMutationVariables
>;
export const CreateVerticalMarketingPlanTemplateDocument = gql`
  mutation CreateVerticalMarketingPlanTemplate($input: CreateVerticalMarketingPlanTemplateInput!) {
    createVerticalMarketingPlanTemplate(input: $input) {
      id
    }
  }
`;
export type CreateVerticalMarketingPlanTemplateMutationFn = Apollo.MutationFunction<
  CreateVerticalMarketingPlanTemplateMutation,
  CreateVerticalMarketingPlanTemplateMutationVariables
>;

/**
 * __useCreateVerticalMarketingPlanTemplateMutation__
 *
 * To run a mutation, you first call `useCreateVerticalMarketingPlanTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVerticalMarketingPlanTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVerticalMarketingPlanTemplateMutation, { data, loading, error }] = useCreateVerticalMarketingPlanTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVerticalMarketingPlanTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVerticalMarketingPlanTemplateMutation,
    CreateVerticalMarketingPlanTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVerticalMarketingPlanTemplateMutation,
    CreateVerticalMarketingPlanTemplateMutationVariables
  >(CreateVerticalMarketingPlanTemplateDocument, options);
}
export type CreateVerticalMarketingPlanTemplateMutationHookResult = ReturnType<
  typeof useCreateVerticalMarketingPlanTemplateMutation
>;
export type CreateVerticalMarketingPlanTemplateMutationResult =
  Apollo.MutationResult<CreateVerticalMarketingPlanTemplateMutation>;
export type CreateVerticalMarketingPlanTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateVerticalMarketingPlanTemplateMutation,
  CreateVerticalMarketingPlanTemplateMutationVariables
>;
export const DeactivateUserAccountDocument = gql`
  mutation DeactivateUserAccount($id: ID!) {
    deactivateUser(id: $id) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;
export type DeactivateUserAccountMutationFn = Apollo.MutationFunction<
  DeactivateUserAccountMutation,
  DeactivateUserAccountMutationVariables
>;

/**
 * __useDeactivateUserAccountMutation__
 *
 * To run a mutation, you first call `useDeactivateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserAccountMutation, { data, loading, error }] = useDeactivateUserAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateUserAccountMutation, DeactivateUserAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivateUserAccountMutation, DeactivateUserAccountMutationVariables>(
    DeactivateUserAccountDocument,
    options
  );
}
export type DeactivateUserAccountMutationHookResult = ReturnType<typeof useDeactivateUserAccountMutation>;
export type DeactivateUserAccountMutationResult = Apollo.MutationResult<DeactivateUserAccountMutation>;
export type DeactivateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  DeactivateUserAccountMutation,
  DeactivateUserAccountMutationVariables
>;
export const DeleteActionDocument = gql`
  mutation DeleteAction($id: String!) {
    deleteAction(id: $id) {
      id
    }
  }
`;
export type DeleteActionMutationFn = Apollo.MutationFunction<DeleteActionMutation, DeleteActionMutationVariables>;

/**
 * __useDeleteActionMutation__
 *
 * To run a mutation, you first call `useDeleteActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionMutation, { data, loading, error }] = useDeleteActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteActionMutation, DeleteActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteActionMutation, DeleteActionMutationVariables>(DeleteActionDocument, options);
}
export type DeleteActionMutationHookResult = ReturnType<typeof useDeleteActionMutation>;
export type DeleteActionMutationResult = Apollo.MutationResult<DeleteActionMutation>;
export type DeleteActionMutationOptions = Apollo.BaseMutationOptions<
  DeleteActionMutation,
  DeleteActionMutationVariables
>;
export const DeleteAgentActionDocument = gql`
  mutation DeleteAgentAction($id: ID!) {
    deleteAgentAction(id: $id) {
      id
    }
  }
`;
export type DeleteAgentActionMutationFn = Apollo.MutationFunction<
  DeleteAgentActionMutation,
  DeleteAgentActionMutationVariables
>;

/**
 * __useDeleteAgentActionMutation__
 *
 * To run a mutation, you first call `useDeleteAgentActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAgentActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAgentActionMutation, { data, loading, error }] = useDeleteAgentActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAgentActionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAgentActionMutation, DeleteAgentActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAgentActionMutation, DeleteAgentActionMutationVariables>(
    DeleteAgentActionDocument,
    options
  );
}
export type DeleteAgentActionMutationHookResult = ReturnType<typeof useDeleteAgentActionMutation>;
export type DeleteAgentActionMutationResult = Apollo.MutationResult<DeleteAgentActionMutation>;
export type DeleteAgentActionMutationOptions = Apollo.BaseMutationOptions<
  DeleteAgentActionMutation,
  DeleteAgentActionMutationVariables
>;
export const DeleteClientDataRowDocument = gql`
  mutation DeleteClientDataRow($input: DeleteClientDataInput!) {
    deleteClientDataRow(input: $input) {
      ...ClientData
    }
  }
  ${ClientDataFragmentDoc}
`;
export type DeleteClientDataRowMutationFn = Apollo.MutationFunction<
  DeleteClientDataRowMutation,
  DeleteClientDataRowMutationVariables
>;

/**
 * __useDeleteClientDataRowMutation__
 *
 * To run a mutation, you first call `useDeleteClientDataRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientDataRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientDataRowMutation, { data, loading, error }] = useDeleteClientDataRowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientDataRowMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteClientDataRowMutation, DeleteClientDataRowMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteClientDataRowMutation, DeleteClientDataRowMutationVariables>(
    DeleteClientDataRowDocument,
    options
  );
}
export type DeleteClientDataRowMutationHookResult = ReturnType<typeof useDeleteClientDataRowMutation>;
export type DeleteClientDataRowMutationResult = Apollo.MutationResult<DeleteClientDataRowMutation>;
export type DeleteClientDataRowMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientDataRowMutation,
  DeleteClientDataRowMutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id) {
      ...OpportunityEvent
    }
  }
  ${OpportunityEventFragmentDoc}
  ${UserCommentFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AssignmentChangeEventFragmentDoc}
  ${TriageAssignmentEventFragmentDoc}
  ${EmailEventFragmentDoc}
  ${GmailMessageEventFragmentDoc}
  ${GmailMessageFragmentDoc}
  ${FileUploadFragmentDoc}
  ${GmailRelayEventFragmentDoc}
  ${FileUploadEventFragmentDoc}
  ${StatusChangeEventFragmentDoc}
  ${SubmissionStatusChangeEventFragmentDoc}
  ${AlbyUpdateEventFragmentDoc}
  ${CreatedEventFragmentDoc}
  ${OutcomeChangeEventFragmentDoc}
  ${DesiredEffectiveDateChangeEventFragmentDoc}
  ${ApplicationSubmittedEventFragmentDoc}
  ${ProductsAddedEventFragmentDoc}
  ${ProductsRemovedEventFragmentDoc}
  ${BotCommentEventFragmentDoc}
  ${AutomatedFollowUpEventFragmentDoc}
  ${ActionRequestedEventFragmentDoc}
  ${ActionApprovedEventFragmentDoc}
  ${ActionRejectedEventFragmentDoc}
  ${ActionDismissedEventFragmentDoc}
  ${CoveragesChangedEventFragmentDoc}
  ${BusinessClassCodesChangedEventFragmentDoc}
  ${RequirementAddedEventFragmentDoc}
`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
}
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const DeleteFileProcessorVersionDocument = gql`
  mutation DeleteFileProcessorVersion($input: UpdateFileProcessorVersionInput!) {
    deleteProcessorVersion(input: $input) {
      success
    }
  }
`;
export type DeleteFileProcessorVersionMutationFn = Apollo.MutationFunction<
  DeleteFileProcessorVersionMutation,
  DeleteFileProcessorVersionMutationVariables
>;

/**
 * __useDeleteFileProcessorVersionMutation__
 *
 * To run a mutation, you first call `useDeleteFileProcessorVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileProcessorVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileProcessorVersionMutation, { data, loading, error }] = useDeleteFileProcessorVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileProcessorVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFileProcessorVersionMutation,
    DeleteFileProcessorVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileProcessorVersionMutation, DeleteFileProcessorVersionMutationVariables>(
    DeleteFileProcessorVersionDocument,
    options
  );
}
export type DeleteFileProcessorVersionMutationHookResult = ReturnType<typeof useDeleteFileProcessorVersionMutation>;
export type DeleteFileProcessorVersionMutationResult = Apollo.MutationResult<DeleteFileProcessorVersionMutation>;
export type DeleteFileProcessorVersionMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileProcessorVersionMutation,
  DeleteFileProcessorVersionMutationVariables
>;
export const DeleteFileUploadDocument = gql`
  mutation DeleteFileUpload($input: DeleteFileUploadInput!) {
    deleteFileUpload(input: $input) {
      id
    }
  }
`;
export type DeleteFileUploadMutationFn = Apollo.MutationFunction<
  DeleteFileUploadMutation,
  DeleteFileUploadMutationVariables
>;

/**
 * __useDeleteFileUploadMutation__
 *
 * To run a mutation, you first call `useDeleteFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileUploadMutation, { data, loading, error }] = useDeleteFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFileUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileUploadMutation, DeleteFileUploadMutationVariables>(
    DeleteFileUploadDocument,
    options
  );
}
export type DeleteFileUploadMutationHookResult = ReturnType<typeof useDeleteFileUploadMutation>;
export type DeleteFileUploadMutationResult = Apollo.MutationResult<DeleteFileUploadMutation>;
export type DeleteFileUploadMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileUploadMutation,
  DeleteFileUploadMutationVariables
>;
export const DeleteInsuredDocument = gql`
  mutation DeleteInsured($id: ID!) {
    deleteInsured(id: $id) {
      ...Insured
    }
  }
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AgencyFragmentDoc}
`;
export type DeleteInsuredMutationFn = Apollo.MutationFunction<DeleteInsuredMutation, DeleteInsuredMutationVariables>;

/**
 * __useDeleteInsuredMutation__
 *
 * To run a mutation, you first call `useDeleteInsuredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuredMutation, { data, loading, error }] = useDeleteInsuredMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInsuredMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInsuredMutation, DeleteInsuredMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInsuredMutation, DeleteInsuredMutationVariables>(DeleteInsuredDocument, options);
}
export type DeleteInsuredMutationHookResult = ReturnType<typeof useDeleteInsuredMutation>;
export type DeleteInsuredMutationResult = Apollo.MutationResult<DeleteInsuredMutation>;
export type DeleteInsuredMutationOptions = Apollo.BaseMutationOptions<
  DeleteInsuredMutation,
  DeleteInsuredMutationVariables
>;
export const DeleteInsuredHazardDocument = gql`
  mutation DeleteInsuredHazard($id: ID!) {
    deleteHazard(id: $id) {
      id
    }
  }
`;
export type DeleteInsuredHazardMutationFn = Apollo.MutationFunction<
  DeleteInsuredHazardMutation,
  DeleteInsuredHazardMutationVariables
>;

/**
 * __useDeleteInsuredHazardMutation__
 *
 * To run a mutation, you first call `useDeleteInsuredHazardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuredHazardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuredHazardMutation, { data, loading, error }] = useDeleteInsuredHazardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInsuredHazardMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInsuredHazardMutation, DeleteInsuredHazardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInsuredHazardMutation, DeleteInsuredHazardMutationVariables>(
    DeleteInsuredHazardDocument,
    options
  );
}
export type DeleteInsuredHazardMutationHookResult = ReturnType<typeof useDeleteInsuredHazardMutation>;
export type DeleteInsuredHazardMutationResult = Apollo.MutationResult<DeleteInsuredHazardMutation>;
export type DeleteInsuredHazardMutationOptions = Apollo.BaseMutationOptions<
  DeleteInsuredHazardMutation,
  DeleteInsuredHazardMutationVariables
>;
export const DeleteInsuredPremiseBuildingDocument = gql`
  mutation DeleteInsuredPremiseBuilding($id: ID!) {
    deleteBuilding(id: $id) {
      id
    }
  }
`;
export type DeleteInsuredPremiseBuildingMutationFn = Apollo.MutationFunction<
  DeleteInsuredPremiseBuildingMutation,
  DeleteInsuredPremiseBuildingMutationVariables
>;

/**
 * __useDeleteInsuredPremiseBuildingMutation__
 *
 * To run a mutation, you first call `useDeleteInsuredPremiseBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuredPremiseBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuredPremiseBuildingMutation, { data, loading, error }] = useDeleteInsuredPremiseBuildingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInsuredPremiseBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInsuredPremiseBuildingMutation,
    DeleteInsuredPremiseBuildingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInsuredPremiseBuildingMutation, DeleteInsuredPremiseBuildingMutationVariables>(
    DeleteInsuredPremiseBuildingDocument,
    options
  );
}
export type DeleteInsuredPremiseBuildingMutationHookResult = ReturnType<typeof useDeleteInsuredPremiseBuildingMutation>;
export type DeleteInsuredPremiseBuildingMutationResult = Apollo.MutationResult<DeleteInsuredPremiseBuildingMutation>;
export type DeleteInsuredPremiseBuildingMutationOptions = Apollo.BaseMutationOptions<
  DeleteInsuredPremiseBuildingMutation,
  DeleteInsuredPremiseBuildingMutationVariables
>;
export const DeleteInsuredPremiseLocationDocument = gql`
  mutation DeleteInsuredPremiseLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
    }
  }
`;
export type DeleteInsuredPremiseLocationMutationFn = Apollo.MutationFunction<
  DeleteInsuredPremiseLocationMutation,
  DeleteInsuredPremiseLocationMutationVariables
>;

/**
 * __useDeleteInsuredPremiseLocationMutation__
 *
 * To run a mutation, you first call `useDeleteInsuredPremiseLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuredPremiseLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuredPremiseLocationMutation, { data, loading, error }] = useDeleteInsuredPremiseLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInsuredPremiseLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInsuredPremiseLocationMutation,
    DeleteInsuredPremiseLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInsuredPremiseLocationMutation, DeleteInsuredPremiseLocationMutationVariables>(
    DeleteInsuredPremiseLocationDocument,
    options
  );
}
export type DeleteInsuredPremiseLocationMutationHookResult = ReturnType<typeof useDeleteInsuredPremiseLocationMutation>;
export type DeleteInsuredPremiseLocationMutationResult = Apollo.MutationResult<DeleteInsuredPremiseLocationMutation>;
export type DeleteInsuredPremiseLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteInsuredPremiseLocationMutation,
  DeleteInsuredPremiseLocationMutationVariables
>;
export const DeleteOpportunityDocument = gql`
  mutation DeleteOpportunity($id: ID!) {
    deleteOpportunity(id: $id) {
      id
    }
  }
`;
export type DeleteOpportunityMutationFn = Apollo.MutationFunction<
  DeleteOpportunityMutation,
  DeleteOpportunityMutationVariables
>;

/**
 * __useDeleteOpportunityMutation__
 *
 * To run a mutation, you first call `useDeleteOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpportunityMutation, { data, loading, error }] = useDeleteOpportunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOpportunityMutation, DeleteOpportunityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOpportunityMutation, DeleteOpportunityMutationVariables>(
    DeleteOpportunityDocument,
    options
  );
}
export type DeleteOpportunityMutationHookResult = ReturnType<typeof useDeleteOpportunityMutation>;
export type DeleteOpportunityMutationResult = Apollo.MutationResult<DeleteOpportunityMutation>;
export type DeleteOpportunityMutationOptions = Apollo.BaseMutationOptions<
  DeleteOpportunityMutation,
  DeleteOpportunityMutationVariables
>;
export const DeletePrimaryLabelDocument = gql`
  mutation DeletePrimaryLabel($id: ID!) {
    deletePrimaryLabel(id: $id) {
      ...PrimaryLabel
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;
export type DeletePrimaryLabelMutationFn = Apollo.MutationFunction<
  DeletePrimaryLabelMutation,
  DeletePrimaryLabelMutationVariables
>;

/**
 * __useDeletePrimaryLabelMutation__
 *
 * To run a mutation, you first call `useDeletePrimaryLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrimaryLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrimaryLabelMutation, { data, loading, error }] = useDeletePrimaryLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePrimaryLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePrimaryLabelMutation, DeletePrimaryLabelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePrimaryLabelMutation, DeletePrimaryLabelMutationVariables>(
    DeletePrimaryLabelDocument,
    options
  );
}
export type DeletePrimaryLabelMutationHookResult = ReturnType<typeof useDeletePrimaryLabelMutation>;
export type DeletePrimaryLabelMutationResult = Apollo.MutationResult<DeletePrimaryLabelMutation>;
export type DeletePrimaryLabelMutationOptions = Apollo.BaseMutationOptions<
  DeletePrimaryLabelMutation,
  DeletePrimaryLabelMutationVariables
>;
export const DeleteVerticalDocument = gql`
  mutation DeleteVertical($id: String!) {
    deleteVertical(id: $id) {
      ...Vertical
    }
  }
  ${VerticalFragmentDoc}
  ${ClassCodeFragmentDoc}
  ${VerticalMarketingPlanTemplateFragmentDoc}
  ${VerticalMarketingPlanTemplateProductFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type DeleteVerticalMutationFn = Apollo.MutationFunction<DeleteVerticalMutation, DeleteVerticalMutationVariables>;

/**
 * __useDeleteVerticalMutation__
 *
 * To run a mutation, you first call `useDeleteVerticalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVerticalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVerticalMutation, { data, loading, error }] = useDeleteVerticalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVerticalMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVerticalMutation, DeleteVerticalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVerticalMutation, DeleteVerticalMutationVariables>(DeleteVerticalDocument, options);
}
export type DeleteVerticalMutationHookResult = ReturnType<typeof useDeleteVerticalMutation>;
export type DeleteVerticalMutationResult = Apollo.MutationResult<DeleteVerticalMutation>;
export type DeleteVerticalMutationOptions = Apollo.BaseMutationOptions<
  DeleteVerticalMutation,
  DeleteVerticalMutationVariables
>;
export const DeleteVerticalMarketingPlanTemplateProductRequirementDocument = gql`
  mutation DeleteVerticalMarketingPlanTemplateProductRequirement(
    $input: DeleteVerticalMarketingPlanTemplateProductRequirementInput!
  ) {
    deleteVerticalMarketingPlanTemplateProductRequirement(input: $input) {
      id
    }
  }
`;
export type DeleteVerticalMarketingPlanTemplateProductRequirementMutationFn = Apollo.MutationFunction<
  DeleteVerticalMarketingPlanTemplateProductRequirementMutation,
  DeleteVerticalMarketingPlanTemplateProductRequirementMutationVariables
>;

/**
 * __useDeleteVerticalMarketingPlanTemplateProductRequirementMutation__
 *
 * To run a mutation, you first call `useDeleteVerticalMarketingPlanTemplateProductRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVerticalMarketingPlanTemplateProductRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVerticalMarketingPlanTemplateProductRequirementMutation, { data, loading, error }] = useDeleteVerticalMarketingPlanTemplateProductRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVerticalMarketingPlanTemplateProductRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVerticalMarketingPlanTemplateProductRequirementMutation,
    DeleteVerticalMarketingPlanTemplateProductRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteVerticalMarketingPlanTemplateProductRequirementMutation,
    DeleteVerticalMarketingPlanTemplateProductRequirementMutationVariables
  >(DeleteVerticalMarketingPlanTemplateProductRequirementDocument, options);
}
export type DeleteVerticalMarketingPlanTemplateProductRequirementMutationHookResult = ReturnType<
  typeof useDeleteVerticalMarketingPlanTemplateProductRequirementMutation
>;
export type DeleteVerticalMarketingPlanTemplateProductRequirementMutationResult =
  Apollo.MutationResult<DeleteVerticalMarketingPlanTemplateProductRequirementMutation>;
export type DeleteVerticalMarketingPlanTemplateProductRequirementMutationOptions = Apollo.BaseMutationOptions<
  DeleteVerticalMarketingPlanTemplateProductRequirementMutation,
  DeleteVerticalMarketingPlanTemplateProductRequirementMutationVariables
>;
export const DeliverBinderDocument = gql`
  mutation DeliverBinder($input: SendPolicyFileInput!) {
    deliverBinder(input: $input) {
      ...Policy
    }
  }
  ${PolicyFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type DeliverBinderMutationFn = Apollo.MutationFunction<DeliverBinderMutation, DeliverBinderMutationVariables>;

/**
 * __useDeliverBinderMutation__
 *
 * To run a mutation, you first call `useDeliverBinderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliverBinderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliverBinderMutation, { data, loading, error }] = useDeliverBinderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliverBinderMutation(
  baseOptions?: Apollo.MutationHookOptions<DeliverBinderMutation, DeliverBinderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeliverBinderMutation, DeliverBinderMutationVariables>(DeliverBinderDocument, options);
}
export type DeliverBinderMutationHookResult = ReturnType<typeof useDeliverBinderMutation>;
export type DeliverBinderMutationResult = Apollo.MutationResult<DeliverBinderMutation>;
export type DeliverBinderMutationOptions = Apollo.BaseMutationOptions<
  DeliverBinderMutation,
  DeliverBinderMutationVariables
>;
export const DeployFileProcessorVersionDocument = gql`
  mutation DeployFileProcessorVersion($input: UpdateFileProcessorVersionInput!) {
    deployProcessorVersion(input: $input) {
      success
    }
  }
`;
export type DeployFileProcessorVersionMutationFn = Apollo.MutationFunction<
  DeployFileProcessorVersionMutation,
  DeployFileProcessorVersionMutationVariables
>;

/**
 * __useDeployFileProcessorVersionMutation__
 *
 * To run a mutation, you first call `useDeployFileProcessorVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployFileProcessorVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployFileProcessorVersionMutation, { data, loading, error }] = useDeployFileProcessorVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeployFileProcessorVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeployFileProcessorVersionMutation,
    DeployFileProcessorVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeployFileProcessorVersionMutation, DeployFileProcessorVersionMutationVariables>(
    DeployFileProcessorVersionDocument,
    options
  );
}
export type DeployFileProcessorVersionMutationHookResult = ReturnType<typeof useDeployFileProcessorVersionMutation>;
export type DeployFileProcessorVersionMutationResult = Apollo.MutationResult<DeployFileProcessorVersionMutation>;
export type DeployFileProcessorVersionMutationOptions = Apollo.BaseMutationOptions<
  DeployFileProcessorVersionMutation,
  DeployFileProcessorVersionMutationVariables
>;
export const DisconnectExtractedLabelFromPrimaryLabelDocument = gql`
  mutation DisconnectExtractedLabelFromPrimaryLabel($input: ConnectOrDisconnectExtractedLabelInput!) {
    disconnectExtractedLabelFromPrimaryLabel(input: $input) {
      ...PrimaryLabel
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;
export type DisconnectExtractedLabelFromPrimaryLabelMutationFn = Apollo.MutationFunction<
  DisconnectExtractedLabelFromPrimaryLabelMutation,
  DisconnectExtractedLabelFromPrimaryLabelMutationVariables
>;

/**
 * __useDisconnectExtractedLabelFromPrimaryLabelMutation__
 *
 * To run a mutation, you first call `useDisconnectExtractedLabelFromPrimaryLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectExtractedLabelFromPrimaryLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectExtractedLabelFromPrimaryLabelMutation, { data, loading, error }] = useDisconnectExtractedLabelFromPrimaryLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectExtractedLabelFromPrimaryLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectExtractedLabelFromPrimaryLabelMutation,
    DisconnectExtractedLabelFromPrimaryLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectExtractedLabelFromPrimaryLabelMutation,
    DisconnectExtractedLabelFromPrimaryLabelMutationVariables
  >(DisconnectExtractedLabelFromPrimaryLabelDocument, options);
}
export type DisconnectExtractedLabelFromPrimaryLabelMutationHookResult = ReturnType<
  typeof useDisconnectExtractedLabelFromPrimaryLabelMutation
>;
export type DisconnectExtractedLabelFromPrimaryLabelMutationResult =
  Apollo.MutationResult<DisconnectExtractedLabelFromPrimaryLabelMutation>;
export type DisconnectExtractedLabelFromPrimaryLabelMutationOptions = Apollo.BaseMutationOptions<
  DisconnectExtractedLabelFromPrimaryLabelMutation,
  DisconnectExtractedLabelFromPrimaryLabelMutationVariables
>;
export const DismissSubmissionStateLogExclusionReasonsDocument = gql`
  mutation DismissSubmissionStateLogExclusionReasons($input: DismissSubmissionStateLogExclusionReasonsInput!) {
    dismissSubmissionStateLogExclusionReasons(input: $input) {
      ...SubmissionStateTransition
    }
  }
  ${SubmissionStateTransitionFragmentDoc}
`;
export type DismissSubmissionStateLogExclusionReasonsMutationFn = Apollo.MutationFunction<
  DismissSubmissionStateLogExclusionReasonsMutation,
  DismissSubmissionStateLogExclusionReasonsMutationVariables
>;

/**
 * __useDismissSubmissionStateLogExclusionReasonsMutation__
 *
 * To run a mutation, you first call `useDismissSubmissionStateLogExclusionReasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissSubmissionStateLogExclusionReasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissSubmissionStateLogExclusionReasonsMutation, { data, loading, error }] = useDismissSubmissionStateLogExclusionReasonsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissSubmissionStateLogExclusionReasonsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissSubmissionStateLogExclusionReasonsMutation,
    DismissSubmissionStateLogExclusionReasonsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissSubmissionStateLogExclusionReasonsMutation,
    DismissSubmissionStateLogExclusionReasonsMutationVariables
  >(DismissSubmissionStateLogExclusionReasonsDocument, options);
}
export type DismissSubmissionStateLogExclusionReasonsMutationHookResult = ReturnType<
  typeof useDismissSubmissionStateLogExclusionReasonsMutation
>;
export type DismissSubmissionStateLogExclusionReasonsMutationResult =
  Apollo.MutationResult<DismissSubmissionStateLogExclusionReasonsMutation>;
export type DismissSubmissionStateLogExclusionReasonsMutationOptions = Apollo.BaseMutationOptions<
  DismissSubmissionStateLogExclusionReasonsMutation,
  DismissSubmissionStateLogExclusionReasonsMutationVariables
>;
export const EditInsuredHazardDocument = gql`
  mutation EditInsuredHazard($input: EditInsuredHazardInput!) {
    editHazard(input: $input) {
      id
    }
  }
`;
export type EditInsuredHazardMutationFn = Apollo.MutationFunction<
  EditInsuredHazardMutation,
  EditInsuredHazardMutationVariables
>;

/**
 * __useEditInsuredHazardMutation__
 *
 * To run a mutation, you first call `useEditInsuredHazardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInsuredHazardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInsuredHazardMutation, { data, loading, error }] = useEditInsuredHazardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInsuredHazardMutation(
  baseOptions?: Apollo.MutationHookOptions<EditInsuredHazardMutation, EditInsuredHazardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditInsuredHazardMutation, EditInsuredHazardMutationVariables>(
    EditInsuredHazardDocument,
    options
  );
}
export type EditInsuredHazardMutationHookResult = ReturnType<typeof useEditInsuredHazardMutation>;
export type EditInsuredHazardMutationResult = Apollo.MutationResult<EditInsuredHazardMutation>;
export type EditInsuredHazardMutationOptions = Apollo.BaseMutationOptions<
  EditInsuredHazardMutation,
  EditInsuredHazardMutationVariables
>;
export const EditInsuredPremiseBuildingDocument = gql`
  mutation EditInsuredPremiseBuilding($input: EditInsuredPremiseBuildingInput!) {
    editBuilding(input: $input) {
      id
    }
  }
`;
export type EditInsuredPremiseBuildingMutationFn = Apollo.MutationFunction<
  EditInsuredPremiseBuildingMutation,
  EditInsuredPremiseBuildingMutationVariables
>;

/**
 * __useEditInsuredPremiseBuildingMutation__
 *
 * To run a mutation, you first call `useEditInsuredPremiseBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInsuredPremiseBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInsuredPremiseBuildingMutation, { data, loading, error }] = useEditInsuredPremiseBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInsuredPremiseBuildingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditInsuredPremiseBuildingMutation,
    EditInsuredPremiseBuildingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditInsuredPremiseBuildingMutation, EditInsuredPremiseBuildingMutationVariables>(
    EditInsuredPremiseBuildingDocument,
    options
  );
}
export type EditInsuredPremiseBuildingMutationHookResult = ReturnType<typeof useEditInsuredPremiseBuildingMutation>;
export type EditInsuredPremiseBuildingMutationResult = Apollo.MutationResult<EditInsuredPremiseBuildingMutation>;
export type EditInsuredPremiseBuildingMutationOptions = Apollo.BaseMutationOptions<
  EditInsuredPremiseBuildingMutation,
  EditInsuredPremiseBuildingMutationVariables
>;
export const EditInsuredPremiseLocationDocument = gql`
  mutation EditInsuredPremiseLocation($input: EditInsuredPremiseLocationInput!) {
    editLocation(input: $input) {
      id
    }
  }
`;
export type EditInsuredPremiseLocationMutationFn = Apollo.MutationFunction<
  EditInsuredPremiseLocationMutation,
  EditInsuredPremiseLocationMutationVariables
>;

/**
 * __useEditInsuredPremiseLocationMutation__
 *
 * To run a mutation, you first call `useEditInsuredPremiseLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInsuredPremiseLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInsuredPremiseLocationMutation, { data, loading, error }] = useEditInsuredPremiseLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInsuredPremiseLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditInsuredPremiseLocationMutation,
    EditInsuredPremiseLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditInsuredPremiseLocationMutation, EditInsuredPremiseLocationMutationVariables>(
    EditInsuredPremiseLocationDocument,
    options
  );
}
export type EditInsuredPremiseLocationMutationHookResult = ReturnType<typeof useEditInsuredPremiseLocationMutation>;
export type EditInsuredPremiseLocationMutationResult = Apollo.MutationResult<EditInsuredPremiseLocationMutation>;
export type EditInsuredPremiseLocationMutationOptions = Apollo.BaseMutationOptions<
  EditInsuredPremiseLocationMutation,
  EditInsuredPremiseLocationMutationVariables
>;
export const GenerateAcordDocument = gql`
  mutation GenerateAcord($input: AcordGenerationInput!) {
    generateAcord(input: $input) {
      id
    }
  }
`;
export type GenerateAcordMutationFn = Apollo.MutationFunction<GenerateAcordMutation, GenerateAcordMutationVariables>;

/**
 * __useGenerateAcordMutation__
 *
 * To run a mutation, you first call `useGenerateAcordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAcordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAcordMutation, { data, loading, error }] = useGenerateAcordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAcordMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateAcordMutation, GenerateAcordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateAcordMutation, GenerateAcordMutationVariables>(GenerateAcordDocument, options);
}
export type GenerateAcordMutationHookResult = ReturnType<typeof useGenerateAcordMutation>;
export type GenerateAcordMutationResult = Apollo.MutationResult<GenerateAcordMutation>;
export type GenerateAcordMutationOptions = Apollo.BaseMutationOptions<
  GenerateAcordMutation,
  GenerateAcordMutationVariables
>;
export const IssuePolicyDocument = gql`
  mutation IssuePolicy($input: SendPolicyFileInput!) {
    issuePolicy(input: $input) {
      ...Policy
    }
  }
  ${PolicyFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type IssuePolicyMutationFn = Apollo.MutationFunction<IssuePolicyMutation, IssuePolicyMutationVariables>;

/**
 * __useIssuePolicyMutation__
 *
 * To run a mutation, you first call `useIssuePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssuePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issuePolicyMutation, { data, loading, error }] = useIssuePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssuePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<IssuePolicyMutation, IssuePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IssuePolicyMutation, IssuePolicyMutationVariables>(IssuePolicyDocument, options);
}
export type IssuePolicyMutationHookResult = ReturnType<typeof useIssuePolicyMutation>;
export type IssuePolicyMutationResult = Apollo.MutationResult<IssuePolicyMutation>;
export type IssuePolicyMutationOptions = Apollo.BaseMutationOptions<IssuePolicyMutation, IssuePolicyMutationVariables>;
export const MergeInsuredDocument = gql`
  mutation MergeInsured($input: MergeInsuredInput!) {
    mergeInsured(input: $input) {
      ...Insured
    }
  }
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AgencyFragmentDoc}
`;
export type MergeInsuredMutationFn = Apollo.MutationFunction<MergeInsuredMutation, MergeInsuredMutationVariables>;

/**
 * __useMergeInsuredMutation__
 *
 * To run a mutation, you first call `useMergeInsuredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeInsuredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeInsuredMutation, { data, loading, error }] = useMergeInsuredMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeInsuredMutation(
  baseOptions?: Apollo.MutationHookOptions<MergeInsuredMutation, MergeInsuredMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MergeInsuredMutation, MergeInsuredMutationVariables>(MergeInsuredDocument, options);
}
export type MergeInsuredMutationHookResult = ReturnType<typeof useMergeInsuredMutation>;
export type MergeInsuredMutationResult = Apollo.MutationResult<MergeInsuredMutation>;
export type MergeInsuredMutationOptions = Apollo.BaseMutationOptions<
  MergeInsuredMutation,
  MergeInsuredMutationVariables
>;
export const PublicCreateOpportunityDocument = gql`
  mutation PublicCreateOpportunity($input: PublicCreateOpportunityInput!) {
    publicCreateOpportunity(input: $input) {
      id
      createdById
    }
  }
`;
export type PublicCreateOpportunityMutationFn = Apollo.MutationFunction<
  PublicCreateOpportunityMutation,
  PublicCreateOpportunityMutationVariables
>;

/**
 * __usePublicCreateOpportunityMutation__
 *
 * To run a mutation, you first call `usePublicCreateOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublicCreateOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publicCreateOpportunityMutation, { data, loading, error }] = usePublicCreateOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicCreateOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<PublicCreateOpportunityMutation, PublicCreateOpportunityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PublicCreateOpportunityMutation, PublicCreateOpportunityMutationVariables>(
    PublicCreateOpportunityDocument,
    options
  );
}
export type PublicCreateOpportunityMutationHookResult = ReturnType<typeof usePublicCreateOpportunityMutation>;
export type PublicCreateOpportunityMutationResult = Apollo.MutationResult<PublicCreateOpportunityMutation>;
export type PublicCreateOpportunityMutationOptions = Apollo.BaseMutationOptions<
  PublicCreateOpportunityMutation,
  PublicCreateOpportunityMutationVariables
>;
export const RemoveCarrierContactFromAppetiteProductDocument = gql`
  mutation RemoveCarrierContactFromAppetiteProduct($input: CarrierContactConnectorInput!) {
    removeCarrierContactFromAppetiteProduct(input: $input) {
      ...CarrierContact
    }
  }
  ${CarrierContactFragmentDoc}
`;
export type RemoveCarrierContactFromAppetiteProductMutationFn = Apollo.MutationFunction<
  RemoveCarrierContactFromAppetiteProductMutation,
  RemoveCarrierContactFromAppetiteProductMutationVariables
>;

/**
 * __useRemoveCarrierContactFromAppetiteProductMutation__
 *
 * To run a mutation, you first call `useRemoveCarrierContactFromAppetiteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCarrierContactFromAppetiteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCarrierContactFromAppetiteProductMutation, { data, loading, error }] = useRemoveCarrierContactFromAppetiteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCarrierContactFromAppetiteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCarrierContactFromAppetiteProductMutation,
    RemoveCarrierContactFromAppetiteProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCarrierContactFromAppetiteProductMutation,
    RemoveCarrierContactFromAppetiteProductMutationVariables
  >(RemoveCarrierContactFromAppetiteProductDocument, options);
}
export type RemoveCarrierContactFromAppetiteProductMutationHookResult = ReturnType<
  typeof useRemoveCarrierContactFromAppetiteProductMutation
>;
export type RemoveCarrierContactFromAppetiteProductMutationResult =
  Apollo.MutationResult<RemoveCarrierContactFromAppetiteProductMutation>;
export type RemoveCarrierContactFromAppetiteProductMutationOptions = Apollo.BaseMutationOptions<
  RemoveCarrierContactFromAppetiteProductMutation,
  RemoveCarrierContactFromAppetiteProductMutationVariables
>;
export const RemoveFIleTagDocument = gql`
  mutation RemoveFIleTag($input: UpdateTagInput!) {
    removeFileTag(input: $input) {
      label
    }
  }
`;
export type RemoveFIleTagMutationFn = Apollo.MutationFunction<RemoveFIleTagMutation, RemoveFIleTagMutationVariables>;

/**
 * __useRemoveFIleTagMutation__
 *
 * To run a mutation, you first call `useRemoveFIleTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFIleTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFIleTagMutation, { data, loading, error }] = useRemoveFIleTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFIleTagMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFIleTagMutation, RemoveFIleTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFIleTagMutation, RemoveFIleTagMutationVariables>(RemoveFIleTagDocument, options);
}
export type RemoveFIleTagMutationHookResult = ReturnType<typeof useRemoveFIleTagMutation>;
export type RemoveFIleTagMutationResult = Apollo.MutationResult<RemoveFIleTagMutation>;
export type RemoveFIleTagMutationOptions = Apollo.BaseMutationOptions<
  RemoveFIleTagMutation,
  RemoveFIleTagMutationVariables
>;
export const ReplaceMarketingPlanSubmissionsDocument = gql`
  mutation ReplaceMarketingPlanSubmissions($input: ReplaceMarketingPlanSubmissionsInput!) {
    replaceMarketingPlanSubmissions(input: $input) {
      id
    }
  }
`;
export type ReplaceMarketingPlanSubmissionsMutationFn = Apollo.MutationFunction<
  ReplaceMarketingPlanSubmissionsMutation,
  ReplaceMarketingPlanSubmissionsMutationVariables
>;

/**
 * __useReplaceMarketingPlanSubmissionsMutation__
 *
 * To run a mutation, you first call `useReplaceMarketingPlanSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceMarketingPlanSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceMarketingPlanSubmissionsMutation, { data, loading, error }] = useReplaceMarketingPlanSubmissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplaceMarketingPlanSubmissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplaceMarketingPlanSubmissionsMutation,
    ReplaceMarketingPlanSubmissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReplaceMarketingPlanSubmissionsMutation, ReplaceMarketingPlanSubmissionsMutationVariables>(
    ReplaceMarketingPlanSubmissionsDocument,
    options
  );
}
export type ReplaceMarketingPlanSubmissionsMutationHookResult = ReturnType<
  typeof useReplaceMarketingPlanSubmissionsMutation
>;
export type ReplaceMarketingPlanSubmissionsMutationResult =
  Apollo.MutationResult<ReplaceMarketingPlanSubmissionsMutation>;
export type ReplaceMarketingPlanSubmissionsMutationOptions = Apollo.BaseMutationOptions<
  ReplaceMarketingPlanSubmissionsMutation,
  ReplaceMarketingPlanSubmissionsMutationVariables
>;
export const RerunProcessorDocument = gql`
  mutation RerunProcessor($input: RerunProcessorInput!) {
    rerunProcessor(input: $input) {
      success
    }
  }
`;
export type RerunProcessorMutationFn = Apollo.MutationFunction<RerunProcessorMutation, RerunProcessorMutationVariables>;

/**
 * __useRerunProcessorMutation__
 *
 * To run a mutation, you first call `useRerunProcessorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRerunProcessorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rerunProcessorMutation, { data, loading, error }] = useRerunProcessorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRerunProcessorMutation(
  baseOptions?: Apollo.MutationHookOptions<RerunProcessorMutation, RerunProcessorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RerunProcessorMutation, RerunProcessorMutationVariables>(RerunProcessorDocument, options);
}
export type RerunProcessorMutationHookResult = ReturnType<typeof useRerunProcessorMutation>;
export type RerunProcessorMutationResult = Apollo.MutationResult<RerunProcessorMutation>;
export type RerunProcessorMutationOptions = Apollo.BaseMutationOptions<
  RerunProcessorMutation,
  RerunProcessorMutationVariables
>;
export const SelectOpportunityCoveragesDocument = gql`
  mutation SelectOpportunityCoverages($input: OpportunitySelectCoveragesInput!) {
    selectOpportunityCoverages(input: $input) {
      id
      insuredId
    }
  }
`;
export type SelectOpportunityCoveragesMutationFn = Apollo.MutationFunction<
  SelectOpportunityCoveragesMutation,
  SelectOpportunityCoveragesMutationVariables
>;

/**
 * __useSelectOpportunityCoveragesMutation__
 *
 * To run a mutation, you first call `useSelectOpportunityCoveragesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectOpportunityCoveragesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectOpportunityCoveragesMutation, { data, loading, error }] = useSelectOpportunityCoveragesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectOpportunityCoveragesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectOpportunityCoveragesMutation,
    SelectOpportunityCoveragesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SelectOpportunityCoveragesMutation, SelectOpportunityCoveragesMutationVariables>(
    SelectOpportunityCoveragesDocument,
    options
  );
}
export type SelectOpportunityCoveragesMutationHookResult = ReturnType<typeof useSelectOpportunityCoveragesMutation>;
export type SelectOpportunityCoveragesMutationResult = Apollo.MutationResult<SelectOpportunityCoveragesMutation>;
export type SelectOpportunityCoveragesMutationOptions = Apollo.BaseMutationOptions<
  SelectOpportunityCoveragesMutation,
  SelectOpportunityCoveragesMutationVariables
>;
export const SendBindRequestDocument = gql`
  mutation SendBindRequest($input: SendBindRequestInput!) {
    sendBindRequest(input: $input) {
      ...Quote
    }
  }
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
`;
export type SendBindRequestMutationFn = Apollo.MutationFunction<
  SendBindRequestMutation,
  SendBindRequestMutationVariables
>;

/**
 * __useSendBindRequestMutation__
 *
 * To run a mutation, you first call `useSendBindRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBindRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBindRequestMutation, { data, loading, error }] = useSendBindRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendBindRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<SendBindRequestMutation, SendBindRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendBindRequestMutation, SendBindRequestMutationVariables>(
    SendBindRequestDocument,
    options
  );
}
export type SendBindRequestMutationHookResult = ReturnType<typeof useSendBindRequestMutation>;
export type SendBindRequestMutationResult = Apollo.MutationResult<SendBindRequestMutation>;
export type SendBindRequestMutationOptions = Apollo.BaseMutationOptions<
  SendBindRequestMutation,
  SendBindRequestMutationVariables
>;
export const SendQuoteEmailDocument = gql`
  mutation SendQuoteEmail($input: SendQuoteInput!) {
    sendQuote(input: $input) {
      ...Quote
    }
  }
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
`;
export type SendQuoteEmailMutationFn = Apollo.MutationFunction<SendQuoteEmailMutation, SendQuoteEmailMutationVariables>;

/**
 * __useSendQuoteEmailMutation__
 *
 * To run a mutation, you first call `useSendQuoteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendQuoteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendQuoteEmailMutation, { data, loading, error }] = useSendQuoteEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendQuoteEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendQuoteEmailMutation, SendQuoteEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendQuoteEmailMutation, SendQuoteEmailMutationVariables>(SendQuoteEmailDocument, options);
}
export type SendQuoteEmailMutationHookResult = ReturnType<typeof useSendQuoteEmailMutation>;
export type SendQuoteEmailMutationResult = Apollo.MutationResult<SendQuoteEmailMutation>;
export type SendQuoteEmailMutationOptions = Apollo.BaseMutationOptions<
  SendQuoteEmailMutation,
  SendQuoteEmailMutationVariables
>;
export const SendSubmissionToCarrierDocument = gql`
  mutation SendSubmissionToCarrier($input: SendSubmissionToCarrierInput!) {
    sendSubmissionToCarrier(input: $input) {
      ...Submission
    }
  }
  ${SubmissionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
  ${SubmissionStateTransitionFragmentDoc}
`;
export type SendSubmissionToCarrierMutationFn = Apollo.MutationFunction<
  SendSubmissionToCarrierMutation,
  SendSubmissionToCarrierMutationVariables
>;

/**
 * __useSendSubmissionToCarrierMutation__
 *
 * To run a mutation, you first call `useSendSubmissionToCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSubmissionToCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSubmissionToCarrierMutation, { data, loading, error }] = useSendSubmissionToCarrierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSubmissionToCarrierMutation(
  baseOptions?: Apollo.MutationHookOptions<SendSubmissionToCarrierMutation, SendSubmissionToCarrierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendSubmissionToCarrierMutation, SendSubmissionToCarrierMutationVariables>(
    SendSubmissionToCarrierDocument,
    options
  );
}
export type SendSubmissionToCarrierMutationHookResult = ReturnType<typeof useSendSubmissionToCarrierMutation>;
export type SendSubmissionToCarrierMutationResult = Apollo.MutationResult<SendSubmissionToCarrierMutation>;
export type SendSubmissionToCarrierMutationOptions = Apollo.BaseMutationOptions<
  SendSubmissionToCarrierMutation,
  SendSubmissionToCarrierMutationVariables
>;
export const SetDefaultFileProcessorVersionDocument = gql`
  mutation SetDefaultFileProcessorVersion($input: UpdateFileProcessorVersionInput!) {
    setDefaultProcessorVersion(input: $input) {
      success
    }
  }
`;
export type SetDefaultFileProcessorVersionMutationFn = Apollo.MutationFunction<
  SetDefaultFileProcessorVersionMutation,
  SetDefaultFileProcessorVersionMutationVariables
>;

/**
 * __useSetDefaultFileProcessorVersionMutation__
 *
 * To run a mutation, you first call `useSetDefaultFileProcessorVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultFileProcessorVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultFileProcessorVersionMutation, { data, loading, error }] = useSetDefaultFileProcessorVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultFileProcessorVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDefaultFileProcessorVersionMutation,
    SetDefaultFileProcessorVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetDefaultFileProcessorVersionMutation, SetDefaultFileProcessorVersionMutationVariables>(
    SetDefaultFileProcessorVersionDocument,
    options
  );
}
export type SetDefaultFileProcessorVersionMutationHookResult = ReturnType<
  typeof useSetDefaultFileProcessorVersionMutation
>;
export type SetDefaultFileProcessorVersionMutationResult =
  Apollo.MutationResult<SetDefaultFileProcessorVersionMutation>;
export type SetDefaultFileProcessorVersionMutationOptions = Apollo.BaseMutationOptions<
  SetDefaultFileProcessorVersionMutation,
  SetDefaultFileProcessorVersionMutationVariables
>;
export const SnoozeOpportunityDocument = gql`
  mutation SnoozeOpportunity($input: SnoozeOpportunityInput!) {
    snoozeOpportunity(input: $input) {
      id
      state
      snoozeUntil
    }
  }
`;
export type SnoozeOpportunityMutationFn = Apollo.MutationFunction<
  SnoozeOpportunityMutation,
  SnoozeOpportunityMutationVariables
>;

/**
 * __useSnoozeOpportunityMutation__
 *
 * To run a mutation, you first call `useSnoozeOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSnoozeOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [snoozeOpportunityMutation, { data, loading, error }] = useSnoozeOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSnoozeOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<SnoozeOpportunityMutation, SnoozeOpportunityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SnoozeOpportunityMutation, SnoozeOpportunityMutationVariables>(
    SnoozeOpportunityDocument,
    options
  );
}
export type SnoozeOpportunityMutationHookResult = ReturnType<typeof useSnoozeOpportunityMutation>;
export type SnoozeOpportunityMutationResult = Apollo.MutationResult<SnoozeOpportunityMutation>;
export type SnoozeOpportunityMutationOptions = Apollo.BaseMutationOptions<
  SnoozeOpportunityMutation,
  SnoozeOpportunityMutationVariables
>;
export const TestAlbyDocument = gql`
  mutation TestAlby($input: TestAlbyInput!) {
    testAlby(input: $input)
  }
`;
export type TestAlbyMutationFn = Apollo.MutationFunction<TestAlbyMutation, TestAlbyMutationVariables>;

/**
 * __useTestAlbyMutation__
 *
 * To run a mutation, you first call `useTestAlbyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestAlbyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testAlbyMutation, { data, loading, error }] = useTestAlbyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestAlbyMutation(
  baseOptions?: Apollo.MutationHookOptions<TestAlbyMutation, TestAlbyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TestAlbyMutation, TestAlbyMutationVariables>(TestAlbyDocument, options);
}
export type TestAlbyMutationHookResult = ReturnType<typeof useTestAlbyMutation>;
export type TestAlbyMutationResult = Apollo.MutationResult<TestAlbyMutation>;
export type TestAlbyMutationOptions = Apollo.BaseMutationOptions<TestAlbyMutation, TestAlbyMutationVariables>;
export const ToggleExtractedLabelToPrimaryLabelConnectionDocument = gql`
  mutation ToggleExtractedLabelToPrimaryLabelConnection($input: ConnectOrDisconnectExtractedLabelInput!) {
    toggleExtractedLabelToPrimaryLabelConnection(input: $input) {
      ...PrimaryLabel
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;
export type ToggleExtractedLabelToPrimaryLabelConnectionMutationFn = Apollo.MutationFunction<
  ToggleExtractedLabelToPrimaryLabelConnectionMutation,
  ToggleExtractedLabelToPrimaryLabelConnectionMutationVariables
>;

/**
 * __useToggleExtractedLabelToPrimaryLabelConnectionMutation__
 *
 * To run a mutation, you first call `useToggleExtractedLabelToPrimaryLabelConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleExtractedLabelToPrimaryLabelConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleExtractedLabelToPrimaryLabelConnectionMutation, { data, loading, error }] = useToggleExtractedLabelToPrimaryLabelConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleExtractedLabelToPrimaryLabelConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleExtractedLabelToPrimaryLabelConnectionMutation,
    ToggleExtractedLabelToPrimaryLabelConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleExtractedLabelToPrimaryLabelConnectionMutation,
    ToggleExtractedLabelToPrimaryLabelConnectionMutationVariables
  >(ToggleExtractedLabelToPrimaryLabelConnectionDocument, options);
}
export type ToggleExtractedLabelToPrimaryLabelConnectionMutationHookResult = ReturnType<
  typeof useToggleExtractedLabelToPrimaryLabelConnectionMutation
>;
export type ToggleExtractedLabelToPrimaryLabelConnectionMutationResult =
  Apollo.MutationResult<ToggleExtractedLabelToPrimaryLabelConnectionMutation>;
export type ToggleExtractedLabelToPrimaryLabelConnectionMutationOptions = Apollo.BaseMutationOptions<
  ToggleExtractedLabelToPrimaryLabelConnectionMutation,
  ToggleExtractedLabelToPrimaryLabelConnectionMutationVariables
>;
export const TransitionMarketingPlanDocument = gql`
  mutation TransitionMarketingPlan($input: TransitionMarketingPlanInput!) {
    transitionMarketingPlan(input: $input) {
      id
    }
  }
`;
export type TransitionMarketingPlanMutationFn = Apollo.MutationFunction<
  TransitionMarketingPlanMutation,
  TransitionMarketingPlanMutationVariables
>;

/**
 * __useTransitionMarketingPlanMutation__
 *
 * To run a mutation, you first call `useTransitionMarketingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionMarketingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionMarketingPlanMutation, { data, loading, error }] = useTransitionMarketingPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransitionMarketingPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<TransitionMarketingPlanMutation, TransitionMarketingPlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TransitionMarketingPlanMutation, TransitionMarketingPlanMutationVariables>(
    TransitionMarketingPlanDocument,
    options
  );
}
export type TransitionMarketingPlanMutationHookResult = ReturnType<typeof useTransitionMarketingPlanMutation>;
export type TransitionMarketingPlanMutationResult = Apollo.MutationResult<TransitionMarketingPlanMutation>;
export type TransitionMarketingPlanMutationOptions = Apollo.BaseMutationOptions<
  TransitionMarketingPlanMutation,
  TransitionMarketingPlanMutationVariables
>;
export const TransitionPolicyDocument = gql`
  mutation TransitionPolicy($input: TransitionPolicyInput!) {
    transitionPolicy(input: $input) {
      ...Policy
    }
  }
  ${PolicyFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type TransitionPolicyMutationFn = Apollo.MutationFunction<
  TransitionPolicyMutation,
  TransitionPolicyMutationVariables
>;

/**
 * __useTransitionPolicyMutation__
 *
 * To run a mutation, you first call `useTransitionPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionPolicyMutation, { data, loading, error }] = useTransitionPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransitionPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<TransitionPolicyMutation, TransitionPolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TransitionPolicyMutation, TransitionPolicyMutationVariables>(
    TransitionPolicyDocument,
    options
  );
}
export type TransitionPolicyMutationHookResult = ReturnType<typeof useTransitionPolicyMutation>;
export type TransitionPolicyMutationResult = Apollo.MutationResult<TransitionPolicyMutation>;
export type TransitionPolicyMutationOptions = Apollo.BaseMutationOptions<
  TransitionPolicyMutation,
  TransitionPolicyMutationVariables
>;
export const TransitionQuoteDocument = gql`
  mutation TransitionQuote($input: TransitionQuoteInput!) {
    transitionQuote(input: $input) {
      ...Quote
    }
  }
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
`;
export type TransitionQuoteMutationFn = Apollo.MutationFunction<
  TransitionQuoteMutation,
  TransitionQuoteMutationVariables
>;

/**
 * __useTransitionQuoteMutation__
 *
 * To run a mutation, you first call `useTransitionQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionQuoteMutation, { data, loading, error }] = useTransitionQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransitionQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<TransitionQuoteMutation, TransitionQuoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TransitionQuoteMutation, TransitionQuoteMutationVariables>(
    TransitionQuoteDocument,
    options
  );
}
export type TransitionQuoteMutationHookResult = ReturnType<typeof useTransitionQuoteMutation>;
export type TransitionQuoteMutationResult = Apollo.MutationResult<TransitionQuoteMutation>;
export type TransitionQuoteMutationOptions = Apollo.BaseMutationOptions<
  TransitionQuoteMutation,
  TransitionQuoteMutationVariables
>;
export const TransitionSubmissionDocument = gql`
  mutation TransitionSubmission($input: TransitionSubmissionInput!) {
    transitionSubmission(input: $input) {
      id
    }
  }
`;
export type TransitionSubmissionMutationFn = Apollo.MutationFunction<
  TransitionSubmissionMutation,
  TransitionSubmissionMutationVariables
>;

/**
 * __useTransitionSubmissionMutation__
 *
 * To run a mutation, you first call `useTransitionSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionSubmissionMutation, { data, loading, error }] = useTransitionSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransitionSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<TransitionSubmissionMutation, TransitionSubmissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TransitionSubmissionMutation, TransitionSubmissionMutationVariables>(
    TransitionSubmissionDocument,
    options
  );
}
export type TransitionSubmissionMutationHookResult = ReturnType<typeof useTransitionSubmissionMutation>;
export type TransitionSubmissionMutationResult = Apollo.MutationResult<TransitionSubmissionMutation>;
export type TransitionSubmissionMutationOptions = Apollo.BaseMutationOptions<
  TransitionSubmissionMutation,
  TransitionSubmissionMutationVariables
>;
export const UncompleteAgentActionDocument = gql`
  mutation uncompleteAgentAction($input: CompleteAgentActionInput!) {
    uncompleteAgentAction(input: $input) {
      id
    }
  }
`;
export type UncompleteAgentActionMutationFn = Apollo.MutationFunction<
  UncompleteAgentActionMutation,
  UncompleteAgentActionMutationVariables
>;

/**
 * __useUncompleteAgentActionMutation__
 *
 * To run a mutation, you first call `useUncompleteAgentActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUncompleteAgentActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uncompleteAgentActionMutation, { data, loading, error }] = useUncompleteAgentActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUncompleteAgentActionMutation(
  baseOptions?: Apollo.MutationHookOptions<UncompleteAgentActionMutation, UncompleteAgentActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UncompleteAgentActionMutation, UncompleteAgentActionMutationVariables>(
    UncompleteAgentActionDocument,
    options
  );
}
export type UncompleteAgentActionMutationHookResult = ReturnType<typeof useUncompleteAgentActionMutation>;
export type UncompleteAgentActionMutationResult = Apollo.MutationResult<UncompleteAgentActionMutation>;
export type UncompleteAgentActionMutationOptions = Apollo.BaseMutationOptions<
  UncompleteAgentActionMutation,
  UncompleteAgentActionMutationVariables
>;
export const UndeleteOpportunityDocument = gql`
  mutation UndeleteOpportunity($id: ID!) {
    undeleteOpportunity(id: $id) {
      id
    }
  }
`;
export type UndeleteOpportunityMutationFn = Apollo.MutationFunction<
  UndeleteOpportunityMutation,
  UndeleteOpportunityMutationVariables
>;

/**
 * __useUndeleteOpportunityMutation__
 *
 * To run a mutation, you first call `useUndeleteOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeleteOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeleteOpportunityMutation, { data, loading, error }] = useUndeleteOpportunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndeleteOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<UndeleteOpportunityMutation, UndeleteOpportunityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UndeleteOpportunityMutation, UndeleteOpportunityMutationVariables>(
    UndeleteOpportunityDocument,
    options
  );
}
export type UndeleteOpportunityMutationHookResult = ReturnType<typeof useUndeleteOpportunityMutation>;
export type UndeleteOpportunityMutationResult = Apollo.MutationResult<UndeleteOpportunityMutation>;
export type UndeleteOpportunityMutationOptions = Apollo.BaseMutationOptions<
  UndeleteOpportunityMutation,
  UndeleteOpportunityMutationVariables
>;
export const UndeployFileProcessorVersionDocument = gql`
  mutation UndeployFileProcessorVersion($input: UpdateFileProcessorVersionInput!) {
    undeployProcessorVersion(input: $input) {
      success
    }
  }
`;
export type UndeployFileProcessorVersionMutationFn = Apollo.MutationFunction<
  UndeployFileProcessorVersionMutation,
  UndeployFileProcessorVersionMutationVariables
>;

/**
 * __useUndeployFileProcessorVersionMutation__
 *
 * To run a mutation, you first call `useUndeployFileProcessorVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeployFileProcessorVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeployFileProcessorVersionMutation, { data, loading, error }] = useUndeployFileProcessorVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUndeployFileProcessorVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UndeployFileProcessorVersionMutation,
    UndeployFileProcessorVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UndeployFileProcessorVersionMutation, UndeployFileProcessorVersionMutationVariables>(
    UndeployFileProcessorVersionDocument,
    options
  );
}
export type UndeployFileProcessorVersionMutationHookResult = ReturnType<typeof useUndeployFileProcessorVersionMutation>;
export type UndeployFileProcessorVersionMutationResult = Apollo.MutationResult<UndeployFileProcessorVersionMutation>;
export type UndeployFileProcessorVersionMutationOptions = Apollo.BaseMutationOptions<
  UndeployFileProcessorVersionMutation,
  UndeployFileProcessorVersionMutationVariables
>;
export const UndismissSubmissionStateLogExclusionReasonsDocument = gql`
  mutation UndismissSubmissionStateLogExclusionReasons($input: DismissSubmissionStateLogExclusionReasonsInput!) {
    undismissSubmissionStateLogExclusionReasons(input: $input) {
      ...SubmissionStateTransition
    }
  }
  ${SubmissionStateTransitionFragmentDoc}
`;
export type UndismissSubmissionStateLogExclusionReasonsMutationFn = Apollo.MutationFunction<
  UndismissSubmissionStateLogExclusionReasonsMutation,
  UndismissSubmissionStateLogExclusionReasonsMutationVariables
>;

/**
 * __useUndismissSubmissionStateLogExclusionReasonsMutation__
 *
 * To run a mutation, you first call `useUndismissSubmissionStateLogExclusionReasonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndismissSubmissionStateLogExclusionReasonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undismissSubmissionStateLogExclusionReasonsMutation, { data, loading, error }] = useUndismissSubmissionStateLogExclusionReasonsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUndismissSubmissionStateLogExclusionReasonsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UndismissSubmissionStateLogExclusionReasonsMutation,
    UndismissSubmissionStateLogExclusionReasonsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UndismissSubmissionStateLogExclusionReasonsMutation,
    UndismissSubmissionStateLogExclusionReasonsMutationVariables
  >(UndismissSubmissionStateLogExclusionReasonsDocument, options);
}
export type UndismissSubmissionStateLogExclusionReasonsMutationHookResult = ReturnType<
  typeof useUndismissSubmissionStateLogExclusionReasonsMutation
>;
export type UndismissSubmissionStateLogExclusionReasonsMutationResult =
  Apollo.MutationResult<UndismissSubmissionStateLogExclusionReasonsMutation>;
export type UndismissSubmissionStateLogExclusionReasonsMutationOptions = Apollo.BaseMutationOptions<
  UndismissSubmissionStateLogExclusionReasonsMutation,
  UndismissSubmissionStateLogExclusionReasonsMutationVariables
>;
export const UpdateActionDocument = gql`
  mutation UpdateAction($input: UpdateActionInput!) {
    updateAction(input: $input) {
      id
      autoExecuteAt
      executedOperation
      executedBy {
        firstName
        lastName
      }
    }
  }
`;
export type UpdateActionMutationFn = Apollo.MutationFunction<UpdateActionMutation, UpdateActionMutationVariables>;

/**
 * __useUpdateActionMutation__
 *
 * To run a mutation, you first call `useUpdateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionMutation, { data, loading, error }] = useUpdateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateActionMutation, UpdateActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateActionMutation, UpdateActionMutationVariables>(UpdateActionDocument, options);
}
export type UpdateActionMutationHookResult = ReturnType<typeof useUpdateActionMutation>;
export type UpdateActionMutationResult = Apollo.MutationResult<UpdateActionMutation>;
export type UpdateActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateActionMutation,
  UpdateActionMutationVariables
>;
export const UpdateAgencyDocument = gql`
  mutation UpdateAgency($input: UpdateAgencyInput!) {
    updateAgency(input: $input) {
      id
      name
      bindHqId
      isTest
    }
  }
`;
export type UpdateAgencyMutationFn = Apollo.MutationFunction<UpdateAgencyMutation, UpdateAgencyMutationVariables>;

/**
 * __useUpdateAgencyMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyMutation, { data, loading, error }] = useUpdateAgencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAgencyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAgencyMutation, UpdateAgencyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAgencyMutation, UpdateAgencyMutationVariables>(UpdateAgencyDocument, options);
}
export type UpdateAgencyMutationHookResult = ReturnType<typeof useUpdateAgencyMutation>;
export type UpdateAgencyMutationResult = Apollo.MutationResult<UpdateAgencyMutation>;
export type UpdateAgencyMutationOptions = Apollo.BaseMutationOptions<
  UpdateAgencyMutation,
  UpdateAgencyMutationVariables
>;
export const UpdateAgencyOwnerDocument = gql`
  mutation UpdateAgencyOwner($input: UpdateAgencyInput!) {
    updateAgency(input: $input) {
      id
      name
      isTest
      isPrivate
      owner {
        ...UserAccount
      }
    }
  }
  ${UserAccountFragmentDoc}
`;
export type UpdateAgencyOwnerMutationFn = Apollo.MutationFunction<
  UpdateAgencyOwnerMutation,
  UpdateAgencyOwnerMutationVariables
>;

/**
 * __useUpdateAgencyOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyOwnerMutation, { data, loading, error }] = useUpdateAgencyOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAgencyOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAgencyOwnerMutation, UpdateAgencyOwnerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAgencyOwnerMutation, UpdateAgencyOwnerMutationVariables>(
    UpdateAgencyOwnerDocument,
    options
  );
}
export type UpdateAgencyOwnerMutationHookResult = ReturnType<typeof useUpdateAgencyOwnerMutation>;
export type UpdateAgencyOwnerMutationResult = Apollo.MutationResult<UpdateAgencyOwnerMutation>;
export type UpdateAgencyOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateAgencyOwnerMutation,
  UpdateAgencyOwnerMutationVariables
>;
export const UpdateAppetiteNoteDocument = gql`
  mutation UpdateAppetiteNote($input: UpdateAppetiteNoteInput!) {
    updateAppetiteNote(input: $input) {
      ...AppetiteNote
    }
  }
  ${AppetiteNoteFragmentDoc}
`;
export type UpdateAppetiteNoteMutationFn = Apollo.MutationFunction<
  UpdateAppetiteNoteMutation,
  UpdateAppetiteNoteMutationVariables
>;

/**
 * __useUpdateAppetiteNoteMutation__
 *
 * To run a mutation, you first call `useUpdateAppetiteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppetiteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppetiteNoteMutation, { data, loading, error }] = useUpdateAppetiteNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppetiteNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAppetiteNoteMutation, UpdateAppetiteNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppetiteNoteMutation, UpdateAppetiteNoteMutationVariables>(
    UpdateAppetiteNoteDocument,
    options
  );
}
export type UpdateAppetiteNoteMutationHookResult = ReturnType<typeof useUpdateAppetiteNoteMutation>;
export type UpdateAppetiteNoteMutationResult = Apollo.MutationResult<UpdateAppetiteNoteMutation>;
export type UpdateAppetiteNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppetiteNoteMutation,
  UpdateAppetiteNoteMutationVariables
>;
export const UpdateAppetiteProductDocument = gql`
  mutation UpdateAppetiteProduct($input: UpdateAppetiteProductInput!) {
    updateAppetiteProduct(input: $input) {
      ...AppetiteProduct
    }
  }
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type UpdateAppetiteProductMutationFn = Apollo.MutationFunction<
  UpdateAppetiteProductMutation,
  UpdateAppetiteProductMutationVariables
>;

/**
 * __useUpdateAppetiteProductMutation__
 *
 * To run a mutation, you first call `useUpdateAppetiteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppetiteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppetiteProductMutation, { data, loading, error }] = useUpdateAppetiteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppetiteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAppetiteProductMutation, UpdateAppetiteProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppetiteProductMutation, UpdateAppetiteProductMutationVariables>(
    UpdateAppetiteProductDocument,
    options
  );
}
export type UpdateAppetiteProductMutationHookResult = ReturnType<typeof useUpdateAppetiteProductMutation>;
export type UpdateAppetiteProductMutationResult = Apollo.MutationResult<UpdateAppetiteProductMutation>;
export type UpdateAppetiteProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppetiteProductMutation,
  UpdateAppetiteProductMutationVariables
>;
export const UpdateBindHqAgencyDocument = gql`
  mutation UpdateBindHqAgency($input: UpdateBindHqAgencyInput!) {
    updateBindHqAgency(input: $input) {
      ...BindHqAgency
    }
  }
  ${BindHqAgencyFragmentDoc}
`;
export type UpdateBindHqAgencyMutationFn = Apollo.MutationFunction<
  UpdateBindHqAgencyMutation,
  UpdateBindHqAgencyMutationVariables
>;

/**
 * __useUpdateBindHqAgencyMutation__
 *
 * To run a mutation, you first call `useUpdateBindHqAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBindHqAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBindHqAgencyMutation, { data, loading, error }] = useUpdateBindHqAgencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBindHqAgencyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBindHqAgencyMutation, UpdateBindHqAgencyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBindHqAgencyMutation, UpdateBindHqAgencyMutationVariables>(
    UpdateBindHqAgencyDocument,
    options
  );
}
export type UpdateBindHqAgencyMutationHookResult = ReturnType<typeof useUpdateBindHqAgencyMutation>;
export type UpdateBindHqAgencyMutationResult = Apollo.MutationResult<UpdateBindHqAgencyMutation>;
export type UpdateBindHqAgencyMutationOptions = Apollo.BaseMutationOptions<
  UpdateBindHqAgencyMutation,
  UpdateBindHqAgencyMutationVariables
>;
export const UpdateCarrierContactDocument = gql`
  mutation UpdateCarrierContact($input: UpdateCarrierContactInput!) {
    updateCarrierContact(input: $input) {
      ...CarrierContact
    }
  }
  ${CarrierContactFragmentDoc}
`;
export type UpdateCarrierContactMutationFn = Apollo.MutationFunction<
  UpdateCarrierContactMutation,
  UpdateCarrierContactMutationVariables
>;

/**
 * __useUpdateCarrierContactMutation__
 *
 * To run a mutation, you first call `useUpdateCarrierContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarrierContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarrierContactMutation, { data, loading, error }] = useUpdateCarrierContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCarrierContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCarrierContactMutation, UpdateCarrierContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCarrierContactMutation, UpdateCarrierContactMutationVariables>(
    UpdateCarrierContactDocument,
    options
  );
}
export type UpdateCarrierContactMutationHookResult = ReturnType<typeof useUpdateCarrierContactMutation>;
export type UpdateCarrierContactMutationResult = Apollo.MutationResult<UpdateCarrierContactMutation>;
export type UpdateCarrierContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateCarrierContactMutation,
  UpdateCarrierContactMutationVariables
>;
export const UpdateCommentDocument = gql`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      ...OpportunityEvent
    }
  }
  ${OpportunityEventFragmentDoc}
  ${UserCommentFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AssignmentChangeEventFragmentDoc}
  ${TriageAssignmentEventFragmentDoc}
  ${EmailEventFragmentDoc}
  ${GmailMessageEventFragmentDoc}
  ${GmailMessageFragmentDoc}
  ${FileUploadFragmentDoc}
  ${GmailRelayEventFragmentDoc}
  ${FileUploadEventFragmentDoc}
  ${StatusChangeEventFragmentDoc}
  ${SubmissionStatusChangeEventFragmentDoc}
  ${AlbyUpdateEventFragmentDoc}
  ${CreatedEventFragmentDoc}
  ${OutcomeChangeEventFragmentDoc}
  ${DesiredEffectiveDateChangeEventFragmentDoc}
  ${ApplicationSubmittedEventFragmentDoc}
  ${ProductsAddedEventFragmentDoc}
  ${ProductsRemovedEventFragmentDoc}
  ${BotCommentEventFragmentDoc}
  ${AutomatedFollowUpEventFragmentDoc}
  ${ActionRequestedEventFragmentDoc}
  ${ActionApprovedEventFragmentDoc}
  ${ActionRejectedEventFragmentDoc}
  ${ActionDismissedEventFragmentDoc}
  ${CoveragesChangedEventFragmentDoc}
  ${BusinessClassCodesChangedEventFragmentDoc}
  ${RequirementAddedEventFragmentDoc}
`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
}
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>;
export const UpdateFileUploadTagsDocument = gql`
  mutation UpdateFileUploadTags($input: UpdateFileUploadTagInput!) {
    updateFileUploadTags(input: $input) {
      label
    }
  }
`;
export type UpdateFileUploadTagsMutationFn = Apollo.MutationFunction<
  UpdateFileUploadTagsMutation,
  UpdateFileUploadTagsMutationVariables
>;

/**
 * __useUpdateFileUploadTagsMutation__
 *
 * To run a mutation, you first call `useUpdateFileUploadTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileUploadTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileUploadTagsMutation, { data, loading, error }] = useUpdateFileUploadTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileUploadTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFileUploadTagsMutation, UpdateFileUploadTagsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFileUploadTagsMutation, UpdateFileUploadTagsMutationVariables>(
    UpdateFileUploadTagsDocument,
    options
  );
}
export type UpdateFileUploadTagsMutationHookResult = ReturnType<typeof useUpdateFileUploadTagsMutation>;
export type UpdateFileUploadTagsMutationResult = Apollo.MutationResult<UpdateFileUploadTagsMutation>;
export type UpdateFileUploadTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileUploadTagsMutation,
  UpdateFileUploadTagsMutationVariables
>;
export const UpdateFileUploadDocument = gql`
  mutation UpdateFileUpload($input: UpdateFileUploadInput!) {
    updateFileUpload(input: $input) {
      id
    }
  }
`;
export type UpdateFileUploadMutationFn = Apollo.MutationFunction<
  UpdateFileUploadMutation,
  UpdateFileUploadMutationVariables
>;

/**
 * __useUpdateFileUploadMutation__
 *
 * To run a mutation, you first call `useUpdateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileUploadMutation, { data, loading, error }] = useUpdateFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>(
    UpdateFileUploadDocument,
    options
  );
}
export type UpdateFileUploadMutationHookResult = ReturnType<typeof useUpdateFileUploadMutation>;
export type UpdateFileUploadMutationResult = Apollo.MutationResult<UpdateFileUploadMutation>;
export type UpdateFileUploadMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileUploadMutation,
  UpdateFileUploadMutationVariables
>;
export const UpdateInsuredDocument = gql`
  mutation UpdateInsured($input: UpdateInsuredInput!) {
    updateInsured(input: $input) {
      ...Insured
    }
  }
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AgencyFragmentDoc}
`;
export type UpdateInsuredMutationFn = Apollo.MutationFunction<UpdateInsuredMutation, UpdateInsuredMutationVariables>;

/**
 * __useUpdateInsuredMutation__
 *
 * To run a mutation, you first call `useUpdateInsuredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsuredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuredMutation, { data, loading, error }] = useUpdateInsuredMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInsuredMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInsuredMutation, UpdateInsuredMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInsuredMutation, UpdateInsuredMutationVariables>(UpdateInsuredDocument, options);
}
export type UpdateInsuredMutationHookResult = ReturnType<typeof useUpdateInsuredMutation>;
export type UpdateInsuredMutationResult = Apollo.MutationResult<UpdateInsuredMutation>;
export type UpdateInsuredMutationOptions = Apollo.BaseMutationOptions<
  UpdateInsuredMutation,
  UpdateInsuredMutationVariables
>;
export const UpdateLeadDocument = gql`
  mutation UpdateLead($input: LeadUpdateInput!) {
    updateLead(input: $input) {
      ...Lead
    }
  }
  ${LeadFragmentDoc}
`;
export type UpdateLeadMutationFn = Apollo.MutationFunction<UpdateLeadMutation, UpdateLeadMutationVariables>;

/**
 * __useUpdateLeadMutation__
 *
 * To run a mutation, you first call `useUpdateLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeadMutation, { data, loading, error }] = useUpdateLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLeadMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLeadMutation, UpdateLeadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLeadMutation, UpdateLeadMutationVariables>(UpdateLeadDocument, options);
}
export type UpdateLeadMutationHookResult = ReturnType<typeof useUpdateLeadMutation>;
export type UpdateLeadMutationResult = Apollo.MutationResult<UpdateLeadMutation>;
export type UpdateLeadMutationOptions = Apollo.BaseMutationOptions<UpdateLeadMutation, UpdateLeadMutationVariables>;
export const UpdateManyLeadsDocument = gql`
  mutation UpdateManyLeads($input: LeadsUpdateManyInput!) {
    updateManyLeads(input: $input) {
      ...Lead
    }
  }
  ${LeadFragmentDoc}
`;
export type UpdateManyLeadsMutationFn = Apollo.MutationFunction<
  UpdateManyLeadsMutation,
  UpdateManyLeadsMutationVariables
>;

/**
 * __useUpdateManyLeadsMutation__
 *
 * To run a mutation, you first call `useUpdateManyLeadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyLeadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyLeadsMutation, { data, loading, error }] = useUpdateManyLeadsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyLeadsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateManyLeadsMutation, UpdateManyLeadsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateManyLeadsMutation, UpdateManyLeadsMutationVariables>(
    UpdateManyLeadsDocument,
    options
  );
}
export type UpdateManyLeadsMutationHookResult = ReturnType<typeof useUpdateManyLeadsMutation>;
export type UpdateManyLeadsMutationResult = Apollo.MutationResult<UpdateManyLeadsMutation>;
export type UpdateManyLeadsMutationOptions = Apollo.BaseMutationOptions<
  UpdateManyLeadsMutation,
  UpdateManyLeadsMutationVariables
>;
export const UpdateOpportunityDocument = gql`
  mutation UpdateOpportunity($input: OpportunityUpdateInput!) {
    updateOpportunity(input: $input) {
      ...AppetiteOpportunity
    }
  }
  ${AppetiteOpportunityFragmentDoc}
`;
export type UpdateOpportunityMutationFn = Apollo.MutationFunction<
  UpdateOpportunityMutation,
  UpdateOpportunityMutationVariables
>;

/**
 * __useUpdateOpportunityMutation__
 *
 * To run a mutation, you first call `useUpdateOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpportunityMutation, { data, loading, error }] = useUpdateOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>(
    UpdateOpportunityDocument,
    options
  );
}
export type UpdateOpportunityMutationHookResult = ReturnType<typeof useUpdateOpportunityMutation>;
export type UpdateOpportunityMutationResult = Apollo.MutationResult<UpdateOpportunityMutation>;
export type UpdateOpportunityMutationOptions = Apollo.BaseMutationOptions<
  UpdateOpportunityMutation,
  UpdateOpportunityMutationVariables
>;
export const UpdatePolicyDocument = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      ...Policy
    }
  }
  ${PolicyFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type UpdatePolicyMutationFn = Apollo.MutationFunction<UpdatePolicyMutation, UpdatePolicyMutationVariables>;

/**
 * __useUpdatePolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyMutation, { data, loading, error }] = useUpdatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePolicyMutation, UpdatePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyMutation, UpdatePolicyMutationVariables>(UpdatePolicyDocument, options);
}
export type UpdatePolicyMutationHookResult = ReturnType<typeof useUpdatePolicyMutation>;
export type UpdatePolicyMutationResult = Apollo.MutationResult<UpdatePolicyMutation>;
export type UpdatePolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyMutation,
  UpdatePolicyMutationVariables
>;
export const UpdatePrimaryLabelDocument = gql`
  mutation UpdatePrimaryLabel($input: UpdatePrimaryLabelInput!) {
    updatePrimaryLabel(input: $input) {
      ...PrimaryLabel
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;
export type UpdatePrimaryLabelMutationFn = Apollo.MutationFunction<
  UpdatePrimaryLabelMutation,
  UpdatePrimaryLabelMutationVariables
>;

/**
 * __useUpdatePrimaryLabelMutation__
 *
 * To run a mutation, you first call `useUpdatePrimaryLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrimaryLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrimaryLabelMutation, { data, loading, error }] = useUpdatePrimaryLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrimaryLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePrimaryLabelMutation, UpdatePrimaryLabelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePrimaryLabelMutation, UpdatePrimaryLabelMutationVariables>(
    UpdatePrimaryLabelDocument,
    options
  );
}
export type UpdatePrimaryLabelMutationHookResult = ReturnType<typeof useUpdatePrimaryLabelMutation>;
export type UpdatePrimaryLabelMutationResult = Apollo.MutationResult<UpdatePrimaryLabelMutation>;
export type UpdatePrimaryLabelMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrimaryLabelMutation,
  UpdatePrimaryLabelMutationVariables
>;
export const UpdateProductExclusionDocument = gql`
  mutation UpdateProductExclusion($input: ProductExclusionUpdateInput!) {
    productExclusionUpdate(input: $input) {
      id
    }
  }
`;
export type UpdateProductExclusionMutationFn = Apollo.MutationFunction<
  UpdateProductExclusionMutation,
  UpdateProductExclusionMutationVariables
>;

/**
 * __useUpdateProductExclusionMutation__
 *
 * To run a mutation, you first call `useUpdateProductExclusionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductExclusionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductExclusionMutation, { data, loading, error }] = useUpdateProductExclusionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductExclusionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductExclusionMutation, UpdateProductExclusionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductExclusionMutation, UpdateProductExclusionMutationVariables>(
    UpdateProductExclusionDocument,
    options
  );
}
export type UpdateProductExclusionMutationHookResult = ReturnType<typeof useUpdateProductExclusionMutation>;
export type UpdateProductExclusionMutationResult = Apollo.MutationResult<UpdateProductExclusionMutation>;
export type UpdateProductExclusionMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductExclusionMutation,
  UpdateProductExclusionMutationVariables
>;
export const UpdateSubmissionDocument = gql`
  mutation UpdateSubmission($input: UpdateSubmissionInput!) {
    updateSubmission(input: $input) {
      id
    }
  }
`;
export type UpdateSubmissionMutationFn = Apollo.MutationFunction<
  UpdateSubmissionMutation,
  UpdateSubmissionMutationVariables
>;

/**
 * __useUpdateSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionMutation, { data, loading, error }] = useUpdateSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>(
    UpdateSubmissionDocument,
    options
  );
}
export type UpdateSubmissionMutationHookResult = ReturnType<typeof useUpdateSubmissionMutation>;
export type UpdateSubmissionMutationResult = Apollo.MutationResult<UpdateSubmissionMutation>;
export type UpdateSubmissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubmissionMutation,
  UpdateSubmissionMutationVariables
>;
export const UpdateFileTagDocument = gql`
  mutation UpdateFileTag($input: UpdateTagInput!) {
    updateFileTag(input: $input) {
      label
    }
  }
`;
export type UpdateFileTagMutationFn = Apollo.MutationFunction<UpdateFileTagMutation, UpdateFileTagMutationVariables>;

/**
 * __useUpdateFileTagMutation__
 *
 * To run a mutation, you first call `useUpdateFileTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileTagMutation, { data, loading, error }] = useUpdateFileTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFileTagMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFileTagMutation, UpdateFileTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFileTagMutation, UpdateFileTagMutationVariables>(UpdateFileTagDocument, options);
}
export type UpdateFileTagMutationHookResult = ReturnType<typeof useUpdateFileTagMutation>;
export type UpdateFileTagMutationResult = Apollo.MutationResult<UpdateFileTagMutation>;
export type UpdateFileTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileTagMutation,
  UpdateFileTagMutationVariables
>;
export const UpdateUserAccountDocument = gql`
  mutation UpdateUserAccount($id: String!, $input: UserAccountUpdateInput!) {
    updateUserAccount(id: $id, input: $input) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(
    UpdateUserAccountDocument,
    options
  );
}
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = Apollo.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAccountMutation,
  UpdateUserAccountMutationVariables
>;
export const UpdateUserAssignmentsEnabledDocument = gql`
  mutation UpdateUserAssignmentsEnabled($input: UpdateUserAssignmentsEnabledInput!) {
    updateUserAssignmentsEnabled(input: $input) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;
export type UpdateUserAssignmentsEnabledMutationFn = Apollo.MutationFunction<
  UpdateUserAssignmentsEnabledMutation,
  UpdateUserAssignmentsEnabledMutationVariables
>;

/**
 * __useUpdateUserAssignmentsEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateUserAssignmentsEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAssignmentsEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAssignmentsEnabledMutation, { data, loading, error }] = useUpdateUserAssignmentsEnabledMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAssignmentsEnabledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAssignmentsEnabledMutation,
    UpdateUserAssignmentsEnabledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserAssignmentsEnabledMutation, UpdateUserAssignmentsEnabledMutationVariables>(
    UpdateUserAssignmentsEnabledDocument,
    options
  );
}
export type UpdateUserAssignmentsEnabledMutationHookResult = ReturnType<typeof useUpdateUserAssignmentsEnabledMutation>;
export type UpdateUserAssignmentsEnabledMutationResult = Apollo.MutationResult<UpdateUserAssignmentsEnabledMutation>;
export type UpdateUserAssignmentsEnabledMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAssignmentsEnabledMutation,
  UpdateUserAssignmentsEnabledMutationVariables
>;
export const UpdateVerticalDocument = gql`
  mutation UpdateVertical($input: UpdateVerticalInput!) {
    updateVertical(input: $input) {
      ...Vertical
    }
  }
  ${VerticalFragmentDoc}
  ${ClassCodeFragmentDoc}
  ${VerticalMarketingPlanTemplateFragmentDoc}
  ${VerticalMarketingPlanTemplateProductFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;
export type UpdateVerticalMutationFn = Apollo.MutationFunction<UpdateVerticalMutation, UpdateVerticalMutationVariables>;

/**
 * __useUpdateVerticalMutation__
 *
 * To run a mutation, you first call `useUpdateVerticalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVerticalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVerticalMutation, { data, loading, error }] = useUpdateVerticalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVerticalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVerticalMutation, UpdateVerticalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVerticalMutation, UpdateVerticalMutationVariables>(UpdateVerticalDocument, options);
}
export type UpdateVerticalMutationHookResult = ReturnType<typeof useUpdateVerticalMutation>;
export type UpdateVerticalMutationResult = Apollo.MutationResult<UpdateVerticalMutation>;
export type UpdateVerticalMutationOptions = Apollo.BaseMutationOptions<
  UpdateVerticalMutation,
  UpdateVerticalMutationVariables
>;
export const UpdateVerticalMarketingPlanTemplateProductRankDocument = gql`
  mutation UpdateVerticalMarketingPlanTemplateProductRank(
    $input: UpdateVerticalMarketingPlanTemplateProductRankInput!
  ) {
    updateVerticalMarketingPlanTemplateProductRank(input: $input) {
      id
    }
  }
`;
export type UpdateVerticalMarketingPlanTemplateProductRankMutationFn = Apollo.MutationFunction<
  UpdateVerticalMarketingPlanTemplateProductRankMutation,
  UpdateVerticalMarketingPlanTemplateProductRankMutationVariables
>;

/**
 * __useUpdateVerticalMarketingPlanTemplateProductRankMutation__
 *
 * To run a mutation, you first call `useUpdateVerticalMarketingPlanTemplateProductRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVerticalMarketingPlanTemplateProductRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVerticalMarketingPlanTemplateProductRankMutation, { data, loading, error }] = useUpdateVerticalMarketingPlanTemplateProductRankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVerticalMarketingPlanTemplateProductRankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVerticalMarketingPlanTemplateProductRankMutation,
    UpdateVerticalMarketingPlanTemplateProductRankMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVerticalMarketingPlanTemplateProductRankMutation,
    UpdateVerticalMarketingPlanTemplateProductRankMutationVariables
  >(UpdateVerticalMarketingPlanTemplateProductRankDocument, options);
}
export type UpdateVerticalMarketingPlanTemplateProductRankMutationHookResult = ReturnType<
  typeof useUpdateVerticalMarketingPlanTemplateProductRankMutation
>;
export type UpdateVerticalMarketingPlanTemplateProductRankMutationResult =
  Apollo.MutationResult<UpdateVerticalMarketingPlanTemplateProductRankMutation>;
export type UpdateVerticalMarketingPlanTemplateProductRankMutationOptions = Apollo.BaseMutationOptions<
  UpdateVerticalMarketingPlanTemplateProductRankMutation,
  UpdateVerticalMarketingPlanTemplateProductRankMutationVariables
>;
export const UpdateVerticalMarketingPlanTemplateProductRequirementDocument = gql`
  mutation UpdateVerticalMarketingPlanTemplateProductRequirement(
    $input: UpdateVerticalMarketingPlanTemplateProductRequirementInput!
  ) {
    updateVerticalMarketingPlanTemplateProductRequirement(input: $input) {
      id
    }
  }
`;
export type UpdateVerticalMarketingPlanTemplateProductRequirementMutationFn = Apollo.MutationFunction<
  UpdateVerticalMarketingPlanTemplateProductRequirementMutation,
  UpdateVerticalMarketingPlanTemplateProductRequirementMutationVariables
>;

/**
 * __useUpdateVerticalMarketingPlanTemplateProductRequirementMutation__
 *
 * To run a mutation, you first call `useUpdateVerticalMarketingPlanTemplateProductRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVerticalMarketingPlanTemplateProductRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVerticalMarketingPlanTemplateProductRequirementMutation, { data, loading, error }] = useUpdateVerticalMarketingPlanTemplateProductRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVerticalMarketingPlanTemplateProductRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVerticalMarketingPlanTemplateProductRequirementMutation,
    UpdateVerticalMarketingPlanTemplateProductRequirementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVerticalMarketingPlanTemplateProductRequirementMutation,
    UpdateVerticalMarketingPlanTemplateProductRequirementMutationVariables
  >(UpdateVerticalMarketingPlanTemplateProductRequirementDocument, options);
}
export type UpdateVerticalMarketingPlanTemplateProductRequirementMutationHookResult = ReturnType<
  typeof useUpdateVerticalMarketingPlanTemplateProductRequirementMutation
>;
export type UpdateVerticalMarketingPlanTemplateProductRequirementMutationResult =
  Apollo.MutationResult<UpdateVerticalMarketingPlanTemplateProductRequirementMutation>;
export type UpdateVerticalMarketingPlanTemplateProductRequirementMutationOptions = Apollo.BaseMutationOptions<
  UpdateVerticalMarketingPlanTemplateProductRequirementMutation,
  UpdateVerticalMarketingPlanTemplateProductRequirementMutationVariables
>;
export const UpdateVerticalMarketingPlanTemplateProductRulesDocument = gql`
  mutation UpdateVerticalMarketingPlanTemplateProductRules(
    $input: UpdateVerticalMarketingPlanTemplateProductRulesInput!
  ) {
    updateVerticalMarketingPlanTemplateProductRules(input: $input) {
      id
    }
  }
`;
export type UpdateVerticalMarketingPlanTemplateProductRulesMutationFn = Apollo.MutationFunction<
  UpdateVerticalMarketingPlanTemplateProductRulesMutation,
  UpdateVerticalMarketingPlanTemplateProductRulesMutationVariables
>;

/**
 * __useUpdateVerticalMarketingPlanTemplateProductRulesMutation__
 *
 * To run a mutation, you first call `useUpdateVerticalMarketingPlanTemplateProductRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVerticalMarketingPlanTemplateProductRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVerticalMarketingPlanTemplateProductRulesMutation, { data, loading, error }] = useUpdateVerticalMarketingPlanTemplateProductRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVerticalMarketingPlanTemplateProductRulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVerticalMarketingPlanTemplateProductRulesMutation,
    UpdateVerticalMarketingPlanTemplateProductRulesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVerticalMarketingPlanTemplateProductRulesMutation,
    UpdateVerticalMarketingPlanTemplateProductRulesMutationVariables
  >(UpdateVerticalMarketingPlanTemplateProductRulesDocument, options);
}
export type UpdateVerticalMarketingPlanTemplateProductRulesMutationHookResult = ReturnType<
  typeof useUpdateVerticalMarketingPlanTemplateProductRulesMutation
>;
export type UpdateVerticalMarketingPlanTemplateProductRulesMutationResult =
  Apollo.MutationResult<UpdateVerticalMarketingPlanTemplateProductRulesMutation>;
export type UpdateVerticalMarketingPlanTemplateProductRulesMutationOptions = Apollo.BaseMutationOptions<
  UpdateVerticalMarketingPlanTemplateProductRulesMutation,
  UpdateVerticalMarketingPlanTemplateProductRulesMutationVariables
>;
export const UpdateVerticalMarketingPlanTemplateProductSubmissionEmailDocument = gql`
  mutation UpdateVerticalMarketingPlanTemplateProductSubmissionEmail(
    $input: UpdateVerticalMarketingPlanTemplateProductSubmissionEmailInput!
  ) {
    updateVerticalMarketingPlanTemplateProductSubmissionEmail(input: $input) {
      id
    }
  }
`;
export type UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationFn = Apollo.MutationFunction<
  UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation,
  UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationVariables
>;

/**
 * __useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation__
 *
 * To run a mutation, you first call `useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVerticalMarketingPlanTemplateProductSubmissionEmailMutation, { data, loading, error }] = useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation,
    UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation,
    UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationVariables
  >(UpdateVerticalMarketingPlanTemplateProductSubmissionEmailDocument, options);
}
export type UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationHookResult = ReturnType<
  typeof useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation
>;
export type UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationResult =
  Apollo.MutationResult<UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation>;
export type UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation,
  UpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutationVariables
>;
export const InviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUserToAgency(input: $input) {
      userId
      email
      agencyId
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const ReassignOpportunityDocument = gql`
  mutation ReassignOpportunity($id: ID!) {
    reassignOpportunity(id: $id) {
      ...OpportunityDetails
    }
  }
  ${OpportunityDetailsFragmentDoc}
  ${UserAccountFragmentDoc}
  ${SubmissionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
  ${SubmissionStateTransitionFragmentDoc}
  ${QuoteSubmissionFragmentDoc}
  ${ProductExclusionFragmentDoc}
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${AgencyFragmentDoc}
  ${LeadFragmentDoc}
  ${MarketingPlanStateTransitionFragmentDoc}
`;
export type ReassignOpportunityMutationFn = Apollo.MutationFunction<
  ReassignOpportunityMutation,
  ReassignOpportunityMutationVariables
>;

/**
 * __useReassignOpportunityMutation__
 *
 * To run a mutation, you first call `useReassignOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignOpportunityMutation, { data, loading, error }] = useReassignOpportunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReassignOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<ReassignOpportunityMutation, ReassignOpportunityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReassignOpportunityMutation, ReassignOpportunityMutationVariables>(
    ReassignOpportunityDocument,
    options
  );
}
export type ReassignOpportunityMutationHookResult = ReturnType<typeof useReassignOpportunityMutation>;
export type ReassignOpportunityMutationResult = Apollo.MutationResult<ReassignOpportunityMutation>;
export type ReassignOpportunityMutationOptions = Apollo.BaseMutationOptions<
  ReassignOpportunityMutation,
  ReassignOpportunityMutationVariables
>;
export const ActionDocument = gql`
  query Action($id: ID!) {
    action(id: $id) {
      ...Action
    }
  }
  ${ActionFragmentDoc}
`;

/**
 * __useActionQuery__
 *
 * To run a query within a React component, call `useActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActionQuery(baseOptions: Apollo.QueryHookOptions<ActionQuery, ActionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActionQuery, ActionQueryVariables>(ActionDocument, options);
}
export function useActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionQuery, ActionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActionQuery, ActionQueryVariables>(ActionDocument, options);
}
export type ActionQueryHookResult = ReturnType<typeof useActionQuery>;
export type ActionLazyQueryHookResult = ReturnType<typeof useActionLazyQuery>;
export type ActionQueryResult = Apollo.QueryResult<ActionQuery, ActionQueryVariables>;
export const ActionsDocument = gql`
  query Actions($input: ActionFilterInput!) {
    actions(input: $input) {
      ...Action
    }
  }
  ${ActionFragmentDoc}
`;

/**
 * __useActionsQuery__
 *
 * To run a query within a React component, call `useActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActionsQuery(baseOptions: Apollo.QueryHookOptions<ActionsQuery, ActionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActionsQuery, ActionsQueryVariables>(ActionsDocument, options);
}
export function useActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionsQuery, ActionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActionsQuery, ActionsQueryVariables>(ActionsDocument, options);
}
export type ActionsQueryHookResult = ReturnType<typeof useActionsQuery>;
export type ActionsLazyQueryHookResult = ReturnType<typeof useActionsLazyQuery>;
export type ActionsQueryResult = Apollo.QueryResult<ActionsQuery, ActionsQueryVariables>;
export const AdminAccountsDocument = gql`
  query AdminAccounts($input: UserAccountFindManyFilter! = { roles: [Admin] }) {
    userAccounts(input: $input) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useAdminAccountsQuery__
 *
 * To run a query within a React component, call `useAdminAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminAccountsQuery, AdminAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminAccountsQuery, AdminAccountsQueryVariables>(AdminAccountsDocument, options);
}
export function useAdminAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminAccountsQuery, AdminAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminAccountsQuery, AdminAccountsQueryVariables>(AdminAccountsDocument, options);
}
export type AdminAccountsQueryHookResult = ReturnType<typeof useAdminAccountsQuery>;
export type AdminAccountsLazyQueryHookResult = ReturnType<typeof useAdminAccountsLazyQuery>;
export type AdminAccountsQueryResult = Apollo.QueryResult<AdminAccountsQuery, AdminAccountsQueryVariables>;
export const AgenciesDocument = gql`
  query Agencies($input: AgenciesFilterInput) {
    agencies(input: $input) {
      id
      name
      isTest
    }
  }
`;

/**
 * __useAgenciesQuery__
 *
 * To run a query within a React component, call `useAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgenciesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgenciesQuery(baseOptions?: Apollo.QueryHookOptions<AgenciesQuery, AgenciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgenciesQuery, AgenciesQueryVariables>(AgenciesDocument, options);
}
export function useAgenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgenciesQuery, AgenciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgenciesQuery, AgenciesQueryVariables>(AgenciesDocument, options);
}
export type AgenciesQueryHookResult = ReturnType<typeof useAgenciesQuery>;
export type AgenciesLazyQueryHookResult = ReturnType<typeof useAgenciesLazyQuery>;
export type AgenciesQueryResult = Apollo.QueryResult<AgenciesQuery, AgenciesQueryVariables>;
export const AgenciesWithOwnerDocument = gql`
  query AgenciesWithOwner {
    agencies {
      id
      name
      isTest
      isPrivate
      owner {
        ...UserAccount
      }
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useAgenciesWithOwnerQuery__
 *
 * To run a query within a React component, call `useAgenciesWithOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgenciesWithOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgenciesWithOwnerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgenciesWithOwnerQuery(
  baseOptions?: Apollo.QueryHookOptions<AgenciesWithOwnerQuery, AgenciesWithOwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgenciesWithOwnerQuery, AgenciesWithOwnerQueryVariables>(AgenciesWithOwnerDocument, options);
}
export function useAgenciesWithOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgenciesWithOwnerQuery, AgenciesWithOwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgenciesWithOwnerQuery, AgenciesWithOwnerQueryVariables>(
    AgenciesWithOwnerDocument,
    options
  );
}
export type AgenciesWithOwnerQueryHookResult = ReturnType<typeof useAgenciesWithOwnerQuery>;
export type AgenciesWithOwnerLazyQueryHookResult = ReturnType<typeof useAgenciesWithOwnerLazyQuery>;
export type AgenciesWithOwnerQueryResult = Apollo.QueryResult<AgenciesWithOwnerQuery, AgenciesWithOwnerQueryVariables>;
export const AgencyDocument = gql`
  query Agency($id: ID!) {
    agency(id: $id) {
      id
      name
      isTest
      isPrivate
      owner {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useAgencyQuery__
 *
 * To run a query within a React component, call `useAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgencyQuery(baseOptions: Apollo.QueryHookOptions<AgencyQuery, AgencyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgencyQuery, AgencyQueryVariables>(AgencyDocument, options);
}
export function useAgencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyQuery, AgencyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgencyQuery, AgencyQueryVariables>(AgencyDocument, options);
}
export type AgencyQueryHookResult = ReturnType<typeof useAgencyQuery>;
export type AgencyLazyQueryHookResult = ReturnType<typeof useAgencyLazyQuery>;
export type AgencyQueryResult = Apollo.QueryResult<AgencyQuery, AgencyQueryVariables>;
export const AgencyAccountsDocument = gql`
  query AgencyAccounts($input: UserAccountFindManyFilter!) {
    agencyAccounts(input: $input) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useAgencyAccountsQuery__
 *
 * To run a query within a React component, call `useAgencyAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgencyAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<AgencyAccountsQuery, AgencyAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgencyAccountsQuery, AgencyAccountsQueryVariables>(AgencyAccountsDocument, options);
}
export function useAgencyAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgencyAccountsQuery, AgencyAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgencyAccountsQuery, AgencyAccountsQueryVariables>(AgencyAccountsDocument, options);
}
export type AgencyAccountsQueryHookResult = ReturnType<typeof useAgencyAccountsQuery>;
export type AgencyAccountsLazyQueryHookResult = ReturnType<typeof useAgencyAccountsLazyQuery>;
export type AgencyAccountsQueryResult = Apollo.QueryResult<AgencyAccountsQuery, AgencyAccountsQueryVariables>;
export const AgencyAffiliationDocument = gql`
  query AgencyAffiliation($id: ID!) {
    agency(id: $id) {
      affiliations {
        ...AgencyAffiliation
      }
    }
  }
  ${AgencyAffiliationFragmentDoc}
`;

/**
 * __useAgencyAffiliationQuery__
 *
 * To run a query within a React component, call `useAgencyAffiliationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyAffiliationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyAffiliationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAgencyAffiliationQuery(
  baseOptions: Apollo.QueryHookOptions<AgencyAffiliationQuery, AgencyAffiliationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgencyAffiliationQuery, AgencyAffiliationQueryVariables>(AgencyAffiliationDocument, options);
}
export function useAgencyAffiliationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgencyAffiliationQuery, AgencyAffiliationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgencyAffiliationQuery, AgencyAffiliationQueryVariables>(
    AgencyAffiliationDocument,
    options
  );
}
export type AgencyAffiliationQueryHookResult = ReturnType<typeof useAgencyAffiliationQuery>;
export type AgencyAffiliationLazyQueryHookResult = ReturnType<typeof useAgencyAffiliationLazyQuery>;
export type AgencyAffiliationQueryResult = Apollo.QueryResult<AgencyAffiliationQuery, AgencyAffiliationQueryVariables>;
export const AgencyAppointmentsDocument = gql`
  query AgencyAppointments($agencyId: String!) {
    agencyAppointments(agencyId: $agencyId) {
      id
      agencyId
      carrierSlug
    }
  }
`;

/**
 * __useAgencyAppointmentsQuery__
 *
 * To run a query within a React component, call `useAgencyAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyAppointmentsQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useAgencyAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<AgencyAppointmentsQuery, AgencyAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgencyAppointmentsQuery, AgencyAppointmentsQueryVariables>(
    AgencyAppointmentsDocument,
    options
  );
}
export function useAgencyAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgencyAppointmentsQuery, AgencyAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgencyAppointmentsQuery, AgencyAppointmentsQueryVariables>(
    AgencyAppointmentsDocument,
    options
  );
}
export type AgencyAppointmentsQueryHookResult = ReturnType<typeof useAgencyAppointmentsQuery>;
export type AgencyAppointmentsLazyQueryHookResult = ReturnType<typeof useAgencyAppointmentsLazyQuery>;
export type AgencyAppointmentsQueryResult = Apollo.QueryResult<
  AgencyAppointmentsQuery,
  AgencyAppointmentsQueryVariables
>;
export const AgentAccountsDocument = gql`
  query AgentAccounts($input: UserAccountFindManyFilter! = { roles: [Agent] }) {
    userAccounts(input: $input) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useAgentAccountsQuery__
 *
 * To run a query within a React component, call `useAgentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgentAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<AgentAccountsQuery, AgentAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgentAccountsQuery, AgentAccountsQueryVariables>(AgentAccountsDocument, options);
}
export function useAgentAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgentAccountsQuery, AgentAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgentAccountsQuery, AgentAccountsQueryVariables>(AgentAccountsDocument, options);
}
export type AgentAccountsQueryHookResult = ReturnType<typeof useAgentAccountsQuery>;
export type AgentAccountsLazyQueryHookResult = ReturnType<typeof useAgentAccountsLazyQuery>;
export type AgentAccountsQueryResult = Apollo.QueryResult<AgentAccountsQuery, AgentAccountsQueryVariables>;
export const AgentActionTitlesDocument = gql`
  query AgentActionTitles($actionType: String!) {
    agentActionTitles(actionType: $actionType)
  }
`;

/**
 * __useAgentActionTitlesQuery__
 *
 * To run a query within a React component, call `useAgentActionTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentActionTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentActionTitlesQuery({
 *   variables: {
 *      actionType: // value for 'actionType'
 *   },
 * });
 */
export function useAgentActionTitlesQuery(
  baseOptions: Apollo.QueryHookOptions<AgentActionTitlesQuery, AgentActionTitlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgentActionTitlesQuery, AgentActionTitlesQueryVariables>(AgentActionTitlesDocument, options);
}
export function useAgentActionTitlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgentActionTitlesQuery, AgentActionTitlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgentActionTitlesQuery, AgentActionTitlesQueryVariables>(
    AgentActionTitlesDocument,
    options
  );
}
export type AgentActionTitlesQueryHookResult = ReturnType<typeof useAgentActionTitlesQuery>;
export type AgentActionTitlesLazyQueryHookResult = ReturnType<typeof useAgentActionTitlesLazyQuery>;
export type AgentActionTitlesQueryResult = Apollo.QueryResult<AgentActionTitlesQuery, AgentActionTitlesQueryVariables>;
export const AiGuessBusinessClassesDocument = gql`
  query AiGuessBusinessClasses($description: String!) {
    aiGuessBusinessClasses(description: $description) {
      ...BusinessClass
    }
  }
  ${BusinessClassFragmentDoc}
`;

/**
 * __useAiGuessBusinessClassesQuery__
 *
 * To run a query within a React component, call `useAiGuessBusinessClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiGuessBusinessClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiGuessBusinessClassesQuery({
 *   variables: {
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAiGuessBusinessClassesQuery(
  baseOptions: Apollo.QueryHookOptions<AiGuessBusinessClassesQuery, AiGuessBusinessClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AiGuessBusinessClassesQuery, AiGuessBusinessClassesQueryVariables>(
    AiGuessBusinessClassesDocument,
    options
  );
}
export function useAiGuessBusinessClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AiGuessBusinessClassesQuery, AiGuessBusinessClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AiGuessBusinessClassesQuery, AiGuessBusinessClassesQueryVariables>(
    AiGuessBusinessClassesDocument,
    options
  );
}
export type AiGuessBusinessClassesQueryHookResult = ReturnType<typeof useAiGuessBusinessClassesQuery>;
export type AiGuessBusinessClassesLazyQueryHookResult = ReturnType<typeof useAiGuessBusinessClassesLazyQuery>;
export type AiGuessBusinessClassesQueryResult = Apollo.QueryResult<
  AiGuessBusinessClassesQuery,
  AiGuessBusinessClassesQueryVariables
>;
export const AppetiteFilterOptionsDocument = gql`
  query AppetiteFilterOptions {
    appetiteFilterOptions {
      verticals {
        id
        name
        linesOfBusiness {
          name
        }
      }
    }
  }
`;

/**
 * __useAppetiteFilterOptionsQuery__
 *
 * To run a query within a React component, call `useAppetiteFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppetiteFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppetiteFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppetiteFilterOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<AppetiteFilterOptionsQuery, AppetiteFilterOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppetiteFilterOptionsQuery, AppetiteFilterOptionsQueryVariables>(
    AppetiteFilterOptionsDocument,
    options
  );
}
export function useAppetiteFilterOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppetiteFilterOptionsQuery, AppetiteFilterOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppetiteFilterOptionsQuery, AppetiteFilterOptionsQueryVariables>(
    AppetiteFilterOptionsDocument,
    options
  );
}
export type AppetiteFilterOptionsQueryHookResult = ReturnType<typeof useAppetiteFilterOptionsQuery>;
export type AppetiteFilterOptionsLazyQueryHookResult = ReturnType<typeof useAppetiteFilterOptionsLazyQuery>;
export type AppetiteFilterOptionsQueryResult = Apollo.QueryResult<
  AppetiteFilterOptionsQuery,
  AppetiteFilterOptionsQueryVariables
>;
export const AppetiteGuidesForOpportunityDocument = gql`
  query AppetiteGuidesForOpportunity($opportunityId: ID!) {
    appetiteGuidesForOpportunity(id: $opportunityId) {
      carrierName
      authority
      code
      lob
      state
      parsedText
      googleDriveUrl
      fileType
    }
  }
`;

/**
 * __useAppetiteGuidesForOpportunityQuery__
 *
 * To run a query within a React component, call `useAppetiteGuidesForOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppetiteGuidesForOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppetiteGuidesForOpportunityQuery({
 *   variables: {
 *      opportunityId: // value for 'opportunityId'
 *   },
 * });
 */
export function useAppetiteGuidesForOpportunityQuery(
  baseOptions: Apollo.QueryHookOptions<AppetiteGuidesForOpportunityQuery, AppetiteGuidesForOpportunityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppetiteGuidesForOpportunityQuery, AppetiteGuidesForOpportunityQueryVariables>(
    AppetiteGuidesForOpportunityDocument,
    options
  );
}
export function useAppetiteGuidesForOpportunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppetiteGuidesForOpportunityQuery,
    AppetiteGuidesForOpportunityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppetiteGuidesForOpportunityQuery, AppetiteGuidesForOpportunityQueryVariables>(
    AppetiteGuidesForOpportunityDocument,
    options
  );
}
export type AppetiteGuidesForOpportunityQueryHookResult = ReturnType<typeof useAppetiteGuidesForOpportunityQuery>;
export type AppetiteGuidesForOpportunityLazyQueryHookResult = ReturnType<
  typeof useAppetiteGuidesForOpportunityLazyQuery
>;
export type AppetiteGuidesForOpportunityQueryResult = Apollo.QueryResult<
  AppetiteGuidesForOpportunityQuery,
  AppetiteGuidesForOpportunityQueryVariables
>;
export const AppetiteNotesDocument = gql`
  query AppetiteNotes($input: AppetiteNoteFilterInput) {
    appetiteNotes(input: $input) {
      ...AppetiteNote
    }
  }
  ${AppetiteNoteFragmentDoc}
`;

/**
 * __useAppetiteNotesQuery__
 *
 * To run a query within a React component, call `useAppetiteNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppetiteNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppetiteNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppetiteNotesQuery(
  baseOptions?: Apollo.QueryHookOptions<AppetiteNotesQuery, AppetiteNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppetiteNotesQuery, AppetiteNotesQueryVariables>(AppetiteNotesDocument, options);
}
export function useAppetiteNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppetiteNotesQuery, AppetiteNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppetiteNotesQuery, AppetiteNotesQueryVariables>(AppetiteNotesDocument, options);
}
export type AppetiteNotesQueryHookResult = ReturnType<typeof useAppetiteNotesQuery>;
export type AppetiteNotesLazyQueryHookResult = ReturnType<typeof useAppetiteNotesLazyQuery>;
export type AppetiteNotesQueryResult = Apollo.QueryResult<AppetiteNotesQuery, AppetiteNotesQueryVariables>;
export const AppetiteNotesForOpportunityDocument = gql`
  query AppetiteNotesForOpportunity($opportunityId: String!) {
    appetiteNotesForOpportunity(opportunityId: $opportunityId) {
      appetiteNote {
        id
        note
      }
      matchedTags {
        value
        type
      }
    }
  }
`;

/**
 * __useAppetiteNotesForOpportunityQuery__
 *
 * To run a query within a React component, call `useAppetiteNotesForOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppetiteNotesForOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppetiteNotesForOpportunityQuery({
 *   variables: {
 *      opportunityId: // value for 'opportunityId'
 *   },
 * });
 */
export function useAppetiteNotesForOpportunityQuery(
  baseOptions: Apollo.QueryHookOptions<AppetiteNotesForOpportunityQuery, AppetiteNotesForOpportunityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppetiteNotesForOpportunityQuery, AppetiteNotesForOpportunityQueryVariables>(
    AppetiteNotesForOpportunityDocument,
    options
  );
}
export function useAppetiteNotesForOpportunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppetiteNotesForOpportunityQuery, AppetiteNotesForOpportunityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppetiteNotesForOpportunityQuery, AppetiteNotesForOpportunityQueryVariables>(
    AppetiteNotesForOpportunityDocument,
    options
  );
}
export type AppetiteNotesForOpportunityQueryHookResult = ReturnType<typeof useAppetiteNotesForOpportunityQuery>;
export type AppetiteNotesForOpportunityLazyQueryHookResult = ReturnType<typeof useAppetiteNotesForOpportunityLazyQuery>;
export type AppetiteNotesForOpportunityQueryResult = Apollo.QueryResult<
  AppetiteNotesForOpportunityQuery,
  AppetiteNotesForOpportunityQueryVariables
>;
export const AppetiteProductLinesDocument = gql`
  query AppetiteProductLines {
    appetiteProductLines
  }
`;

/**
 * __useAppetiteProductLinesQuery__
 *
 * To run a query within a React component, call `useAppetiteProductLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppetiteProductLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppetiteProductLinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppetiteProductLinesQuery(
  baseOptions?: Apollo.QueryHookOptions<AppetiteProductLinesQuery, AppetiteProductLinesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppetiteProductLinesQuery, AppetiteProductLinesQueryVariables>(
    AppetiteProductLinesDocument,
    options
  );
}
export function useAppetiteProductLinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppetiteProductLinesQuery, AppetiteProductLinesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppetiteProductLinesQuery, AppetiteProductLinesQueryVariables>(
    AppetiteProductLinesDocument,
    options
  );
}
export type AppetiteProductLinesQueryHookResult = ReturnType<typeof useAppetiteProductLinesQuery>;
export type AppetiteProductLinesLazyQueryHookResult = ReturnType<typeof useAppetiteProductLinesLazyQuery>;
export type AppetiteProductLinesQueryResult = Apollo.QueryResult<
  AppetiteProductLinesQuery,
  AppetiteProductLinesQueryVariables
>;
export const AppetiteProductsDocument = gql`
  query AppetiteProducts {
    appetiteProducts {
      ...AppetiteProduct
    }
  }
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;

/**
 * __useAppetiteProductsQuery__
 *
 * To run a query within a React component, call `useAppetiteProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppetiteProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppetiteProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppetiteProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<AppetiteProductsQuery, AppetiteProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppetiteProductsQuery, AppetiteProductsQueryVariables>(AppetiteProductsDocument, options);
}
export function useAppetiteProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppetiteProductsQuery, AppetiteProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppetiteProductsQuery, AppetiteProductsQueryVariables>(AppetiteProductsDocument, options);
}
export type AppetiteProductsQueryHookResult = ReturnType<typeof useAppetiteProductsQuery>;
export type AppetiteProductsLazyQueryHookResult = ReturnType<typeof useAppetiteProductsLazyQuery>;
export type AppetiteProductsQueryResult = Apollo.QueryResult<AppetiteProductsQuery, AppetiteProductsQueryVariables>;
export const AppetiteProductsCountDocument = gql`
  query AppetiteProductsCount {
    productsCount
  }
`;

/**
 * __useAppetiteProductsCountQuery__
 *
 * To run a query within a React component, call `useAppetiteProductsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppetiteProductsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppetiteProductsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppetiteProductsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<AppetiteProductsCountQuery, AppetiteProductsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppetiteProductsCountQuery, AppetiteProductsCountQueryVariables>(
    AppetiteProductsCountDocument,
    options
  );
}
export function useAppetiteProductsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppetiteProductsCountQuery, AppetiteProductsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppetiteProductsCountQuery, AppetiteProductsCountQueryVariables>(
    AppetiteProductsCountDocument,
    options
  );
}
export type AppetiteProductsCountQueryHookResult = ReturnType<typeof useAppetiteProductsCountQuery>;
export type AppetiteProductsCountLazyQueryHookResult = ReturnType<typeof useAppetiteProductsCountLazyQuery>;
export type AppetiteProductsCountQueryResult = Apollo.QueryResult<
  AppetiteProductsCountQuery,
  AppetiteProductsCountQueryVariables
>;
export const BankanMarketingPlanColumnDocument = gql`
  query BankanMarketingPlanColumn($input: BankanMarketingPlanColumnInput!) {
    bankanMarketingPlanColumn(input: $input) {
      groupedMarketingPlans {
        insuredId
        insuredName
        insured {
          id
          name
          createdAt
          agent {
            firstName
            lastName
            agency {
              name
            }
          }
        }
        marketingPlans {
          id
          state
          desiredEffectiveDate
          lastTransitionAt
          selectedLinesOfBusiness
          snoozeUntil
          broker {
            firstName
            lastName
          }
          score {
            score
            verticalScore
            affiliationScore
            effectiveDateScore
            bindScore
            bindRatioScore
          }
        }
      }
    }
  }
`;

/**
 * __useBankanMarketingPlanColumnQuery__
 *
 * To run a query within a React component, call `useBankanMarketingPlanColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankanMarketingPlanColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankanMarketingPlanColumnQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBankanMarketingPlanColumnQuery(
  baseOptions: Apollo.QueryHookOptions<BankanMarketingPlanColumnQuery, BankanMarketingPlanColumnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankanMarketingPlanColumnQuery, BankanMarketingPlanColumnQueryVariables>(
    BankanMarketingPlanColumnDocument,
    options
  );
}
export function useBankanMarketingPlanColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankanMarketingPlanColumnQuery, BankanMarketingPlanColumnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankanMarketingPlanColumnQuery, BankanMarketingPlanColumnQueryVariables>(
    BankanMarketingPlanColumnDocument,
    options
  );
}
export type BankanMarketingPlanColumnQueryHookResult = ReturnType<typeof useBankanMarketingPlanColumnQuery>;
export type BankanMarketingPlanColumnLazyQueryHookResult = ReturnType<typeof useBankanMarketingPlanColumnLazyQuery>;
export type BankanMarketingPlanColumnQueryResult = Apollo.QueryResult<
  BankanMarketingPlanColumnQuery,
  BankanMarketingPlanColumnQueryVariables
>;
export const BankanPolicyColumnDocument = gql`
  query BankanPolicyColumn($input: BankanPolicyColumnInput!) {
    bankanPolicyColumn(input: $input) {
      groupedPolicies {
        insuredId
        insuredName
        insured {
          id
          name
          createdAt
          agent {
            firstName
            lastName
            agency {
              name
            }
          }
        }
        policies {
          id
          state
          policyNumber
          expirationDate
          lastTransitionAt
          appetiteProduct {
            id
            carrierName
            carrierProductName
          }
        }
      }
    }
  }
`;

/**
 * __useBankanPolicyColumnQuery__
 *
 * To run a query within a React component, call `useBankanPolicyColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankanPolicyColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankanPolicyColumnQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBankanPolicyColumnQuery(
  baseOptions: Apollo.QueryHookOptions<BankanPolicyColumnQuery, BankanPolicyColumnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankanPolicyColumnQuery, BankanPolicyColumnQueryVariables>(
    BankanPolicyColumnDocument,
    options
  );
}
export function useBankanPolicyColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankanPolicyColumnQuery, BankanPolicyColumnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankanPolicyColumnQuery, BankanPolicyColumnQueryVariables>(
    BankanPolicyColumnDocument,
    options
  );
}
export type BankanPolicyColumnQueryHookResult = ReturnType<typeof useBankanPolicyColumnQuery>;
export type BankanPolicyColumnLazyQueryHookResult = ReturnType<typeof useBankanPolicyColumnLazyQuery>;
export type BankanPolicyColumnQueryResult = Apollo.QueryResult<
  BankanPolicyColumnQuery,
  BankanPolicyColumnQueryVariables
>;
export const BankanQuoteColumnDocument = gql`
  query BankanQuoteColumn($input: BankanQuoteColumnInput!) {
    bankanQuoteColumn(input: $input) {
      groupedQuotes {
        insuredId
        insuredName
        insured {
          id
          name
          createdAt
          agent {
            firstName
            lastName
            agency {
              name
            }
          }
        }
        quotes {
          id
          state
          premium
          effectiveDate
          lastTransitionAt
          appetiteProduct {
            id
            carrierName
            carrierProductName
          }
        }
      }
    }
  }
`;

/**
 * __useBankanQuoteColumnQuery__
 *
 * To run a query within a React component, call `useBankanQuoteColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankanQuoteColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankanQuoteColumnQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBankanQuoteColumnQuery(
  baseOptions: Apollo.QueryHookOptions<BankanQuoteColumnQuery, BankanQuoteColumnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BankanQuoteColumnQuery, BankanQuoteColumnQueryVariables>(BankanQuoteColumnDocument, options);
}
export function useBankanQuoteColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BankanQuoteColumnQuery, BankanQuoteColumnQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BankanQuoteColumnQuery, BankanQuoteColumnQueryVariables>(
    BankanQuoteColumnDocument,
    options
  );
}
export type BankanQuoteColumnQueryHookResult = ReturnType<typeof useBankanQuoteColumnQuery>;
export type BankanQuoteColumnLazyQueryHookResult = ReturnType<typeof useBankanQuoteColumnLazyQuery>;
export type BankanQuoteColumnQueryResult = Apollo.QueryResult<BankanQuoteColumnQuery, BankanQuoteColumnQueryVariables>;
export const BindHqAgenciesDocument = gql`
  query BindHqAgencies {
    bindHqAgencies {
      ...BindHqAgency
    }
  }
  ${BindHqAgencyFragmentDoc}
`;

/**
 * __useBindHqAgenciesQuery__
 *
 * To run a query within a React component, call `useBindHqAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBindHqAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBindHqAgenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBindHqAgenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<BindHqAgenciesQuery, BindHqAgenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BindHqAgenciesQuery, BindHqAgenciesQueryVariables>(BindHqAgenciesDocument, options);
}
export function useBindHqAgenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BindHqAgenciesQuery, BindHqAgenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BindHqAgenciesQuery, BindHqAgenciesQueryVariables>(BindHqAgenciesDocument, options);
}
export type BindHqAgenciesQueryHookResult = ReturnType<typeof useBindHqAgenciesQuery>;
export type BindHqAgenciesLazyQueryHookResult = ReturnType<typeof useBindHqAgenciesLazyQuery>;
export type BindHqAgenciesQueryResult = Apollo.QueryResult<BindHqAgenciesQuery, BindHqAgenciesQueryVariables>;
export const BindHqAgentsDocument = gql`
  query BindHqAgents($input: BindHqFetchAgentsInput!) {
    bindHqAgents(input: $input) {
      id
      firstName
      lastName
    }
  }
`;

/**
 * __useBindHqAgentsQuery__
 *
 * To run a query within a React component, call `useBindHqAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBindHqAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBindHqAgentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBindHqAgentsQuery(
  baseOptions: Apollo.QueryHookOptions<BindHqAgentsQuery, BindHqAgentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BindHqAgentsQuery, BindHqAgentsQueryVariables>(BindHqAgentsDocument, options);
}
export function useBindHqAgentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BindHqAgentsQuery, BindHqAgentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BindHqAgentsQuery, BindHqAgentsQueryVariables>(BindHqAgentsDocument, options);
}
export type BindHqAgentsQueryHookResult = ReturnType<typeof useBindHqAgentsQuery>;
export type BindHqAgentsLazyQueryHookResult = ReturnType<typeof useBindHqAgentsLazyQuery>;
export type BindHqAgentsQueryResult = Apollo.QueryResult<BindHqAgentsQuery, BindHqAgentsQueryVariables>;
export const BindHqCarriersDocument = gql`
  query BindHqCarriers {
    bindHqCarriers {
      id
      name
    }
  }
`;

/**
 * __useBindHqCarriersQuery__
 *
 * To run a query within a React component, call `useBindHqCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBindHqCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBindHqCarriersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBindHqCarriersQuery(
  baseOptions?: Apollo.QueryHookOptions<BindHqCarriersQuery, BindHqCarriersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BindHqCarriersQuery, BindHqCarriersQueryVariables>(BindHqCarriersDocument, options);
}
export function useBindHqCarriersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BindHqCarriersQuery, BindHqCarriersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BindHqCarriersQuery, BindHqCarriersQueryVariables>(BindHqCarriersDocument, options);
}
export type BindHqCarriersQueryHookResult = ReturnType<typeof useBindHqCarriersQuery>;
export type BindHqCarriersLazyQueryHookResult = ReturnType<typeof useBindHqCarriersLazyQuery>;
export type BindHqCarriersQueryResult = Apollo.QueryResult<BindHqCarriersQuery, BindHqCarriersQueryVariables>;
export const BindHqInsuredsDocument = gql`
  query BindHqInsureds {
    bindHqInsureds {
      id
      name
      type
    }
  }
`;

/**
 * __useBindHqInsuredsQuery__
 *
 * To run a query within a React component, call `useBindHqInsuredsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBindHqInsuredsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBindHqInsuredsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBindHqInsuredsQuery(
  baseOptions?: Apollo.QueryHookOptions<BindHqInsuredsQuery, BindHqInsuredsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BindHqInsuredsQuery, BindHqInsuredsQueryVariables>(BindHqInsuredsDocument, options);
}
export function useBindHqInsuredsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BindHqInsuredsQuery, BindHqInsuredsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BindHqInsuredsQuery, BindHqInsuredsQueryVariables>(BindHqInsuredsDocument, options);
}
export type BindHqInsuredsQueryHookResult = ReturnType<typeof useBindHqInsuredsQuery>;
export type BindHqInsuredsLazyQueryHookResult = ReturnType<typeof useBindHqInsuredsLazyQuery>;
export type BindHqInsuredsQueryResult = Apollo.QueryResult<BindHqInsuredsQuery, BindHqInsuredsQueryVariables>;
export const BindHqMarketingCompaniesDocument = gql`
  query BindHqMarketingCompanies {
    bindHqMarketingCompanies {
      id
      name
    }
  }
`;

/**
 * __useBindHqMarketingCompaniesQuery__
 *
 * To run a query within a React component, call `useBindHqMarketingCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBindHqMarketingCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBindHqMarketingCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBindHqMarketingCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<BindHqMarketingCompaniesQuery, BindHqMarketingCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BindHqMarketingCompaniesQuery, BindHqMarketingCompaniesQueryVariables>(
    BindHqMarketingCompaniesDocument,
    options
  );
}
export function useBindHqMarketingCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BindHqMarketingCompaniesQuery, BindHqMarketingCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BindHqMarketingCompaniesQuery, BindHqMarketingCompaniesQueryVariables>(
    BindHqMarketingCompaniesDocument,
    options
  );
}
export type BindHqMarketingCompaniesQueryHookResult = ReturnType<typeof useBindHqMarketingCompaniesQuery>;
export type BindHqMarketingCompaniesLazyQueryHookResult = ReturnType<typeof useBindHqMarketingCompaniesLazyQuery>;
export type BindHqMarketingCompaniesQueryResult = Apollo.QueryResult<
  BindHqMarketingCompaniesQuery,
  BindHqMarketingCompaniesQueryVariables
>;
export const BrokerAccountsDocument = gql`
  query BrokerAccounts($input: UserAccountFindManyFilter! = { roles: [Broker] }) {
    userAccounts(input: $input) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useBrokerAccountsQuery__
 *
 * To run a query within a React component, call `useBrokerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokerAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrokerAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<BrokerAccountsQuery, BrokerAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrokerAccountsQuery, BrokerAccountsQueryVariables>(BrokerAccountsDocument, options);
}
export function useBrokerAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BrokerAccountsQuery, BrokerAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrokerAccountsQuery, BrokerAccountsQueryVariables>(BrokerAccountsDocument, options);
}
export type BrokerAccountsQueryHookResult = ReturnType<typeof useBrokerAccountsQuery>;
export type BrokerAccountsLazyQueryHookResult = ReturnType<typeof useBrokerAccountsLazyQuery>;
export type BrokerAccountsQueryResult = Apollo.QueryResult<BrokerAccountsQuery, BrokerAccountsQueryVariables>;
export const AssignemntQueueDocument = gql`
  query AssignemntQueue {
    brokerAssignmentQueue {
      brokers {
        ...UserAccount
      }
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useAssignemntQueueQuery__
 *
 * To run a query within a React component, call `useAssignemntQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignemntQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignemntQueueQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssignemntQueueQuery(
  baseOptions?: Apollo.QueryHookOptions<AssignemntQueueQuery, AssignemntQueueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssignemntQueueQuery, AssignemntQueueQueryVariables>(AssignemntQueueDocument, options);
}
export function useAssignemntQueueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignemntQueueQuery, AssignemntQueueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssignemntQueueQuery, AssignemntQueueQueryVariables>(AssignemntQueueDocument, options);
}
export type AssignemntQueueQueryHookResult = ReturnType<typeof useAssignemntQueueQuery>;
export type AssignemntQueueLazyQueryHookResult = ReturnType<typeof useAssignemntQueueLazyQuery>;
export type AssignemntQueueQueryResult = Apollo.QueryResult<AssignemntQueueQuery, AssignemntQueueQueryVariables>;
export const CarrierContactsDocument = gql`
  query CarrierContacts {
    carrierContacts {
      ...CarrierContact
    }
  }
  ${CarrierContactFragmentDoc}
`;

/**
 * __useCarrierContactsQuery__
 *
 * To run a query within a React component, call `useCarrierContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarrierContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarrierContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarrierContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<CarrierContactsQuery, CarrierContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarrierContactsQuery, CarrierContactsQueryVariables>(CarrierContactsDocument, options);
}
export function useCarrierContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CarrierContactsQuery, CarrierContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarrierContactsQuery, CarrierContactsQueryVariables>(CarrierContactsDocument, options);
}
export type CarrierContactsQueryHookResult = ReturnType<typeof useCarrierContactsQuery>;
export type CarrierContactsLazyQueryHookResult = ReturnType<typeof useCarrierContactsLazyQuery>;
export type CarrierContactsQueryResult = Apollo.QueryResult<CarrierContactsQuery, CarrierContactsQueryVariables>;
export const ClassCodesDocument = gql`
  query ClassCodes {
    classCodes {
      ...ClassCode
    }
  }
  ${ClassCodeFragmentDoc}
`;

/**
 * __useClassCodesQuery__
 *
 * To run a query within a React component, call `useClassCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassCodesQuery(baseOptions?: Apollo.QueryHookOptions<ClassCodesQuery, ClassCodesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClassCodesQuery, ClassCodesQueryVariables>(ClassCodesDocument, options);
}
export function useClassCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClassCodesQuery, ClassCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClassCodesQuery, ClassCodesQueryVariables>(ClassCodesDocument, options);
}
export type ClassCodesQueryHookResult = ReturnType<typeof useClassCodesQuery>;
export type ClassCodesLazyQueryHookResult = ReturnType<typeof useClassCodesLazyQuery>;
export type ClassCodesQueryResult = Apollo.QueryResult<ClassCodesQuery, ClassCodesQueryVariables>;
export const ClientDataDocument = gql`
  query ClientData($input: ClientDataFilterInput!) {
    clientData(input: $input) {
      ...ClientData
    }
  }
  ${ClientDataFragmentDoc}
`;

/**
 * __useClientDataQuery__
 *
 * To run a query within a React component, call `useClientDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientDataQuery(baseOptions: Apollo.QueryHookOptions<ClientDataQuery, ClientDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientDataQuery, ClientDataQueryVariables>(ClientDataDocument, options);
}
export function useClientDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientDataQuery, ClientDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientDataQuery, ClientDataQueryVariables>(ClientDataDocument, options);
}
export type ClientDataQueryHookResult = ReturnType<typeof useClientDataQuery>;
export type ClientDataLazyQueryHookResult = ReturnType<typeof useClientDataLazyQuery>;
export type ClientDataQueryResult = Apollo.QueryResult<ClientDataQuery, ClientDataQueryVariables>;
export const GmailThreadsDocument = gql`
  query GmailThreads($input: GmailThreadFilterInput!) {
    gmailThreads(input: $input) {
      id
      gmailMessages {
        id
        to
        from
        subject
        text
        textAsHtml
        html
      }
    }
  }
`;

/**
 * __useGmailThreadsQuery__
 *
 * To run a query within a React component, call `useGmailThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGmailThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGmailThreadsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGmailThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<GmailThreadsQuery, GmailThreadsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GmailThreadsQuery, GmailThreadsQueryVariables>(GmailThreadsDocument, options);
}
export function useGmailThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GmailThreadsQuery, GmailThreadsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GmailThreadsQuery, GmailThreadsQueryVariables>(GmailThreadsDocument, options);
}
export type GmailThreadsQueryHookResult = ReturnType<typeof useGmailThreadsQuery>;
export type GmailThreadsLazyQueryHookResult = ReturnType<typeof useGmailThreadsLazyQuery>;
export type GmailThreadsQueryResult = Apollo.QueryResult<GmailThreadsQuery, GmailThreadsQueryVariables>;
export const ExtractedLabelsDocument = gql`
  query ExtractedLabels {
    extractedLabels {
      ...ExtractedLabel
    }
  }
  ${ExtractedLabelFragmentDoc}
`;

/**
 * __useExtractedLabelsQuery__
 *
 * To run a query within a React component, call `useExtractedLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtractedLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtractedLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExtractedLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<ExtractedLabelsQuery, ExtractedLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExtractedLabelsQuery, ExtractedLabelsQueryVariables>(ExtractedLabelsDocument, options);
}
export function useExtractedLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExtractedLabelsQuery, ExtractedLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExtractedLabelsQuery, ExtractedLabelsQueryVariables>(ExtractedLabelsDocument, options);
}
export type ExtractedLabelsQueryHookResult = ReturnType<typeof useExtractedLabelsQuery>;
export type ExtractedLabelsLazyQueryHookResult = ReturnType<typeof useExtractedLabelsLazyQuery>;
export type ExtractedLabelsQueryResult = Apollo.QueryResult<ExtractedLabelsQuery, ExtractedLabelsQueryVariables>;
export const FileJobsDocument = gql`
  query FileJobs($input: FindJobsInput!) {
    fileJobs(input: $input) {
      id
      status
      pipeline
      createdAt
    }
  }
`;

/**
 * __useFileJobsQuery__
 *
 * To run a query within a React component, call `useFileJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileJobsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFileJobsQuery(baseOptions: Apollo.QueryHookOptions<FileJobsQuery, FileJobsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileJobsQuery, FileJobsQueryVariables>(FileJobsDocument, options);
}
export function useFileJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileJobsQuery, FileJobsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileJobsQuery, FileJobsQueryVariables>(FileJobsDocument, options);
}
export type FileJobsQueryHookResult = ReturnType<typeof useFileJobsQuery>;
export type FileJobsLazyQueryHookResult = ReturnType<typeof useFileJobsLazyQuery>;
export type FileJobsQueryResult = Apollo.QueryResult<FileJobsQuery, FileJobsQueryVariables>;
export const FileProcessingPipelineDocument = gql`
  query FileProcessingPipeline {
    fileProcessingPipeline {
      ...FilePipelineVersion
    }
  }
  ${FilePipelineVersionFragmentDoc}
`;

/**
 * __useFileProcessingPipelineQuery__
 *
 * To run a query within a React component, call `useFileProcessingPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileProcessingPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileProcessingPipelineQuery({
 *   variables: {
 *   },
 * });
 */
export function useFileProcessingPipelineQuery(
  baseOptions?: Apollo.QueryHookOptions<FileProcessingPipelineQuery, FileProcessingPipelineQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileProcessingPipelineQuery, FileProcessingPipelineQueryVariables>(
    FileProcessingPipelineDocument,
    options
  );
}
export function useFileProcessingPipelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileProcessingPipelineQuery, FileProcessingPipelineQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileProcessingPipelineQuery, FileProcessingPipelineQueryVariables>(
    FileProcessingPipelineDocument,
    options
  );
}
export type FileProcessingPipelineQueryHookResult = ReturnType<typeof useFileProcessingPipelineQuery>;
export type FileProcessingPipelineLazyQueryHookResult = ReturnType<typeof useFileProcessingPipelineLazyQuery>;
export type FileProcessingPipelineQueryResult = Apollo.QueryResult<
  FileProcessingPipelineQuery,
  FileProcessingPipelineQueryVariables
>;
export const FileProcessingPipelineIssuesDocument = gql`
  query FileProcessingPipelineIssues {
    pipelineIssues {
      nodeId
      nodeType
      type
    }
  }
`;

/**
 * __useFileProcessingPipelineIssuesQuery__
 *
 * To run a query within a React component, call `useFileProcessingPipelineIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileProcessingPipelineIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileProcessingPipelineIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFileProcessingPipelineIssuesQuery(
  baseOptions?: Apollo.QueryHookOptions<FileProcessingPipelineIssuesQuery, FileProcessingPipelineIssuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileProcessingPipelineIssuesQuery, FileProcessingPipelineIssuesQueryVariables>(
    FileProcessingPipelineIssuesDocument,
    options
  );
}
export function useFileProcessingPipelineIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FileProcessingPipelineIssuesQuery,
    FileProcessingPipelineIssuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileProcessingPipelineIssuesQuery, FileProcessingPipelineIssuesQueryVariables>(
    FileProcessingPipelineIssuesDocument,
    options
  );
}
export type FileProcessingPipelineIssuesQueryHookResult = ReturnType<typeof useFileProcessingPipelineIssuesQuery>;
export type FileProcessingPipelineIssuesLazyQueryHookResult = ReturnType<
  typeof useFileProcessingPipelineIssuesLazyQuery
>;
export type FileProcessingPipelineIssuesQueryResult = Apollo.QueryResult<
  FileProcessingPipelineIssuesQuery,
  FileProcessingPipelineIssuesQueryVariables
>;
export const FileProcessorDocument = gql`
  query FileProcessor($id: ID!) {
    fileProcessor(id: $id) {
      id
      type
      category
      name
      processorId
      totalProcessedFiles
      startPage
      endPage
      defaultProcessorVersion
      processorVersions {
        id
        displayName
        state
        f1Score
        createdAt
      }
      files {
        ...FileUpload
        labels
        insuredId
        createdAt
        deletedAt
      }
      issues {
        nodeId
        nodeType
        type
      }
    }
  }
  ${FileUploadFragmentDoc}
`;

/**
 * __useFileProcessorQuery__
 *
 * To run a query within a React component, call `useFileProcessorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileProcessorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileProcessorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileProcessorQuery(
  baseOptions: Apollo.QueryHookOptions<FileProcessorQuery, FileProcessorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileProcessorQuery, FileProcessorQueryVariables>(FileProcessorDocument, options);
}
export function useFileProcessorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileProcessorQuery, FileProcessorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileProcessorQuery, FileProcessorQueryVariables>(FileProcessorDocument, options);
}
export type FileProcessorQueryHookResult = ReturnType<typeof useFileProcessorQuery>;
export type FileProcessorLazyQueryHookResult = ReturnType<typeof useFileProcessorLazyQuery>;
export type FileProcessorQueryResult = Apollo.QueryResult<FileProcessorQuery, FileProcessorQueryVariables>;
export const FileTagsDocument = gql`
  query FileTags($input: FindTagsInput!) {
    fileTags(input: $input) {
      label
      parentTags {
        label
      }
      childTags {
        label
      }
    }
  }
`;

/**
 * __useFileTagsQuery__
 *
 * To run a query within a React component, call `useFileTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFileTagsQuery(baseOptions: Apollo.QueryHookOptions<FileTagsQuery, FileTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileTagsQuery, FileTagsQueryVariables>(FileTagsDocument, options);
}
export function useFileTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileTagsQuery, FileTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileTagsQuery, FileTagsQueryVariables>(FileTagsDocument, options);
}
export type FileTagsQueryHookResult = ReturnType<typeof useFileTagsQuery>;
export type FileTagsLazyQueryHookResult = ReturnType<typeof useFileTagsLazyQuery>;
export type FileTagsQueryResult = Apollo.QueryResult<FileTagsQuery, FileTagsQueryVariables>;
export const FilePathsDocument = gql`
  query FilePaths {
    paths
  }
`;

/**
 * __useFilePathsQuery__
 *
 * To run a query within a React component, call `useFilePathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilePathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilePathsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFilePathsQuery(baseOptions?: Apollo.QueryHookOptions<FilePathsQuery, FilePathsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilePathsQuery, FilePathsQueryVariables>(FilePathsDocument, options);
}
export function useFilePathsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilePathsQuery, FilePathsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilePathsQuery, FilePathsQueryVariables>(FilePathsDocument, options);
}
export type FilePathsQueryHookResult = ReturnType<typeof useFilePathsQuery>;
export type FilePathsLazyQueryHookResult = ReturnType<typeof useFilePathsLazyQuery>;
export type FilePathsQueryResult = Apollo.QueryResult<FilePathsQuery, FilePathsQueryVariables>;
export const FilesDocument = gql`
  query Files($input: FindManyFileUploadInput!) {
    files(input: $input) {
      ...FileUpload
    }
  }
  ${FileUploadFragmentDoc}
`;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilesQuery(baseOptions: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
}
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
}
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const FilesByLabelDocument = gql`
  query FilesByLabel($input: FindFilesByLabelInput!) {
    filesByLabel(input: $input) {
      ...FileUpload
      deletedAt
      uploader {
        firstName
        lastName
      }
      createdAt
      labels
    }
  }
  ${FileUploadFragmentDoc}
`;

/**
 * __useFilesByLabelQuery__
 *
 * To run a query within a React component, call `useFilesByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesByLabelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilesByLabelQuery(
  baseOptions: Apollo.QueryHookOptions<FilesByLabelQuery, FilesByLabelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilesByLabelQuery, FilesByLabelQueryVariables>(FilesByLabelDocument, options);
}
export function useFilesByLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilesByLabelQuery, FilesByLabelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilesByLabelQuery, FilesByLabelQueryVariables>(FilesByLabelDocument, options);
}
export type FilesByLabelQueryHookResult = ReturnType<typeof useFilesByLabelQuery>;
export type FilesByLabelLazyQueryHookResult = ReturnType<typeof useFilesByLabelLazyQuery>;
export type FilesByLabelQueryResult = Apollo.QueryResult<FilesByLabelQuery, FilesByLabelQueryVariables>;
export const FindFileUploadDocument = gql`
  query FindFileUpload($input: FindFileInput!) {
    findFile(input: $input) {
      ...FileUpload
      labels
      audience
    }
  }
  ${FileUploadFragmentDoc}
`;

/**
 * __useFindFileUploadQuery__
 *
 * To run a query within a React component, call `useFindFileUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFileUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFileUploadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindFileUploadQuery(
  baseOptions: Apollo.QueryHookOptions<FindFileUploadQuery, FindFileUploadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindFileUploadQuery, FindFileUploadQueryVariables>(FindFileUploadDocument, options);
}
export function useFindFileUploadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindFileUploadQuery, FindFileUploadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindFileUploadQuery, FindFileUploadQueryVariables>(FindFileUploadDocument, options);
}
export type FindFileUploadQueryHookResult = ReturnType<typeof useFindFileUploadQuery>;
export type FindFileUploadLazyQueryHookResult = ReturnType<typeof useFindFileUploadLazyQuery>;
export type FindFileUploadQueryResult = Apollo.QueryResult<FindFileUploadQuery, FindFileUploadQueryVariables>;
export const FindProductExclusionsDocument = gql`
  query FindProductExclusions($where: ProductExclusionWhere) {
    productExclusionsFind(where: $where) {
      id
      reason
      state
      naicsCode
      appetiteProduct {
        id
        carrierName
        carrierProductName
      }
      submission {
        id
        opportunityId
      }
    }
  }
`;

/**
 * __useFindProductExclusionsQuery__
 *
 * To run a query within a React component, call `useFindProductExclusionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductExclusionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductExclusionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindProductExclusionsQuery(
  baseOptions?: Apollo.QueryHookOptions<FindProductExclusionsQuery, FindProductExclusionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindProductExclusionsQuery, FindProductExclusionsQueryVariables>(
    FindProductExclusionsDocument,
    options
  );
}
export function useFindProductExclusionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindProductExclusionsQuery, FindProductExclusionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindProductExclusionsQuery, FindProductExclusionsQueryVariables>(
    FindProductExclusionsDocument,
    options
  );
}
export type FindProductExclusionsQueryHookResult = ReturnType<typeof useFindProductExclusionsQuery>;
export type FindProductExclusionsLazyQueryHookResult = ReturnType<typeof useFindProductExclusionsLazyQuery>;
export type FindProductExclusionsQueryResult = Apollo.QueryResult<
  FindProductExclusionsQuery,
  FindProductExclusionsQueryVariables
>;
export const GroupedMarketingPlansDocument = gql`
  query GroupedMarketingPlans($input: GroupedMarketingPlansInput!) {
    groupedMarketingPlans(input: $input) {
      groupedMarketingPlans {
        insuredId
        insuredName
        insured {
          id
          name
          createdAt
          agent {
            firstName
            lastName
            agency {
              name
            }
          }
        }
        marketingPlans {
          id
          state
          desiredEffectiveDate
          lastTransitionAt
          selectedLinesOfBusiness
          broker {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __useGroupedMarketingPlansQuery__
 *
 * To run a query within a React component, call `useGroupedMarketingPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedMarketingPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedMarketingPlansQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupedMarketingPlansQuery(
  baseOptions: Apollo.QueryHookOptions<GroupedMarketingPlansQuery, GroupedMarketingPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupedMarketingPlansQuery, GroupedMarketingPlansQueryVariables>(
    GroupedMarketingPlansDocument,
    options
  );
}
export function useGroupedMarketingPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupedMarketingPlansQuery, GroupedMarketingPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupedMarketingPlansQuery, GroupedMarketingPlansQueryVariables>(
    GroupedMarketingPlansDocument,
    options
  );
}
export type GroupedMarketingPlansQueryHookResult = ReturnType<typeof useGroupedMarketingPlansQuery>;
export type GroupedMarketingPlansLazyQueryHookResult = ReturnType<typeof useGroupedMarketingPlansLazyQuery>;
export type GroupedMarketingPlansQueryResult = Apollo.QueryResult<
  GroupedMarketingPlansQuery,
  GroupedMarketingPlansQueryVariables
>;
export const GroupedQuotesDocument = gql`
  query GroupedQuotes($input: GroupedQuotesInput!) {
    groupedQuotes(input: $input) {
      groupedQuotes {
        insuredId
        insuredName
        quotes {
          id
          state
          premium
          effectiveDate
          lastTransitionAt
          appetiteProduct {
            id
            carrierName
            carrierProductName
          }
        }
      }
    }
  }
`;

/**
 * __useGroupedQuotesQuery__
 *
 * To run a query within a React component, call `useGroupedQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedQuotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupedQuotesQuery(
  baseOptions: Apollo.QueryHookOptions<GroupedQuotesQuery, GroupedQuotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupedQuotesQuery, GroupedQuotesQueryVariables>(GroupedQuotesDocument, options);
}
export function useGroupedQuotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupedQuotesQuery, GroupedQuotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupedQuotesQuery, GroupedQuotesQueryVariables>(GroupedQuotesDocument, options);
}
export type GroupedQuotesQueryHookResult = ReturnType<typeof useGroupedQuotesQuery>;
export type GroupedQuotesLazyQueryHookResult = ReturnType<typeof useGroupedQuotesLazyQuery>;
export type GroupedQuotesQueryResult = Apollo.QueryResult<GroupedQuotesQuery, GroupedQuotesQueryVariables>;
export const InsuredDocument = gql`
  query Insured($id: ID!) {
    insured(id: $id) {
      ...Insured
    }
  }
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AgencyFragmentDoc}
`;

/**
 * __useInsuredQuery__
 *
 * To run a query within a React component, call `useInsuredQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuredQuery(baseOptions: Apollo.QueryHookOptions<InsuredQuery, InsuredQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredQuery, InsuredQueryVariables>(InsuredDocument, options);
}
export function useInsuredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuredQuery, InsuredQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredQuery, InsuredQueryVariables>(InsuredDocument, options);
}
export type InsuredQueryHookResult = ReturnType<typeof useInsuredQuery>;
export type InsuredLazyQueryHookResult = ReturnType<typeof useInsuredLazyQuery>;
export type InsuredQueryResult = Apollo.QueryResult<InsuredQuery, InsuredQueryVariables>;
export const InsuredFileTagsDocument = gql`
  query InsuredFileTags($input: FindTagsInput!, $insuredId: ID!) {
    fileTags(input: $input) {
      label
      hasFile(insuredId: $insuredId)
      parentTags {
        label
      }
      childTags {
        label
      }
    }
  }
`;

/**
 * __useInsuredFileTagsQuery__
 *
 * To run a query within a React component, call `useInsuredFileTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredFileTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredFileTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      insuredId: // value for 'insuredId'
 *   },
 * });
 */
export function useInsuredFileTagsQuery(
  baseOptions: Apollo.QueryHookOptions<InsuredFileTagsQuery, InsuredFileTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredFileTagsQuery, InsuredFileTagsQueryVariables>(InsuredFileTagsDocument, options);
}
export function useInsuredFileTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsuredFileTagsQuery, InsuredFileTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredFileTagsQuery, InsuredFileTagsQueryVariables>(InsuredFileTagsDocument, options);
}
export type InsuredFileTagsQueryHookResult = ReturnType<typeof useInsuredFileTagsQuery>;
export type InsuredFileTagsLazyQueryHookResult = ReturnType<typeof useInsuredFileTagsLazyQuery>;
export type InsuredFileTagsQueryResult = Apollo.QueryResult<InsuredFileTagsQuery, InsuredFileTagsQueryVariables>;
export const InsuredHazardsDocument = gql`
  query InsuredHazards($id: ID!) {
    hazards(id: $id) {
      ...InsuredHazard
    }
  }
  ${InsuredHazardFragmentDoc}
`;

/**
 * __useInsuredHazardsQuery__
 *
 * To run a query within a React component, call `useInsuredHazardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredHazardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredHazardsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuredHazardsQuery(
  baseOptions: Apollo.QueryHookOptions<InsuredHazardsQuery, InsuredHazardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredHazardsQuery, InsuredHazardsQueryVariables>(InsuredHazardsDocument, options);
}
export function useInsuredHazardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsuredHazardsQuery, InsuredHazardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredHazardsQuery, InsuredHazardsQueryVariables>(InsuredHazardsDocument, options);
}
export type InsuredHazardsQueryHookResult = ReturnType<typeof useInsuredHazardsQuery>;
export type InsuredHazardsLazyQueryHookResult = ReturnType<typeof useInsuredHazardsLazyQuery>;
export type InsuredHazardsQueryResult = Apollo.QueryResult<InsuredHazardsQuery, InsuredHazardsQueryVariables>;
export const InsuredOpportunitiesDocument = gql`
  query InsuredOpportunities($id: ID!) {
    insuredOpportunities(id: $id) {
      ...AppetiteOpportunity
    }
  }
  ${AppetiteOpportunityFragmentDoc}
`;

/**
 * __useInsuredOpportunitiesQuery__
 *
 * To run a query within a React component, call `useInsuredOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredOpportunitiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuredOpportunitiesQuery(
  baseOptions: Apollo.QueryHookOptions<InsuredOpportunitiesQuery, InsuredOpportunitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredOpportunitiesQuery, InsuredOpportunitiesQueryVariables>(
    InsuredOpportunitiesDocument,
    options
  );
}
export function useInsuredOpportunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsuredOpportunitiesQuery, InsuredOpportunitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredOpportunitiesQuery, InsuredOpportunitiesQueryVariables>(
    InsuredOpportunitiesDocument,
    options
  );
}
export type InsuredOpportunitiesQueryHookResult = ReturnType<typeof useInsuredOpportunitiesQuery>;
export type InsuredOpportunitiesLazyQueryHookResult = ReturnType<typeof useInsuredOpportunitiesLazyQuery>;
export type InsuredOpportunitiesQueryResult = Apollo.QueryResult<
  InsuredOpportunitiesQuery,
  InsuredOpportunitiesQueryVariables
>;
export const InsuredPoliciesDocument = gql`
  query InsuredPolicies($id: ID!) {
    insured(id: $id) {
      id
      policies {
        id
        state
        policyNumber
        awaitingSubjectivities
        endorsementsRequested
        policyFile {
          ...FileUpload
        }
        redactedBinderFile {
          ...FileUpload
        }
        processedBinderFile {
          ...FileUpload
        }
        createdAt
      }
    }
  }
  ${FileUploadFragmentDoc}
`;

/**
 * __useInsuredPoliciesQuery__
 *
 * To run a query within a React component, call `useInsuredPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredPoliciesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuredPoliciesQuery(
  baseOptions: Apollo.QueryHookOptions<InsuredPoliciesQuery, InsuredPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredPoliciesQuery, InsuredPoliciesQueryVariables>(InsuredPoliciesDocument, options);
}
export function useInsuredPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsuredPoliciesQuery, InsuredPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredPoliciesQuery, InsuredPoliciesQueryVariables>(InsuredPoliciesDocument, options);
}
export type InsuredPoliciesQueryHookResult = ReturnType<typeof useInsuredPoliciesQuery>;
export type InsuredPoliciesLazyQueryHookResult = ReturnType<typeof useInsuredPoliciesLazyQuery>;
export type InsuredPoliciesQueryResult = Apollo.QueryResult<InsuredPoliciesQuery, InsuredPoliciesQueryVariables>;
export const InsuredPremiseLocationsDocument = gql`
  query InsuredPremiseLocations($id: ID!) {
    locations(id: $id) {
      ...InsuredPremiseLocation
    }
  }
  ${InsuredPremiseLocationFragmentDoc}
  ${InsuredPremiseBuildingFragmentDoc}
`;

/**
 * __useInsuredPremiseLocationsQuery__
 *
 * To run a query within a React component, call `useInsuredPremiseLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredPremiseLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredPremiseLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuredPremiseLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<InsuredPremiseLocationsQuery, InsuredPremiseLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredPremiseLocationsQuery, InsuredPremiseLocationsQueryVariables>(
    InsuredPremiseLocationsDocument,
    options
  );
}
export function useInsuredPremiseLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsuredPremiseLocationsQuery, InsuredPremiseLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredPremiseLocationsQuery, InsuredPremiseLocationsQueryVariables>(
    InsuredPremiseLocationsDocument,
    options
  );
}
export type InsuredPremiseLocationsQueryHookResult = ReturnType<typeof useInsuredPremiseLocationsQuery>;
export type InsuredPremiseLocationsLazyQueryHookResult = ReturnType<typeof useInsuredPremiseLocationsLazyQuery>;
export type InsuredPremiseLocationsQueryResult = Apollo.QueryResult<
  InsuredPremiseLocationsQuery,
  InsuredPremiseLocationsQueryVariables
>;
export const InsuredSubmissionsDocument = gql`
  query InsuredSubmissions($id: ID!) {
    insured(id: $id) {
      id
      submissions {
        id
        state
        appetiteProduct {
          carrierName
          carrierProductName
          submissionCarrierContacts {
            email
          }
        }
        quotes {
          ...Quote
        }
      }
    }
  }
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
`;

/**
 * __useInsuredSubmissionsQuery__
 *
 * To run a query within a React component, call `useInsuredSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredSubmissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuredSubmissionsQuery(
  baseOptions: Apollo.QueryHookOptions<InsuredSubmissionsQuery, InsuredSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredSubmissionsQuery, InsuredSubmissionsQueryVariables>(
    InsuredSubmissionsDocument,
    options
  );
}
export function useInsuredSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsuredSubmissionsQuery, InsuredSubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredSubmissionsQuery, InsuredSubmissionsQueryVariables>(
    InsuredSubmissionsDocument,
    options
  );
}
export type InsuredSubmissionsQueryHookResult = ReturnType<typeof useInsuredSubmissionsQuery>;
export type InsuredSubmissionsLazyQueryHookResult = ReturnType<typeof useInsuredSubmissionsLazyQuery>;
export type InsuredSubmissionsQueryResult = Apollo.QueryResult<
  InsuredSubmissionsQuery,
  InsuredSubmissionsQueryVariables
>;
export const InsuredWithDuplicateFlagDocument = gql`
  query InsuredWithDuplicateFlag($id: ID!) {
    insured(id: $id) {
      ...Insured
      possibleDuplicates {
        id
        createdAt
        name
      }
    }
  }
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AgencyFragmentDoc}
`;

/**
 * __useInsuredWithDuplicateFlagQuery__
 *
 * To run a query within a React component, call `useInsuredWithDuplicateFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredWithDuplicateFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredWithDuplicateFlagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuredWithDuplicateFlagQuery(
  baseOptions: Apollo.QueryHookOptions<InsuredWithDuplicateFlagQuery, InsuredWithDuplicateFlagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredWithDuplicateFlagQuery, InsuredWithDuplicateFlagQueryVariables>(
    InsuredWithDuplicateFlagDocument,
    options
  );
}
export function useInsuredWithDuplicateFlagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsuredWithDuplicateFlagQuery, InsuredWithDuplicateFlagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredWithDuplicateFlagQuery, InsuredWithDuplicateFlagQueryVariables>(
    InsuredWithDuplicateFlagDocument,
    options
  );
}
export type InsuredWithDuplicateFlagQueryHookResult = ReturnType<typeof useInsuredWithDuplicateFlagQuery>;
export type InsuredWithDuplicateFlagLazyQueryHookResult = ReturnType<typeof useInsuredWithDuplicateFlagLazyQuery>;
export type InsuredWithDuplicateFlagQueryResult = Apollo.QueryResult<
  InsuredWithDuplicateFlagQuery,
  InsuredWithDuplicateFlagQueryVariables
>;
export const InsuredsDocument = gql`
  query Insureds($input: InsuredFilterInput!) {
    insureds(input: $input) {
      ...Insured
    }
  }
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AgencyFragmentDoc}
`;

/**
 * __useInsuredsQuery__
 *
 * To run a query within a React component, call `useInsuredsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsuredsQuery(baseOptions: Apollo.QueryHookOptions<InsuredsQuery, InsuredsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredsQuery, InsuredsQueryVariables>(InsuredsDocument, options);
}
export function useInsuredsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuredsQuery, InsuredsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredsQuery, InsuredsQueryVariables>(InsuredsDocument, options);
}
export type InsuredsQueryHookResult = ReturnType<typeof useInsuredsQuery>;
export type InsuredsLazyQueryHookResult = ReturnType<typeof useInsuredsLazyQuery>;
export type InsuredsQueryResult = Apollo.QueryResult<InsuredsQuery, InsuredsQueryVariables>;
export const InsuredsDeepDocument = gql`
  query InsuredsDeep($input: InsuredFilterInput!) {
    insureds(input: $input) {
      id
      name
      agent {
        firstName
        lastName
        agency {
          name
        }
      }
      opportunities {
        id
        deletedAt
        desiredEffectiveDate
        selectedLinesOfBusiness
        state
      }
    }
  }
`;

/**
 * __useInsuredsDeepQuery__
 *
 * To run a query within a React component, call `useInsuredsDeepQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuredsDeepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuredsDeepQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsuredsDeepQuery(
  baseOptions: Apollo.QueryHookOptions<InsuredsDeepQuery, InsuredsDeepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsuredsDeepQuery, InsuredsDeepQueryVariables>(InsuredsDeepDocument, options);
}
export function useInsuredsDeepLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsuredsDeepQuery, InsuredsDeepQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsuredsDeepQuery, InsuredsDeepQueryVariables>(InsuredsDeepDocument, options);
}
export type InsuredsDeepQueryHookResult = ReturnType<typeof useInsuredsDeepQuery>;
export type InsuredsDeepLazyQueryHookResult = ReturnType<typeof useInsuredsDeepLazyQuery>;
export type InsuredsDeepQueryResult = Apollo.QueryResult<InsuredsDeepQuery, InsuredsDeepQueryVariables>;
export const LeadAgentNamesDocument = gql`
  query LeadAgentNames($agencyId: String) {
    leadAgentNames(agencyId: $agencyId) {
      firstName
      lastName
    }
  }
`;

/**
 * __useLeadAgentNamesQuery__
 *
 * To run a query within a React component, call `useLeadAgentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadAgentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadAgentNamesQuery({
 *   variables: {
 *      agencyId: // value for 'agencyId'
 *   },
 * });
 */
export function useLeadAgentNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<LeadAgentNamesQuery, LeadAgentNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeadAgentNamesQuery, LeadAgentNamesQueryVariables>(LeadAgentNamesDocument, options);
}
export function useLeadAgentNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeadAgentNamesQuery, LeadAgentNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeadAgentNamesQuery, LeadAgentNamesQueryVariables>(LeadAgentNamesDocument, options);
}
export type LeadAgentNamesQueryHookResult = ReturnType<typeof useLeadAgentNamesQuery>;
export type LeadAgentNamesLazyQueryHookResult = ReturnType<typeof useLeadAgentNamesLazyQuery>;
export type LeadAgentNamesQueryResult = Apollo.QueryResult<LeadAgentNamesQuery, LeadAgentNamesQueryVariables>;
export const LeadsDocument = gql`
  query Leads($input: LeadsFilterInput!) {
    leads(input: $input) {
      ...Lead
    }
  }
  ${LeadFragmentDoc}
`;

/**
 * __useLeadsQuery__
 *
 * To run a query within a React component, call `useLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeadsQuery(baseOptions: Apollo.QueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
}
export function useLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
}
export type LeadsQueryHookResult = ReturnType<typeof useLeadsQuery>;
export type LeadsLazyQueryHookResult = ReturnType<typeof useLeadsLazyQuery>;
export type LeadsQueryResult = Apollo.QueryResult<LeadsQuery, LeadsQueryVariables>;
export const LinesOfBusinessDocument = gql`
  query LinesOfBusiness {
    linesOfBusiness {
      ...LineOfBusiness
    }
  }
  ${LineOfBusinessFragmentDoc}
`;

/**
 * __useLinesOfBusinessQuery__
 *
 * To run a query within a React component, call `useLinesOfBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinesOfBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinesOfBusinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinesOfBusinessQuery(
  baseOptions?: Apollo.QueryHookOptions<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>(LinesOfBusinessDocument, options);
}
export function useLinesOfBusinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>(LinesOfBusinessDocument, options);
}
export type LinesOfBusinessQueryHookResult = ReturnType<typeof useLinesOfBusinessQuery>;
export type LinesOfBusinessLazyQueryHookResult = ReturnType<typeof useLinesOfBusinessLazyQuery>;
export type LinesOfBusinessQueryResult = Apollo.QueryResult<LinesOfBusinessQuery, LinesOfBusinessQueryVariables>;
export const MyAccountDocument = gql`
  query MyAccount {
    myAccount {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useMyAccountQuery__
 *
 * To run a query within a React component, call `useMyAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAccountQuery(baseOptions?: Apollo.QueryHookOptions<MyAccountQuery, MyAccountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyAccountQuery, MyAccountQueryVariables>(MyAccountDocument, options);
}
export function useMyAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyAccountQuery, MyAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyAccountQuery, MyAccountQueryVariables>(MyAccountDocument, options);
}
export type MyAccountQueryHookResult = ReturnType<typeof useMyAccountQuery>;
export type MyAccountLazyQueryHookResult = ReturnType<typeof useMyAccountLazyQuery>;
export type MyAccountQueryResult = Apollo.QueryResult<MyAccountQuery, MyAccountQueryVariables>;
export const OpportunityDocument = gql`
  query Opportunity($id: ID!) {
    opportunity(id: $id) {
      ...AppetiteOpportunity
    }
  }
  ${AppetiteOpportunityFragmentDoc}
`;

/**
 * __useOpportunityQuery__
 *
 * To run a query within a React component, call `useOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpportunityQuery(baseOptions: Apollo.QueryHookOptions<OpportunityQuery, OpportunityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpportunityQuery, OpportunityQueryVariables>(OpportunityDocument, options);
}
export function useOpportunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpportunityQuery, OpportunityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpportunityQuery, OpportunityQueryVariables>(OpportunityDocument, options);
}
export type OpportunityQueryHookResult = ReturnType<typeof useOpportunityQuery>;
export type OpportunityLazyQueryHookResult = ReturnType<typeof useOpportunityLazyQuery>;
export type OpportunityQueryResult = Apollo.QueryResult<OpportunityQuery, OpportunityQueryVariables>;
export const OpportunityDetailsDocument = gql`
  query OpportunityDetails($id: ID!) {
    opportunity(id: $id) {
      ...OpportunityDetails
    }
  }
  ${OpportunityDetailsFragmentDoc}
  ${UserAccountFragmentDoc}
  ${SubmissionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
  ${SubmissionStateTransitionFragmentDoc}
  ${QuoteSubmissionFragmentDoc}
  ${ProductExclusionFragmentDoc}
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${AgencyFragmentDoc}
  ${LeadFragmentDoc}
  ${MarketingPlanStateTransitionFragmentDoc}
`;

/**
 * __useOpportunityDetailsQuery__
 *
 * To run a query within a React component, call `useOpportunityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpportunityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<OpportunityDetailsQuery, OpportunityDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpportunityDetailsQuery, OpportunityDetailsQueryVariables>(
    OpportunityDetailsDocument,
    options
  );
}
export function useOpportunityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpportunityDetailsQuery, OpportunityDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpportunityDetailsQuery, OpportunityDetailsQueryVariables>(
    OpportunityDetailsDocument,
    options
  );
}
export type OpportunityDetailsQueryHookResult = ReturnType<typeof useOpportunityDetailsQuery>;
export type OpportunityDetailsLazyQueryHookResult = ReturnType<typeof useOpportunityDetailsLazyQuery>;
export type OpportunityDetailsQueryResult = Apollo.QueryResult<
  OpportunityDetailsQuery,
  OpportunityDetailsQueryVariables
>;
export const OpportunityEventDocument = gql`
  query OpportunityEvent($id: ID!) {
    opportunityEvent(id: $id) {
      ...OpportunityEvent
    }
  }
  ${OpportunityEventFragmentDoc}
  ${UserCommentFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AssignmentChangeEventFragmentDoc}
  ${TriageAssignmentEventFragmentDoc}
  ${EmailEventFragmentDoc}
  ${GmailMessageEventFragmentDoc}
  ${GmailMessageFragmentDoc}
  ${FileUploadFragmentDoc}
  ${GmailRelayEventFragmentDoc}
  ${FileUploadEventFragmentDoc}
  ${StatusChangeEventFragmentDoc}
  ${SubmissionStatusChangeEventFragmentDoc}
  ${AlbyUpdateEventFragmentDoc}
  ${CreatedEventFragmentDoc}
  ${OutcomeChangeEventFragmentDoc}
  ${DesiredEffectiveDateChangeEventFragmentDoc}
  ${ApplicationSubmittedEventFragmentDoc}
  ${ProductsAddedEventFragmentDoc}
  ${ProductsRemovedEventFragmentDoc}
  ${BotCommentEventFragmentDoc}
  ${AutomatedFollowUpEventFragmentDoc}
  ${ActionRequestedEventFragmentDoc}
  ${ActionApprovedEventFragmentDoc}
  ${ActionRejectedEventFragmentDoc}
  ${ActionDismissedEventFragmentDoc}
  ${CoveragesChangedEventFragmentDoc}
  ${BusinessClassCodesChangedEventFragmentDoc}
  ${RequirementAddedEventFragmentDoc}
`;

/**
 * __useOpportunityEventQuery__
 *
 * To run a query within a React component, call `useOpportunityEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpportunityEventQuery(
  baseOptions: Apollo.QueryHookOptions<OpportunityEventQuery, OpportunityEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpportunityEventQuery, OpportunityEventQueryVariables>(OpportunityEventDocument, options);
}
export function useOpportunityEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpportunityEventQuery, OpportunityEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpportunityEventQuery, OpportunityEventQueryVariables>(OpportunityEventDocument, options);
}
export type OpportunityEventQueryHookResult = ReturnType<typeof useOpportunityEventQuery>;
export type OpportunityEventLazyQueryHookResult = ReturnType<typeof useOpportunityEventLazyQuery>;
export type OpportunityEventQueryResult = Apollo.QueryResult<OpportunityEventQuery, OpportunityEventQueryVariables>;
export const OpportunityEventsDocument = gql`
  query OpportunityEvents($input: OpportunityEventsFilterInput!) {
    opportunityEvents(input: $input) {
      ...OpportunityEvent
    }
  }
  ${OpportunityEventFragmentDoc}
  ${UserCommentFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AssignmentChangeEventFragmentDoc}
  ${TriageAssignmentEventFragmentDoc}
  ${EmailEventFragmentDoc}
  ${GmailMessageEventFragmentDoc}
  ${GmailMessageFragmentDoc}
  ${FileUploadFragmentDoc}
  ${GmailRelayEventFragmentDoc}
  ${FileUploadEventFragmentDoc}
  ${StatusChangeEventFragmentDoc}
  ${SubmissionStatusChangeEventFragmentDoc}
  ${AlbyUpdateEventFragmentDoc}
  ${CreatedEventFragmentDoc}
  ${OutcomeChangeEventFragmentDoc}
  ${DesiredEffectiveDateChangeEventFragmentDoc}
  ${ApplicationSubmittedEventFragmentDoc}
  ${ProductsAddedEventFragmentDoc}
  ${ProductsRemovedEventFragmentDoc}
  ${BotCommentEventFragmentDoc}
  ${AutomatedFollowUpEventFragmentDoc}
  ${ActionRequestedEventFragmentDoc}
  ${ActionApprovedEventFragmentDoc}
  ${ActionRejectedEventFragmentDoc}
  ${ActionDismissedEventFragmentDoc}
  ${CoveragesChangedEventFragmentDoc}
  ${BusinessClassCodesChangedEventFragmentDoc}
  ${RequirementAddedEventFragmentDoc}
`;

/**
 * __useOpportunityEventsQuery__
 *
 * To run a query within a React component, call `useOpportunityEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpportunityEventsQuery(
  baseOptions: Apollo.QueryHookOptions<OpportunityEventsQuery, OpportunityEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpportunityEventsQuery, OpportunityEventsQueryVariables>(OpportunityEventsDocument, options);
}
export function useOpportunityEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpportunityEventsQuery, OpportunityEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpportunityEventsQuery, OpportunityEventsQueryVariables>(
    OpportunityEventsDocument,
    options
  );
}
export type OpportunityEventsQueryHookResult = ReturnType<typeof useOpportunityEventsQuery>;
export type OpportunityEventsLazyQueryHookResult = ReturnType<typeof useOpportunityEventsLazyQuery>;
export type OpportunityEventsQueryResult = Apollo.QueryResult<OpportunityEventsQuery, OpportunityEventsQueryVariables>;
export const OpportunityRequirementDocument = gql`
  query OpportunityRequirement($id: ID!) {
    agentAction(id: $id) {
      id
      title
      description
      actionType
      supplementalForm
      complete
      expeditedRequirement
    }
  }
`;

/**
 * __useOpportunityRequirementQuery__
 *
 * To run a query within a React component, call `useOpportunityRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityRequirementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpportunityRequirementQuery(
  baseOptions: Apollo.QueryHookOptions<OpportunityRequirementQuery, OpportunityRequirementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpportunityRequirementQuery, OpportunityRequirementQueryVariables>(
    OpportunityRequirementDocument,
    options
  );
}
export function useOpportunityRequirementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpportunityRequirementQuery, OpportunityRequirementQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpportunityRequirementQuery, OpportunityRequirementQueryVariables>(
    OpportunityRequirementDocument,
    options
  );
}
export type OpportunityRequirementQueryHookResult = ReturnType<typeof useOpportunityRequirementQuery>;
export type OpportunityRequirementLazyQueryHookResult = ReturnType<typeof useOpportunityRequirementLazyQuery>;
export type OpportunityRequirementQueryResult = Apollo.QueryResult<
  OpportunityRequirementQuery,
  OpportunityRequirementQueryVariables
>;
export const OpportunityRequirementsDocument = gql`
  query OpportunityRequirements($id: ID!) {
    opportunity(id: $id) {
      id
      state
      insured {
        id
        name
        agent {
          firstName
        }
      }
      filesProcessing
      agentActions {
        id
        title
        description
        actionType
        supplementalForm
        complete
        expeditedRequirement
        ui {
          title
          description
          steps {
            title
            description
            elements {
              element
              clientDataKey
              array
              props
            }
          }
        }
        logs {
          id
          response
          file {
            ...FileUpload
            deletedAt
            uploader {
              firstName
              lastName
            }
            createdAt
            labels
          }
        }
      }
    }
  }
  ${FileUploadFragmentDoc}
`;

/**
 * __useOpportunityRequirementsQuery__
 *
 * To run a query within a React component, call `useOpportunityRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityRequirementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpportunityRequirementsQuery(
  baseOptions: Apollo.QueryHookOptions<OpportunityRequirementsQuery, OpportunityRequirementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpportunityRequirementsQuery, OpportunityRequirementsQueryVariables>(
    OpportunityRequirementsDocument,
    options
  );
}
export function useOpportunityRequirementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpportunityRequirementsQuery, OpportunityRequirementsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpportunityRequirementsQuery, OpportunityRequirementsQueryVariables>(
    OpportunityRequirementsDocument,
    options
  );
}
export type OpportunityRequirementsQueryHookResult = ReturnType<typeof useOpportunityRequirementsQuery>;
export type OpportunityRequirementsLazyQueryHookResult = ReturnType<typeof useOpportunityRequirementsLazyQuery>;
export type OpportunityRequirementsQueryResult = Apollo.QueryResult<
  OpportunityRequirementsQuery,
  OpportunityRequirementsQueryVariables
>;
export const PaginatedAgenciesDocument = gql`
  query PaginatedAgencies($args: AgenciesFilterInput, $pagination: PaginationInput!) {
    paginatedAgencies(args: $args, pagination: $pagination) {
      agencies {
        id
        name
        isTest
        createdAt
        owner {
          firstName
          lastName
        }
      }
      numRecords
      numPages
    }
  }
`;

/**
 * __usePaginatedAgenciesQuery__
 *
 * To run a query within a React component, call `usePaginatedAgenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedAgenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedAgenciesQuery({
 *   variables: {
 *      args: // value for 'args'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginatedAgenciesQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedAgenciesQuery, PaginatedAgenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedAgenciesQuery, PaginatedAgenciesQueryVariables>(PaginatedAgenciesDocument, options);
}
export function usePaginatedAgenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedAgenciesQuery, PaginatedAgenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedAgenciesQuery, PaginatedAgenciesQueryVariables>(
    PaginatedAgenciesDocument,
    options
  );
}
export type PaginatedAgenciesQueryHookResult = ReturnType<typeof usePaginatedAgenciesQuery>;
export type PaginatedAgenciesLazyQueryHookResult = ReturnType<typeof usePaginatedAgenciesLazyQuery>;
export type PaginatedAgenciesQueryResult = Apollo.QueryResult<PaginatedAgenciesQuery, PaginatedAgenciesQueryVariables>;
export const PaginatedExtractedLabelsDocument = gql`
  query PaginatedExtractedLabels($input: FindExtractedLabelsInput, $pagination: PaginationInput!) {
    paginatedExtractedLabels(input: $input, pagination: $pagination) {
      extractedLabels {
        ...ExtractedLabel
      }
      numPages
      numRecords
    }
  }
  ${ExtractedLabelFragmentDoc}
`;

/**
 * __usePaginatedExtractedLabelsQuery__
 *
 * To run a query within a React component, call `usePaginatedExtractedLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedExtractedLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedExtractedLabelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginatedExtractedLabelsQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedExtractedLabelsQuery, PaginatedExtractedLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedExtractedLabelsQuery, PaginatedExtractedLabelsQueryVariables>(
    PaginatedExtractedLabelsDocument,
    options
  );
}
export function usePaginatedExtractedLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedExtractedLabelsQuery, PaginatedExtractedLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedExtractedLabelsQuery, PaginatedExtractedLabelsQueryVariables>(
    PaginatedExtractedLabelsDocument,
    options
  );
}
export type PaginatedExtractedLabelsQueryHookResult = ReturnType<typeof usePaginatedExtractedLabelsQuery>;
export type PaginatedExtractedLabelsLazyQueryHookResult = ReturnType<typeof usePaginatedExtractedLabelsLazyQuery>;
export type PaginatedExtractedLabelsQueryResult = Apollo.QueryResult<
  PaginatedExtractedLabelsQuery,
  PaginatedExtractedLabelsQueryVariables
>;
export const PaginatedInsuredsDocument = gql`
  query PaginatedInsureds($args: InsuredFilterInput, $pagination: PaginationInput!) {
    paginatedInsureds(args: $args, pagination: $pagination) {
      insureds {
        id
        name
      }
      numRecords
      numPages
    }
  }
`;

/**
 * __usePaginatedInsuredsQuery__
 *
 * To run a query within a React component, call `usePaginatedInsuredsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedInsuredsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedInsuredsQuery({
 *   variables: {
 *      args: // value for 'args'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginatedInsuredsQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedInsuredsQuery, PaginatedInsuredsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedInsuredsQuery, PaginatedInsuredsQueryVariables>(PaginatedInsuredsDocument, options);
}
export function usePaginatedInsuredsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedInsuredsQuery, PaginatedInsuredsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedInsuredsQuery, PaginatedInsuredsQueryVariables>(
    PaginatedInsuredsDocument,
    options
  );
}
export type PaginatedInsuredsQueryHookResult = ReturnType<typeof usePaginatedInsuredsQuery>;
export type PaginatedInsuredsLazyQueryHookResult = ReturnType<typeof usePaginatedInsuredsLazyQuery>;
export type PaginatedInsuredsQueryResult = Apollo.QueryResult<PaginatedInsuredsQuery, PaginatedInsuredsQueryVariables>;
export const PaginatedPrimaryLabelsDocument = gql`
  query PaginatedPrimaryLabels($input: FindPrimaryLabelsArgs, $pagination: PaginationInput!) {
    paginatedPrimaryLabels(input: $input, pagination: $pagination) {
      PrimaryLabels {
        ...PrimaryLabel
      }
      numPages
      numRecords
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;

/**
 * __usePaginatedPrimaryLabelsQuery__
 *
 * To run a query within a React component, call `usePaginatedPrimaryLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPrimaryLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPrimaryLabelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePaginatedPrimaryLabelsQuery(
  baseOptions: Apollo.QueryHookOptions<PaginatedPrimaryLabelsQuery, PaginatedPrimaryLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedPrimaryLabelsQuery, PaginatedPrimaryLabelsQueryVariables>(
    PaginatedPrimaryLabelsDocument,
    options
  );
}
export function usePaginatedPrimaryLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaginatedPrimaryLabelsQuery, PaginatedPrimaryLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedPrimaryLabelsQuery, PaginatedPrimaryLabelsQueryVariables>(
    PaginatedPrimaryLabelsDocument,
    options
  );
}
export type PaginatedPrimaryLabelsQueryHookResult = ReturnType<typeof usePaginatedPrimaryLabelsQuery>;
export type PaginatedPrimaryLabelsLazyQueryHookResult = ReturnType<typeof usePaginatedPrimaryLabelsLazyQuery>;
export type PaginatedPrimaryLabelsQueryResult = Apollo.QueryResult<
  PaginatedPrimaryLabelsQuery,
  PaginatedPrimaryLabelsQueryVariables
>;
export const PoliciesDocument = gql`
  query Policies($input: PolicyFilterInput!) {
    policies(input: $input) {
      ...Policy
    }
  }
  ${PolicyFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;

/**
 * __usePoliciesQuery__
 *
 * To run a query within a React component, call `usePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoliciesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePoliciesQuery(baseOptions: Apollo.QueryHookOptions<PoliciesQuery, PoliciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, options);
}
export function usePoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PoliciesQuery, PoliciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PoliciesQuery, PoliciesQueryVariables>(PoliciesDocument, options);
}
export type PoliciesQueryHookResult = ReturnType<typeof usePoliciesQuery>;
export type PoliciesLazyQueryHookResult = ReturnType<typeof usePoliciesLazyQuery>;
export type PoliciesQueryResult = Apollo.QueryResult<PoliciesQuery, PoliciesQueryVariables>;
export const PolicyDocument = gql`
  query Policy($id: ID!) {
    policy(id: $id) {
      ...Policy
    }
  }
  ${PolicyFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;

/**
 * __usePolicyQuery__
 *
 * To run a query within a React component, call `usePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePolicyQuery(baseOptions: Apollo.QueryHookOptions<PolicyQuery, PolicyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PolicyQuery, PolicyQueryVariables>(PolicyDocument, options);
}
export function usePolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PolicyQuery, PolicyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PolicyQuery, PolicyQueryVariables>(PolicyDocument, options);
}
export type PolicyQueryHookResult = ReturnType<typeof usePolicyQuery>;
export type PolicyLazyQueryHookResult = ReturnType<typeof usePolicyLazyQuery>;
export type PolicyQueryResult = Apollo.QueryResult<PolicyQuery, PolicyQueryVariables>;
export const PolicyFileTypesDocument = gql`
  query PolicyFileTypes {
    policyFileTypes
  }
`;

/**
 * __usePolicyFileTypesQuery__
 *
 * To run a query within a React component, call `usePolicyFileTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyFileTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyFileTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePolicyFileTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<PolicyFileTypesQuery, PolicyFileTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PolicyFileTypesQuery, PolicyFileTypesQueryVariables>(PolicyFileTypesDocument, options);
}
export function usePolicyFileTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PolicyFileTypesQuery, PolicyFileTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PolicyFileTypesQuery, PolicyFileTypesQueryVariables>(PolicyFileTypesDocument, options);
}
export type PolicyFileTypesQueryHookResult = ReturnType<typeof usePolicyFileTypesQuery>;
export type PolicyFileTypesLazyQueryHookResult = ReturnType<typeof usePolicyFileTypesLazyQuery>;
export type PolicyFileTypesQueryResult = Apollo.QueryResult<PolicyFileTypesQuery, PolicyFileTypesQueryVariables>;
export const PrimaryLabelDocument = gql`
  query PrimaryLabel($id: ID!) {
    primaryLabel(id: $id) {
      ...PrimaryLabel
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;

/**
 * __usePrimaryLabelQuery__
 *
 * To run a query within a React component, call `usePrimaryLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimaryLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimaryLabelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrimaryLabelQuery(
  baseOptions: Apollo.QueryHookOptions<PrimaryLabelQuery, PrimaryLabelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrimaryLabelQuery, PrimaryLabelQueryVariables>(PrimaryLabelDocument, options);
}
export function usePrimaryLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrimaryLabelQuery, PrimaryLabelQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrimaryLabelQuery, PrimaryLabelQueryVariables>(PrimaryLabelDocument, options);
}
export type PrimaryLabelQueryHookResult = ReturnType<typeof usePrimaryLabelQuery>;
export type PrimaryLabelLazyQueryHookResult = ReturnType<typeof usePrimaryLabelLazyQuery>;
export type PrimaryLabelQueryResult = Apollo.QueryResult<PrimaryLabelQuery, PrimaryLabelQueryVariables>;
export const PrimaryLabelsDocument = gql`
  query PrimaryLabels($input: FindPrimaryLabelsArgs) {
    primaryLabels(input: $input) {
      ...PrimaryLabel
    }
  }
  ${PrimaryLabelFragmentDoc}
  ${UserAccountFragmentDoc}
`;

/**
 * __usePrimaryLabelsQuery__
 *
 * To run a query within a React component, call `usePrimaryLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimaryLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimaryLabelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrimaryLabelsQuery(
  baseOptions?: Apollo.QueryHookOptions<PrimaryLabelsQuery, PrimaryLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrimaryLabelsQuery, PrimaryLabelsQueryVariables>(PrimaryLabelsDocument, options);
}
export function usePrimaryLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrimaryLabelsQuery, PrimaryLabelsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrimaryLabelsQuery, PrimaryLabelsQueryVariables>(PrimaryLabelsDocument, options);
}
export type PrimaryLabelsQueryHookResult = ReturnType<typeof usePrimaryLabelsQuery>;
export type PrimaryLabelsLazyQueryHookResult = ReturnType<typeof usePrimaryLabelsLazyQuery>;
export type PrimaryLabelsQueryResult = Apollo.QueryResult<PrimaryLabelsQuery, PrimaryLabelsQueryVariables>;
export const ProposalValuesDocument = gql`
  query ProposalValues($input: QuoteProposalValuesInput!) {
    proposalValues(input: $input) {
      stampingFee
      mep
      carrierName
      premium
      subjectivities
      insuredName
      effectiveDate
      lines
      homeState
      agentFirstName
      agencyName
      agentCommission
      surplusLinesTaxes
      brokerFee
      inspectionFee
      totalPremium
    }
  }
`;

/**
 * __useProposalValuesQuery__
 *
 * To run a query within a React component, call `useProposalValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProposalValuesQuery(
  baseOptions: Apollo.QueryHookOptions<ProposalValuesQuery, ProposalValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProposalValuesQuery, ProposalValuesQueryVariables>(ProposalValuesDocument, options);
}
export function useProposalValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProposalValuesQuery, ProposalValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProposalValuesQuery, ProposalValuesQueryVariables>(ProposalValuesDocument, options);
}
export type ProposalValuesQueryHookResult = ReturnType<typeof useProposalValuesQuery>;
export type ProposalValuesLazyQueryHookResult = ReturnType<typeof useProposalValuesLazyQuery>;
export type ProposalValuesQueryResult = Apollo.QueryResult<ProposalValuesQuery, ProposalValuesQueryVariables>;
export const QuoteDocument = gql`
  query Quote($id: ID!) {
    quote(id: $id) {
      ...Quote
    }
  }
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
`;

/**
 * __useQuoteQuery__
 *
 * To run a query within a React component, call `useQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteQuery(baseOptions: Apollo.QueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, options);
}
export function useQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, options);
}
export type QuoteQueryHookResult = ReturnType<typeof useQuoteQuery>;
export type QuoteLazyQueryHookResult = ReturnType<typeof useQuoteLazyQuery>;
export type QuoteQueryResult = Apollo.QueryResult<QuoteQuery, QuoteQueryVariables>;
export const QuoteKitchenSinkDocument = gql`
  query QuoteKitchenSink($id: ID!) {
    quote(id: $id) {
      ...QuoteKitchenSink
    }
  }
  ${QuoteKitchenSinkFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
  ${UserAccountFragmentDoc}
`;

/**
 * __useQuoteKitchenSinkQuery__
 *
 * To run a query within a React component, call `useQuoteKitchenSinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteKitchenSinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteKitchenSinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteKitchenSinkQuery(
  baseOptions: Apollo.QueryHookOptions<QuoteKitchenSinkQuery, QuoteKitchenSinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuoteKitchenSinkQuery, QuoteKitchenSinkQueryVariables>(QuoteKitchenSinkDocument, options);
}
export function useQuoteKitchenSinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QuoteKitchenSinkQuery, QuoteKitchenSinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuoteKitchenSinkQuery, QuoteKitchenSinkQueryVariables>(QuoteKitchenSinkDocument, options);
}
export type QuoteKitchenSinkQueryHookResult = ReturnType<typeof useQuoteKitchenSinkQuery>;
export type QuoteKitchenSinkLazyQueryHookResult = ReturnType<typeof useQuoteKitchenSinkLazyQuery>;
export type QuoteKitchenSinkQueryResult = Apollo.QueryResult<QuoteKitchenSinkQuery, QuoteKitchenSinkQueryVariables>;
export const QuotesDocument = gql`
  query Quotes($input: QuotesInput!) {
    quotes(input: $input) {
      ...QuoteKitchenSink
    }
  }
  ${QuoteKitchenSinkFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
  ${UserAccountFragmentDoc}
`;

/**
 * __useQuotesQuery__
 *
 * To run a query within a React component, call `useQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuotesQuery(baseOptions: Apollo.QueryHookOptions<QuotesQuery, QuotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, options);
}
export function useQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotesQuery, QuotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuotesQuery, QuotesQueryVariables>(QuotesDocument, options);
}
export type QuotesQueryHookResult = ReturnType<typeof useQuotesQuery>;
export type QuotesLazyQueryHookResult = ReturnType<typeof useQuotesLazyQuery>;
export type QuotesQueryResult = Apollo.QueryResult<QuotesQuery, QuotesQueryVariables>;
export const SearchAppetiteDocument = gql`
  query SearchAppetite($id: ID!) {
    appetiteSearchForOpportunity(id: $id) {
      scoredProducts {
        product {
          id
          carrierName
          carrierProductName
          marketType
          coverages
          endorsements
          coreLines
          productDescription
          portalLink
          submissionInstructions
          submissionCarrierContacts {
            ...CarrierContact
          }
          referenceCarrierContacts {
            ...CarrierContact
          }
          authority(id: $id)
          bindingGuideResults(id: $id) {
            id
            cgl
            lob
            result
            explanation
          }
        }
      }
    }
  }
  ${CarrierContactFragmentDoc}
`;

/**
 * __useSearchAppetiteQuery__
 *
 * To run a query within a React component, call `useSearchAppetiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAppetiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAppetiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchAppetiteQuery(
  baseOptions: Apollo.QueryHookOptions<SearchAppetiteQuery, SearchAppetiteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchAppetiteQuery, SearchAppetiteQueryVariables>(SearchAppetiteDocument, options);
}
export function useSearchAppetiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchAppetiteQuery, SearchAppetiteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchAppetiteQuery, SearchAppetiteQueryVariables>(SearchAppetiteDocument, options);
}
export type SearchAppetiteQueryHookResult = ReturnType<typeof useSearchAppetiteQuery>;
export type SearchAppetiteLazyQueryHookResult = ReturnType<typeof useSearchAppetiteLazyQuery>;
export type SearchAppetiteQueryResult = Apollo.QueryResult<SearchAppetiteQuery, SearchAppetiteQueryVariables>;
export const SearchAppetiteForOpportunityDocument = gql`
  query SearchAppetiteForOpportunity($id: ID!) {
    appetiteSearchForOpportunity(id: $id) {
      scoredProducts {
        ...ScoredAppetiteProduct
      }
    }
  }
  ${ScoredAppetiteProductFragmentDoc}
`;

/**
 * __useSearchAppetiteForOpportunityQuery__
 *
 * To run a query within a React component, call `useSearchAppetiteForOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAppetiteForOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAppetiteForOpportunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSearchAppetiteForOpportunityQuery(
  baseOptions: Apollo.QueryHookOptions<SearchAppetiteForOpportunityQuery, SearchAppetiteForOpportunityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchAppetiteForOpportunityQuery, SearchAppetiteForOpportunityQueryVariables>(
    SearchAppetiteForOpportunityDocument,
    options
  );
}
export function useSearchAppetiteForOpportunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAppetiteForOpportunityQuery,
    SearchAppetiteForOpportunityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchAppetiteForOpportunityQuery, SearchAppetiteForOpportunityQueryVariables>(
    SearchAppetiteForOpportunityDocument,
    options
  );
}
export type SearchAppetiteForOpportunityQueryHookResult = ReturnType<typeof useSearchAppetiteForOpportunityQuery>;
export type SearchAppetiteForOpportunityLazyQueryHookResult = ReturnType<
  typeof useSearchAppetiteForOpportunityLazyQuery
>;
export type SearchAppetiteForOpportunityQueryResult = Apollo.QueryResult<
  SearchAppetiteForOpportunityQuery,
  SearchAppetiteForOpportunityQueryVariables
>;
export const SearchBusinessClassesDocument = gql`
  query SearchBusinessClasses($input: BusinessClassificationsSearchInput!) {
    searchBusinessClasses(input: $input) {
      ...BusinessClass
    }
  }
  ${BusinessClassFragmentDoc}
`;

/**
 * __useSearchBusinessClassesQuery__
 *
 * To run a query within a React component, call `useSearchBusinessClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBusinessClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBusinessClassesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchBusinessClassesQuery(
  baseOptions: Apollo.QueryHookOptions<SearchBusinessClassesQuery, SearchBusinessClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchBusinessClassesQuery, SearchBusinessClassesQueryVariables>(
    SearchBusinessClassesDocument,
    options
  );
}
export function useSearchBusinessClassesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchBusinessClassesQuery, SearchBusinessClassesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchBusinessClassesQuery, SearchBusinessClassesQueryVariables>(
    SearchBusinessClassesDocument,
    options
  );
}
export type SearchBusinessClassesQueryHookResult = ReturnType<typeof useSearchBusinessClassesQuery>;
export type SearchBusinessClassesLazyQueryHookResult = ReturnType<typeof useSearchBusinessClassesLazyQuery>;
export type SearchBusinessClassesQueryResult = Apollo.QueryResult<
  SearchBusinessClassesQuery,
  SearchBusinessClassesQueryVariables
>;
export const SelectProductsOpportunityDocument = gql`
  query SelectProductsOpportunity {
    appetiteProductLines
  }
`;

/**
 * __useSelectProductsOpportunityQuery__
 *
 * To run a query within a React component, call `useSelectProductsOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectProductsOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectProductsOpportunityQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectProductsOpportunityQuery(
  baseOptions?: Apollo.QueryHookOptions<SelectProductsOpportunityQuery, SelectProductsOpportunityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelectProductsOpportunityQuery, SelectProductsOpportunityQueryVariables>(
    SelectProductsOpportunityDocument,
    options
  );
}
export function useSelectProductsOpportunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelectProductsOpportunityQuery, SelectProductsOpportunityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelectProductsOpportunityQuery, SelectProductsOpportunityQueryVariables>(
    SelectProductsOpportunityDocument,
    options
  );
}
export type SelectProductsOpportunityQueryHookResult = ReturnType<typeof useSelectProductsOpportunityQuery>;
export type SelectProductsOpportunityLazyQueryHookResult = ReturnType<typeof useSelectProductsOpportunityLazyQuery>;
export type SelectProductsOpportunityQueryResult = Apollo.QueryResult<
  SelectProductsOpportunityQuery,
  SelectProductsOpportunityQueryVariables
>;
export const StateTransitionsDocument = gql`
  query StateTransitions($id: ID!) {
    opportunity(id: $id) {
      id
      stateTransitions {
        ...MarketingPlanStateTransitionFragment
      }
      submissions {
        id
        appetiteProduct {
          ...SlimAppetiteProductFragment
        }
        stateTransitions {
          ...SubmissionStateTransitionFragment
        }
        quotes {
          id
          stateTransitions {
            ...QuoteStateTransitionFragment
          }
          policy {
            id
            stateTransitions {
              ...PolicyStateTransitionFragment
            }
          }
        }
      }
    }
  }
  ${MarketingPlanStateTransitionFragmentFragmentDoc}
  ${SlimAppetiteProductFragmentFragmentDoc}
  ${SubmissionStateTransitionFragmentFragmentDoc}
  ${QuoteStateTransitionFragmentFragmentDoc}
  ${PolicyStateTransitionFragmentFragmentDoc}
`;

/**
 * __useStateTransitionsQuery__
 *
 * To run a query within a React component, call `useStateTransitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStateTransitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStateTransitionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStateTransitionsQuery(
  baseOptions: Apollo.QueryHookOptions<StateTransitionsQuery, StateTransitionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StateTransitionsQuery, StateTransitionsQueryVariables>(StateTransitionsDocument, options);
}
export function useStateTransitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StateTransitionsQuery, StateTransitionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StateTransitionsQuery, StateTransitionsQueryVariables>(StateTransitionsDocument, options);
}
export type StateTransitionsQueryHookResult = ReturnType<typeof useStateTransitionsQuery>;
export type StateTransitionsLazyQueryHookResult = ReturnType<typeof useStateTransitionsLazyQuery>;
export type StateTransitionsQueryResult = Apollo.QueryResult<StateTransitionsQuery, StateTransitionsQueryVariables>;
export const SubmissionDocument = gql`
  query Submission($id: ID!) {
    submission(id: $id) {
      ...Submission
    }
  }
  ${SubmissionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
  ${SubmissionStateTransitionFragmentDoc}
`;

/**
 * __useSubmissionQuery__
 *
 * To run a query within a React component, call `useSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmissionQuery(baseOptions: Apollo.QueryHookOptions<SubmissionQuery, SubmissionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubmissionQuery, SubmissionQueryVariables>(SubmissionDocument, options);
}
export function useSubmissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubmissionQuery, SubmissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubmissionQuery, SubmissionQueryVariables>(SubmissionDocument, options);
}
export type SubmissionQueryHookResult = ReturnType<typeof useSubmissionQuery>;
export type SubmissionLazyQueryHookResult = ReturnType<typeof useSubmissionLazyQuery>;
export type SubmissionQueryResult = Apollo.QueryResult<SubmissionQuery, SubmissionQueryVariables>;
export const SubmissionDetailsDocument = gql`
  query SubmissionDetails($id: ID!) {
    submission(id: $id) {
      ...SubmissionDetails
    }
  }
  ${SubmissionDetailsFragmentDoc}
  ${SubmissionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
  ${SubmissionStateTransitionFragmentDoc}
`;

/**
 * __useSubmissionDetailsQuery__
 *
 * To run a query within a React component, call `useSubmissionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmissionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<SubmissionDetailsQuery, SubmissionDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubmissionDetailsQuery, SubmissionDetailsQueryVariables>(SubmissionDetailsDocument, options);
}
export function useSubmissionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubmissionDetailsQuery, SubmissionDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubmissionDetailsQuery, SubmissionDetailsQueryVariables>(
    SubmissionDetailsDocument,
    options
  );
}
export type SubmissionDetailsQueryHookResult = ReturnType<typeof useSubmissionDetailsQuery>;
export type SubmissionDetailsLazyQueryHookResult = ReturnType<typeof useSubmissionDetailsLazyQuery>;
export type SubmissionDetailsQueryResult = Apollo.QueryResult<SubmissionDetailsQuery, SubmissionDetailsQueryVariables>;
export const SubmissionStateLogExclusionReasonsDocument = gql`
  query SubmissionStateLogExclusionReasons($input: SubmissionStateLogExclusionReasonsFilter!) {
    submissionStateLogExclusionReasons(input: $input) {
      ...SubmissionStateTransition
    }
  }
  ${SubmissionStateTransitionFragmentDoc}
`;

/**
 * __useSubmissionStateLogExclusionReasonsQuery__
 *
 * To run a query within a React component, call `useSubmissionStateLogExclusionReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionStateLogExclusionReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionStateLogExclusionReasonsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmissionStateLogExclusionReasonsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubmissionStateLogExclusionReasonsQuery,
    SubmissionStateLogExclusionReasonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubmissionStateLogExclusionReasonsQuery, SubmissionStateLogExclusionReasonsQueryVariables>(
    SubmissionStateLogExclusionReasonsDocument,
    options
  );
}
export function useSubmissionStateLogExclusionReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubmissionStateLogExclusionReasonsQuery,
    SubmissionStateLogExclusionReasonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubmissionStateLogExclusionReasonsQuery, SubmissionStateLogExclusionReasonsQueryVariables>(
    SubmissionStateLogExclusionReasonsDocument,
    options
  );
}
export type SubmissionStateLogExclusionReasonsQueryHookResult = ReturnType<
  typeof useSubmissionStateLogExclusionReasonsQuery
>;
export type SubmissionStateLogExclusionReasonsLazyQueryHookResult = ReturnType<
  typeof useSubmissionStateLogExclusionReasonsLazyQuery
>;
export type SubmissionStateLogExclusionReasonsQueryResult = Apollo.QueryResult<
  SubmissionStateLogExclusionReasonsQuery,
  SubmissionStateLogExclusionReasonsQueryVariables
>;
export const SubmissionsDocument = gql`
  query Submissions($args: SubmissionsFilterInput!) {
    submissions(args: $args) {
      ...SubmissionResponse
    }
  }
  ${SubmissionResponseFragmentDoc}
`;

/**
 * __useSubmissionsQuery__
 *
 * To run a query within a React component, call `useSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmissionsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
}
export function useSubmissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubmissionsQuery, SubmissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubmissionsQuery, SubmissionsQueryVariables>(SubmissionsDocument, options);
}
export type SubmissionsQueryHookResult = ReturnType<typeof useSubmissionsQuery>;
export type SubmissionsLazyQueryHookResult = ReturnType<typeof useSubmissionsLazyQuery>;
export type SubmissionsQueryResult = Apollo.QueryResult<SubmissionsQuery, SubmissionsQueryVariables>;
export const UnderwritingAssistantAccountsDocument = gql`
  query UnderwritingAssistantAccounts($input: UserAccountFindManyFilter! = { roles: [UnderwritingAssistant] }) {
    userAccounts(input: $input) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useUnderwritingAssistantAccountsQuery__
 *
 * To run a query within a React component, call `useUnderwritingAssistantAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnderwritingAssistantAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnderwritingAssistantAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnderwritingAssistantAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<UnderwritingAssistantAccountsQuery, UnderwritingAssistantAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnderwritingAssistantAccountsQuery, UnderwritingAssistantAccountsQueryVariables>(
    UnderwritingAssistantAccountsDocument,
    options
  );
}
export function useUnderwritingAssistantAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnderwritingAssistantAccountsQuery,
    UnderwritingAssistantAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnderwritingAssistantAccountsQuery, UnderwritingAssistantAccountsQueryVariables>(
    UnderwritingAssistantAccountsDocument,
    options
  );
}
export type UnderwritingAssistantAccountsQueryHookResult = ReturnType<typeof useUnderwritingAssistantAccountsQuery>;
export type UnderwritingAssistantAccountsLazyQueryHookResult = ReturnType<
  typeof useUnderwritingAssistantAccountsLazyQuery
>;
export type UnderwritingAssistantAccountsQueryResult = Apollo.QueryResult<
  UnderwritingAssistantAccountsQuery,
  UnderwritingAssistantAccountsQueryVariables
>;
export const UserAccountsDocument = gql`
  query UserAccounts($input: UserAccountFindManyFilter!) {
    userAccounts(input: $input) {
      ...UserAccount
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useUserAccountsQuery__
 *
 * To run a query within a React component, call `useUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<UserAccountsQuery, UserAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAccountsQuery, UserAccountsQueryVariables>(UserAccountsDocument, options);
}
export function useUserAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserAccountsQuery, UserAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserAccountsQuery, UserAccountsQueryVariables>(UserAccountsDocument, options);
}
export type UserAccountsQueryHookResult = ReturnType<typeof useUserAccountsQuery>;
export type UserAccountsLazyQueryHookResult = ReturnType<typeof useUserAccountsLazyQuery>;
export type UserAccountsQueryResult = Apollo.QueryResult<UserAccountsQuery, UserAccountsQueryVariables>;
export const VerticalDocument = gql`
  query Vertical($id: String!) {
    vertical(id: $id) {
      ...Vertical
    }
  }
  ${VerticalFragmentDoc}
  ${ClassCodeFragmentDoc}
  ${VerticalMarketingPlanTemplateFragmentDoc}
  ${VerticalMarketingPlanTemplateProductFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;

/**
 * __useVerticalQuery__
 *
 * To run a query within a React component, call `useVerticalQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerticalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerticalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerticalQuery(baseOptions: Apollo.QueryHookOptions<VerticalQuery, VerticalQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerticalQuery, VerticalQueryVariables>(VerticalDocument, options);
}
export function useVerticalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerticalQuery, VerticalQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerticalQuery, VerticalQueryVariables>(VerticalDocument, options);
}
export type VerticalQueryHookResult = ReturnType<typeof useVerticalQuery>;
export type VerticalLazyQueryHookResult = ReturnType<typeof useVerticalLazyQuery>;
export type VerticalQueryResult = Apollo.QueryResult<VerticalQuery, VerticalQueryVariables>;
export const VerticalByCglDocument = gql`
  query VerticalByCGL($input: FindVerticalByCglInput!) {
    verticalByCGL(input: $input) {
      ...Vertical
    }
  }
  ${VerticalFragmentDoc}
  ${ClassCodeFragmentDoc}
  ${VerticalMarketingPlanTemplateFragmentDoc}
  ${VerticalMarketingPlanTemplateProductFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;

/**
 * __useVerticalByCglQuery__
 *
 * To run a query within a React component, call `useVerticalByCglQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerticalByCglQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerticalByCglQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerticalByCglQuery(
  baseOptions: Apollo.QueryHookOptions<VerticalByCglQuery, VerticalByCglQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerticalByCglQuery, VerticalByCglQueryVariables>(VerticalByCglDocument, options);
}
export function useVerticalByCglLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VerticalByCglQuery, VerticalByCglQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerticalByCglQuery, VerticalByCglQueryVariables>(VerticalByCglDocument, options);
}
export type VerticalByCglQueryHookResult = ReturnType<typeof useVerticalByCglQuery>;
export type VerticalByCglLazyQueryHookResult = ReturnType<typeof useVerticalByCglLazyQuery>;
export type VerticalByCglQueryResult = Apollo.QueryResult<VerticalByCglQuery, VerticalByCglQueryVariables>;
export const VerticalsDocument = gql`
  query Verticals {
    verticals {
      ...Vertical
    }
  }
  ${VerticalFragmentDoc}
  ${ClassCodeFragmentDoc}
  ${VerticalMarketingPlanTemplateFragmentDoc}
  ${VerticalMarketingPlanTemplateProductFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
`;

/**
 * __useVerticalsQuery__
 *
 * To run a query within a React component, call `useVerticalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerticalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerticalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerticalsQuery(baseOptions?: Apollo.QueryHookOptions<VerticalsQuery, VerticalsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerticalsQuery, VerticalsQueryVariables>(VerticalsDocument, options);
}
export function useVerticalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VerticalsQuery, VerticalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerticalsQuery, VerticalsQueryVariables>(VerticalsDocument, options);
}
export type VerticalsQueryHookResult = ReturnType<typeof useVerticalsQuery>;
export type VerticalsLazyQueryHookResult = ReturnType<typeof useVerticalsLazyQuery>;
export type VerticalsQueryResult = Apollo.QueryResult<VerticalsQuery, VerticalsQueryVariables>;
export const OnBrokerAssignmentQueueUpdatedDocument = gql`
  subscription OnBrokerAssignmentQueueUpdated {
    brokerAssignmentQueueUpdated {
      brokers {
        ...UserAccount
      }
    }
  }
  ${UserAccountFragmentDoc}
`;

/**
 * __useOnBrokerAssignmentQueueUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnBrokerAssignmentQueueUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnBrokerAssignmentQueueUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnBrokerAssignmentQueueUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnBrokerAssignmentQueueUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OnBrokerAssignmentQueueUpdatedSubscription,
    OnBrokerAssignmentQueueUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnBrokerAssignmentQueueUpdatedSubscription,
    OnBrokerAssignmentQueueUpdatedSubscriptionVariables
  >(OnBrokerAssignmentQueueUpdatedDocument, options);
}
export type OnBrokerAssignmentQueueUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnBrokerAssignmentQueueUpdatedSubscription
>;
export type OnBrokerAssignmentQueueUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnBrokerAssignmentQueueUpdatedSubscription>;
export const OnOpportunityEventAddedDocument = gql`
  subscription OnOpportunityEventAdded($id: String!) {
    opportunityEventAdded(id: $id) {
      ...OpportunityEvent
    }
  }
  ${OpportunityEventFragmentDoc}
  ${UserCommentFragmentDoc}
  ${UserAccountFragmentDoc}
  ${AssignmentChangeEventFragmentDoc}
  ${TriageAssignmentEventFragmentDoc}
  ${EmailEventFragmentDoc}
  ${GmailMessageEventFragmentDoc}
  ${GmailMessageFragmentDoc}
  ${FileUploadFragmentDoc}
  ${GmailRelayEventFragmentDoc}
  ${FileUploadEventFragmentDoc}
  ${StatusChangeEventFragmentDoc}
  ${SubmissionStatusChangeEventFragmentDoc}
  ${AlbyUpdateEventFragmentDoc}
  ${CreatedEventFragmentDoc}
  ${OutcomeChangeEventFragmentDoc}
  ${DesiredEffectiveDateChangeEventFragmentDoc}
  ${ApplicationSubmittedEventFragmentDoc}
  ${ProductsAddedEventFragmentDoc}
  ${ProductsRemovedEventFragmentDoc}
  ${BotCommentEventFragmentDoc}
  ${AutomatedFollowUpEventFragmentDoc}
  ${ActionRequestedEventFragmentDoc}
  ${ActionApprovedEventFragmentDoc}
  ${ActionRejectedEventFragmentDoc}
  ${ActionDismissedEventFragmentDoc}
  ${CoveragesChangedEventFragmentDoc}
  ${BusinessClassCodesChangedEventFragmentDoc}
  ${RequirementAddedEventFragmentDoc}
`;

/**
 * __useOnOpportunityEventAddedSubscription__
 *
 * To run a query within a React component, call `useOnOpportunityEventAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOpportunityEventAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOpportunityEventAddedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnOpportunityEventAddedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnOpportunityEventAddedSubscription,
    OnOpportunityEventAddedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnOpportunityEventAddedSubscription, OnOpportunityEventAddedSubscriptionVariables>(
    OnOpportunityEventAddedDocument,
    options
  );
}
export type OnOpportunityEventAddedSubscriptionHookResult = ReturnType<typeof useOnOpportunityEventAddedSubscription>;
export type OnOpportunityEventAddedSubscriptionResult = Apollo.SubscriptionResult<OnOpportunityEventAddedSubscription>;
export const OnOpportunityStateChangedDocument = gql`
  subscription OnOpportunityStateChanged($id: String!) {
    opportunityStateChanged(id: $id) {
      ...OpportunityDetails
    }
  }
  ${OpportunityDetailsFragmentDoc}
  ${UserAccountFragmentDoc}
  ${SubmissionFragmentDoc}
  ${AppetiteProductFragmentDoc}
  ${CarrierContactFragmentDoc}
  ${QuoteFragmentDoc}
  ${FileUploadFragmentDoc}
  ${PolicyFragmentDoc}
  ${PolicyStateTransitionFragmentDoc}
  ${QuoteStateTransitionFragmentDoc}
  ${SubmissionStateTransitionFragmentDoc}
  ${QuoteSubmissionFragmentDoc}
  ${ProductExclusionFragmentDoc}
  ${InsuredFragmentDoc}
  ${BusinessClassFragmentDoc}
  ${AgencyFragmentDoc}
  ${LeadFragmentDoc}
  ${MarketingPlanStateTransitionFragmentDoc}
`;

/**
 * __useOnOpportunityStateChangedSubscription__
 *
 * To run a query within a React component, call `useOnOpportunityStateChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnOpportunityStateChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnOpportunityStateChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnOpportunityStateChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnOpportunityStateChangedSubscription,
    OnOpportunityStateChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OnOpportunityStateChangedSubscription, OnOpportunityStateChangedSubscriptionVariables>(
    OnOpportunityStateChangedDocument,
    options
  );
}
export type OnOpportunityStateChangedSubscriptionHookResult = ReturnType<
  typeof useOnOpportunityStateChangedSubscription
>;
export type OnOpportunityStateChangedSubscriptionResult =
  Apollo.SubscriptionResult<OnOpportunityStateChangedSubscription>;
