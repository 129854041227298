import { Badge } from "@/components/ui/badge";
import { CardHeader } from "@/components/ui/card";
import { VerticalMarketingPlanTemplateFragment } from "src/generated/graphql";

export const TemplateHeader = ({ template }: { template: VerticalMarketingPlanTemplateFragment }) => (
  <CardHeader className="flex-row gap h-16 items-center">
    {template.lobs.map((lob) => (
      <Badge key={lob}>{lob}</Badge>
    ))}
  </CardHeader>
);
