import { FC } from "react";
import { z } from "zod";

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { ButtonGroup } from "@/forms-v2/button-group";
import { FieldHidden } from "@/forms-v2/fields/field-hidden";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { Form } from "@/forms-v2/form";
import { FormGroup } from "@/forms-v2/form-group";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import { PrimaryLabelQuery, UpdatePrimaryLabelInput, useUpdatePrimaryLabelMutation } from "src/generated/graphql";
import { cn, parseError } from "src/utils";

export interface UpdatePrimaryLabelDialogProps {
  label?: PrimaryLabelQuery["primaryLabel"];
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onSubmit?: (values: UpdatePrimaryLabelInput) => void;
}

const validationSchema = z.object({
  id: z.string().min(1, { message: "ID is required." }),
  primaryKey: z.string().min(1, { message: "Please enter a primary key." }),
  displayName: z.string().optional(),
  dataType: z.string().min(1, { message: "Please select a data type." }),
  description: z.string().optional(),
});

export const UpdatePrimaryLabelDialog: FC<UpdatePrimaryLabelDialogProps> = ({
  open,
  onOpenChange,
  onSubmit,
  ...props
}) => {
  const { toast } = useToast();
  const [updatePrimaryLabelMutation] = useUpdatePrimaryLabelMutation();

  const defaultValues = {
    primaryKey: "",
    displayName: "",
    dataType: "",
    description: "",
    ...props.label,
  };

  const handleSubmit = async (values: UpdatePrimaryLabelInput) => {
    await updatePrimaryLabelMutation({
      variables: {
        input: {
          id: values.id,
          primaryKey: values.primaryKey,
          displayName: values.displayName,
          dataType: values.dataType,
          description: values.description,
        },
      },
      refetchQueries: ["PrimaryLabels", "PaginatedPrimaryLabels"],
      onCompleted: () => {
        onOpenChange(false);
        onSubmit?.(values);
      },
      onError: (error) => toast({ title: "Error", description: parseError(error.message), variant: "destructive" }),
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <Form
          onSubmit={handleSubmit}
          defaultValues={defaultValues as UpdatePrimaryLabelInput}
          validationSchema={validationSchema}
        >
          <FormGroup className="gap-6">
            <DialogHeader>
              <DialogTitle>Edit primary label</DialogTitle>
              <DialogDescription>Some descriptive text about editing this primary label.</DialogDescription>
            </DialogHeader>

            <FormGroup>
              <FieldInput name="primaryKey" label="Primary key" />
              <FieldInput name="displayName" label="Display name" />
              <FieldSelect
                name="dataType"
                label="Data type"
                options={[
                  { label: "Boolean", value: "boolean" },
                  { label: "Date", value: "date" },
                  { label: "Number", value: "number" },
                  { label: "Percent", value: "percent" },
                  { label: "String", value: "string" },
                ]}
              />
              <FieldTextarea name="description" label="Description" />
            </FormGroup>

            {/* TODO: Still need to determine the best way to view/update/reorder the associated labels. */}
            <div>
              <Label className="block text-sm mb-2">Associated labels</Label>
              <div className="flex flex-col max-h-48 overflow-y-auto border border-input rounded-md divide-y">
                {!defaultValues.extractedLabels?.length && (
                  <div className="p-4 text-sm text-muted-foreground text-center">No associated labels</div>
                )}

                {!!defaultValues.extractedLabels?.length && (
                  <>
                    {defaultValues?.extractedLabels?.map((extractedLabel, index) => (
                      <div
                        key={`${extractedLabel.key}_${extractedLabel.source}`}
                        className={cn("flex items-center gap-2 pl-2.5 pr-4 pt-2.5 pb-2.5")}
                      >
                        <Icon
                          icon="drag_indicator"
                          className="cursor-not-allowed grow-0 shrink-0 text-secondary -mx-0.5"
                        />
                        <span className="grow-0 shrink-0 flex items-center justify-center h-5 aspect-square border bg-secondary rounded-full text-2xs text-secondary-foreground">
                          {index + 1}
                        </span>
                        <div className="flex flex-col">
                          <span className="text-sm">{extractedLabel.key}</span>
                          <span className="text-2xs text-muted-foreground">{extractedLabel.source}</span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>

            <FieldHidden name="id" />

            <ButtonGroup className="justify-end">
              <FormReset onClick={() => onOpenChange(false)} />
              <FormSubmit />
            </ButtonGroup>
          </FormGroup>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
