import { SectionContent } from "@/components/section";
import { useVertical } from "./components/use-vertical";
import { TemplateExclusionReasons } from "./template-exclusion-reasons";

export const VerticalExclusionReasons = () => {
  const { templates } = useVertical();

  return (
    <div className="divide-y">
      {templates.map((template) => (
        <SectionContent key={template.id}>
          <TemplateExclusionReasons template={template} />
        </SectionContent>
      ))}
    </div>
  );
};
