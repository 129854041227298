import { Node } from "@xyflow/react";
import { CircleDot } from "lucide-react";
import { FC, useMemo, useState } from "react";
import { Fragment } from "react/jsx-runtime";

import { Pagination, PaginationProvider, usePagination } from "@/components/pagination";
import { SidePanel, SidePanelHeader, SidePanelSection } from "@/components/side-panel";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import {
  FileProcessingPipelineIssuesQuery,
  FileProcessorIssueType,
  useFileProcessingPipelineIssuesQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";

import {
  getPipelineIssueColor,
  getPipelineIssueIcon,
  getPipelineIssueLabel,
} from "../file-processing-pipeline.helpers";
import { useNavigateToNode, useNodeById, useParentNodeByChildId } from "../file-processing-pipeline.hooks";

export interface PipelineIssue {
  type: FileProcessorIssueType;
  node: Node;
}

export const IssuesSidePanel = () => {
  const { data, loading } = useFileProcessingPipelineIssuesQuery();
  const [filterValue, setFilterValue] = useState<string>("");

  const issues = useMemo(
    () => (data?.pipelineIssues || []).filter((issue) => (filterValue ? issue.type === filterValue : true)),
    [data?.pipelineIssues, filterValue]
  );

  return (
    <SidePanel className="landscape:basis-[640px] landscape:max-w-[560px]">
      <SidePanelHeader
        icon={<CircleDot className="w-5 h-5" />}
        label="Manage"
        title="Issues"
        onCloseNavigatePath="/file-processing-pipeline"
      />

      <SidePanelSection className="flex-1">
        <Bar className="z-50 flex items-center gap-2 -mt-5 -mx-6 bg-card">
          <span className="text-xs font-semibold text-muted-foreground">
            {Intl.NumberFormat("en-us").format(issues.length)} issues
          </span>

          <span className="flex-1" />

          {filterValue && (
            <Button
              variant="secondary"
              size="xs"
              className="flex items-center gap-1 pr-1.5"
              onClick={() => setFilterValue("")}
            >
              {getPipelineIssueLabel(filterValue as FileProcessorIssueType)}
              <Icon icon="close" />
            </Button>
          )}

          {loading && (
            <span>
              <Loading label="" className="text-muted-foreground text-xs" />
            </span>
          )}

          <div className="-mr-3">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm">
                  <span>Filters</span>
                  <Icon icon="filter_list" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Issue type</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {Object.values(FileProcessorIssueType).map((issueType) => (
                  <DropdownMenuCheckboxItem
                    key={issueType}
                    checked={filterValue === issueType}
                    onCheckedChange={(checked) => setFilterValue(checked ? issueType : "")}
                  >
                    <div className="flex items-start w-full gap-2 py-0.5">
                      <Icon
                        icon={getPipelineIssueIcon(issueType)}
                        className={cn("text-base", getPipelineIssueColor(issueType))}
                      />
                      {getPipelineIssueLabel(issueType)}
                    </div>
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </Bar>

        <PaginationProvider
          numRecords={issues.length}
          rowsPerPage="25"
          storageKeyPrefix="pipelineIssues"
          shouldResetPageOnNumRecordsChange
          shouldResetPageOnRowsPerPageChange
        >
          {loading && !data?.pipelineIssues && <PipelineIssuesListLoading />}

          {issues.length > 0 && <PipelineIssuesList issues={issues} />}

          {!loading && issues.length === 0 && (
            <Alert className="my-3 flex items-start gap-2">
              {filterValue && (
                <>
                  <span>
                    <Icon icon="error" className="text-sm" />
                  </span>
                  <div className="flex-1">
                    <AlertTitle>No matching issues found</AlertTitle>
                    <AlertDescription className="text-muted-foreground">
                      Adjust your filters to view results.
                    </AlertDescription>
                  </div>
                  <Button size="sm" variant="secondary" onClick={() => setFilterValue("")}>
                    <Icon icon="filter_list_off" />
                    Clear filters
                  </Button>
                </>
              )}

              {!filterValue && (
                <>
                  <Icon icon="check_circle" className="text-sm text-success" />
                  <div>
                    <AlertTitle>All issues resolved</AlertTitle>
                    <AlertDescription className="text-muted-foreground">
                      There are no open issues at this time.
                    </AlertDescription>
                  </div>
                </>
              )}
            </Alert>
          )}

          <Pagination label="issues" rowsPerPageOptions={["25", "50", "100"]} className="z-50 -mx-6 -mb-6 bg-card" />
        </PaginationProvider>
      </SidePanelSection>
    </SidePanel>
  );
};

export interface PipelineIssuesListProps {
  issues: Exclude<FileProcessingPipelineIssuesQuery["pipelineIssues"], null | undefined>;
}

export const PipelineIssuesList: FC<PipelineIssuesListProps> = ({ issues }) => {
  const { rowsPerPage, page } = usePagination();

  const paginatedIssues = useMemo(() => {
    const start = page === 1 ? 0 : (page - 1) * Number(rowsPerPage);
    const end = page * Number(rowsPerPage);

    return issues.slice(start, end);
  }, [issues, page, rowsPerPage]);

  return (
    <ul className="flex flex-col gap-0 -mx-2">
      {paginatedIssues.map((issue, index) => (
        <PipelineIssuesListItem
          key={`${issue.nodeId}_${issue.type}`}
          issue={issue}
          hasDivider={index < paginatedIssues.length - 1}
        />
      ))}
    </ul>
  );
};

export interface PipelineIssuesListItemProps {
  issue: Exclude<FileProcessingPipelineIssuesQuery["pipelineIssues"], null | undefined>[0];
  hasDivider?: boolean;
}

export const PipelineIssuesListItem: FC<PipelineIssuesListItemProps> = ({ issue, hasDivider }) => {
  const node = useNodeById(issue.nodeId);
  const navigateToNode = useNavigateToNode();
  const parentNode = useParentNodeByChildId(issue.nodeId);

  if (!node) {
    return null;
  }

  return (
    <Fragment key={`${issue.type}_${issue.nodeId}`}>
      <li className="w-full -my-px">
        <Button
          variant="outline"
          onClick={() => navigateToNode(node.id)}
          className="relative hover:z-20 hover:bg-foreground/5 focus-visible:z-20 flex border-none flex-col gap-0 text-wrap w-full h-auto !text-left items-start justify-start p-0"
        >
          <div className="flex gap-2 items-start px-2 pt-3 pb-3.5">
            <Icon
              icon={getPipelineIssueIcon(issue.type)}
              className={cn("text-base w-6 -mt-0.5 -mb-2", getPipelineIssueColor(issue.type))}
            />
            <div>
              <div>{getPipelineIssueLabel(issue.type)}</div>
              <div className="text-xs text-muted-foreground font-normal">
                {node.data.name as string}
                {` `}
                <span className="text-2xs">({parentNode?.data.name as string})</span>
              </div>
            </div>
          </div>
        </Button>
      </li>

      {hasDivider && (
        <div className="relative z-10 px-2">
          <Separator />
        </div>
      )}
    </Fragment>
  );
};

export const PipelineIssuesListItemSkeleton = () => (
  <div className="flex flex-col gap-2 my-2">
    <div className="flex gap-2">
      <Skeleton className="h-4 w-4 mx-1" />
      <div className="flex flex-col gap-1">
        <Skeleton className="h-4 w-52" />
        <Skeleton className="h-3 w-44" />
      </div>
    </div>
  </div>
);

export const PipelineIssuesListLoading = () => (
  <div className="flex flex-col gap-2 my-1">
    <PipelineIssuesListItemSkeleton />
    <Separator />
    <PipelineIssuesListItemSkeleton />
    <Separator />
    <PipelineIssuesListItemSkeleton />
    <Separator />
    <PipelineIssuesListItemSkeleton />
    <Separator />
    <PipelineIssuesListItemSkeleton />
  </div>
);
