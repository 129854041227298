import { Grid, GridCell, GridRowHeader } from "@/components/grid";
import { HasInternalRole } from "@/components/has-role";
import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { useMyAccount } from "@/hooks/use-my-account";
import { useOpportunity } from "@/hooks/use-opportunity";
import { MarketingPlanActions } from "@/marketing-plans/actions/marketing-plan-actions";
import { AGENT_MESSAGE_METADATA } from "@/metadata";
import { SubmissionTemplateProvider } from "@/submission-template/use-submission-template";
import { MarketingPlanState, SubmissionState } from "src/generated/graphql";
import { cn } from "src/utils";
import { Submission } from "./submission";

const SUBMISSION_ORDER = [
  SubmissionState.Quoted,
  SubmissionState.Submitted,
  SubmissionState.Referred,
  SubmissionState.ReadyToSubmit,
  SubmissionState.Pending,
  SubmissionState.RuledOut,
  SubmissionState.MarketBlocked,
  SubmissionState.Declined,
];

const AGENT_VISIBLE_SUBMISSIONS = new Set([
  SubmissionState.Quoted,
  SubmissionState.Submitted,
  SubmissionState.Referred,
  SubmissionState.RuledOut,
  SubmissionState.MarketBlocked,
  SubmissionState.Declined,
]);

export const Submissions = () => {
  const { data: user } = useMyAccount();
  const { opportunity, refetch } = useOpportunity();

  if (!user || !opportunity || opportunity.state === MarketingPlanState.InformationGathering) {
    return null;
  }

  const { state, submissions } = opportunity;

  const visibleSubmissions = submissions.filter(
    (submission) => AGENT_VISIBLE_SUBMISSIONS.has(submission.state) || user.internal
  );

  const { className, cardClassName, icon, agentHeader, agentDescription } = AGENT_MESSAGE_METADATA[state];

  return (
    <Card className={cn("ring-1", cardClassName)}>
      <SectionHeader className="space-y-2">
        <SectionTitle className={className}>
          <Icon icon={icon} className="flex-none text-lg w-6" />
          <h3 className={cn("flex-auto", className)}>{user.internal ? "Marketing Plan" : agentHeader}</h3>
          <HasInternalRole>
            <MarketingPlanActions marketingPlan={opportunity} />
          </HasInternalRole>
        </SectionTitle>
        <SectionDescription className="ml-10">{agentDescription}</SectionDescription>
      </SectionHeader>
      {visibleSubmissions.length > 0 && (
        <Grid className="bg-transparent grid-cols-[1.5rem_1fr_1.5fr_1fr_1.5fr]">
          <GridRowHeader className="-mt-3">
            <GridCell />
            <GridCell>Carrier</GridCell>
            <GridCell>Product</GridCell>
            <GridCell>Updated</GridCell>
            <GridCell />
          </GridRowHeader>
          <SubmissionTemplateProvider>
            {visibleSubmissions
              .sort((a, b) => {
                const aIndex = SUBMISSION_ORDER.indexOf(a.state);
                const bIndex = SUBMISSION_ORDER.indexOf(b.state);
                return aIndex - bIndex;
              })
              .map((submission) => (
                <Submission key={submission.id} submission={submission} refetch={refetch} />
              ))}
          </SubmissionTemplateProvider>
        </Grid>
      )}
    </Card>
  );
};
