import { useAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounceValue } from "usehooks-ts";

import { pinnedInsuredsAtom } from "@/atoms";
import { InsuredFragment, useInsuredsQuery, usePaginatedInsuredsQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { useKeyboardShortcut } from "../hooks/use-keyboard-shortcut";
import { buttonClassName } from "./app-nav";
import { InsuredPin } from "./insured-pin";
import { PaginationProvider, usePagination } from "./pagination";
import { Button } from "./ui/button";
import { Command, CommandInput, CommandItem, CommandList } from "./ui/command";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "./ui/dialog";
import { Icon } from "./ui/icon";

export function Search() {
  const [isOpen, setIsOpen] = useState(false);

  const handleSearchClick = () => setIsOpen(true);

  useKeyboardShortcut(["ctrl+k", "command+k"], handleSearchClick, { disabled: isOpen });

  return (
    <PaginationProvider storageKeyPrefix="search">
      <Button
        variant="unstyled"
        size="sm"
        className={cn(buttonClassName, "bg-primary/5 border border-primary/5 rounded-full text-primary")}
        onClick={handleSearchClick}
      >
        <Icon icon="search" />
      </Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogHeader className="hidden">
          <DialogTitle>Search</DialogTitle>
          <DialogDescription>Search</DialogDescription>
        </DialogHeader>
        <DialogContent className="bg-transparent border-0 p-0 shadow-none">
          <CommandK setOpen={setIsOpen} />
        </DialogContent>
      </Dialog>
    </PaginationProvider>
  );
}

export const CommandK = ({ setOpen }: { setOpen: (open: boolean) => void }) => {
  const [pinnedInsuredIds] = useAtom(pinnedInsuredsAtom);
  const { setNumRecords, page, setPage } = usePagination();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [term] = useDebounceValue(searchTerm, 222);

  const { data: { insureds: pinnedInsureds } = { insureds: [] } } = useInsuredsQuery({
    variables: {
      input: {
        ids: Object.keys(pinnedInsuredIds),
      },
    },
    fetchPolicy: "cache-first",
  });

  const { data } = usePaginatedInsuredsQuery({
    variables: {
      args: {
        term,
      },
      pagination: { page },
    },
    skip: !term,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setNumRecords(data.paginatedInsureds.numRecords);
    },
  });

  const { insureds = [] } = data?.paginatedInsureds ?? {};

  const selectInsured = (v: string) => {
    setOpen(false);
    navigate(`/insured/${v}`);
  };

  return (
    <Command shouldFilter={false} className="bg-transparent gap-1.5 overflow-visible">
      <CommandCard>
        <CommandInput
          placeholder="Search Insureds"
          value={searchTerm}
          onValueChange={(term) => {
            setSearchTerm(term);
            setPage(1);
          }}
        />
        {term && (
          <>
            <CommandPagination term={term} />
            {insureds[0] && (
              <CommandList className="max-h-[33dvh] -mt-2 p">
                {insureds.map((insured) => (
                  <CommandListItem key={insured.id} insured={insured} handleSelect={selectInsured} />
                ))}
              </CommandList>
            )}
          </>
        )}
        {pinnedInsureds.length > 0 && (
          <>
            <CommandCardHeader>Pinned Insureds</CommandCardHeader>
            <CommandList className="-mt-2 p">
              {pinnedInsureds.map((ins) => (
                <CommandListItem key={ins.id} insured={ins} pin handleSelect={selectInsured} />
              ))}
            </CommandList>
          </>
        )}
      </CommandCard>
      <CommandCard>
        <CommandList className="p">
          <CommandItem
            className="gap-4 h-12 pl-3 pr-10 py-2"
            onSelect={() => {
              setOpen(false);
              navigate("/insured/new");
            }}
          >
            <div className="bg-primary flex h-6 items-center justify-center rounded-full text-background text-lg w-6">
              <Icon icon="add_2" />
            </div>
            <div className="text-sm">Create New Insured</div>
          </CommandItem>
        </CommandList>
      </CommandCard>
    </Command>
  );
};

const CommandPagination = ({ term }: { term: string }) => {
  const { numPages, numRecords, page, setPage } = usePagination();
  return (
    <CommandCardHeader>
      {numRecords} {numRecords === 1 ? `insured ` : `insureds `}
      matched &ldquo;{term}&rdquo;
      {numPages > 1 && (
        <div className="flex items-center ml-auto">
          <Button
            className="text-base"
            variant="ghost"
            size="xs"
            display="icon"
            disabled={page === 1 || page === 0}
            onClick={() => setPage((page) => page - 1)}
          >
            <Icon icon="chevron_left" />
          </Button>
          <div className="flex-auto font-mono mx-2 text-center text-2xs">
            {page ?? 1} <span className="text-foreground/20">/</span> {numPages}
          </div>
          <Button
            variant="ghost"
            size="xs"
            display="icon"
            className="text-base"
            disabled={page === numPages}
            onClick={() => setPage((page) => (page === 0 ? 1 : page) + 1)}
          >
            <Icon icon="chevron_right" />
          </Button>
        </div>
      )}
    </CommandCardHeader>
  );
};

const CommandCard = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-background rounded-md shadow-md transition-all">{children}</div>
);

const CommandCardHeader = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <header className={cn("border-t flex font-medium gap-4 mb-px px-4 py-3 text-muted-foreground text-2xs", className)}>
    {children}
  </header>
);

const CommandListItem = ({
  insured,
  handleSelect,
  pin = false,
}: {
  insured: Pick<InsuredFragment, "id" | "name">;
  handleSelect: (v: string) => void;
  pin?: boolean;
}) => {
  return (
    <CommandItem
      value={pin ? `${insured.id}-pin` : insured.id}
      className="gap-4 h-8 px-3 text-xs truncate"
      onSelect={(v) => handleSelect(v.replace("-pin", ""))}
    >
      <span className="truncate">{insured.name}</span>
      <InsuredPin id={insured.id} className="ml-auto" />
    </CommandItem>
  );
};
