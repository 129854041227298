import { Avatar } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { UserAccountFragment } from "src/generated/graphql";

interface Props {
  agent?: UserAccountFragment;
  broker?: UserAccountFragment;
}

export const Team = ({ agent, broker }: Props) => (
  <Card className="px-6 py-5 space-y-4">
    {broker && <UserInfo user={broker} title="QuoteWell" />}
    {agent && <UserInfo user={agent} title={agent.agency.name} />}
  </Card>
);

const UserInfo = ({ user, title }: { user: UserAccountFragment; title: string }) => (
  <div className="flex gap-4 items-center">
    <Avatar user={user} className="text-sm" />
    <div className="flex-auto space-y-0.5">
      <div className="font-semibold text-xs truncate">{`${user?.firstName} ${user?.lastName}`}</div>
      <div className="italic text-muted-foreground text-2xs truncate">{title}</div>
    </div>
    <Button variant="ghost" size="sm" display="icon" className="rounded-full" asChild>
      <a href={`mailto:${user?.email}`} target="_blank" rel="noreferrer">
        <Icon icon="mail" />
      </a>
    </Button>
  </div>
);
