import { useImpersonateUser } from "@cp/auth";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { UserAccountFragment } from "src/generated/graphql";

export const ImpersonateUser = ({ user }: { user: UserAccountFragment }) => {
  const { mutate: impersonateUser } = useImpersonateUser();
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          display="icon"
          onClick={() => {
            impersonateUser(
              { userIdToImpersonate: user.id },
              { onSuccess: () => window.location.replace(window.location.origin) }
            );
          }}
        >
          <Icon icon="account_circle" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        Impersonate {user.firstName} {user.lastName}
      </TooltipContent>
    </Tooltip>
  );
};
