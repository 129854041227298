import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { SubmissionTemplateModal } from "@/submission-template/submission-template-modal";
import { SubmissionAction, SubmissionFragment } from "src/generated/graphql";
import { SubmissionMenu } from "./submission-menu";
import { useSubmissionActions } from "./use-submission-actions";

export const ReadyToSubmit = ({ submission }: { submission: SubmissionFragment; refetch: () => void }) => {
  const { handleTransition } = useSubmissionActions();
  return (
    <>
      <SubmissionTemplateModal submissionId={submission.id} />
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            display="icon"
            onClick={() => handleTransition(submission, SubmissionAction.Submit)}
          >
            <Icon icon="check_circle" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Mark Submitted</TooltipContent>
      </Tooltip>
      <SubmissionMenu submission={submission}>
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionAction.Submit)}>
          <Icon icon="check_circle" />
          Mark Submitted
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionAction.RuleOut)}>
          <Icon icon="block" />
          Rule Out
        </DropdownMenuItem>
      </SubmissionMenu>
    </>
  );
};
