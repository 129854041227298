import { z } from "zod";

export type RuleItem = ClientDataRuleItem | MinimumPremiumRuleItem | StateRuleItem | CglRuleItem;
export type Rule = RuleItem[];
export type Rules = Rule[];

export enum RuleType {
  MinimumPremium = "minimum-premium",
  ClientData = "client-data",
  State = "state",
  CGL = "cgl",
}

export enum ClientDataRuleValueType {
  Text = "text",
  Number = "number",
  Boolean = "boolean",
}

export interface ClientDataRuleItem {
  type: RuleType.ClientData;
  key: string;
  operator?: ClientDataRuleBooleanOperator | ClientDataRuleNumberOperator | ClientDataRuleTextOperator;
  valueType: ClientDataRuleValueType;
  value?: string;
}

export interface MinimumPremiumRuleItem {
  type: RuleType.MinimumPremium;
  value?: number;
}

export interface StateRuleItem {
  type: RuleType.State;
  operator?: StateRuleOperator;
  value?: string;
}

export interface CglRuleItem {
  type: RuleType.CGL;
  operator?: CglRuleOperator;
  value?: string;
}

export enum ClientDataRuleTextOperator {
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
  Contains = "Contains",
  Equals = "Equals",
}

export enum ClientDataRuleNumberOperator {
  GreaterThan = ">",
  GreaterThanEqualTo = ">=",
  Equal = "=",
  LessThan = "<",
  LessThanEqualTo = "<=",
}

export enum ClientDataRuleBooleanOperator {
  ExcludeIf = "ExcludeIf",
}

export enum StateRuleOperator {
  ExcludeIf = "ExcludeIf",
  IncludeIf = "IncludeIf",
}

export enum CglRuleOperator {
  ExcludeIf = "ExcludeIf",
  IncludeIf = "IncludeIf",
}

const ClientDataRuleSchema = z.object({
  type: z.literal(RuleType.ClientData),
  key: z.string().optional(),
  operator: z.string().optional(),
  valueType: z.string(),
  value: z.union([z.string(), z.number()]).optional(),
});

const MinimumPremiumRuleSchema = z.object({
  type: z.literal(RuleType.MinimumPremium),
  value: z.union([z.string(), z.number()]).optional(),
});

const StateRuleSchema = z.object({
  type: z.literal(RuleType.State),
  operator: z.string().optional(),
  value: z.union([z.string(), z.number()]).optional(),
});

const CglRuleSchema = z.object({
  type: z.literal(RuleType.CGL),
  operator: z.string().optional(),
  value: z.union([z.string(), z.number()]).optional(),
});

export const RuleSchema = z.discriminatedUnion("type", [
  ClientDataRuleSchema,
  MinimumPremiumRuleSchema,
  StateRuleSchema,
  CglRuleSchema,
]);

export function getRuleKey(rule: Rule) {
  return rule.map((r) => `${r.type}--${"key" in r ? r.key : ""}`).join("---");
}

export function getRuleFromKey(ruleKey: string) {
  return ruleKey.split("---").map((part) => {
    const [type, key] = part.split("--");
    return { type, key };
  });
}

export const validRule = (rule: RuleItem) =>
  rule.value && (rule.type === RuleType.MinimumPremium || rule.operator) ? true : false;

export const operatorString = {
  [CglRuleOperator.ExcludeIf]: "excluded",
  [CglRuleOperator.IncludeIf]: "included",
  [ClientDataRuleNumberOperator.Equal]: "is equal to",
  [ClientDataRuleNumberOperator.GreaterThan]: "is greater than",
  [ClientDataRuleNumberOperator.GreaterThanEqualTo]: "is greater than or equal to",
  [ClientDataRuleNumberOperator.LessThan]: "is less than",
  [ClientDataRuleNumberOperator.LessThanEqualTo]: "is less than or equal to",
  [ClientDataRuleTextOperator.Contains]: "contains",
  [ClientDataRuleTextOperator.EndsWith]: "ends with",
  [ClientDataRuleTextOperator.Equals]: "equals",
  [ClientDataRuleTextOperator.StartsWith]: "starts with",
};
