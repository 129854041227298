import { Viewport } from "@xyflow/react";
import { MaterialSymbol } from "material-symbols";

import { FileProcessorCategory, FileProcessorIssueType } from "src/generated/graphql";

export const initialViewport: Viewport = {
  x: 400,
  y: 410,
  zoom: 0.75,
};

export const processorCategoryToIconMap: Record<FileProcessorCategory, MaterialSymbol> = {
  [FileProcessorCategory.Splitter]: "arrow_split",
  [FileProcessorCategory.Classifier]: "folder_data",
  [FileProcessorCategory.Extractor]: "database",
  [FileProcessorCategory.PromptExtractor]: "database",
  [FileProcessorCategory.Redactor]: "variable_remove",
};

export const pipelineIssueTypeToIconMap: Record<FileProcessorIssueType, MaterialSymbol> = {
  [FileProcessorIssueType.DocumentLabelNeedsFileProcessor]: "folder_check_2",
  [FileProcessorIssueType.FileProcessorNeedsDeployedVersion]: "deployed_code_alert",
  [FileProcessorIssueType.FileProcessorNeedsDefaultVersion]: "release_alert",
  [FileProcessorIssueType.FileProcessorHasMultipleDeployedVersions]: "tab_close",
};

export const pipelineIssueTypeToColorMap: Record<FileProcessorIssueType, string> = {
  [FileProcessorIssueType.DocumentLabelNeedsFileProcessor]: "text-success",
  [FileProcessorIssueType.FileProcessorNeedsDeployedVersion]: "text-destructive",
  [FileProcessorIssueType.FileProcessorNeedsDefaultVersion]: "text-destructive",
  [FileProcessorIssueType.FileProcessorHasMultipleDeployedVersions]: "text-amber-500",
};

export const pipelineIssueTypeToLabelMap: Record<FileProcessorIssueType, string> = {
  [FileProcessorIssueType.DocumentLabelNeedsFileProcessor]: "Ready for processor",
  [FileProcessorIssueType.FileProcessorNeedsDeployedVersion]: "Missing deployed version",
  [FileProcessorIssueType.FileProcessorNeedsDefaultVersion]: "Missing default version",
  [FileProcessorIssueType.FileProcessorHasMultipleDeployedVersions]: "Multiple deployed versions",
};
