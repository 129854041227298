import { FC } from "react";

import { Alert, AlertTitle } from "@/components/ui/alert";
import { Icon } from "@/components/ui/icon";
import { FileProcessorQuery } from "src/generated/graphql";

import {
  getPipelineIssueColor,
  getPipelineIssueIcon,
  getPipelineIssueLabel,
} from "../file-processing-pipeline.helpers";

export interface DetailsIssuesListProps {
  issues: Exclude<Exclude<FileProcessorQuery["fileProcessor"], null | undefined>["issues"], null | undefined>;
}

export const DetailsIssuesList: FC<DetailsIssuesListProps> = ({ issues }) => (
  <Alert className="flex flex-col gap-1 pt-3 pb-3.5">
    <AlertTitle className="text-xs text-muted-foreground">Issues</AlertTitle>
    {issues.map((issue) => (
      <div key={issue.type} className="flex gap-2 items-center">
        <Icon icon={getPipelineIssueIcon(issue.type)} className={getPipelineIssueColor(issue.type)} />
        {getPipelineIssueLabel(issue.type)}
      </div>
    ))}
  </Alert>
);
