import { MaterialSymbol } from "material-symbols";

import { BankanPolicyColumn, BankanQuoteColumn, MarketingPlanBankanColumn } from "src/generated/graphql";
import { cn } from "src/utils";
import { Icon } from "./components/ui/icon";

export enum UserState {
  EmailDomainUnverified = "Email Domain Not Verified",
}

export enum InsuredState {
  NoMarketingPlans = "NoMarketingPlans",
  NoAssignedMarketingPlans = "NoAssignedMarketingPlans",
}

interface StateMetadata {
  label: string;
  className: string;
  cardClassName: string;
  icon: MaterialSymbol;
}

export type ColumnStates =
  | UserState
  | InsuredState
  | MarketingPlanBankanColumn
  | BankanQuoteColumn
  | BankanPolicyColumn;

export const USER_STATE_METADATA: Record<UserState, StateMetadata> = {
  [UserState.EmailDomainUnverified]: {
    className: "filled text-red-600",
    cardClassName: "border-red-500",
    icon: "warning",
    // label: "Agents Not Verified for their Agency",
    label: "Please Alert EPD About These Agents",
  },
};

export const INSURED_STATE_METADATA: Record<InsuredState, StateMetadata> = {
  [InsuredState.NoMarketingPlans]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "shield",
    label: "No Marketing Plans",
  },
  [InsuredState.NoAssignedMarketingPlans]: {
    className: "text-slate-600",
    cardClassName: "border-slate-500",
    icon: "error",
    label: "No Assigned Marketing Plans",
  },
};

export const MARKETING_PLAN_COLUMN_METADATA: Record<MarketingPlanBankanColumn, StateMetadata> = {
  [MarketingPlanBankanColumn.WaitingForCoverages]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500/50",
    icon: "shield",
    label: "Waiting for Coverages",
  },
  [MarketingPlanBankanColumn.InformationGathering]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500/50",
    icon: "info",
    label: "Information Gathering",
  },
  [MarketingPlanBankanColumn.InformationReview]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500/50",
    icon: "info",
    label: "Broker Review",
  },
  [MarketingPlanBankanColumn.MarketingInfoRequired]: {
    className: "filled text-red-600",
    cardClassName: "outline-red-500/50",
    icon: "warning",
    label: "Marketing: Information Required",
  },
  [MarketingPlanBankanColumn.MarketingReady]: {
    className: "filled text-emerald-600",
    cardClassName: "outline-emerald-500/50",
    icon: "campaign",
    label: "Marketing: Ready",
  },
  [MarketingPlanBankanColumn.UnassignedInformationGathering]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500/50",
    icon: "psychology_alt",
    label: "Unassigned: Information Gathering",
  },
  [MarketingPlanBankanColumn.UnassignedWaitingForCoverages]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500/50",
    icon: "psychology_alt",
    label: "Unassigned: Waiting For Coverages",
  },
  [MarketingPlanBankanColumn.UnassignedOther]: {
    className: "text-slate-600",
    cardClassName: "outline-slate-500/50",
    icon: "psychology_alt",
    label: "Unassigned: Other",
  },
};

export const QUOTE_COLUMN_METADATA: Record<BankanQuoteColumn, StateMetadata> = {
  [BankanQuoteColumn.ReadyToSend]: {
    className: "filled text-emerald-600",
    cardClassName: "outline-emerald-500/50",
    icon: "send",
    label: "Ready to Send",
  },
  [BankanQuoteColumn.Delivered]: {
    className: "filled text-blue-600",
    cardClassName: "outline-blue-500/50",
    icon: "mail",
    label: "Delivered",
  },
  [BankanQuoteColumn.BindRequestReceived]: {
    className: "text-emerald-600",
    cardClassName: "outline-emerald-500/50",
    icon: "thumb_up",
    label: "Bind Request Received",
  },
};

export const POLICY_COLUMN_METADATA: Record<BankanPolicyColumn, StateMetadata> = {
  [BankanPolicyColumn.AwaitingBinderFromCarrier]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500/50",
    icon: "hourglass_empty",
    label: "Awaiting Binder",
  },
  [BankanPolicyColumn.ReceivedBinderFromCarrier]: {
    className: "text-emerald-600",
    cardClassName: "outline-emerald-500/50",
    icon: "receipt_long",
    label: "Received Binder",
  },
  [BankanPolicyColumn.BinderProcessed]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500/50",
    icon: "hourglass_empty",
    label: "Binder Processed",
  },
  [BankanPolicyColumn.AwaitingPolicyFromCarrier]: {
    className: "text-orange-600",
    cardClassName: "outline-orange-500/50",
    icon: "hourglass_empty",
    label: "Awaiting Policy",
  },
  [BankanPolicyColumn.ReceivedPolicyFromCarrier]: {
    className: "text-emerald-600",
    cardClassName: "outline-emerald-500/50",
    icon: "receipt_long",
    label: "Received Policy",
  },
  [BankanPolicyColumn.IssuedEndorsementsRequested]: {
    className: "filled text-purple-600",
    cardClassName: "outline-purple-500/50",
    icon: "verified",
    label: "Issued - Endorsements Requested",
  },
  [BankanPolicyColumn.ExpiringSoon]: {
    className: "filled text-red-600",
    cardClassName: "outline-red-500/50",
    icon: "warning",
    label: "Expiring Soon",
  },
};

export const COLUMN_STATE_METADATA: Record<ColumnStates, StateMetadata> = {
  ...USER_STATE_METADATA,
  ...INSURED_STATE_METADATA,
  ...MARKETING_PLAN_COLUMN_METADATA,
  ...QUOTE_COLUMN_METADATA,
  ...POLICY_COLUMN_METADATA,
};

export const ColumnStateIndicator = ({
  state,
  displayLabel = true,
  className,
}: {
  state: ColumnStates;
  displayLabel?: boolean;
  className?: string;
}) => {
  const { className: stateClassName, icon, label } = COLUMN_STATE_METADATA[state];

  return (
    <span className={cn("flex gap-[0.5em] items-center", stateClassName, className)}>
      <Icon icon={icon} />
      {displayLabel && <span className="truncate">{label}</span>}
    </span>
  );
};
