import { zodResolver } from "@hookform/resolvers/zod";
import { FormEvent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { Field, Input } from "@/forms/default";
import {
  CarrierContactFragment,
  CarrierContactType,
  useCreateCarrierContactMutation,
  useUpdateCarrierContactMutation,
} from "src/generated/graphql";

const FormSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email(),
  phone: z.string().optional(),
  title: z.string().optional(),
});

interface Props {
  carrierContact?: CarrierContactFragment | null;
  type: CarrierContactType;
  appetiteProductId: string;
  onCompleted: () => void;
}

export const CarrierContactForm: React.FC<Props> = ({ carrierContact, type, appetiteProductId, onCompleted }) => {
  const { toast } = useToast();
  const [createCarrierContact] = useCreateCarrierContactMutation();
  const [updateCarrierContact] = useUpdateCarrierContactMutation();

  const methods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: carrierContact
      ? {
          firstName: carrierContact.firstName,
          lastName: carrierContact.lastName,
          email: carrierContact.email,
          phone: carrierContact.phone ?? undefined,
          title: carrierContact.title ?? undefined,
        }
      : {
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          phone: undefined,
          title: undefined,
        },
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await methods.trigger();
    e.preventDefault();

    if (isValid) {
      const values = methods.getValues();

      await (carrierContact
        ? updateCarrierContact({
            variables: {
              input: {
                id: carrierContact.id,
                ...values,
              },
            },
            onCompleted: () => {
              toast({ title: "Carrier Contact Updated Successfully" });
              onCompleted();
            },
            refetchQueries: ["OpportunityDetails"],
          })
        : createCarrierContact({
            variables: {
              input: {
                appetiteProductId,
                type,
                ...values,
              },
            },
            onCompleted: () => {
              toast({ title: "Carrier Contact Created Successfully" });
              onCompleted();
            },
            refetchQueries: ["OpportunityDetails"],
          }));
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Field label="First Name" name="firstName">
          <Input name="firstName" />
        </Field>

        <Field label="Last Name" name="lastName">
          <Input name="lastName" />
        </Field>

        <Field label="Email" name="email">
          <Input name="email" />
        </Field>

        <Field label="Phone" name="phone">
          <Input name="phone" />
        </Field>

        <Field label="Title" name="title">
          <Input name="title" />
        </Field>

        <div className="flex justify-between">
          <Button variant="outline" theme="destructive" onClick={onCompleted}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </form>
    </FormProvider>
  );
};
