import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { useFileTagsQuery, useRemoveFIleTagMutation, useUpdateFileTagMutation } from "src/generated/graphql";

export const TagMenu = ({
  refetch,
  allTags,
  tag,
}: {
  refetch: () => void;
  allTags: NonNullable<ReturnType<typeof useFileTagsQuery>["data"]>["fileTags"];
  tag?: NonNullable<ReturnType<typeof useFileTagsQuery>["data"]>["fileTags"][0];
}) => {
  const [update] = useUpdateFileTagMutation();
  const { toast } = useToast();

  const label = tag?.label ?? "";
  const labels = allTags;
  const parentLabels = tag?.parentTags ?? [];
  const childLabels = tag?.childTags ?? [];

  const SelectLabelParent = () => {
    return (
      <Select
        onValueChange={(value) => {
          void update({
            variables: {
              input: {
                label: label,
                parentTag: value,
              },
            },
            onError: (e) => {
              toast({ title: e.message });
            },
            onCompleted: () => {
              toast({ title: `Tag added` });
              void refetch();
            },
          });
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Label" />
        </SelectTrigger>
        <SelectContent>
          {labels?.map((label) => (
            <SelectItem key={label.label} value={label.label}>
              {label.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  };

  return (
    <Dialog>
      <DialogTrigger className="filled text-muted-foreground text-2xs">
        <Icon icon="edit" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{label}</DialogTitle>
          <DialogDescription>Edit the hierarchy of this tag.</DialogDescription>
        </DialogHeader>
        <div>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="space-y-2">
              <h4>Parent Tags</h4>
              <SelectLabelParent />
              {parentLabels[0] && <TagTableParents labels={parentLabels} rootLabel={label} refetch={refetch} />}
            </div>
            <div className="space-y-2">
              <h4>Child Tags</h4>
              <Select
                onValueChange={(value) => {
                  void update({
                    variables: {
                      input: {
                        label: label,
                        childTag: value,
                      },
                    },
                    onError: (e) => {
                      toast({ title: e.message });
                    },
                    onCompleted: () => {
                      toast({ title: `Tag added` });
                      void refetch();
                    },
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Tag" />
                </SelectTrigger>
                <SelectContent>
                  {labels?.map((label) => (
                    <SelectItem key={label.label} value={label.label}>
                      {label.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {childLabels[0] && <TagTableChildren labels={childLabels} rootLabel={label} refetch={refetch} />}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const TagTableParents = ({
  rootLabel,
  labels,
  refetch,
}: {
  refetch: () => void;
  rootLabel: string;
  labels: Array<{
    __typename?: "FileTag" | undefined;
    label: string;
  }>;
}) => {
  const [update] = useRemoveFIleTagMutation();
  const { toast } = useToast();
  return (
    <div>
      {labels.map(({ label }) => (
        <div key={label} className="flex gap-3 h-8 items-center text-xs">
          <span className="flex-auto truncate">{label}</span>
          <Button
            variant="ghost"
            size="sm"
            display="icon"
            onClick={() => {
              void update({
                variables: {
                  input: {
                    label: rootLabel,
                    parentTag: label,
                  },
                },
                onError: (e) => {
                  toast({ title: e.message });
                },
                onCompleted: () => {
                  toast({ title: `Tag removed` });
                  refetch();
                },
              });
              return;
            }}
          >
            <Icon icon="cancel" />
          </Button>
        </div>
      ))}
    </div>
  );
};
const TagTableChildren = ({
  rootLabel,
  labels,
  refetch,
}: {
  refetch: () => void;
  rootLabel: string;
  labels: Array<{
    __typename?: "FileTag" | undefined;
    label: string;
  }>;
}) => {
  const [update] = useRemoveFIleTagMutation();
  const { toast } = useToast();
  return (
    <div>
      {labels.map(({ label }) => (
        <div key={label} className="border-t flex gap-3 h-8 items-center text-xs">
          <span className="flex-auto truncate">{label}</span>
          <Button
            variant="ghost"
            size="sm"
            display="icon"
            onClick={() => {
              void update({
                variables: {
                  input: {
                    label: rootLabel,
                    childTag: label,
                  },
                },
                onError: (e) => {
                  toast({ title: e.message });
                },
                onCompleted: () => {
                  toast({ title: `Tag removed` });
                  refetch();
                },
              });
              return;
            }}
          >
            <Icon icon="cancel" />
          </Button>
        </div>
      ))}
    </div>
  );
};
