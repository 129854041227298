import { ReactFlowProvider } from "@xyflow/react";
import { Outlet } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { ScrollPane } from "@/components/scroll-pane";

import { FileProcessingPipelineGraphContainer } from "./file-processing-pipeline-graph/file-processing-pipeline-graph";
import { FileProcessingPipelineSidePanel } from "./file-processing-pipeline-side-panel";
import { FileProcessingPipelineProvider } from "./file-processing-pipeline.provider";

export const FileProcessingPipeline = () => {
  useDocumentTitle("File Processing Pipeline: QuoteWell");

  return (
    <ReactFlowProvider>
      <FileProcessingPipelineProvider>
        <ScrollPane className="flex-row">
          <div className="flex flex-auto items-center justify-center h-full w-full">
            <FileProcessingPipelineGraphContainer />
          </div>
          <FileProcessingPipelineSidePanel>
            <Outlet />
          </FileProcessingPipelineSidePanel>
        </ScrollPane>
      </FileProcessingPipelineProvider>
    </ReactFlowProvider>
  );
};
