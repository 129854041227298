import { FC } from "react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { PrimaryLabelQuery, useDeletePrimaryLabelMutation } from "src/generated/graphql";

export interface DeletePrimaryLabelDialogProps {
  label: PrimaryLabelQuery["primaryLabel"];
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

export const DeletePrimaryLabelDialog: FC<DeletePrimaryLabelDialogProps> = ({ label, open, onOpenChange }) => {
  const [deletePrimaryLabelMutation, { loading }] = useDeletePrimaryLabelMutation();

  const handleConfirm = async () => {
    await deletePrimaryLabelMutation({
      variables: { id: label.id },
      refetchQueries: ["PrimaryLabels", "PaginatedPrimaryLabels"],
      onCompleted: () => onOpenChange(false),
    });
  };

  const handleCancel = () => {
    onOpenChange(false);
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete primary label</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to delete primary label &ldquo;
            <strong>{label.displayName || label.primaryKey}</strong>
            ?&rdquo; This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={loading} onClick={handleCancel}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction theme="destructive" disabled={loading} onClick={handleConfirm}>
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
