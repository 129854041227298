import { FC, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

import { Slot } from "@radix-ui/react-slot";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "src/utils";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Icon } from "./ui/icon";
import { Skeleton } from "./ui/skeleton";

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

export const Grid = ({ asChild, className, ...props }: GridProps) => {
  const Comp = asChild ? Slot : "div";
  return <Comp className={cn("bg-inherit divide-y gap-x-4 grid auto-rows-min px-6 text-xs", className)} {...props} />;
};

export const gridRowClassName = [
  "col-span-full grid grid-cols-subgrid items-center -mx-6 px-6 relative",
  "bg-background min-h-12 last:shadow-border-b [.bg-card_&]:last:shadow-none [.bg-card_&]:bg-transparent",
  "data-[dragging=true]:!bg-background data-[dragging=true]:!border data-[dragging=true]:!-mx-[calc(1.5rem+1px)]",
];

const gridRowHeaderClassName = "bg-inherit font-semibold min-h-10 text-2xs text-muted-foreground";

export interface GridRowProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

export const GridRow = ({ asChild, className, ...props }: GridRowProps) => {
  const Comp = asChild ? Slot : "div";
  return <Comp className={cn(gridRowClassName, className)} {...props} />;
};

const gridRowHeaderVariants = cva([gridRowClassName[0], gridRowHeaderClassName], {
  variants: {
    position: {
      relative: "",
      sticky: "border-b -mb-px top-12 sticky z-10",
    },
  },
  defaultVariants: {
    position: "relative",
  },
});

export interface GridRowHeaderProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof gridRowHeaderVariants> {}

export const GridRowHeader = ({ className, position, ...props }: GridRowHeaderProps) => (
  <header className={cn(gridRowHeaderVariants({ position }), className)} {...props} />
);

export const GridRowFooter = ({ className, ...props }: React.HTMLAttributes<HTMLElement>) => (
  <footer className={cn(gridRowClassName[0], gridRowHeaderClassName, className)} {...props} />
);

export const GridRowLink = ({ children, className, ...props }: LinkProps) => (
  <Link className={cn(gridRowClassName, "group", className)} {...props}>
    {children}
    <Icon
      icon="arrow_forward"
      className={cn(
        "flex items-center justify-center pointer-events-none rounded-full ",
        "opacity-25 group-hover:opacity-100 transition-all",
        "absolute top bottom right-4 group-hover:right-3 m-auto h-4 w-4"
      )}
    />
  </Link>
);

export interface GridCellProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

export const GridCell = ({ asChild, className, ...props }: GridCellProps) => {
  const Comp = asChild ? Slot : "div";
  return <Comp className={cn("truncate", className)} {...props} />;
};

export const GridCellLoading = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <Skeleton className="inline-block h-4 w-1/2" />
  </div>
);

export interface GridLoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  rows?: number;
  columns: number;
}

export const GridLoading: FC<GridLoadingProps> = ({ rows = 1, columns, className, ...props }) => (
  <>
    {Array.from({ length: rows }).map((_, i) => (
      <GridRow className={cn("[&>*:last-child]:text-right", className)} key={i} {...props}>
        {Array.from({ length: columns }).map((_, j) => (
          <GridCellLoading key={j} />
        ))}
      </GridRow>
    ))}
  </>
);

export interface GridEmptyProps {
  title?: ReactNode;
  description?: ReactNode;
}

export const GridEmpty: FC<GridEmptyProps> = ({ title, description }) => (
  <div className="col-span-full py-8">
    <Alert className="text-center py-6">
      <AlertTitle className="text-base">{title}</AlertTitle>
      <AlertDescription className="text-muted-foreground">{description}</AlertDescription>
    </Alert>
  </div>
);
