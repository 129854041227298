import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input as FieldInput } from "@/forms/default";

export const RequirementForm = ({ defaultGroups }: { defaultGroups?: string }) => {
  const { setValue } = useFormContext();
  const [groups, setGroups] = useState<
    Array<{
      title: string;
      description: string;
      elements: Array<{ element: string; props: string; clientDataKey: string; array: boolean }>;
    }>
  >(defaultGroups ? JSON.parse(defaultGroups) : []);

  useEffect(() => {
    setValue("groups", JSON.stringify(groups));
  }, [groups]);

  return (
    <div className="flex flex-col gap">
      <Label>Requirement Title</Label>
      <FieldInput name="title" required />
      <Label>Requirement Description</Label>
      <FieldInput name="description" />
      {groups.map((group, index) => (
        <Card key={index}>
          <CardContent>
            <Label>Group Title</Label>
            <Input
              value={group.title}
              onChange={({ target }) =>
                setGroups((groups) =>
                  groups.map((g, i) => {
                    if (index !== i) {
                      return g;
                    }

                    return {
                      ...g,
                      title: target.value,
                    };
                  })
                )
              }
            />
            <Label>Group Description</Label>
            <Input
              value={group.description}
              onChange={({ target }) =>
                setGroups((groups) =>
                  groups.map((g, i) => {
                    if (index !== i) {
                      return g;
                    }

                    return {
                      ...g,
                      description: target.value,
                    };
                  })
                )
              }
            />

            {group.elements.map((element, elementIndex) => (
              <>
                <Label>Element Type</Label>
                <Select
                  key={elementIndex}
                  value={element.element}
                  onValueChange={(v) =>
                    setGroups((groups) =>
                      groups.map((g, i) => {
                        if (index !== i) {
                          return g;
                        }

                        return {
                          ...g,
                          elements: g.elements.map((e, j) => {
                            if (elementIndex !== j) {
                              return e;
                            }

                            return {
                              ...e,
                              element: v,
                            };
                          }),
                        };
                      })
                    )
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="select type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="input">Input</SelectItem>
                    <SelectItem value="textarea">Text Area</SelectItem>
                    <SelectItem value="checkbox">Checkbox</SelectItem>
                  </SelectContent>
                </Select>

                <Label>Label</Label>
                <Input
                  value={JSON.parse(element.props).label}
                  onChange={({ target }) =>
                    setGroups((groups) =>
                      groups.map((g, i) => {
                        if (index !== i) {
                          return g;
                        }

                        return {
                          ...g,
                          elements: g.elements.map((e, j) => {
                            if (elementIndex !== j) {
                              return e;
                            }

                            return {
                              ...e,
                              props: JSON.stringify({ label: target.value }),
                            };
                          }),
                        };
                      })
                    )
                  }
                />

                <Label>Client Data Key</Label>
                <Input
                  value={element.clientDataKey}
                  onChange={({ target }) =>
                    setGroups((groups) =>
                      groups.map((g, i) => {
                        if (index !== i) {
                          return g;
                        }

                        return {
                          ...g,
                          elements: g.elements.map((e, j) => {
                            if (elementIndex !== j) {
                              return e;
                            }

                            return {
                              ...e,
                              clientDataKey: target.value,
                            };
                          }),
                        };
                      })
                    )
                  }
                />
              </>
            ))}
            <Button
              type="button"
              size="sm"
              className="mt-2"
              onClick={() =>
                setGroups((groups) =>
                  groups.map((g, i) => {
                    if (index !== i) {
                      return g;
                    }

                    return {
                      ...g,
                      elements: [...g.elements, { element: "", props: "{}", clientDataKey: "", array: false }],
                    };
                  })
                )
              }
            >
              <Icon icon="add_2" />
              Add Element
            </Button>
          </CardContent>
        </Card>
      ))}
      <Button
        type="button"
        size="sm"
        onClick={() => setGroups((groups) => [...groups, { title: "", description: "", elements: [] }])}
      >
        <Icon icon="add_2" />
        Add Group
      </Button>
    </div>
  );
};
