import { useAtom } from "jotai";

import {
  eventsBotAtom,
  eventsCommentAtom,
  eventsDescAtom,
  eventsEmailAtom,
  eventsOpportunityAtom,
  eventsSubmissionAtom,
  eventsUploadAtom,
  stateTransitionAtom,
} from "@/atoms";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Button } from "../ui/button";

export const EventFilter = ({ allActivity = false }: { allActivity?: boolean }) => {
  const [comment, setComment] = useAtom(eventsCommentAtom);
  const [opportunity, setOpportunity] = useAtom(eventsOpportunityAtom);
  const [submission, setSubmission] = useAtom(eventsSubmissionAtom);
  const [email, setEmail] = useAtom(eventsEmailAtom);
  const [bot, setBot] = useAtom(eventsBotAtom);
  const [upload, setUpload] = useAtom(eventsUploadAtom);
  const [desc, setDesc] = useAtom(eventsDescAtom);
  const [transitions, setTransitions] = useAtom(stateTransitionAtom);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" display="icon">
          <Icon icon="filter_list" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Sort Activity</DropdownMenuLabel>
        <DropdownMenuRadioGroup value={desc.toString()} onValueChange={(v) => setDesc(v === "true")}>
          <DropdownMenuRadioItem value="false">Newest First</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="true">Oldest First</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>Filter Activity</DropdownMenuLabel>
        <DropdownMenuCheckboxItem checked={email} onClick={() => setEmail(!email)}>
          Emails
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem checked={comment} onClick={() => setComment(!comment)}>
          Comments
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem checked={bot} onClick={() => setBot(!bot)}>
          Bot Comments
        </DropdownMenuCheckboxItem>
        {allActivity && (
          <>
            <DropdownMenuCheckboxItem checked={opportunity} onClick={() => setOpportunity(!opportunity)}>
              Opportunity Activity
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem checked={submission} onClick={() => setSubmission(!submission)}>
              Submission Activity
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem checked={upload} onClick={() => setUpload(!upload)}>
              Uploads
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem checked={transitions} onClick={() => setTransitions(!transitions)}>
              Transitions
            </DropdownMenuCheckboxItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
