import { first } from "lodash";
import { z } from "zod";

import { EmptyState } from "@/components/empty-state";
import { useModal } from "@/components/modal-provider";
import { SectionContent, SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { Input } from "@/forms/default";
import { useCreateTagMutation, useFileTagsQuery } from "src/generated/graphql";
import { TagMenu } from "../files/tag-menu";

export const Tags = () => {
  const { openForm } = useModal();
  const { toast } = useToast();

  const {
    data: { fileTags } = { fileTags: [] },
    loading,
    refetch,
  } = useFileTagsQuery({
    variables: { input: {} },
  });

  const [createTag] = useCreateTagMutation({
    onError: (e) => {
      toast({ title: e.message });
    },
    onCompleted: () => {
      toast({ title: `label created` });
      void refetch();
    },
    refetchQueries: ["FileTags"],
  });

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Tags</h1>
          {loading ? (
            <Spinner />
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={async () => {
                const formData = await openForm(
                  z.object({
                    label: z.string(),
                  }),
                  <div className="space-y-1.5">
                    <h5>Label</h5>
                    <Input name="label" />
                  </div>,
                  {
                    defaultValues: {
                      label: "",
                    },
                    title: "Add Tag",
                  }
                );

                if (formData) {
                  void createTag({
                    variables: {
                      input: {
                        label: formData.label,
                      },
                    },
                  });
                }
              }}
            >
              Add
            </Button>
          )}
        </SectionTitle>
      </SectionHeader>
      {fileTags[0] ? (
        <SectionContent>
          {fileTags
            .filter((t) => t?.parentTags?.length === 0)
            .map((t) => (
              <TagsRow key={t.label} label={t.label} depth={0} tags={fileTags} />
            ))}
        </SectionContent>
      ) : (
        <EmptyState loading={loading} title={<h2>No tags found.</h2>} />
      )}
    </>
  );
};

const TagsRow = ({
  label,
  depth,
  tags,
}: {
  label: string;
  depth: number;
  tags: NonNullable<ReturnType<typeof useFileTagsQuery>["data"]>["fileTags"];
}) => {
  const { data: tagData, refetch } = useFileTagsQuery({
    variables: {
      input: {
        label,
      },
    },
  });
  const tag = first(tagData?.fileTags);
  const paddingLeft = `${depth * 1}rem`;

  if (!tag) {
    return null;
  }

  return (
    <div style={{ paddingLeft }} className="my-2 text-sm">
      <div className="flex gap-3 items-center">
        <Icon icon="folder" />
        <span>{tag?.label}</span>
        <TagMenu allTags={tags} tag={tagData?.fileTags[0]} refetch={refetch} />
      </div>
      {tag.label !== "Acords" &&
        tag?.childTags?.map((t) => <TagsRow key={tag.label} label={t?.label} depth={depth + 1} tags={tags} />)}
    </div>
  );
};
