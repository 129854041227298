import { orderBy } from "lodash";
import { Link, Outlet } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRowHeader, GridRowLink } from "@/components/grid";
import { ScrollPane } from "@/components/scroll-pane";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useVerticalsQuery } from "src/generated/graphql";
import { cn } from "src/utils";

export const Verticals = () => {
  useDocumentTitle("Admin: Verticals");

  return (
    <ScrollPane>
      <Outlet />
    </ScrollPane>
  );
};

export const VerticalsList = () => {
  const { data: { verticals = [] } = {}, loading } = useVerticalsQuery();
  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Verticals</h1>
          {loading ? (
            <Spinner />
          ) : (
            <Button type="button" variant="outline" size="sm" asChild>
              <Link to="create">Create Vertical</Link>
            </Button>
          )}
        </SectionTitle>
      </SectionHeader>
      {verticals[0] ? (
        <Grid className="grid-cols-[0.75rem_1fr_4rem_3fr]">
          <GridRowHeader position="sticky">
            <GridCell />
            <GridCell>Vertical</GridCell>
            <GridCell>Templates</GridCell>
            <GridCell>CGL(s)</GridCell>
          </GridRowHeader>
          {orderBy(verticals, [(vertical) => !vertical.enabled, (vertical) => vertical.name.toLowerCase()]).map(
            (vertical) => (
              <GridRowLink key={vertical.id} to={`${vertical.id}/hierarchy`}>
                <Icon icon="circle" className={cn(vertical.enabled ? "filled text-success" : "opacity-25")} />
                <GridCell>{vertical.name}</GridCell>
                <GridCell>{vertical.marketingPlanTemplates.length}</GridCell>
                <Pills items={vertical.isoCglCodes.map((cgl) => cgl.classCode)} />
              </GridRowLink>
            )
          )}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No verticals found.</h2>} />
      )}
    </>
  );
};

const Pill = ({
  text,
  onClick,
  onDelete,
}: {
  text: string;
  onClick?: React.MouseEventHandler;
  onDelete?: (item: string) => void;
}) => (
  <Badge className="bg-muted gap-1 infline-flex justify-between tabular-nums text-foreground" onClick={onClick}>
    {text}
    {onDelete && (
      <Button
        variant="ghost"
        size="xs"
        display="icon"
        onClick={(e) => {
          e.preventDefault();
          onDelete(text);
        }}
      >
        <Icon icon="close" />
      </Button>
    )}
  </Badge>
);

const Pills = ({
  items,
  onClick,
  onDelete,
}: {
  items: string[];
  onClick?: React.MouseEventHandler;
  onDelete?: (item: string) => void;
}) => (
  <div className="flex gap-1 mr-3 py-3 truncate">
    {items.map((item) => (
      <Pill key={item} text={item} onClick={onClick} onDelete={onDelete} />
    ))}
  </div>
);
