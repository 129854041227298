import { createContext, CSSProperties, useContext, useEffect, useState } from "react";

import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom";
import { cn } from "src/utils";
import { Bar } from "./ui/bar";
import { Button, ButtonProps } from "./ui/button";
import { Icon } from "./ui/icon";
import { Separator } from "./ui/separator";

const SIDEBAR_WIDTH = "16rem";

export type Side = "right" | "left" | null;

interface SidebarState {
  side: Side;
  setSide: React.Dispatch<React.SetStateAction<Side>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  wide: boolean;
  setWide: React.Dispatch<React.SetStateAction<boolean>>;
  width: string;
  setWidth: React.Dispatch<React.SetStateAction<string>>;
}

const initialState: SidebarState = {
  side: null,
  setSide: () => null,
  open: true,
  setOpen: () => null,
  wide: true,
  setWide: () => null,
  width: SIDEBAR_WIDTH,
  setWidth: () => null,
};

const SidebarContext = createContext<SidebarState>(initialState);

export function SidebarProvider({ children, style, ...props }: { children: React.ReactNode; style?: CSSProperties }) {
  const [side, setSide] = useState<Side>(null);
  const [open, setOpen] = useState(true);
  const [wide, setWide] = useState(true);
  const [width, setWidth] = useState(SIDEBAR_WIDTH);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");

    const handleOrientationChange = (e: any) => {
      setOpen(e.matches);
      setWide(e.matches);
    };

    mediaQuery.addEventListener("change", handleOrientationChange);

    handleOrientationChange(mediaQuery);

    return () => mediaQuery.removeEventListener("change", handleOrientationChange);
  }, []);

  const value = {
    side,
    setSide,
    open,
    setOpen,
    wide,
    setWide,
    width,
    setWidth,
  };

  return (
    <SidebarContext.Provider {...props} value={value}>
      <div
        style={
          {
            "--sidebar-width": width,
            ...style,
          } as CSSProperties
        }
        className={cn("flex flex-auto relative", "md:backdrop-brightness-200 md:backdrop-opacity-50 md:rounded-md")}
      >
        {children}
      </div>
    </SidebarContext.Provider>
  );
}

export const useSidebar = () => {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }

  return context;
};

export const SidebarTrigger = ({ children, seperator = true, ...rest }: ButtonProps & { seperator?: boolean }) => {
  const { side, open, setOpen } = useSidebar();

  if (!side) {
    return null;
  }

  return (
    <div
      className={cn(
        "flex gap-2 items-center",
        side === "right" ? "flex-row-reverse -mr-1.5 order-last" : "-ml-1.5 order-first"
      )}
    >
      <Button variant="ghost" size="sm" display="icon" onClick={() => setOpen((open: boolean) => !open)} {...rest}>
        <Icon icon={open ? `${side}_panel_close` : `${side}_panel_open`} />
      </Button>
      {seperator && <Separator orientation="vertical" className="h-3" />}
    </div>
  );
};

export const Sidebar = ({
  children,
  bar,
  width = SIDEBAR_WIDTH,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & { bar?: React.ReactNode; width?: string }) => {
  const { open, setOpen, side, setWidth } = useSidebar();

  useEffect(() => {
    setWidth(width);
  }, []);

  return (
    <div
      className={cn(
        "flex flex-none inset-0 overflow-hidden transition-all",
        "max-md:absolute max-md:z-10",
        side === "right" && "justify-end",
        open
          ? "max-md:backdrop-blur-sm max-md:backdrop-saturate-150 md:w-[var(--sidebar-width)]"
          : "max-md:pointer-events-none max-md:opacity-0 md:w-0"
      )}
    >
      <aside
        className={cn(
          "flex flex-col relative w-[var(--sidebar-width)] z-10",
          "max-md:bg-accent max-md:m-2 max-md:shadow-xl max-md:transition-transform max-md:duration-300",
          side === "left"
            ? "max-md:rounded-md max-md:-translate-x-[var(--sidebar-width)]"
            : "max-md:rounded-md max-md:translate-x-[var(--sidebar-width)]",
          open && "max-md:translate-x-0"
        )}
        {...rest}
      >
        <div className="divide-foreground/10 divide-y flex flex-auto flex-col w-[var(--sidebar-width)]">{children}</div>
      </aside>
      <div className={cn("absolute md:hidden inset-0 w-0 z-0", open && "w-full")} onClick={() => setOpen(false)} />
    </div>
  );
};

export const SidebarHeader = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <Bar className={cn("bg-transparent border-0", className)} {...rest}>
    {children}
  </Bar>
);

export const SidebarFooter = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <footer className={cn("px-6 py-4 space-y-3", className)} {...rest}>
    {children}
  </footer>
);

export const SidebarContent = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("divide-foreground/10 divide-y flex-auto overflow-auto", className)} {...rest}>
    {children}
  </div>
);

export const SidebarSection = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <section className={cn("px-6 py-5 space-y-3", className)} {...rest}>
    {children}
  </section>
);

export const SidebarLinkClassName = "flex gap-3 items-center justify-between text-xs truncate";

export const SidebarLink = ({ children, className, to, ...rest }: LinkProps) => {
  const { setOpen, wide } = useSidebar();
  return (
    <Link to={to} className={cn(SidebarLinkClassName, className)} onClick={() => setOpen(wide)} {...rest}>
      {children}
    </Link>
  );
};

export const SidebarNavLink = ({ children, className, to, ...rest }: NavLinkProps) => {
  const { setOpen, wide } = useSidebar();
  return (
    <NavLink
      to={to}
      className={({ isPending, isActive }) =>
        cn(SidebarLinkClassName, isPending && "opacity-75", isActive && "filled font-semibold", className)
      }
      onClick={() => setOpen(wide)}
      {...rest}
    >
      {children}
    </NavLink>
  );
};
