import { createContext, useContext, useState } from "react";
import { useParams } from "react-router";

import { useToast } from "@/components/ui/use-toast";
import {
  FileUploadFragment,
  OpportunityEmailTemplate,
  useCreateEmailTemplateDraftMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

interface SubmissionTemplateState {
  createEmailDraft: (fileIds: string[]) => Promise<void>;
  insuredId: string;
  fileIds: string[];
  setFileIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFile?: FileUploadFragment;
  setSelectedFile: React.Dispatch<React.SetStateAction<FileUploadFragment | undefined>>;
  submissionId?: string;
  setSubmissionId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SubmissionTemplateContext = createContext<SubmissionTemplateState>({} as SubmissionTemplateState);

export function SubmissionTemplateProvider({ children }: { children: React.ReactNode }) {
  const { insuredId = "" } = useParams<"insuredId">();
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileUploadFragment | undefined>(undefined);
  const [submissionId, setSubmissionId] = useState<string>();
  const { toast } = useToast();
  const [createEmailTemplateDraft] = useCreateEmailTemplateDraftMutation();

  const createEmailDraft = async (fileIds: string[]) => {
    if (submissionId) {
      toast({ title: "Creating Email Draft", description: "Please wait..." });
    } else {
      toast({ variant: "destructive", title: "Error: No submission ID" });
    }

    await createEmailTemplateDraft({
      variables: {
        input: {
          submissionId: submissionId ?? "",
          template: OpportunityEmailTemplate.SubmitToCarrier,
          fileIds: fileIds,
        },
      },
      onCompleted: (data) => {
        if (data?.createEmailTemplateDraft) {
          window.open(data.createEmailTemplateDraft, "_blank");
        }
      },
      onError: (error) => {
        toast({ title: "Error", description: parseError(error), variant: "destructive" });
      },
    });
  };

  const value = {
    createEmailDraft,
    insuredId,
    fileIds,
    setFileIds,
    selectedFile,
    setSelectedFile,
    submissionId,
    setSubmissionId,
  };

  return <SubmissionTemplateContext.Provider value={value}>{children}</SubmissionTemplateContext.Provider>;
}

export const useSubmissionTemplate = () => {
  const context = useContext(SubmissionTemplateContext);

  if (context === undefined) {
    throw new Error("useSubmissionTemplate must be used within a SubmissionTemplateProvider");
  }

  return context;
};
