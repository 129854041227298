import { uniqBy } from "lodash";

import { EditorCount } from "@/components/editor";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { Button } from "@/components/ui/button";
import { Card, CardFooter } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { VerticalMarketingPlanTemplateFragment } from "src/generated/graphql";
import { TemplateHeader } from "./components/template-header";
import { useRequirements } from "./components/use-requirements";

export const TemplateRequirements = ({ template }: { template: VerticalMarketingPlanTemplateFragment }) => {
  const { addAcord125, addAcord126, addAcord140, addRequirement, updateRequirement, deleteRequirement } =
    useRequirements({ products: template.products });

  const requirements = uniqBy(
    template.products.flatMap((product) => product.requirements),
    (requirement) => requirement.title
  );

  return (
    <Card>
      <TemplateHeader template={template} />
      {requirements[0] && (
        <Grid className="grid-cols-[2fr_2fr_1fr_1.5rem_1.5rem]">
          <GridRowHeader>
            <GridCell>Title</GridCell>
            <GridCell>Description</GridCell>
            <GridCell>Label</GridCell>
            <GridCell />
            <GridCell />
          </GridRowHeader>
          {requirements.map((requirement) => (
            <GridRow key={requirement.id}>
              <GridCell>{requirement.title}</GridCell>
              <GridCell>{requirement.description}</GridCell>
              <GridCell>
                <EditorCount>{requirement.label}</EditorCount>
              </GridCell>
              <Button variant="ghost" size="sm" display="icon" onClick={() => updateRequirement(requirement.title)}>
                <Icon icon="edit" />
              </Button>
              <Button variant="ghost" size="sm" display="icon" onClick={() => deleteRequirement(requirement.title)}>
                <Icon icon="delete" />
              </Button>
            </GridRow>
          ))}
        </Grid>
      )}
      <CardFooter className="bg-accent border-t gap py-3 rounded-b-lg">
        <Button type="button" variant="outline" size="xs" onClick={addAcord125}>
          Acord 125
        </Button>
        <Button type="button" variant="outline" size="xs" onClick={addAcord126}>
          Acord 126
        </Button>
        <Button type="button" variant="outline" size="xs" onClick={addAcord140}>
          Acord 140
        </Button>
        <Button type="button" variant="outline" size="xs" onClick={addRequirement}>
          Custom
        </Button>
      </CardFooter>
    </Card>
  );
};
