import { useNavigate } from "react-router";
import { z } from "zod";

import { SectionHeader, SectionTitle } from "@/components/section";
import { toast } from "@/components/ui/use-toast";
import {
  CreateVerticalInput,
  UpdateVerticalInput,
  useCreateVerticalMutation,
  useUpdateVerticalMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";
import { useVertical } from "./components/use-vertical";
import { VerticalForm } from "./components/vertical-form";

const CreateSchema = z.object({
  name: z.string(),
  description: z.string(),
});

const UpdateSchema = CreateSchema.extend({
  id: z.string(),
});

export const EditVertical = () => {
  const navigate = useNavigate();
  const { vertical } = useVertical();

  const [create] = useCreateVerticalMutation({
    onError: (error) =>
      toast({ title: "Error creating vertical", description: parseError(error), variant: "destructive" }),
  });

  const [update] = useUpdateVerticalMutation({
    onError: (error) =>
      toast({ title: "Error updating vertical", description: parseError(error), variant: "destructive" }),
  });

  return vertical ? (
    <VerticalForm
      vertical={vertical}
      schema={UpdateSchema}
      onSubmit={(values, selectedCgls) => {
        void update({
          variables: {
            input: sanitizeUpdateInput({ ...values }, selectedCgls),
          },
          refetchQueries: ["Vertical"],
        });
      }}
      defaultValues={{
        id: vertical.id,
        name: vertical.name,
        description: vertical.description ?? "",
      }}
    />
  ) : (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Add Vertical</h1>
        </SectionTitle>
      </SectionHeader>
      <VerticalForm
        schema={CreateSchema}
        onSubmit={(values, selectedCgl) => {
          void create({
            variables: { input: sanitizeCreateInput(values, selectedCgl) },
            onCompleted: (data) => {
              navigate(data.createVertical.id);
            },
          });
        }}
      />
    </>
  );
};

const sanitizeCreateInput = (
  formValues: z.infer<typeof CreateSchema>,
  selectedCgls: Set<string>
): CreateVerticalInput => {
  const { name, description } = formValues;
  return {
    name: name.trim(),
    description: description?.length === 0 ? undefined : description.trim(),
    isoCglCodes: [...selectedCgls],
  };
};

const sanitizeUpdateInput = (
  formValues: z.infer<typeof UpdateSchema>,
  selectedCgls: Set<string>
): UpdateVerticalInput => {
  return {
    id: formValues.id,
    ...sanitizeCreateInput(formValues, selectedCgls),
  };
};
