import { useNodes } from "@xyflow/react";
import type { FC } from "react";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { ButtonGroup } from "@/forms-v2/button-group";
import { FieldDatePicker } from "@/forms-v2/fields/field-date-picker";
import { FieldHidden } from "@/forms-v2/fields/field-hidden";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { Form } from "@/forms-v2/form";
import { FormGroup } from "@/forms-v2/form-group";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import {
  FileProcessingPipelineQuery,
  FileProcessorCategory,
  FileProcessorQuery,
  RerunProcessorInput,
  useFileProcessingPipelineQuery,
  useFileProcessorQuery,
  useRerunProcessorMutation,
} from "src/generated/graphql";

import {
  convertPipelineDataToNodesAndEdges,
  findNodeById,
  findParentNodeByChildId,
} from "../../file-processing-pipeline.helpers";

export interface RerunProcessorFormValues {
  id: string;
  startDate: Date | null;
  endDate: Date | null;
  maxFiles: string;
}

export interface RerunProcessorFormProps {
  processorId: string;
  processor?: FileProcessorQuery["fileProcessor"];
}

const validationSchema = z.object({
  id: z.string().min(1),
  startDate: z.date({ coerce: true }).optional(),
  endDate: z.date({ coerce: true }).optional(),
  // SEE: https://github.com/colinhacks/zod/discussions/330
  maxFiles: z
    .number({ coerce: true })
    .int()
    .optional()
    .refine((value) => Number(value) >= 0, { message: "Must be a positive number" }),
});

const defaultMaxFiles = 500;

export const RerunProcessorFormContent: FC<RerunProcessorFormProps> = ({ processor }) => {
  const { closeModal } = useModal();

  return (
    <FormGroup className="gap-6 mt-2">
      <FormGroup>
        <FormGroup orientation="horizontal">
          <FieldDatePicker name="startDate" label="Start date" placeholder="Pick a start date" />
          <FieldDatePicker name="endDate" label="End date" placeholder="Pick an end date" />
        </FormGroup>

        <FieldInput type="number" name="maxFiles" label="Max files" placeholder={`${defaultMaxFiles} (default)`} />
      </FormGroup>

      <Alert>
        <div className="flex items-start gap-3">
          <Icon icon="arrow_range" className="text-lg" />
          <div>
            <AlertTitle>
              Page range:{" "}
              <span className="inline-block bg-secondary py-1 px-1 rounded-sm mx-0.5 -my-1">
                {processor?.startPage || 1}
              </span>{" "}
              <em>to</em>{" "}
              <span className="inline-block bg-secondary py-1 px-1 rounded-sm mx-0.5 -my-1">
                {processor?.endPage || <>end</>}
              </span>
            </AlertTitle>
            <AlertDescription className="text-muted-foreground">
              The above page range will be used when running this processor. You may modify this in the processor
              settings.
            </AlertDescription>
          </div>
        </div>

        <Separator className="-mx-4 my-4 w-auto" />

        <div className="flex items-start gap-3">
          <Icon icon="paid" className="text-lg" />
          <div>
            <AlertTitle>Estimating the processing cost</AlertTitle>
            <AlertDescription className="text-muted-foreground flex flex-col gap-2">
              <p>Processing costs are calculated based on the following rates:</p>
              <ul className="list-disc list-outside pl-5">
                <li>$5 per 1,000 pages for classifiers</li>
                <li>$5 per 1,000 pages for splitters</li>
                <li>$30 per 1,000 pages for extractors</li>
              </ul>
              <p>
                Please note that files could have a variable number of pages, so there is not a one-to-one correlation
                between the max files set and the number of pages processed.
              </p>
            </AlertDescription>
            {/* <AlertTitle>Estimated Cost: TBD</AlertTitle>
            <AlertDescription className="text-muted-foreground">
              This estimate is based on the number of documents to be reprocessed per the date range and max files
              above.
            </AlertDescription> */}
          </div>
        </div>
      </Alert>

      <FieldHidden name="id" />

      <ButtonGroup className="justify-end">
        <FormReset onClick={closeModal}>Cancel</FormReset>
        <FormSubmit>Rerun processor</FormSubmit>
      </ButtonGroup>
    </FormGroup>
  );
};

export const RerunProcessorForm: FC<RerunProcessorFormProps> = (props) => {
  const { closeModal } = useModal();
  const { toast } = useToast();
  const [rerunProcessor] = useRerunProcessorMutation();
  const { data: pipelineData, loading: pipelineDataLoading } = useFileProcessingPipelineQuery();
  const { data: fileProcessorData, loading: fileProcessorDataLoading } = useFileProcessorQuery({
    variables: { id: props.processorId },
  });

  const defaultValues = {
    id: props.processorId,
    startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
    endDate: new Date(),
    maxFiles: "",
  };

  const handleSubmit = async (values: RerunProcessorFormValues) => {
    if (!pipelineData) {
      return;
    }

    const input = getRerunProcessorInput(values, pipelineData.fileProcessingPipeline);

    if (!input) {
      return;
    }

    await rerunProcessor({
      variables: { input },
      refetchQueries: ["FileProcessingPipeline"],
    });

    toast({ title: <>Rerun processor initiated for {input.name}</> });
    closeModal();
  };

  if (!pipelineData && pipelineDataLoading && !fileProcessorData && fileProcessorDataLoading) {
    return <Loading />;
  }

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      <RerunProcessorFormContent processor={fileProcessorData?.fileProcessor} {...props} />
    </Form>
  );
};

export interface UseRerunProcessorFormModalOptions {
  processorId?: string;
}

export const useRerunProcessorFormModal = ({ processorId }: UseRerunProcessorFormModalOptions) => {
  const { openModal } = useModal();
  const nodes = useNodes();

  const processor = findNodeById(processorId, nodes);

  return {
    openRerunProcessorForm: async () => {
      if (!processorId || !processor) {
        return;
      }

      await openModal(() => <RerunProcessorForm processorId={processorId} />, {
        title: "Rerun processor",
        description: "Choose a time frame and/or a max document count to limit the number of documents to process.",
      });
    },
  };
};

function getRerunProcessorInput(
  values: RerunProcessorFormValues,
  pipelineData: FileProcessingPipelineQuery["fileProcessingPipeline"]
): RerunProcessorInput | null {
  // QUESTION: Can we just use the `useNodes` and `useEdges` hooks here?
  const { nodes, edges } = convertPipelineDataToNodesAndEdges(pipelineData);

  const currentNode = findNodeById(values.id, nodes);

  if (!currentNode) {
    return null;
  }

  const parentNode = findParentNodeByChildId(currentNode.id, nodes, edges);

  if (!parentNode) {
    return null;
  }

  const grandparentNode = findParentNodeByChildId(parentNode.id, nodes, edges);

  if (!grandparentNode) {
    return null;
  }

  return {
    id: values.id,
    startDate: values.startDate,
    endDate: values.endDate,
    maxFiles: Number(values.maxFiles) || defaultMaxFiles,
    name: currentNode?.data.name as string,
    category: currentNode?.data.category as FileProcessorCategory,
    label: parentNode?.data.name as string,
    parentProcessorId: grandparentNode?.data.id as string,
    parentProcessorCategory: grandparentNode?.data.category as FileProcessorCategory,
  };
}
