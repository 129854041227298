import { Link } from "react-router-dom";

import { useModal } from "@/components/modal-provider";
import { MoreMenu } from "@/components/more-menu";
import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import {
  MarketingPlanAction,
  OpportunityDetailsFragment,
  useTransitionMarketingPlanMutation,
} from "src/generated/graphql";
import RulesForm from "./rules-form";

export const Marketing = ({ marketingPlan }: { marketingPlan: OpportunityDetailsFragment }) => {
  const [transition] = useTransitionMarketingPlanMutation();
  const { openConfirmation } = useModal();

  const handleTransition = async (action: MarketingPlanAction) => {
    const isConfirmed = await openConfirmation({
      title: "Confirm marketing complete",
    });

    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          id: marketingPlan.id,
          expectedState: marketingPlan.state,
          action,
        },
      },
    });
  };

  return (
    <div className="flex gap-2 items-center">
      <div className="@xl/opp:flex gap-2 hidden items-center">
        <Button variant="outline" size="xs" asChild>
          <Link to="markets">Select Markets</Link>
        </Button>
        <Button variant="outline" size="xs" onClick={() => handleTransition(MarketingPlanAction.CompleteMarketing)}>
          Complete Marketing
        </Button>
      </div>
      <MoreMenu>
        <DropdownMenuItem asChild>
          <Link to="markets">
            <Icon icon="checklist" />
            Select Markets
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleTransition(MarketingPlanAction.CompleteMarketing)}>
          <Icon icon="verified" />
          Complete Marketing
        </DropdownMenuItem>
        <RulesForm />
      </MoreMenu>
    </div>
  );
};
