import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { FilesByLabelQuery, useFilesByLabelQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { SubmissionTemplateAttachments } from "./submission-template-attachments";
import { SubmissionTemplateForm } from "./submission-template-form";
import { SubmissionTemplatePdf } from "./submission-template-pdf";
import { useSubmissionTemplate } from "./use-submission-template";

export type FilesByLabel = FilesByLabelQuery["filesByLabel"][number];

export const SubmissionTemplateModal = ({ submissionId }: { submissionId: string }) => {
  const { createEmailDraft, fileIds, insuredId, selectedFile, setSelectedFile, setSubmissionId } =
    useSubmissionTemplate();

  const { data: { filesByLabel: linkedFiles = [] } = {}, loading } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId,
        includeGmailAttachments: false,
        includeCorrespondence: false,
        labels: [],
        deletedAt: false,
      },
    },
  });

  // Concurrently load all files (incl. email attachments), because this can take a while
  const { data: { filesByLabel: allFiles = [] } = {} } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId,
        includeGmailAttachments: true,
        includeCorrespondence: false,
        labels: [],
        deletedAt: false,
      },
    },
  });

  const files = allFiles.length > 0 ? allFiles : linkedFiles;

  return (
    <AlertDialog
      onOpenChange={(open) => {
        if (open) {
          setSubmissionId(submissionId);
          setSelectedFile(undefined);
        }
      }}
    >
      <AlertDialogTrigger asChild>
        <Button variant="ghost" size="sm" display="icon">
          <Icon icon="mail" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="flex flex-col gap-0 h-dvh min-w-full p-4 pb-0">
        <AlertDialogHeader className="hidden">
          <AlertDialogTitle>Submission Template</AlertDialogTitle>
          <AlertDialogDescription>Submission Template</AlertDialogDescription>
        </AlertDialogHeader>
        <div className={cn("flex-auto overflow-y-auto relative", selectedFile && gridClassName)}>
          <SubmissionTemplatePdf files={files} />
          <div className={cn(selectedFile ? "flex flex-col" : gridClassName)}>
            <SubmissionTemplateForm />
            <SubmissionTemplateAttachments loading={loading} files={files} />
          </div>
        </div>
        <AlertDialogFooter className="border-t h-16">
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => createEmailDraft(fileIds)}>Create Draft</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const gridClassName = "gap-4 grid grid-cols-[3fr_2fr] items-start";
