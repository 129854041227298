import { useEffect } from "react";
import { useParams } from "react-router";
import { z } from "zod";

import { Input } from "@/forms/default";
import { RequirementElementProps } from "@/requirements/requirement-elements/requirement-element-props";
import {
  InsuredPremiseBuildingFragment,
  useEditInsuredPremiseBuildingMutation,
  useInsuredPremiseLocationsQuery,
} from "src/generated/graphql";
import { Editor, EditorCount, EditorHeader, EditorItem, EditorRow } from "./editor";
import { useModal } from "./modal-provider";
import { Button } from "./ui/button";
import { Icon } from "./ui/icon";
import { Loading } from "./ui/loading";
import { useToast } from "./ui/use-toast";

const WriteBuildingSchema = z.object({
  wiringYear: z.string().min(4),
  roofingYear: z.string().min(4),
  heatingYear: z.string().min(4),
  plumbingYear: z.string().min(4),
  roofType: z.string().min(2),
  constructionType: z.string().min(2),
  yearBuilt: z.string().min(4),
  burglarAlarmType: z.string().min(2),
  sprinklerPercent: z.string().min(1),
});

export default function PropertyEditor({
  onSubmissionRequirementsMet,
  onSubmissionRequirementsNotMet,
}: RequirementElementProps) {
  const { insuredId } = useParams();
  const { openForm } = useModal();
  const { toast } = useToast();

  const { data, loading, refetch } = useInsuredPremiseLocationsQuery({ variables: { id: insuredId! } });

  useEffect(() => {
    const reqsMet =
      data?.locations.every((location) =>
        location.buildings.every((building) => {
          return (
            building.wiringYear &&
            building.roofingYear &&
            building.heatingYear &&
            building.plumbingYear &&
            building.roofType &&
            building.constructionType &&
            building.yearBuilt &&
            building.burglarAlarmType
          );
        })
      ) ?? false;

    if (reqsMet) {
      onSubmissionRequirementsMet();
    } else {
      onSubmissionRequirementsNotMet("Fill in all building information");
    }
  }, [data?.locations]);

  const [editBuildingTrigger] = useEditInsuredPremiseBuildingMutation({
    async onCompleted() {
      await refetch();
      toast({ title: "Building edited" });
    },
    onError(e) {
      if (e.message.includes("Unique constraint")) {
        toast({ title: "Error editing building: duplicate location number" });
      } else {
        toast({ title: "Error editing building" });
      }
    },
  });

  if (loading || !data || !data.locations) {
    return <Loading />;
  }

  const locations = data.locations;

  const editBuilding = async (building: InsuredPremiseBuildingFragment & { locationNumber: number }) => {
    const fd = await openForm(WriteBuildingSchema, <WriteBuildingForm />, {
      defaultValues: {
        wiringYear: building.wiringYear ?? undefined,
        roofingYear: building.roofingYear ?? undefined,
        heatingYear: building.heatingYear ?? undefined,
        plumbingYear: building.plumbingYear ?? undefined,
        roofType: building.roofType ?? undefined,
        constructionType: building.constructionType ?? undefined,
        yearBuilt: building.yearBuilt ?? undefined,
        burglarAlarmType: building.burglarAlarmType ?? undefined,
        sprinklerPercent: building.sprinklerPercent ?? undefined,
      },
      title: "Edit Building",
    });
    if (!fd) {
      return;
    }

    await editBuildingTrigger({
      variables: {
        input: {
          locationId: building.locationId,
          buildingId: building.id,
          buildingNumber: building.buildingNumber,
          annualRevenue: building.annualRevenue?.toString() ?? "",
          ...fd,
        },
      },
    });
  };

  return (
    <Editor>
      <EditorHeader
        title="Edit Property Details"
        columns={[
          "Bldg. #",
          "Wiring Year",
          "Roofing Year",
          "Heating Year",
          "Plumbing Year",
          "Roof Type",
          "Construction Type",
          "Year Built",
          "Alarm Type",
          "Sprinkler Percent",
          "",
        ]}
      ></EditorHeader>
      {locations
        .flatMap((location) =>
          location.buildings.map((building) => ({ ...building, locationNumber: location.locationNumber }))
        )
        .map((building) => (
          <EditorItem key={building.id}>
            <EditorRow>
              <div>
                <EditorCount>
                  L{building.locationNumber} - B{building.buildingNumber}
                </EditorCount>
              </div>
              <div>{building.wiringYear}</div>
              <div>{building.roofingYear}</div>
              <div>{building.heatingYear}</div>
              <div>{building.plumbingYear}</div>
              <div>{building.roofType}</div>
              <div>{building.constructionType}</div>
              <div>{building.yearBuilt}</div>
              <div>{building.burglarAlarmType}</div>
              <div>{building.sprinklerPercent}</div>
              <div className="flex gap items-center justify-end">
                <Button type="button" variant="ghost" size="sm" display="icon" onClick={() => editBuilding(building)}>
                  <Icon icon="edit" />
                </Button>
              </div>
            </EditorRow>
          </EditorItem>
        ))}
    </Editor>
  );
}

function WriteBuildingForm() {
  return (
    <div className="space-y-3">
      <div className="space-y-1.5">
        <h5>Wiring Year</h5>
        <Input name="wiringYear" />
      </div>
      <div className="space-y-1.5">
        <h5>Roofing Year</h5>
        <Input name="roofingYear" />
      </div>
      <div className="space-y-1.5">
        <h5>Heating Year</h5>
        <Input name="heatingYear" />
      </div>
      <div className="space-y-1.5">
        <h5>Plumbing Year</h5>
        <Input name="plumbingYear" />
      </div>
      <div className="space-y-1.5">
        <h5>Roof Type</h5>
        <Input name="roofType" />
      </div>
      <div className="space-y-1.5">
        <h5>Construction Type</h5>
        <Input name="constructionType" />
      </div>
      <div className="space-y-1.5">
        <h5>Year Built</h5>
        <Input name="yearBuilt" />
      </div>
      <div className="space-y-1.5">
        <h5>Burglar Alarm Type</h5>
        <Input name="burglarAlarmType" />
      </div>
      <div className="space-y-1.5">
        <h5>Sprinkler Percent</h5>
        <Input name="sprinklerPercent" />
      </div>
    </div>
  );
}
