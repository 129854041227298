import { Button } from "@/components/ui/button";
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { File_Audience, useUpdateFileUploadMutation } from "src/generated/graphql";
import { RenameFileContent } from "./file-menu";
import TagSelector from "./tag-selector";

export const EditFileDialog = ({ fileId, setFileId }: { fileId: string; setFileId: (v: any) => void }) => {
  const { toast } = useToast();
  const [update] = useUpdateFileUploadMutation();

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Edit Permissions</DialogTitle>
        <DialogDescription>Select who should have access to this file</DialogDescription>
        <DialogDescription>
          The default is `All Users`. Please select `Internal Users Only` if you do not want the agent to have access to
          this file.
        </DialogDescription>
      </DialogHeader>
      <div>
        <Select
          defaultValue={File_Audience.Internal}
          onValueChange={(value) => {
            void update({
              variables: {
                input: {
                  id: fileId,
                  audience: value as File_Audience,
                },
              },
              onError: (e) => {
                toast({ title: e.message });
              },
              onCompleted: () => {
                toast({ title: `Permission Updated` });
              },
              refetchQueries: ["FilesByLabel"],
            });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Audience" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={File_Audience.Internal}>Internal Users Only</SelectItem>
            <SelectItem value={File_Audience.External}>All Users</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <DialogHeader>
        <DialogTitle>Add Tags</DialogTitle>
      </DialogHeader>
      <div>
        <div>
          <TagSelector fileId={fileId} />
        </div>
        <Separator />
        <div className="my-2">
          <DialogHeader>
            <DialogTitle>Edit Filename</DialogTitle>
          </DialogHeader>
          <RenameFileContent fileId={fileId} />
        </div>
        <div>
          <Button className="my-2 w-full" variant="ghost" onClick={() => setFileId(undefined)}>
            Close
          </Button>
        </div>
      </div>
    </DialogContent>
  );
};
