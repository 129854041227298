import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useState } from "react";
import { PolicyAction, PolicyFileType, PolicyFragment, useTransitionPolicyMutation } from "src/generated/graphql";
import { cn } from "../../../utils";
import { uploadPolicyDocument } from "../../../utils/file";
import { Spinner } from "../../components/ui/loading";

export const AwaitingBinderFromCarrier = ({
  policy: { id: policyId, state },
  refetch,
}: {
  policy: PolicyFragment;
  refetch: () => void;
}) => {
  const [transition, { loading: transitionLoading }] = useTransitionPolicyMutation();
  const [submitting, setSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSkipBinderTransition = async () => {
    await transition({
      variables: {
        input: {
          id: policyId,
          expectedState: state,
          action: PolicyAction.SkipBinder,
        },
      },
    });
  };

  return (
    <>
      <Button asChild variant="outline" size="sm" display="flex">
        <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
          <input
            type="file"
            name="file"
            className="hidden"
            onChange={async (e) => {
              setSubmitting(true);
              if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];

                if (file.type !== "application/pdf") {
                  toast({ title: "We only accept PDF files" });
                  setSubmitting(false);
                  return;
                }
                await uploadPolicyDocument(file, policyId, PolicyFileType.RedactedBinder).then((res) => {
                  if (res.success) {
                    toast({ title: "Redacted Binder Uploaded" });
                    refetch();
                  } else {
                    toast({ title: "Error" });
                  }
                });
              }
              setSubmitting(false);
            }}
          />
          {submitting ? <Spinner /> : "Upload Redacted Binder"}
        </label>
      </Button>
      <Button
        onClick={handleSkipBinderTransition}
        variant="outline"
        size="sm"
        display="flex"
        disabled={transitionLoading}
      >
        No Binder Required
      </Button>
    </>
  );
};
