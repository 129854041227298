import { useTheme } from "@/components/theme-provider";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@radix-ui/react-tooltip";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { daysDelta } from "./bankan-cards";

type BankanScore =
  BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number]["marketingPlans"][number]["score"];

export const BankanScore = ({ bankanScore }: { bankanScore: BankanScore }) => {
  const { theme } = useTheme();

  if (!bankanScore) {
    return null;
  }

  return (
    <div className="flex gap-0.5 text-[8px]">
      {Array.from({ length: bankanScore.score }).map((_, index) => (
        <span key={index}>{theme === "beef" ? "🍤" : "🌶️"}</span>
      ))}
    </div>
  );
};

export const OldBankanScore = ({
  bankanScore,
  lastTransitionAt,
}: {
  bankanScore: BankanScore;
  lastTransitionAt: string;
}) => {
  const { theme } = useTheme();

  if (!bankanScore) {
    return null;
  }

  const { affiliationScore, bindRatioScore, bindScore, effectiveDateScore, score, verticalScore } = bankanScore;

  const Score = () => (
    <div className="flex gap-0.5 text-[8px]">
      {Array.from({ length: score }).map((_, index) => (
        <span key={index}>{theme === "beef" ? "🍤" : "🌶️"}</span>
      ))}
    </div>
  );

  return (
    <Tooltip delayDuration={111}>
      <TooltipTrigger>
        <Score />
      </TooltipTrigger>
      <TooltipContent align="end" alignOffset={-50} side="bottom" sideOffset={20} className="z-30">
        <Card className="text-xs w-64">
          <header className="border-b flex items-center p-3">
            Time in this column
            <span className="font-semibold ml-auto">{daysDelta(lastTransitionAt)} days</span>
          </header>
          <div className="gap-x-4 gap-y-2 grid grid-cols-[1fr_1rem] p-3">
            <div>Effective Date +/- 7 days</div>
            <div className="text-right">
              <Check value={effectiveDateScore} />
            </div>
            <div>In a vertical</div>
            <div className="text-right">
              <Check value={verticalScore} />
            </div>
            <div>Affiliated Agency</div>
            <div className="text-right">
              <Check value={affiliationScore} />
            </div>
            <div>Less than 5 Agency Binds</div>
            <div className="text-right">
              <Check value={bindScore} />
            </div>
            <div>Agency Bind Ratio less than 25%</div>
            <div className="text-right">
              <Check value={bindRatioScore} />
            </div>
          </div>
        </Card>
      </TooltipContent>
    </Tooltip>
  );
};

const Check = ({ value }: { value: number }) => (
  <Icon className={cn("filled", value ? "text-success" : "text-muted-foreground")} icon={value ? "circle" : "close"} />
);
