import { Outlet } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { ScrollPane } from "@/components/scroll-pane";

export const LabelManagement = () => {
  useDocumentTitle("Label Management");
  return (
    <ScrollPane>
      <Outlet />
    </ScrollPane>
  );
};
