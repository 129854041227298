import { FC, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { PrimaryLabelQuery } from "src/generated/graphql";

import { DeletePrimaryLabelDialog } from "./delete-primary-label-dialog";
import { UpdatePrimaryLabelDialog } from "./update-primary-label-dialog";

export interface PrimaryLabelsGridRowActionsProps {
  row: PrimaryLabelQuery["primaryLabel"];
}

export const PrimaryLabelGridRowActions: FC<PrimaryLabelsGridRowActionsProps> = ({ row }) => {
  const [dialog, setDialog] = useState<"edit" | "delete" | null>(null);

  const handleSelectEdit = () => setDialog("edit");

  const handleSelectDelete = () => setDialog("delete");

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" display="icon" className="text-base font-bold">
            <Icon icon="more_horiz" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent>
          <DropdownMenuItem onSelect={handleSelectEdit}>
            <Icon icon="edit" />
            Edit
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={handleSelectDelete}>
            <Icon icon="delete" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <UpdatePrimaryLabelDialog
        open={dialog === "edit"}
        onOpenChange={(open) => setDialog(open ? "edit" : null)}
        label={row}
      />

      <DeletePrimaryLabelDialog
        open={dialog === "delete"}
        onOpenChange={(open) => setDialog(open ? "delete" : null)}
        label={row}
      />
    </>
  );
};
