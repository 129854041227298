import { coerceBoolean } from "@cp/toolkit";
import { MarketingPlanState } from "src/generated/graphql";

export const adaptSearchParams = (params: URLSearchParams) => {
  const getParam = (key: string) => params.get(key) ?? undefined;

  return {
    states: getParam("states")
      ? (getParam("states")!.split(",") as MarketingPlanState[])
      : getParam("term") // Update this
      ? undefined
      : [MarketingPlanState.Marketing],
    agencyId: getParam("agencyId"),
    agentId: getParam("agentId"),
    term: getParam("term"),
    isTest: coerceBoolean(getParam("isTest")),
    hoursSinceLastActivity: getParam("hoursSinceLastActivity")
      ? Number.parseInt(getParam("hoursSinceLastActivity")!)
      : undefined,
    effectiveDate: getParam("effectiveDate") ? Number.parseInt(getParam("effectiveDate")!) : undefined,
    dateRange: getParam("dateRange") ? Number.parseInt(getParam("dateRange")!) : undefined,
    brokerId: getParam("brokerId") === "null" ? null : getParam("brokerId") || undefined,
    submissionCoreLines: getParam("submissionCoreLines") ? getParam("submissionCoreLines")!.split(",") : undefined,
    page: Number(getParam("page")) || 1,
  };
};
