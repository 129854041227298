import { useToggle } from "usehooks-ts";

import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { ScoredAppetiteProductFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import { Product } from "./product";

export const ProductChecklist: React.FC<{
  label: string;
  products: ScoredAppetiteProductFragment[];
  selectedProductIds: string[] | undefined;
  requestedLines: string[];
  isDirectAppointment?: boolean;
}> = ({ products, selectedProductIds, label, requestedLines, isDirectAppointment }) => {
  const [showMore, toggleShowMore] = useToggle(false);

  const preferredProducts = products.filter((p) => p.score > 0);
  const showShowMore = products.length > preferredProducts.length;
  const visibleProducts = showMore ? products : products.filter((p) => p.score > 0);

  return (
    <Card
      className={cn("bg-muted overflow-hidden space-y-px", { "text-muted-foreground": isDirectAppointment })}
      key={label}
    >
      <CardHeader className="bg-background">
        <CardTitle>
          <span className="flex-auto">{isDirectAppointment ? `${label} (Direct Appointment)` : label}</span>
          {showShowMore && (
            <Button
              type="button"
              variant="ghost"
              size="sm"
              display="icon"
              className="-mr-0.5"
              onClick={() => toggleShowMore()}
            >
              <Icon icon={showMore ? "unfold_less" : "unfold_more"} />
            </Button>
          )}
        </CardTitle>
      </CardHeader>
      <div className="space-y-px">
        {visibleProducts
          .sort((a, b) => b.score - a.score)
          .map((scoredProduct) => {
            return (
              <Product
                key={scoredProduct.product.id}
                scoredProduct={scoredProduct}
                selectedProductIds={selectedProductIds}
                requestedLines={requestedLines}
                disabled={isDirectAppointment}
              />
            );
          })}
      </div>
    </Card>
  );
};
