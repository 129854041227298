import { FC, PropsWithChildren, ReactNode } from "react";

import { SectionHeader, SectionTitle } from "@/components/section";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { Spinner } from "@/components/ui/loading";

export interface LabelManagementPageTemplateProps {
  loading?: boolean;
  actions?: ReactNode;
}

export const LabelManagementPageTemplate: FC<PropsWithChildren<LabelManagementPageTemplateProps>> = ({
  loading,
  actions,
  children,
}) => (
  <>
    <SectionHeader>
      <SectionTitle>
        <h1 className="mr-auto">Label Management</h1>
        <div className="flex-1" />
        {loading && <Spinner />}
        {actions}
      </SectionTitle>
    </SectionHeader>

    <Bar>
      <BarLinks>
        <BarLink to="/label-management" end>
          Extracted Labels
        </BarLink>
        <BarLink to="/label-management/primary-labels">Primary Labels</BarLink>
      </BarLinks>
    </Bar>

    {children}
  </>
);
