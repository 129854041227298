import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ClientDataRuleItem, ClientDataRuleValueType, Rule, RuleType } from "../rule";

export const TemplateRuleForm = ({ existingRule }: { existingRule?: Rule }) => {
  const { setValue } = useFormContext();
  const [currentRule, setCurrentRule] = useState<Rule | undefined>(existingRule);

  useEffect(() => {
    setValue("rule", currentRule);
  }, [currentRule]);

  return (
    <div className="space-y-3">
      <div className="space-y-1.5">
        <h5>Type</h5>
        <Select
          value={currentRule?.[0]?.type}
          onValueChange={(v: RuleType) =>
            setCurrentRule(
              v === RuleType.ClientData
                ? [{ type: RuleType.ClientData, key: "", valueType: ClientDataRuleValueType.Text }]
                : [{ type: v }]
            )
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Select Rule Type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={RuleType.MinimumPremium}>Minimum Premium</SelectItem>
            <SelectItem value={RuleType.ClientData}>Client Data</SelectItem>
            <SelectItem value={RuleType.State}>State</SelectItem>
            <SelectItem value={RuleType.CGL}>CGL</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <RuleEditor rule={currentRule} onRuleChange={setCurrentRule} />
    </div>
  );
};

function RuleEditor({ rule, onRuleChange }: { rule?: Rule; onRuleChange: (rule: Rule) => void }) {
  switch (rule?.[0]?.type) {
    case RuleType.ClientData:
      return <ClientDataRule rule={rule as ClientDataRuleItem[]} onRuleChange={onRuleChange} />;
    default:
      return null;
  }
}

function ClientDataRule({ rule, onRuleChange }: { rule?: ClientDataRuleItem[]; onRuleChange: (rule: Rule) => void }) {
  const parsedRule = rule ?? [];
  return (
    <>
      <div className="space-y-1.5">
        <h5>Client Data Key</h5>
        <Input
          type="text"
          value={parsedRule[0]?.key}
          onChange={({ target }) =>
            onRuleChange([
              {
                type: RuleType.ClientData,
                key: target.value,
                valueType: parsedRule[0]?.valueType ?? ClientDataRuleValueType.Text,
              },
            ])
          }
        />
      </div>
      <div className="space-y-1.5">
        <h5>Value Type</h5>
        <Select
          value={parsedRule[0]?.valueType}
          onValueChange={(v: ClientDataRuleValueType) =>
            onRuleChange([{ type: RuleType.ClientData, key: parsedRule[0]?.key, valueType: v }])
          }
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            {Object.values(ClientDataRuleValueType).map((v) => (
              <SelectItem key={v} value={v}>
                {v}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </>
  );
}
