import { Outlet, useNavigate, useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { MoreMenu } from "@/components/more-menu";
import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { Link } from "react-router-dom";
import { useDeleteVerticalMutation, useUpdateVerticalMutation, useVerticalQuery } from "src/generated/graphql";
import { parseError } from "src/utils";
import { AddTemplate } from "./components/add-template";
import { useVertical } from "./components/use-vertical";

export const Vertical = () => {
  const { vertical, loading } = useVertical();
  useDocumentTitle(`Admin: Verticals: ${vertical.name}`);

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>{vertical.name}</h1>
          {loading && <Spinner />}
        </SectionTitle>
        <SectionDescription>{vertical.description}</SectionDescription>
      </SectionHeader>
      <Bar>
        <BarLinks>
          <BarLink to="hierarchy">Hierarchy</BarLink>
          <BarLink to="rules">Rules</BarLink>
          <BarLink to="requirements">Requirements</BarLink>
          <BarLink to="exclusion-reasons">Exclusion Reasons</BarLink>
          <BarLink to="appetite">Appetite</BarLink>
        </BarLinks>
        <AddTemplate />
      </Bar>
      <Outlet />
    </>
  );
};

export const VerticalCrumb = () => {
  const { verticalId } = useParams();

  const { data: { vertical } = {} } = useVerticalQuery({
    variables: { id: verticalId! },
    skip: !verticalId,
  });

  if (!vertical) {
    return null;
  }

  return <Link to={`/verticals/${vertical.id}`}>{vertical.name}</Link>;
};

export const VerticalMenu = () => {
  const { verticalId } = useParams();
  const navigate = useNavigate();

  const { data: { vertical } = {}, refetch } = useVerticalQuery({
    variables: { id: verticalId! },
    skip: !verticalId,
  });

  const [update] = useUpdateVerticalMutation({
    onCompleted() {
      void refetch();
    },
  });

  if (!vertical) {
    return null;
  }

  return (
    <AlertDialog>
      <MoreMenu>
        <DropdownMenuItem onClick={() => navigate(`${vertical.id}/edit`)}>
          <Icon icon="edit" />
          Edit Vertical
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>
          <Icon icon="visibility" /> Visibility
        </DropdownMenuLabel>
        <DropdownMenuRadioGroup
          value={vertical.enabled ? "enabled" : "disabled"}
          onValueChange={(v) =>
            update({
              variables: { input: { id: vertical.id, name: vertical.name, enabled: v === "enabled" ? true : false } },
            })
          }
        >
          <DropdownMenuRadioItem value="enabled">Enabled</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="disabled">Disabled</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />
        <AlertDialogTrigger asChild>
          <DropdownMenuItem>
            <Icon icon="delete" />
            Delete Vertical
          </DropdownMenuItem>
        </AlertDialogTrigger>
      </MoreMenu>
      <DeleteVertical id={vertical.id} />
    </AlertDialog>
  );
};

const DeleteVertical = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const [deleteVertical] = useDeleteVerticalMutation({
    onCompleted: () => navigate("/verticals"),
    onError: (error) =>
      toast({ title: "Error deleting vertical", description: parseError(error), variant: "destructive" }),
  });

  return (
    <>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="gap-3 grid grid-cols-2">
          <AlertDialogCancel>No</AlertDialogCancel>
          <AlertDialogAction
            theme="destructive"
            onClick={() => {
              void deleteVertical({ variables: { id } });
            }}
          >
            Yes
          </AlertDialogAction>
        </div>
      </AlertDialogContent>
    </>
  );
};
