import { FC, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

import { Grid, GridCell, GridEmpty, GridLoading, GridRow, GridRowHeader } from "@/components/grid";
import { Pagination, PaginationProvider, usePagination } from "@/components/pagination";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { SearchInput } from "@/components/ui/input";
import {
  FindPrimaryLabelsArgs,
  PrimaryLabelsQuery,
  PrimaryLabelsSortBy,
  usePaginatedPrimaryLabelsQuery,
} from "src/generated/graphql";

import { CreatePrimaryLabelDialog } from "./components/create-primary-label-dialog";
import { LabelManagementPageTemplate } from "./components/label-management-page-template";
import { PrimaryLabelGridRowActions } from "./components/primary-label-grid-row-actions";

export type PrimaryLabelsGridFilterValue = Exclude<FindPrimaryLabelsArgs["filter"], undefined | null>;

const initialFilterValue: PrimaryLabelsGridFilterValue = {
  sortBy: PrimaryLabelsSortBy.CreatedAt,
  sortOrder: "desc",
};

export const PrimaryLabels: FC = () => (
  <PaginationProvider storageKeyPrefix="PrimaryLabels">
    <PrimaryLabelsGrid />
  </PaginationProvider>
);

export const PrimaryLabelsGrid = () => {
  const { page, rowsPerPage, setNumRecords } = usePagination();
  const [filter, setFilter] = useState<PrimaryLabelsGridFilterValue>(initialFilterValue);
  const [searchValue, setSearchValue] = useState("");
  const [query] = useDebounceValue(searchValue, 500);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data, loading } = usePaginatedPrimaryLabelsQuery({
    variables: {
      pagination: { page, rowsPerPage: Number(rowsPerPage) },
      input: { query, filter },
    },
    onCompleted: ({ paginatedPrimaryLabels }) => setNumRecords(paginatedPrimaryLabels.numRecords),
  });

  const gridData = data?.paginatedPrimaryLabels?.PrimaryLabels;

  return (
    <LabelManagementPageTemplate
      loading={loading}
      actions={
        <>
          <div className="flex gap-2">
            <PrimaryLabelsGridFilters onChange={setFilter} initialValue={initialFilterValue} />

            <SearchInput
              name="search"
              placeholder="Search primary labels"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </div>

          <Button variant="outline" size="sm" onClick={() => setIsDialogOpen(true)}>
            Create primary label
          </Button>
          <CreatePrimaryLabelDialog open={isDialogOpen} onOpenChange={setIsDialogOpen} />
        </>
      }
    >
      <Grid className="grid-cols-[3fr_3fr_2fr_2fr_1fr_1fr]">
        <GridRowHeader position="sticky">
          <GridCell>Primary Key</GridCell>
          <GridCell>Display Name</GridCell>
          <GridCell>Data Type</GridCell>
          <GridCell>Created By</GridCell>
          <GridCell className="text-right">Associated Labels</GridCell>
          <GridCell className="text-right">Actions</GridCell>
        </GridRowHeader>

        <PrimaryLabelsGridBody data={gridData} loading={loading} />
      </Grid>

      <Pagination label="Labels" />
    </LabelManagementPageTemplate>
  );
};

export interface PrimaryLabelsGridBodyProps {
  data?: PrimaryLabelsQuery["primaryLabels"];
  loading: boolean;
}

export const PrimaryLabelsGridBody: FC<PrimaryLabelsGridBodyProps> = ({ data, loading }) => {
  if (!loading && !data?.length) {
    return <PrimaryLabelsGridEmpty />;
  }

  if (loading && !data?.length) {
    return <PrimaryLabelsGridLoading />;
  }

  return (
    <>
      {data?.map((row) => (
        <PrimaryLabelsGridRow key={row.id} row={row} />
      ))}
    </>
  );
};

export interface PrimaryLabelsGridRowProps {
  row: PrimaryLabelsQuery["primaryLabels"][number];
}

export const PrimaryLabelsGridRow: FC<PrimaryLabelsGridRowProps> = ({ row }) => (
  <GridRow>
    <GridCell>{row.primaryKey}</GridCell>
    <GridCell>{row.displayName}</GridCell>
    <GridCell className="capitalize">{row.dataType}</GridCell>
    <GridCell>
      {row.createdBy && (
        <>
          {row.createdBy.firstName} {row.createdBy.lastName}
        </>
      )}
    </GridCell>
    <GridCell className="text-right">{row.extractedLabels?.length || 0}</GridCell>
    <GridCell className="text-right -mr-2">
      <PrimaryLabelGridRowActions row={row} />
    </GridCell>
  </GridRow>
);

export const PrimaryLabelsGridLoading: FC = () => {
  const { rowsPerPage } = usePagination();

  return <GridLoading className="[&>*:nth-child(n+5)]:text-right" rows={Number(rowsPerPage)} columns={6} />;
};

export const PrimaryLabelsGridEmpty: FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <GridEmpty
      title="No primary labels"
      description={
        <>
          <p>Create a primary label to get started.</p>
          <Button className="mt-3" variant="secondary" size="sm" onClick={() => setIsDialogOpen(true)}>
            Create primary label
          </Button>
          <CreatePrimaryLabelDialog open={isDialogOpen} onOpenChange={setIsDialogOpen} />
        </>
      }
    />
  );
};

export interface PrimaryLabelsGridFiltersProps {
  onChange?: (value: PrimaryLabelsGridFilterValue) => void;
  initialValue: PrimaryLabelsGridFilterValue;
}

export const PrimaryLabelsGridFilters: FC<PrimaryLabelsGridFiltersProps> = ({ onChange, initialValue }) => {
  const { setPage } = usePagination();
  const [filters, setFilters] = useState<PrimaryLabelsGridFilterValue>(initialValue);

  const handleFiltersChange = (value: PrimaryLabelsGridFilterValue) => {
    setPage(1);
    setFilters({ ...filters, ...value });
    onChange?.(value);
  };

  const handleSortChange = (value: string) => {
    const [sortBy, sortOrder] = value.split(":") as [PrimaryLabelsSortBy, "asc" | "desc"];
    handleFiltersChange({ ...filters, sortBy, sortOrder });
  };

  const options = [
    { label: "Most recent", value: `${PrimaryLabelsSortBy.CreatedAt}:desc` },
    { label: "Oldest to newest", value: `${PrimaryLabelsSortBy.CreatedAt}:asc` },
    { label: "Primary Key (A-Z)", value: `${PrimaryLabelsSortBy.PrimaryKey}:asc` },
    { label: "Display Name (A-Z)", value: `${PrimaryLabelsSortBy.DisplayName}:asc` },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm">
          Filter
          <Icon icon="filter_list" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Sort by</DropdownMenuLabel>
        <DropdownMenuRadioGroup value={`${filters.sortBy}:${filters.sortOrder}`} onValueChange={handleSortChange}>
          {options.map((option) => (
            <DropdownMenuRadioItem key={option.value} value={option.value}>
              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
