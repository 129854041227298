import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import {
  useUpdateVerticalMarketingPlanTemplateProductRulesMutation,
  VerticalMarketingPlanTemplateFragment,
  VerticalMarketingPlanTemplateProductFragment,
} from "src/generated/graphql";
import { getRuleKey, Rules, RuleSchema } from "../rule";
import { ProductRuleForm } from "./product-rule-form";
import { TemplateRuleForm } from "./template-rule-form";
import { useVertical } from "./use-vertical";

export const useTemplate = ({ template }: { template: VerticalMarketingPlanTemplateFragment }) => {
  const { openForm, openConfirmation } = useModal();
  const { refetch } = useVertical();

  const [updateVerticalMarketingPlanTemplateProductRulesTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductRulesMutation({
      onCompleted() {
        void refetch();
      },
    });

  const addTemplateRule = async () => {
    const fd = await openForm(
      z.object({
        rule: z.array(
          z.object({
            type: z.string(),
            key: z.string().optional(),
            valueType: z.string().optional(),
          })
        ),
      }),
      <TemplateRuleForm />,
      { title: "Add Template Rule" }
    );

    if (!fd) {
      return;
    }

    await Promise.all(
      template.products.map((p) => {
        const parsedRules = JSON.parse(p.rules);
        const updatedRules = [...parsedRules, fd.rule];
        return updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: p.id, rules: JSON.stringify(updatedRules) } },
        });
      })
    );
  };

  const editTemplateRule = async (ruleKey: string, update: () => void) => {
    const firstMatch = template.products
      .flatMap((p) => JSON.parse(p.rules) as Rules)
      .find((rule) => getRuleKey(rule) === ruleKey);

    if (!firstMatch) {
      return;
    }

    const fd = await openForm(
      z.object({
        rule: z.array(
          z.object({
            type: z.string(),
            key: z.string().optional(),
            valueType: z.string().optional(),
          })
        ),
      }),
      <TemplateRuleForm
        existingRule={[
          firstMatch[0].type === "client-data"
            ? {
                type: firstMatch[0].type,
                key: firstMatch?.[0].key,
                valueType: firstMatch[0].valueType,
              }
            : { type: firstMatch[0].type },
        ]}
      />,
      {
        defaultValues: {
          rule: [
            {
              type: firstMatch[0].type,
              key: "key" in firstMatch[0] ? firstMatch?.[0].key : "",
              valueType: "valueType" in firstMatch[0] ? firstMatch[0].valueType : "",
            },
          ],
        },
        title: "Edit Template Rule",
      }
    );

    if (!fd) {
      return;
    }

    await Promise.all(
      template.products.map((p) => {
        const parsedRules = JSON.parse(p.rules) as Rules;
        const updatedRules = parsedRules.map((rule) => {
          const key = getRuleKey(rule);
          if (key !== ruleKey) {
            return rule;
          }

          return [{ ...rule[0], ...fd.rule[0] }];
        });
        return updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: p.id, rules: JSON.stringify(updatedRules) } },
          update,
        });
      })
    );
  };

  const deleteTemplateRule = async (ruleKey: string, update: () => void) => {
    const isConfirmed = await openConfirmation({
      title: "Delete Template Rule",
      buttonProps: { theme: "destructive" },
    });

    if (!isConfirmed) {
      return;
    }

    const rules = template.products.map((product) => {
      const parsedRules = JSON.parse(product.rules) as Rules;
      return parsedRules.filter((r) => getRuleKey(r) !== ruleKey);
    });

    await Promise.all(
      template.products.map((product, i) =>
        updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: product.id, rules: JSON.stringify(rules[i]) } },
          update,
        })
      )
    );
  };

  const editProductRules = async (products: VerticalMarketingPlanTemplateProductFragment[], ruleKey: string) => {
    const rules = JSON.parse(products[0].rules) as Rules;
    const ruleIndex = rules.findIndex((rule) => getRuleKey(rule) === ruleKey);
    const fd = await openForm(
      z.object({
        rule: RuleSchema,
      }),
      <ProductRuleForm products={products} rule={rules[ruleIndex][0]} />,
      {
        title: "Edit Product Rule",
      }
    );

    if (!fd) {
      return;
    }

    const updatedRules = rules.map((rule, i: number) => {
      if (i !== ruleIndex) {
        return rule;
      }

      return [fd.rule];
    });

    await Promise.all(
      products.map((p) =>
        updateVerticalMarketingPlanTemplateProductRulesTrigger({
          variables: { input: { id: p.id, rules: JSON.stringify(updatedRules) } },
        })
      )
    );
  };

  const editProductRule = async (product: VerticalMarketingPlanTemplateProductFragment, index: number) => {
    const rules = JSON.parse(product.rules);
    const fd = await openForm(
      z.object({
        rule: RuleSchema,
      }),
      <ProductRuleForm products={[product]} rule={rules[index][0]} />,
      { title: product.appetiteProduct.carrierName, description: product.appetiteProduct.carrierProductName }
    );

    if (!fd) {
      return;
    }

    const updatedRules = rules.map((rule: any, i: number) => {
      if (i !== index) {
        return rule;
      }

      return [fd.rule];
    });

    await updateVerticalMarketingPlanTemplateProductRulesTrigger({
      variables: { input: { id: product.id, rules: JSON.stringify(updatedRules) } },
    });
  };

  return {
    addTemplateRule,
    editTemplateRule,
    deleteTemplateRule,
    editProductRules,
    editProductRule,
  };
};
