import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Input as FormInput } from "@/forms/default";
import { SUBMISSION_STATE_METADATA } from "@/metadata";
import { SubmissionAction, SubmissionFragment, useTransitionSubmissionMutation } from "src/generated/graphql";

export const useSubmissionActions = () => {
  const [transition] = useTransitionSubmissionMutation();
  const { openConfirmation, openForm } = useModal();

  const handleConfirmation = async (
    submission: SubmissionFragment,
    action: SubmissionAction,
    includeDetails: boolean = false
  ) => {
    const isConfirmed = await (includeDetails
      ? openForm(
          z.object({ details: z.string() }),
          <div className="space-y-1.5">
            <p>
              {submission.appetiteProduct.carrierName} - {submission.appetiteProduct.carrierProductName}
            </p>
            <FormInput name="details" placeholder="Reason" />
          </div>,
          {
            title: "Confirm",
          }
        )
      : openConfirmation({
          title: `Confirm ${SUBMISSION_STATE_METADATA[submission?.state].actionDisplayNames?.[action] ?? action}`,
          description: `${submission.appetiteProduct.carrierName} - ${submission.appetiteProduct.carrierProductName}`,
        }));

    if (!isConfirmed) {
      return;
    }

    await handleTransition(submission, action, typeof isConfirmed === "object" ? isConfirmed.details : undefined);
  };

  const handleTransition = async (submission: SubmissionFragment, action: SubmissionAction, details?: string) => {
    await transition({
      variables: {
        input: {
          id: submission.id,
          expectedState: submission.state,
          action,
          details,
        },
      },
      refetchQueries: ["OpportunityDetails"],
    });
  };

  return {
    handleConfirmation,
    handleTransition,
  };
};
