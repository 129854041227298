import { Button } from "@/components/ui/button";
import {
  MarketingPlanAction,
  OpportunityDetailsFragment,
  useTransitionMarketingPlanMutation,
} from "src/generated/graphql";

export const InformationReview = ({ marketingPlan }: { marketingPlan: OpportunityDetailsFragment }) => {
  const [transition] = useTransitionMarketingPlanMutation();

  if (!marketingPlan) {
    return null;
  }

  const handleTransition = async (action: MarketingPlanAction) => {
    await transition({
      variables: {
        input: {
          id: marketingPlan.id,
          expectedState: marketingPlan.state,
          action,
        },
      },
    });
  };

  return (
    <Button variant="outline" size="xs" onClick={() => handleTransition(MarketingPlanAction.StartMarketing)}>
      Start Marketing
    </Button>
  );
};
