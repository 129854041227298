import { Edge, Node } from "@xyflow/react";
import { stratify, tree } from "d3";
import { MaterialSymbol } from "material-symbols";

import type { BadgeProps } from "@/components/ui/badge";
import {
  FilePipelineVersionFragment,
  FileProcessorCategory,
  FileProcessorIssueType,
  FileProcessorNodeType,
} from "src/generated/graphql";

import {
  pipelineIssueTypeToColorMap,
  pipelineIssueTypeToIconMap,
  pipelineIssueTypeToLabelMap,
  processorCategoryToIconMap,
} from "./file-processing-pipeline.constants";
import { FileProcessingPipelineSettings } from "./file-processing-pipeline.provider";

export function getDocumentLabelStatus(totalProcessedFiles: number): { label: string; variant: BadgeProps["variant"] } {
  if (totalProcessedFiles >= 100) {
    return {
      variant: "green",
      label: "over ready",
    };
  }

  if (totalProcessedFiles >= 50) {
    return {
      variant: "lime",
      label: "ready",
    };
  }

  if (totalProcessedFiles >= 20) {
    return {
      variant: "amber",
      label: "semi ready",
    };
  }

  return {
    variant: "orange",
    label: "not ready",
  };
}

export function getNodeIcon(node: Node): MaterialSymbol {
  if (node.type === FileProcessorNodeType.DocumentLabel) {
    return "folder";
  }

  return processorCategoryToIconMap[node.data.category as FileProcessorCategory];
}

export function getPipelineIssueIcon(issueType: FileProcessorIssueType): MaterialSymbol {
  return pipelineIssueTypeToIconMap[issueType];
}

export function getPipelineIssueLabel(issueType: FileProcessorIssueType): string {
  return pipelineIssueTypeToLabelMap[issueType];
}

export function getPipelineIssueColor(issueType: FileProcessorIssueType): string {
  return pipelineIssueTypeToColorMap[issueType];
}

export function getNodeLabel(node?: Node): string {
  return (node?.data as any).name;
}

export function findNodeById(id?: string, nodes?: Node[]): Node | undefined {
  if (!id || !nodes) {
    return undefined;
  }

  return nodes.find((node) => node.id === id || node.data.id === id);
}

export function findNodesByIds(ids?: string[], nodes?: Node[]) {
  if (!ids || !nodes) {
    return [];
  }

  return nodes.filter((node) => ids.includes(node.id));
}

export function findParentNodeByChildId(id: string, nodes: Node[], edges: Edge[]): Node | undefined {
  const childNode = findNodeById(id, nodes);

  if (!childNode) {
    return undefined;
  }

  return findNodeById(edges.find((edge) => edge.target === childNode?.id)?.source as string, nodes);
}

export function findChildNodesByParentId(id?: string, nodes?: Node[], edges?: Edge[]): Node[] {
  if (!id || !nodes || !edges) {
    return [];
  }

  return edges.filter((edge) => edge.source === id).map((edge) => findNodeById(edge.target, nodes)!);
}

export function findNodeAncestors(id: string, nodes: Node[], edges: Edge[]): Node[] {
  if (!id) {
    return [];
  }

  const parentNode = findParentNodeByChildId(id, nodes, edges);

  if (!parentNode) {
    return [];
  }

  return [parentNode, ...findNodeAncestors(parentNode.id, nodes, edges)];
}

export function findRootNode(nodes: Node[], edges: Edge[]): Node | undefined {
  return nodes.find((node) => !edges.some((edge) => edge.target === node.id));
}

export function isRootNode(nodeId: string, edges: Edge[]): boolean {
  return !edges.some((edge) => edge.target === nodeId);
}

export function convertPipelineDataToNodesAndEdges(pipelineData: FilePipelineVersionFragment): {
  nodes: Node[];
  edges: Edge[];
} {
  return {
    nodes: convertPipelineDataToNodes(pipelineData),
    edges: covertPipelineDataToEdges(pipelineData),
  };
}

export function convertPipelineDataToNodes({ pipeline }: FilePipelineVersionFragment): Node[] {
  return [
    {
      id: pipeline.initial.name,
      type: FileProcessorNodeType.FileProcessor,
      data: pipeline.initial,
      position: { x: 0, y: 0 },
    },
    ...pipeline.transitions.flatMap((edge) => [
      ...edge.destinationNodes.map((destinationNode) => ({
        id: destinationNode.name,
        type: FileProcessorNodeType.FileProcessor,
        position: { x: 0, y: 0 },
        data: destinationNode,
      })),
      {
        id: `${edge.sourceNodeName}::${edge.label}`,
        type: FileProcessorNodeType.DocumentLabel,
        position: { x: 0, y: 0 },
        data: {
          id: `${edge.sourceNodeName}::${edge.label}`,
          category: edge.sourceNodeName,
          name: edge.label,
        },
      },
    ]),
  ];
}

export function covertPipelineDataToEdges({ pipeline }: FilePipelineVersionFragment): Edge[] {
  return pipeline.transitions.flatMap((edge) => [
    {
      id: `edge-${edge.sourceNodeName}-${edge.label}`,
      source: edge.sourceNodeName,
      target: `${edge.sourceNodeName}::${edge.label}`,
    },
    ...edge.destinationNodes.map((destinationNode) => ({
      id: `edge-${edge.label}:${destinationNode.name}`,
      source: `${edge.sourceNodeName}::${edge.label}`,
      target: destinationNode.name,
    })),
  ]);
}

export interface ApplyPipelineSettingsBaseParams {
  nodes: Node[];
  edges: Edge[];
}

export interface ApplyPipelineSettingsParams extends ApplyPipelineSettingsBaseParams {
  settings: FileProcessingPipelineSettings;
}

export function applyPipelineSettings({ settings, nodes, edges }: ApplyPipelineSettingsParams) {
  return applyExpandedNodes({ ...applyBaseNodeParams({ nodes, edges }), expandedNodes: settings.expandedNodes });
}

export function applyBaseNodeParams({ nodes, edges }: ApplyPipelineSettingsBaseParams) {
  const updatedNodes = nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      isRoot: !edges.some((edge) => edge.target === node.id),
      hasChildren: edges.some((edge) => edge.source === node.id),
      hasGrandchildren: findChildNodesByParentId(node.id, nodes, edges).some((child) =>
        edges.some((edge) => edge.source === child?.id)
      ),
    },
  }));

  return { nodes: updatedNodes, edges };
}

export interface ApplyExpandedNodesParams extends ApplyPipelineSettingsBaseParams {
  expandedNodes: string[];
  expandCurrent?: boolean;
}

export function applyExpandedNodes({ expandedNodes, nodes, edges }: ApplyExpandedNodesParams) {
  const rootNode = findRootNode(nodes, edges) as Node;

  const visibleNodes = [...new Set([rootNode.id, ...expandedNodes])].flatMap((expandedNodeId) => {
    const isRoot = expandedNodeId === rootNode.id;

    const nodeAncestors = findNodeAncestors(expandedNodeId, nodes, edges);
    const isAllAncestorsExpanded = nodeAncestors
      .filter((node) => node.id !== rootNode.id)
      .every((node) => expandedNodes.includes(node.id));

    if (!isRoot && !isAllAncestorsExpanded) {
      return [];
    }

    return findChildNodesByParentId(expandedNodeId, nodes, edges);
  });

  return { nodes: [rootNode, ...visibleNodes], edges };
}

export interface ApplyCollapsedNodesParams extends ApplyPipelineSettingsBaseParams {
  collapsedNodes: string[];
  collapseCurrent?: boolean;
}

// NOTE: This function is not used at the moment, because we went with the "expand nodes" paradigm, instead.
// But let's keep this here for now, because it could be useful in the future.
export function applyCollapsedNodes({ collapsedNodes, nodes, edges, collapseCurrent }: ApplyCollapsedNodesParams) {
  collapsedNodes.forEach((collapsedNodeId) => {
    const node = nodes.find((node) => node.id === collapsedNodeId);

    if (!node) {
      return;
    }

    node.hidden = collapseCurrent;

    const childNodes = edges.filter((edge) => edge.source === collapsedNodeId).map((edge) => edge.target);

    edges.forEach((edge) => {
      if (edge.source === collapsedNodeId) {
        edge.hidden = true;
      }
    });

    return applyCollapsedNodes({ collapsedNodes: childNodes, collapseCurrent: true, nodes, edges });
  });

  return { nodes, edges };
}

export function prepareNodes({ nodes, edges }: ApplyPipelineSettingsBaseParams): Node[] {
  // TODO: Update this so we get the width and height from the DOM element?
  // See example here: https://reactflow.dev/learn/layouting/layouting#d3-hierarchy
  const nodeWidth = 288;
  const nodeHeight = 72;
  const g = tree();

  // NOTE: We don't need this here at the moment, because we are pre-filtering
  // the hidden nodes before passing them to `prepareNodes`.
  // const filteredNodes = nodes.filter((node) => !node.hidden);

  const hierarchy = stratify()
    .id((node: any) => node.id)
    .parentId((node: any) => edges.find((edge) => edge.target === node.id)?.source);

  const root = hierarchy(nodes);
  const layout = g.nodeSize([nodeHeight * 2, nodeWidth * 2])(root);

  return layout.descendants().map((node) => {
    const nodeData: Node = {
      ...(node.data as Node),
      data: {
        ...(node.data as Node)?.data,
      },
    };

    return {
      ...nodeData,
      deletable: false,
      draggable: false,
      position: {
        y: node.x || 0,
        x: node.y || 0,
      },
    };
  });
}

function prepareEdges(edges: Edge[]): Edge[] {
  return edges.map((edge) => ({
    ...edge,
    deletable: false,
    draggable: false,
    selectable: false,
  }));
}

export function prepareLayout(pipeline: FilePipelineVersionFragment, settings: FileProcessingPipelineSettings) {
  const { nodes, edges } = applyPipelineSettings({ ...convertPipelineDataToNodesAndEdges(pipeline), settings });

  return {
    nodes: prepareNodes({ nodes, edges }),
    edges: prepareEdges(edges),
  };
}

export function getDetailUrlForNode(node?: Node): string {
  if (!node) {
    return "";
  }

  const urlByType = {
    [FileProcessorNodeType.FileProcessor]: (node: Node) =>
      `/file-processing-pipeline/file-processor/${encodeURIComponent(node.data.id as string)}`,
    [FileProcessorNodeType.DocumentLabel]: (node: Node) =>
      `/file-processing-pipeline/document-label/${encodeURIComponent(node.data.id as string)}`,
  };

  return urlByType[node.type as FileProcessorNodeType]?.(node) || "";
}
