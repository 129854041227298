import { SectionContent } from "@/components/section";
import { useVertical } from "./components/use-vertical";
import { TemplateRequirements } from "./template-requirements";

export const VerticalRequirements = () => {
  const { templates } = useVertical();
  return (
    <div className="divide-y">
      {templates.map((template) => (
        <SectionContent key={template.id}>
          <TemplateRequirements template={template} />
        </SectionContent>
      ))}
    </div>
  );
};
