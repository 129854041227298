import { useState } from "react";
import { UseFormRegister } from "react-hook-form";

import { HasInternalRole } from "@/components/has-role";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Field, FieldInput, fieldInputClassName } from "@/forms/borderless";
import { AgenciesQuery, AgentAccountsQuery } from "src/generated/graphql";

type Agents = AgentAccountsQuery["userAccounts"];
type Agent = NonNullable<Agents>[number];

type Agencies = AgenciesQuery["agencies"];
type Agency = NonNullable<Agencies>[number];

interface Props {
  agents: AgentAccountsQuery["userAccounts"];
  agencies: AgenciesQuery["agencies"];
  register: UseFormRegister<any>;
  setValues: (keyVals: Array<[string, string]>) => void;
}

export const AgentForm = ({ agents, agencies, register, setValues }: Props) => {
  const [selectedAgent, setSelectedAgent] = useState<Agent>();
  const [selectedAgency, setSelectedAgency] = useState<Agency>();

  return (
    <HasInternalRole>
      <div className="bg-border border-y mt space-y-px">
        <Field icon="person_search">
          <Autocomplete
            placeholder="Search Agents"
            options={agents}
            selected={selectedAgent}
            onSelect={(agent) => {
              setSelectedAgent(agent);
              setSelectedAgency(agent.agency);
              setValues([
                ["firstName", agent.firstName],
                ["lastName", agent.lastName],
                ["agentEmail", agent.email],
                ["agencyId", agent.agencyId],
              ]);
            }}
            toValue={(agent) => `${agent.firstName} ${agent.lastName} ${agent.agency.name} ${agent.email}`}
            toLabel={(agent) => `${agent.firstName} ${agent.lastName} (${agent.agency.name})`}
            buttonProps={{ variant: "unstyled", display: "flex" }}
          />
        </Field>
        <FieldInput
          icon="badge"
          placeholder="First Name"
          className={fieldInputClassName}
          required={true}
          {...register("firstName")}
          onChange={() => setSelectedAgent(undefined)}
        />
        <FieldInput
          icon="badge"
          placeholder="Last Name"
          className={fieldInputClassName}
          required={true}
          {...register("lastName")}
          onChange={() => setSelectedAgent(undefined)}
        />
        <FieldInput
          icon="alternate_email"
          placeholder="Email"
          required={true}
          {...register("agentEmail")}
          onChange={() => setSelectedAgent(undefined)}
        />
        <Field icon="screen_search_desktop">
          <Autocomplete
            placeholder={"Search Agencies"}
            options={agencies}
            selected={selectedAgency}
            onSelect={(agency) => {
              setSelectedAgency(agency);
              setValues([["agencyId", agency.id]]);
            }}
            toValue={(agency) => agency.name}
            toLabel={(agency) => agency.name}
            buttonProps={{ variant: "unstyled", display: "flex" }}
          />
        </Field>
      </div>
    </HasInternalRole>
  );
};
