import { useEdges, useNodes, useReactFlow } from "@xyflow/react";

import { useMemo } from "react";

import { useNavigate } from "react-router";
import { useFileProcessingPipelineQuery } from "../../generated/graphql";
import {
  convertPipelineDataToNodesAndEdges,
  findNodeAncestors,
  findNodeById,
  findParentNodeByChildId,
  getDetailUrlForNode,
} from "./file-processing-pipeline.helpers";
import { useFileProcessingPipelineState } from "./file-processing-pipeline.provider";

export const useHasDownStreamProcessors = (processorId?: string): boolean => {
  const nodes = useNodes();
  const edges = useEdges();

  return useMemo(() => {
    if (!processorId) {
      return false;
    }

    return !!findNodeById(processorId, nodes)?.data?.hasGrandchildren;
  }, [processorId, nodes, edges]);
};

export const useFormattedPipelineData = () => {
  const { data, ...restQueryParams } = useFileProcessingPipelineQuery();

  const { nodes, edges } = useMemo(
    () =>
      data?.fileProcessingPipeline
        ? convertPipelineDataToNodesAndEdges(data.fileProcessingPipeline)
        : { nodes: [], edges: [] },
    [data]
  );

  return { data: { nodes, edges }, ...restQueryParams };
};

export const useAllNodes = () => {
  const { data } = useFormattedPipelineData();

  return data.nodes;
};

export const useAllEdges = () => {
  const { data } = useFormattedPipelineData();

  return data.edges;
};

export const useNodeById = (id?: string) => {
  const nodes = useAllNodes();

  return useMemo(() => {
    if (!id) {
      return null;
    }

    return findNodeById(id, nodes);
  }, [id, nodes]);
};

export const useParentNodeByChildId = (id?: string) => {
  const nodes = useAllNodes();
  const edges = useAllEdges();

  return useMemo(() => {
    if (!id) {
      return null;
    }

    return findParentNodeByChildId(id, nodes, edges);
  }, [id, nodes, edges]);
};

export const useNavigateToNode = () => {
  const navigate = useNavigate();
  const { fitView } = useReactFlow();
  const { data } = useFormattedPipelineData();
  const { setExpandedNodes } = useFileProcessingPipelineState();

  return (nodeId: string, cb?: () => void) => {
    const node = findNodeById(nodeId, data.nodes);
    const nodeAncestors = findNodeAncestors(nodeId, data.nodes, data.edges);
    const detailUrl = getDetailUrlForNode(node);

    navigate(detailUrl);

    setExpandedNodes((expandedNodes) => [
      ...nodeAncestors.map((node) => node.id).filter((id) => !expandedNodes.includes(id)),
      ...expandedNodes,
    ]);

    setTimeout(async () => {
      cb?.();

      await fitView({
        nodes: [{ id: nodeId }],
        minZoom: 0.75,
        maxZoom: 0.75,
        duration: 1000,
      });
    }, 600);
  };
};
