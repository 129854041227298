import { uniqBy } from "lodash";
import { useState } from "react";
import { useParams } from "react-router";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { SearchInput } from "@/components/ui/input";
import { LabelledCheckbox } from "@/forms/labelled-checkbox";
import { useAppetiteFilterOptionsQuery, useCreateVerticalMarketingPlanTemplateMutation } from "src/generated/graphql";
import { useVertical } from "./use-vertical";

const CORE_LINES = new Set([
  "General Liability",
  "Property",
  "E&O/Professional Liability",
  "Workers Compensation",
  "Commercial Auto",
  "Excess Liability/Umbrella",
]);

export const AddTemplate = () => {
  const { verticalId } = useParams();
  const { openForm } = useModal();
  const { refetch } = useVertical();

  const [createVerticalMarketingPlanTemplateTrigger] = useCreateVerticalMarketingPlanTemplateMutation({
    onCompleted() {
      void refetch();
    },
  });

  if (!verticalId) {
    return null;
  }

  const createVerticalMarketingPlan = async () => {
    const fd = await openForm(
      z.object({
        lobs: z.array(z.string()),
      }),
      <SelectLinesOfBusiness />,
      { defaultValues: { lobs: [] }, title: "Select LOBs" }
    );

    if (!fd) {
      return;
    }

    await createVerticalMarketingPlanTemplateTrigger({
      variables: { input: { verticalId: verticalId, lobs: fd.lobs.sort() } },
    });
  };

  return (
    <Button variant="outline" size="xs" onClick={createVerticalMarketingPlan}>
      Add Template
    </Button>
  );
};

function SelectLinesOfBusiness() {
  const [term, setTerm] = useState("");
  const { data } = useAppetiteFilterOptionsQuery({});

  const sortedVerticals = [...(data?.appetiteFilterOptions?.verticals || [])].sort(
    (a, b) => (b?.linesOfBusiness.length || 0) - (a?.linesOfBusiness.length || 0)
  );

  const linesOfBusiness = uniqBy(
    sortedVerticals.flatMap((vertical) => vertical.linesOfBusiness),
    (v) => v.name
  ).filter((l) => l.name.toLowerCase().includes(term.toLowerCase()));

  const coreLines = linesOfBusiness.filter((l) => CORE_LINES.has(l.name));
  const otherLines = linesOfBusiness.filter((l) => !CORE_LINES.has(l.name));

  return (
    <div className="space-y-6">
      <SearchInput
        name="search"
        value={term}
        placeholder="Search LOBs"
        onChange={(event) => setTerm(event.currentTarget.value)}
      />
      <div className="space-y-3">
        <h3>Core Lines</h3>
        {coreLines.map(({ name }) => (
          <LabelledCheckbox key={name} value={name} name="lobs" />
        ))}
      </div>
      <div className="space-y-3">
        <h3>Other</h3>
        {otherLines.map(({ name }) => (
          <LabelledCheckbox key={name} value={name} name="lobs" />
        ))}
      </div>
    </div>
  );
}
