import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { Check } from "lucide-react";
import { useState } from "react";
import { DefaultValues } from "react-hook-form";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import { input, z, ZodObject } from "zod";

import { Section, SectionContent, SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Bar } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { toast } from "@/components/ui/use-toast";
import { Field, Input, TextArea } from "@/forms/default";
import { Reform } from "@/forms/reform";
import {
  AppetiteNotesQuery,
  Coverages,
  CreateAppetiteNoteInput,
  UpdateAppetiteNoteInput,
  useAppetiteNotesQuery,
  useCreateAppetiteNoteMutation,
  useUpdateAppetiteNoteMutation,
} from "src/generated/graphql";
import { cn, parseError } from "src/utils";

type AppetiteNote = AppetiteNotesQuery["appetiteNotes"][number];
type FormMode = "create" | "edit";

const validateStates = (value?: string) => {
  if (!value || value === "") {
    return true;
  }
  const values = value.split(",").map((v) => v.trim());
  const allowedStates = new Set<string>(STRICT_STATE_OPTIONS.map((s) => s.value));
  for (const val of values) {
    if (!allowedStates.has(val)) {
      return false;
    }
  }
  return true;
};

const CreateSchema = z.object({
  isoCglCodes: z
    .string()
    .regex(/^\d+(,\s*\d+)*$/)
    .or(z.literal("")),
  states: z.string().optional().refine(validateStates, { message: `States input are not all valid states.` }),
  note: z.string().min(1),
});

const UpdateSchema = CreateSchema.extend({
  id: z.string(),
});

export const AppetiteNote: React.FC<{ mode: FormMode }> = ({ mode }) => {
  useDocumentTitle("Admin: Appetite Note");
  const { appetiteNoteId } = useParams<{ appetiteNoteId: string | undefined }>();
  const { data: { appetiteNotes = [] } = {}, loading } = useAppetiteNotesQuery();

  let appetiteNote: AppetiteNote | undefined;
  if (appetiteNoteId) {
    if (loading) {
      return <Loading />;
    } else {
      // TODO: this is a bit hacky, but there isn't a way to get a single appetite note by id.
      appetiteNote = appetiteNotes.find((n) => n.id === appetiteNoteId);
    }
  }

  return (
    <Section>
      <SectionHeader className="bg-accent border-b">
        <SectionTitle>
          <h1>{appetiteNote ? "Edit" : "Create"} Note</h1>
        </SectionTitle>
      </SectionHeader>
      <FormDrawerWrapper mode={mode} appetiteNote={appetiteNote} />
    </Section>
  );
};

const FormDrawerWrapper: React.FC<{
  mode: FormMode;
  appetiteNote?: AppetiteNote;
}> = ({ mode, appetiteNote }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlCglCodes = queryParams.get("cglCodes")?.split(",");
  const [createAppetiteNote] = useCreateAppetiteNoteMutation({
    onError: (error) => toast({ title: "Error creating note", description: parseError(error), variant: "destructive" }),
  });
  const [updateAppetiteNote] = useUpdateAppetiteNoteMutation({
    onError: (error) => toast({ title: "Error updating note", description: parseError(error), variant: "destructive" }),
  });

  return mode === "edit" && appetiteNote ? (
    <FormDrawer
      appetiteNote={appetiteNote}
      schema={UpdateSchema}
      defaultValues={{
        id: appetiteNote.id,
        isoCglCodes: appetiteNote?.isoCglCodes?.toString(),
        states: appetiteNote?.states?.toString(),
        note: appetiteNote?.note,
      }}
      onSubmit={async (values, selectedLobs) => {
        await updateAppetiteNote({ variables: { input: sanitizeUpdateInput(values, selectedLobs) } });
        navigate(-1);
      }}
    />
  ) : (
    <FormDrawer
      schema={CreateSchema}
      defaultValues={{ isoCglCodes: urlCglCodes?.join(",") }}
      onSubmit={async (values, selectedLobs) => {
        await createAppetiteNote({
          variables: {
            input: sanitizeCreateInput(values, selectedLobs),
          },
        });
        navigate(-1);
      }}
    />
  );
};

interface FormDrawerProps<T extends ZodObject<any>> {
  appetiteNote?: AppetiteNote;
  schema: T;
  onSubmit: (values: z.infer<T>, selectedLobs: Set<Coverages>) => void;
  defaultValues?: DefaultValues<input<T>>;
}

const FormDrawer = <T extends ZodObject<any>>({
  appetiteNote,
  schema,
  onSubmit,
  defaultValues,
}: FormDrawerProps<T>) => {
  const [selectedLobs, setSelectedLobs] = useState<Set<Coverages>>(new Set<Coverages>(appetiteNote?.lobs));

  return (
    <Reform
      schema={schema}
      onSubmit={(_, values) => onSubmit(values, selectedLobs)}
      defaultValues={defaultValues}
      className="flex flex-auto flex-col"
    >
      <SectionContent className="flex-auto space-y-6">
        <Field label={fieldLabel("CGL Codes", "Example: 94007, 94006")} name="isoCglCodes">
          <Input name="isoCglCodes" />
        </Field>
        <Field label={fieldLabel("States", "Example: TX, KS")} name="states">
          <Input name="states" />
        </Field>
        <LobSelector selectedLobs={selectedLobs} setSelectedLobs={setSelectedLobs} />
        <div className="flex flex-row flex-wrap gap-1">
          {[...selectedLobs].map((lob) => (
            <Pill key={lob}>
              <div className="flex flex-row justify-between gap-1">
                {lob}
                <div
                  className="flex"
                  onClick={() => {
                    selectedLobs.delete(lob);
                    setSelectedLobs(new Set(selectedLobs));
                  }}
                >
                  <Icon icon="close" />
                </div>
              </div>
            </Pill>
          ))}
        </div>
        <Field label="Note" name="note">
          <TextArea name="note" />
        </Field>
      </SectionContent>
      <Bar as="footer">
        <Button type="submit">Save</Button>
      </Bar>
    </Reform>
  );
};

const LobSelector: React.FC<{
  selectedLobs: Set<Coverages>;
  setSelectedLobs: React.Dispatch<React.SetStateAction<Set<Coverages>>>;
}> = ({ selectedLobs, setSelectedLobs }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <div className="flex flex-row justify-between content-center mt-4 font-semibold">
        <span>Lines of Business</span>
        <PopoverTrigger asChild>
          <Button variant="ghost" display="icon">
            <Icon icon="add_2" />
          </Button>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandEmpty>No results.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {Object.values(Coverages).map((lob: Coverages) => (
                <CommandItem
                  key={lob}
                  value={lob}
                  onSelect={() => {
                    selectedLobs.has(lob) ? selectedLobs.delete(lob) : selectedLobs.add(lob);
                    setSelectedLobs(new Set(selectedLobs));
                    setOpen(false);
                  }}
                >
                  <Check className={cn("mr-2 h-4 w-4", selectedLobs.has(lob) ? "opacity-100" : "opacity-0")} />
                  {lob}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const Pill: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Badge variant="secondary">{children}</Badge>;
};

export const Pills: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {items.map((item) => (
        <Pill key={item}>{item}</Pill>
      ))}
    </div>
  );
};

const fieldLabel = (title: string, example?: string) => {
  return (
    <div className="flex flex-row justify-between">
      <span>{title}</span>
      {example && <h5 className="text-muted-foreground">{example}</h5>}
    </div>
  );
};

const sanitizeCreateInput = (
  values: z.infer<typeof CreateSchema>,
  linesOfBusiness: Set<Coverages>
): CreateAppetiteNoteInput => {
  return {
    isoCglCodes: values.isoCglCodes
      .split(",")
      .filter((v) => v !== "")
      .map((v) => v.trim()),
    states:
      values?.states
        ?.split(",")
        ?.filter((v) => v !== "")
        ?.map((v) => v.trim()) ?? [],
    linesOfBusiness: [...linesOfBusiness],
    note: values.note,
  };
};

const sanitizeUpdateInput = (
  values: z.infer<typeof UpdateSchema>,
  linesOfBusiness: Set<Coverages>
): UpdateAppetiteNoteInput => {
  return {
    id: values.id,
    ...sanitizeCreateInput(values, linesOfBusiness),
  };
};
