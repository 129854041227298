import { FC, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

import { Grid, GridCell, GridEmpty, GridLoading, GridRow, GridRowHeader } from "@/components/grid";
import { Pagination, PaginationProvider, usePagination } from "@/components/pagination";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { SearchInput } from "@/components/ui/input";
import {
  ExtractedLabelsPrimaryLabelsFilter,
  ExtractedLabelsQuery,
  ExtractedLabelsSortBy,
  FindExtractedLabelsInput,
  usePaginatedExtractedLabelsQuery,
} from "src/generated/graphql";

import { AssignPrimaryLabelPopover } from "./components/assign-primary-label-popover";
import { LabelManagementPageTemplate } from "./components/label-management-page-template";

export type ExtractedLabelsGridFilterValue = Exclude<FindExtractedLabelsInput["filter"], undefined | null>;

const initialFilterValue: ExtractedLabelsGridFilterValue = {
  sortBy: ExtractedLabelsSortBy.Key,
  sortOrder: "asc",
  primaryLabels: ExtractedLabelsPrimaryLabelsFilter.All,
};

export const ExtractedLabels: FC = () => (
  <PaginationProvider storageKeyPrefix="extractedLabels">
    <ExtractedLabelsGrid />
  </PaginationProvider>
);

export const ExtractedLabelsGrid: FC = () => {
  const { page, rowsPerPage, setNumRecords } = usePagination();
  const [filter, setFilter] = useState<ExtractedLabelsGridFilterValue>(initialFilterValue);
  const [searchValue, setSearchValue] = useState("");
  const [query] = useDebounceValue(searchValue, 500);

  const { data, loading } = usePaginatedExtractedLabelsQuery({
    variables: {
      pagination: { page, rowsPerPage: Number(rowsPerPage) },
      input: { query, filter },
    },
    onCompleted: ({ paginatedExtractedLabels }) => setNumRecords(paginatedExtractedLabels?.numRecords),
  });

  const gridData = data?.paginatedExtractedLabels?.extractedLabels;

  return (
    <LabelManagementPageTemplate
      loading={loading}
      actions={
        <div className="flex gap-2">
          <ExtractedLabelsGridFilters onChange={setFilter} initialValue={initialFilterValue} />

          <SearchInput
            name="search"
            placeholder="Search extracted labels"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            className="min-w-52"
          />
        </div>
      }
    >
      <Grid className="grid-cols-[6fr_3fr_1fr_2fr]">
        <GridRowHeader position="sticky">
          <GridCell>Key</GridCell>
          <GridCell>Source</GridCell>
          <GridCell className="text-right">Count</GridCell>
          <GridCell className="text-right">Primary Label</GridCell>
        </GridRowHeader>

        <ExtractedLabelsGridBody data={gridData} loading={loading} />
      </Grid>

      <Pagination label="Labels" />
    </LabelManagementPageTemplate>
  );
};

export interface ExtractedLabelsGridBodyProps {
  data?: ExtractedLabelsQuery["extractedLabels"];
  loading: boolean;
}

export const ExtractedLabelsGridBody: FC<ExtractedLabelsGridBodyProps> = ({ data, loading }) => {
  if (!loading && !data?.length) {
    return <ExtractedLabelsGridEmpty />;
  }

  if (loading && !data?.length) {
    return <ExtractedLabelsGridLoading />;
  }

  return (
    <>
      {data?.map((row) => (
        <ExtractedLabelsGridRow key={`${row.key}_${row.source}_${row.count}`} row={row} />
      ))}
    </>
  );
};

export interface ExtractedLabelsGridRowProps {
  row: ExtractedLabelsQuery["extractedLabels"][number];
}

export const ExtractedLabelsGridRow: FC<ExtractedLabelsGridRowProps> = ({ row }) => (
  <GridRow>
    <GridCell>{row.key}</GridCell>
    <GridCell>{row.source}</GridCell>
    <GridCell className="text-right">{Intl.NumberFormat("en-us").format(Number(row.count))}</GridCell>
    <GridCell className="text-right -mr-4">
      <AssignPrimaryLabelPopover extractedLabel={row} />
    </GridCell>
  </GridRow>
);

export const ExtractedLabelsGridLoading: FC = () => {
  const { rowsPerPage } = usePagination();

  return <GridLoading className="[&>*:nth-child(n+3)]:text-right" rows={Number(rowsPerPage)} columns={4} />;
};

export const ExtractedLabelsGridEmpty: FC = () => (
  <GridEmpty title="No extracted labels" description="There are no extracted labels to display." />
);

export interface ExtractedLabelsGridFiltersProps {
  onChange?: (value: ExtractedLabelsGridFilterValue) => void;
  initialValue: ExtractedLabelsGridFilterValue;
}

const filterOptions = {
  primaryLabels: [
    { label: "All", value: ExtractedLabelsPrimaryLabelsFilter.All },
    { label: "Assigned", value: ExtractedLabelsPrimaryLabelsFilter.Some },
    { label: "Unassigned", value: ExtractedLabelsPrimaryLabelsFilter.None },
  ],
  sort: [
    { label: "Key (A-Z)", value: `${ExtractedLabelsSortBy.Key}:asc` },
    { label: "Source (A-Z)", value: `${ExtractedLabelsSortBy.Source}:asc` },
    { label: "Count (Asc)", value: `${ExtractedLabelsSortBy.Count}:asc` },
    { label: "Count (Desc)", value: `${ExtractedLabelsSortBy.Count}:desc` },
  ],
};

export const ExtractedLabelsGridFilters: FC<ExtractedLabelsGridFiltersProps> = ({ onChange, initialValue }) => {
  const { setPage } = usePagination();
  const [filters, setFilters] = useState<ExtractedLabelsGridFilterValue>(initialValue);

  const handleFiltersChange = (value: ExtractedLabelsGridFilterValue) => {
    setPage(1);
    setFilters({ ...filters, ...value });
    onChange?.(value);
  };

  const handlePrimaryLabelsChange = (value: string) => {
    handleFiltersChange({ ...filters, primaryLabels: value as ExtractedLabelsPrimaryLabelsFilter });
  };

  const handleSortChange = (value: string) => {
    const [sortBy, sortOrder] = value.split(":") as [ExtractedLabelsSortBy, "asc" | "desc"];
    handleFiltersChange({ ...filters, sortBy, sortOrder });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm">
          Filter
          <Icon icon="filter_list" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <DropdownMenuLabel>Primary Label</DropdownMenuLabel>
        <DropdownMenuRadioGroup value={`${filters.primaryLabels}`} onValueChange={handlePrimaryLabelsChange}>
          {filterOptions.primaryLabels.map((option) => (
            <DropdownMenuRadioItem key={option.value} value={option.value}>
              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>

        <DropdownMenuSeparator />

        <DropdownMenuLabel>Sort by</DropdownMenuLabel>
        <DropdownMenuRadioGroup value={`${filters.sortBy}:${filters.sortOrder}`} onValueChange={handleSortChange}>
          {filterOptions.sort.map((option) => (
            <DropdownMenuRadioItem key={option.value} value={option.value}>
              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
