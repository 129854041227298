import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { TextArea } from "@/forms/default";
import { PolicyFragment, useCreateProcessedBinderMutation, useQuoteQuery } from "src/generated/graphql";

export const GenerateProcessedBinderButton = ({ policy }: { policy: PolicyFragment }) => {
  const { id, quoteId } = policy;
  const { openForm } = useModal();
  const { toast } = useToast();
  const [createBinder, { loading }] = useCreateProcessedBinderMutation();
  const { data } = useQuoteQuery({
    variables: {
      id: quoteId,
    },
  });

  if (!data) {
    return null;
  }

  const { quote } = data;

  const createQuoteMetadata = async () => {
    const fd = await openForm(
      z.object({
        outstandingSubjectivities: z.string().min(1, { message: "Outstanding subjectivities is required" }),
      }),
      <CreateProcessedBinderForm />,
      { defaultValues: { outstandingSubjectivities: quote.outstandingSubjectivities ?? "" }, title: "Create Binder" }
    );

    if (!fd) {
      return;
    }

    await createBinder({
      variables: {
        input: {
          policyId: id,
          outstandingSubjectivities: fd.outstandingSubjectivities,
        },
      },
      onCompleted: () => {
        toast({ title: "Binder Processed" });
      },
    });
  };

  return (
    <Button onClick={createQuoteMetadata} variant="outline" size="sm" display="flex" disabled={loading}>
      {loading ? <Spinner /> : "Generate Binder Cover Letter"}
    </Button>
  );
};

function CreateProcessedBinderForm() {
  const { formState } = useFormContext();
  const errors = formState.errors;
  return (
    <div className="space-y-1.5">
      <TextArea
        required={true}
        name="outstandingSubjectivities"
        placeholder="Outstanding subjectivities (enter N/A if none apply)"
        className="px-4"
      />
      <div className="text-xs text-destructive">{errors?.outstandingSubjectivities?.message?.toString()}</div>
    </div>
  );
}
