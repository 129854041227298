import { Outlet } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { ScrollPane } from "@/components/scroll-pane";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";

const settingsPages = [
  { to: "/admin/enablement", label: "Enablement" },
  { to: "/admin/appetite", label: "Appetite" },
  { to: "/admin/appetite-notes", label: "Appetite Notes" },
  { to: "/admin/tags", label: "Tags" },
];

export const Admin = () => {
  useDocumentTitle("Admin: QuoteWell");
  return (
    <ScrollPane id="AdminRoot">
      <Bar>
        <BarLinks>
          {settingsPages.map(({ label, to }) => (
            <BarLink key={to} to={to}>
              {label}
            </BarLink>
          ))}
        </BarLinks>
      </Bar>
      <Outlet />
    </ScrollPane>
  );
};
