import { FC } from "react";
import { z } from "zod";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { ButtonGroup } from "@/forms-v2/button-group";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { Form } from "@/forms-v2/form";
import { FormGroup } from "@/forms-v2/form-group";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import { CreatePrimaryLabelInput, ExtractedLabelFragment, useCreatePrimaryLabelMutation } from "src/generated/graphql";
import { parseError } from "src/utils";

export interface CreatePrimaryLabelDialogProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  defaultValues?: Omit<CreatePrimaryLabelInput, "extractedLabels">;
  extractedLabels?: ExtractedLabelFragment[];
  onSubmit?: (values: CreatePrimaryLabelInput) => void;
}

const validationSchema = z.object({
  primaryKey: z.string().min(1, { message: "Please enter a primary key." }),
  displayName: z.string().optional(),
  dataType: z.string().min(1, { message: "Please select a data type." }),
  description: z.string().optional(),
  extractedLabels: z.array(z.string()).optional(),
});

export const CreatePrimaryLabelDialog: FC<CreatePrimaryLabelDialogProps> = ({
  open,
  onOpenChange,
  onSubmit,
  extractedLabels,
  ...props
}) => {
  const { toast } = useToast();
  const [createPrimaryLabelMutation] = useCreatePrimaryLabelMutation();

  const defaultValues = {
    primaryKey: "",
    displayName: "",
    dataType: "",
    description: "",
    extractedLabels: extractedLabels?.map(({ id }) => id),
    ...props.defaultValues,
  };

  const handleSubmit = async (values: CreatePrimaryLabelInput) => {
    await createPrimaryLabelMutation({
      variables: { input: { ...values, extractedLabels: defaultValues.extractedLabels } },
      refetchQueries: ["PrimaryLabels", "PaginatedPrimaryLabels"],
      onCompleted: () => {
        onSubmit?.(values);
        onOpenChange(false);
      },
      onError: (error) => toast({ title: "Error", description: parseError(error.message), variant: "destructive" }),
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <Form
          onSubmit={handleSubmit}
          defaultValues={defaultValues as CreatePrimaryLabelInput}
          validationSchema={validationSchema}
        >
          <FormGroup className="gap-6">
            <DialogHeader>
              <DialogTitle>Create primary label</DialogTitle>
              <DialogDescription>Some descriptive text about creating a new primary label.</DialogDescription>
            </DialogHeader>

            <FormGroup>
              <FieldInput name="primaryKey" label="Primary key" />
              <FieldInput name="displayName" label="Display name" />
              <FieldSelect
                name="dataType"
                label="Data type"
                options={[
                  { label: "Boolean", value: "boolean" },
                  { label: "Date", value: "date" },
                  { label: "Number", value: "number" },
                  { label: "Percent", value: "percent" },
                  { label: "String", value: "string" },
                ]}
              />
              <FieldTextarea name="description" label="Description" />
            </FormGroup>

            {!!extractedLabels?.length && (
              <div>
                <Label className="block text-sm mb-2">Assigning to</Label>
                <Alert>
                  <AlertDescription className="flex gap-3">
                    <Icon icon="label" className="text-lg" />
                    <div className="flex flex-col">
                      <span className="break-words">{extractedLabels?.[0].key}</span>
                      <span className="break-words text-xs text-muted-foreground">{extractedLabels?.[0].source}</span>
                    </div>
                  </AlertDescription>
                </Alert>
              </div>
            )}

            <ButtonGroup className="justify-end">
              <FormReset onClick={() => onOpenChange(false)} />
              <FormSubmit />
            </ButtonGroup>
          </FormGroup>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
