import type { ComponentProps, FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { Textarea } from "@/components/ui/textarea";
import { cn } from "src/utils";

import { FieldBase, FieldBaseProps } from "./field-base";

export type FieldTextareaProps = FieldBaseProps & {
  placeholder?: string;
  defaultValue?: ComponentProps<typeof Textarea>["defaultValue"];
  onChange?: ComponentProps<typeof Textarea>["onChange"];
  onBlur?: ComponentProps<typeof Textarea>["onBlur"];
  inputProps?: ComponentProps<typeof Textarea>;
};

export const FieldTextarea: FC<FieldTextareaProps> = ({
  placeholder,
  defaultValue,
  onChange,
  onBlur,
  inputProps,
  disabled,
  readOnly,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const { field } = useController({ control, name: props.name, disabled, defaultValue });

  const error = !!formState.errors[props.name];

  const handleChange: FieldTextareaProps["onChange"] = (event) => {
    field.onChange(event);
    onChange?.(event);
  };

  const handleBlur: FieldTextareaProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <Textarea
        readOnly={readOnly}
        {...field}
        {...inputProps}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        className={cn({ "border-destructive": !!error }, inputProps?.className)}
      />
    </FieldBase>
  );
};
