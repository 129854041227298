import { add, compareAsc, sub } from "date-fns";
import { useState } from "react";

import { useSnoozeOpportunityMutation } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { Button } from "./ui/button";
import { DatePicker } from "./ui/date-picker";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Icon } from "./ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import { useToast } from "./ui/use-toast";

export const SnoozeButton = ({
  opportunityId,
  desiredEffectiveDate,
  snoozeUntil,
}: {
  opportunityId: string;
  desiredEffectiveDate: string;
  snoozeUntil?: string | null;
}) => {
  const now = new Date();
  const effectiveDate = new Date(desiredEffectiveDate);

  const oneDayFromNow = add(now, { days: 1 });
  const oneWeekFromNow = add(now, { weeks: 1 });
  const oneMonthFromNow = add(now, { months: 1 });

  const oneDayFromEffDate = sub(effectiveDate, { days: 1 });
  const oneDayFromEffDateValid = compareAsc(oneDayFromEffDate, now) > 0;

  const threeDaysFromEffDate = sub(effectiveDate, { days: 3 });
  const threeDaysFromEffDateValid = compareAsc(threeDaysFromEffDate, now) > 0;

  const sevenDaysFromEffDate = sub(effectiveDate, { days: 7 });
  const sevenDaysFromEffDateValid = compareAsc(sevenDaysFromEffDate, now) > 0;

  const [mutate] = useSnoozeOpportunityMutation();
  const [desiredSnoozeUntil, setDesiredSnoozeUntil] = useState<string | undefined>(oneDayFromNow.toISOString());
  const { toast } = useToast();

  const handleSnooze = async () => {
    await mutate({
      variables: {
        input: {
          id: opportunityId,
          snoozeUntil: desiredSnoozeUntil,
        },
      },
    });
    toast({
      title: "Snoozed",
      description: "The marketing plan has been snoozed.",
    });
  };

  if (snoozeUntil) {
    return (
      <Tooltip>
        <TooltipTrigger className="filled absolute top-3 right-4 z-1 opacity-25 text-xs">
          <Icon icon="bedtime" />
        </TooltipTrigger>
        <TooltipContent>Snoozed until {formatTimezoneDateString(snoozeUntil)}</TooltipContent>
      </Tooltip>
    );
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" display="icon" className="absolute top-2 right-2.5 z-1">
          <Icon icon="notifications_paused" />
        </Button>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>Select Snooze Date</DialogTitle>
          <DialogDescription>
            Desired Effective Date: {formatTimezoneDateString(desiredEffectiveDate)}
          </DialogDescription>
        </DialogHeader>
        <DatePicker
          selected={desiredSnoozeUntil}
          onSelect={(e) => {
            setDesiredSnoozeUntil(e?.toISOString());
          }}
          disabledMatch={[{ before: now }]}
          inputClassName="border"
          className="absolute right top bottom"
        />
        <div className="gap-2 grid grid-cols-3">
          <Button variant="outline" size="sm" onClick={() => setDesiredSnoozeUntil(oneDayFromNow.toISOString())}>
            Snooze for 1D
          </Button>
          <Button variant="outline" size="sm" onClick={() => setDesiredSnoozeUntil(oneWeekFromNow.toISOString())}>
            Snooze for 1W
          </Button>
          <Button variant="outline" size="sm" onClick={() => setDesiredSnoozeUntil(oneMonthFromNow.toISOString())}>
            Snooze for 1M
          </Button>
          <Button
            variant="outline"
            size="sm"
            disabled={!oneDayFromEffDateValid}
            onClick={() => setDesiredSnoozeUntil(oneDayFromEffDate.toISOString())}
          >
            1D before Eff. Date
          </Button>
          <Button
            variant="outline"
            size="sm"
            disabled={!threeDaysFromEffDateValid}
            onClick={() => setDesiredSnoozeUntil(threeDaysFromEffDate.toISOString())}
          >
            3D before Eff. Date
          </Button>
          <Button
            variant="outline"
            size="sm"
            disabled={!sevenDaysFromEffDateValid}
            onClick={() => setDesiredSnoozeUntil(sevenDaysFromEffDate.toISOString())}
          >
            7D before Eff. Date
          </Button>
        </div>
        <DialogClose asChild>
          <Button variant="outline" size="sm" onClick={handleSnooze}>
            Snooze until {formatTimezoneDateString(desiredSnoozeUntil ?? "", "MMMM d, yyyy")}
          </Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};
