import { ApolloError } from "@apollo/client";
import { clsx, type ClassValue } from "clsx";
import { GraphQLFormattedError } from "graphql";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const waitMs = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export interface VerboseError extends ApolloError {
  message: string;
  graphQLErrors: ReadonlyArray<
    GraphQLFormattedError & {
      extensions?: {
        [key: string]: unknown;
        code?: string;
        response?: {
          statusCode: number;
          message: string[];
          error: string;
        };
      };
    }
  >;
}

export function parseError(error: VerboseError | string) {
  if (typeof error === "string") {
    return error;
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const { extensions, message } = error.graphQLErrors[0];

    if (!extensions?.response) {
      return message;
    }

    const messages = Array.isArray(extensions.response.message)
      ? extensions.response.message
      : [extensions.response.message];
    return `${extensions.response.error || "Error"}: ${messages.join(", ")}`;
  }
  return error.message;
}
