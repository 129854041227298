import { FC, useMemo, useState } from "react";

import { Button } from "@/components/ui/button";
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import {
  ExtractedLabelsQuery,
  PrimaryLabelsQuery,
  PrimaryLabelsSortBy,
  usePrimaryLabelsQuery,
  useToggleExtractedLabelToPrimaryLabelConnectionMutation,
} from "src/generated/graphql";
import { cn } from "src/utils";

import { CreatePrimaryLabelDialog } from "./create-primary-label-dialog";

export interface AssignPrimaryLabelPopoverProps {
  extractedLabel: ExtractedLabelsQuery["extractedLabels"][number];
}

export const AssignPrimaryLabelPopover: FC<AssignPrimaryLabelPopoverProps> = ({ extractedLabel }) => {
  const { data } = usePrimaryLabelsQuery({
    variables: {
      input: {
        filter: {
          sortBy: PrimaryLabelsSortBy.DisplayName,
          sortOrder: "asc",
        },
      },
    },
  });
  const [toggleExtractedLabelToPrimaryLabelConnectionMutation] =
    useToggleExtractedLabelToPrimaryLabelConnectionMutation();
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  const primaryLabels = data?.primaryLabels;

  const assignedPrimaryLabels = useMemo(
    () =>
      primaryLabels?.filter((primaryLabel) => primaryLabel.extractedLabels.some((el) => el.id === extractedLabel.id)),
    [primaryLabels, extractedLabel.id]
  );

  const unassignedPrimaryLabels = useMemo(
    () =>
      primaryLabels?.filter((primaryLabel) => !primaryLabel.extractedLabels.some((el) => el.id === extractedLabel.id)),
    [primaryLabels, extractedLabel.id]
  );

  const hasAssignedPrimaryLabels = !!assignedPrimaryLabels?.length;

  const assignedPrimaryLabelText = useMemo(() => {
    if (assignedPrimaryLabels?.length === 1) {
      return assignedPrimaryLabels[0].displayName || assignedPrimaryLabels[0].primaryKey;
    }

    if (assignedPrimaryLabels?.length) {
      return `${assignedPrimaryLabels?.length} assigned`;
    }

    return "Unassigned";
  }, [assignedPrimaryLabels]);

  const toggleSelect = (id: string) => {
    toggleExtractedLabelToPrimaryLabelConnectionMutation({
      variables: { input: { id, extractedLabelId: extractedLabel.id } },
      refetchQueries: ["PrimaryLabels", "PaginatedPrimaryLabels"],
    });
  };

  const handlePopoverOpenChange = (isOpen: boolean) => {
    setIsPopoverOpen?.(isOpen);

    if (!isOpen) {
      setTimeout(() => setValue(""), 150);
    }
  };

  return (
    <>
      <Popover open={isPopoverOpen} onOpenChange={handlePopoverOpenChange}>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className={cn("max-w-full font-normal gap-1", {
              "text-foreground": hasAssignedPrimaryLabels,
            })}
          >
            <span className="w-full truncate">{assignedPrimaryLabelText}</span>
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0">
          <Command>
            <div className="flex items-center">
              <div className="flex-1">
                <CommandInput value={value} onValueChange={setValue} className="border-b -mb-px" />
              </div>
              <div className="grow-0 pr-2">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="ghost" display="icon" size="sm" onClick={() => setIsDialogOpen(true)}>
                      <Icon icon="add_2" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">Create new primary label</TooltipContent>
                </Tooltip>
              </div>
            </div>

            {/* TODO: Maybe delete this, if we don't plan on using it. */}
            {/* {!!assignedPrimaryLabels?.length && (
              <div className="py-3 px-3">
                <div className="flex flex-wrap gap-2">
                  {assignedPrimaryLabels?.map((primaryLabel) => (
                    <div
                      key={`${primaryLabel.displayName} | ${primaryLabel.primaryKey} | ${primaryLabel.id}`}
                      className="flex gap-2"
                    >
                      <Button
                        variant="secondary"
                        size="xs"
                        className="gap-1"
                        onClick={() => toggleSelect(primaryLabel.id)}
                      >
                        <span className="max-w-56 truncate">{primaryLabel.displayName || primaryLabel.primaryKey}</span>
                        <Icon icon="close" />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )} */}

            <CommandList className="max-h-none">
              {!!primaryLabels?.length && (
                <>
                  <Separator />

                  <CommandGroup className="max-h-80 overflow-y-auto">
                    {assignedPrimaryLabels?.map((primaryLabel) => (
                      <PrimaryLabelCommandItem
                        key={primaryLabel.id}
                        primaryLabel={primaryLabel}
                        isSelected
                        onSelect={() => toggleSelect(primaryLabel.id)}
                      />
                    ))}

                    {unassignedPrimaryLabels?.map((primaryLabel) => (
                      <PrimaryLabelCommandItem
                        key={primaryLabel.id}
                        primaryLabel={primaryLabel}
                        onSelect={() => toggleSelect(primaryLabel.id)}
                      />
                    ))}
                  </CommandGroup>
                </>
              )}

              <Separator />

              <CommandGroup forceMount>
                <CommandItem forceMount value={`create new: ${value}`} onSelect={() => setIsDialogOpen(true)}>
                  <Icon icon="add_2" className="text-primary" />
                  <span className="break-words max-w-[87%]">Create new primary label {value && `for "${value}"`}</span>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      <CreatePrimaryLabelDialog
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        defaultValues={{ primaryKey: value, displayName: value }}
        extractedLabels={[extractedLabel]}
      />
    </>
  );
};

export interface PrimaryLabelCommandItemProps {
  primaryLabel: PrimaryLabelsQuery["primaryLabels"][number];
  onSelect: (value: string) => void;
  isSelected?: boolean;
}

const PrimaryLabelCommandItem: FC<PrimaryLabelCommandItemProps> = ({ primaryLabel, isSelected, onSelect }) => (
  <CommandItem
    value={`${primaryLabel.displayName} | ${primaryLabel.primaryKey} | ${primaryLabel.id}`}
    onSelect={onSelect}
    className="cursor-pointer flex max-w-full break-words"
  >
    <Icon icon="check" className={cn("shrink-0 grow-0 text-primary", { "opacity-0": !isSelected })} />
    <div
      className={cn("flex flex-col", {
        "max-w-[calc(100%_-_32px)]": !isSelected,
        "max-w-[calc(100%_-_64px)]": isSelected,
      })}
    >
      <span>{primaryLabel.displayName || primaryLabel.primaryKey}</span>
      <span className="text-2xs text-muted-foreground">{primaryLabel.primaryKey}</span>
    </div>
    <div className="flex-1" />
    {isSelected && <Icon icon="close" className={cn("shrink-0 grow-0 text-muted-foreground")} />}
  </CommandItem>
);
