import { MotionValue, Reorder, useDragControls, useMotionValue } from "motion/react";
import { ComponentProps, createContext, FC, useContext, useEffect, useState } from "react";

import { EditorCount } from "@/components/editor";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { cn } from "src/utils";

export const ReorderGroup = Reorder.Group;

const ReorderItemContext = createContext<ReturnType<typeof useDragControls> | null>(null);

export const ReorderItem: FC<ComponentProps<typeof Reorder.Item>> = ({ className, dragListener = true, ...props }) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const isDragging = useIsDragging({ x, y });
  const controls = useDragControls();

  return (
    <ReorderItemContext.Provider value={controls}>
      <Reorder.Item
        className={cn("group/reorder-item data-[dragging=true]:cursor-grabbing", className)}
        data-dragging={isDragging.isDraggingX || isDragging.isDraggingY}
        data-dragging-x={isDragging.isDraggingX}
        data-dragging-y={isDragging.isDraggingY}
        dragListener={dragListener}
        dragControls={controls}
        style={{ x, y }}
        {...props}
      />
    </ReorderItemContext.Provider>
  );
};

export interface ReorderHandleProps extends ComponentProps<typeof Button> {
  count?: number;
}

export const ReorderHandle: FC<ReorderHandleProps> = ({ count, className, ...props }) => {
  const controls = useContext(ReorderItemContext);
  const hasCount = count !== undefined;

  if (!controls) {
    throw new Error("ReorderHandle must be used within a ReorderItem");
  }

  return (
    <Button
      type="button"
      variant="ghost"
      size="sm"
      display="icon"
      onPointerDown={(e) => controls.start(e)}
      className={cn(
        "cursor-grab active:cursor-grabbing justify-center [[data-dragging=true]_&]:!bg-foreground/5",
        className
      )}
      {...props}
    >
      {hasCount && <EditorCount className="group-hover/reorder-item:hidden">{count}</EditorCount>}

      <Icon
        icon="drag_indicator"
        className={cn("text-muted-foreground", { "hidden group-hover/reorder-item:inline-flex": hasCount })}
      />
    </Button>
  );
};

export function useIsDragging({ x, y }: { x: MotionValue<number>; y: MotionValue<number> }) {
  const [isDraggingX, setIsDraggingX] = useState(false);
  const [isDraggingY, setIsDraggingY] = useState(false);

  useEffect(() => {
    x.on("change", (latest: number) => setIsDraggingX(latest === 0 ? false : true));
  }, [x]);

  useEffect(() => {
    y.on("change", (latest: number) => setIsDraggingY(latest === 0 ? false : true));
  }, [y]);

  return { isDraggingX, isDraggingY };
}
