import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Input } from "@/forms/default";
import {
  useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation,
  VerticalMarketingPlanTemplateProductFragment,
} from "src/generated/graphql";
import { useVertical } from "./use-vertical";

export const UpdateSubmissionEmail = ({ product }: { product: VerticalMarketingPlanTemplateProductFragment }) => {
  const { openForm } = useModal();
  const { refetch } = useVertical();

  const [updateVerticalMarketingPlanTemplateProductSubmissionEmailTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation({
      onCompleted() {
        void refetch();
      },
    });

  const updateSubmissionEmail = async (product: VerticalMarketingPlanTemplateProductFragment) => {
    const fd = await openForm(
      z.object({
        submissionEmail: z.string(),
      }),
      <Input name="submissionEmail" />,
      {
        defaultValues: {
          submissionEmail: product.submissionEmail ?? "",
        },
        title: "Update Submission Email",
      }
    );

    if (!fd) {
      return;
    }

    await updateVerticalMarketingPlanTemplateProductSubmissionEmailTrigger({
      variables: { input: { id: product.id, submissionEmail: fd.submissionEmail } },
    });
  };

  return (
    <Button variant="outline" size="xs" onClick={() => updateSubmissionEmail(product)}>
      {product.submissionEmail ?? "N / A"}
    </Button>
  );
};
