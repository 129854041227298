import React, { useCallback, useContext, useRef, useState } from "react";
import { DefaultValues } from "react-hook-form";
import { z, ZodRawShape } from "zod";

import { Reform } from "@/forms/reform";
import { cn } from "src/utils";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { Button, ButtonProps } from "./ui/button";

type RenderFn<T> = (close: (data?: T) => void) => React.ReactNode;
interface ModalContextData {
  open<T>(render: RenderFn<T>): Promise<T | undefined>;
  close<T>(data?: T): void;
  setTitle: React.Dispatch<
    React.SetStateAction<{
      title: React.ReactNode;
      description?: React.ReactNode;
    }>
  >;
}

const ModalContext = React.createContext<ModalContextData>({
  open: () => Promise.resolve(undefined),
  close: () => {},
  setTitle: () => {},
});

const Modal = ({
  onClose,
  content,
  isOpen,
  title,
  description,
}: {
  onClose: () => void;
  isOpen: boolean;
  content?: React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
}) => {
  return (
    <AlertDialog
      open={isOpen}
      onOpenChange={(shouldBeOpen) => {
        if (!shouldBeOpen) {
          onClose();
        }
      }}
    >
      <AlertDialogContent className="max-h-[85dvh] overflow-y-auto">
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription className={cn(!description && "hidden")}>
            {description ?? title}
          </AlertDialogDescription>
        </AlertDialogHeader>
        {content}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ isOpen, content }, setData] = useState<{
    isOpen: boolean;
    content?: React.ReactNode;
  }>({
    isOpen: false,
  });

  const [{ title, description }, setTitle] = useState<{
    title: React.ReactNode;
    description?: React.ReactNode;
  }>({ title: "", description: "" });

  const resolver = useRef<(v?: unknown) => void>();

  const close = useCallback(function <T>(data?: T) {
    resolver.current?.(data);
    setData((prev) => ({ ...prev, isOpen: false }));
  }, []);

  const open = useCallback(
    function <T>(fn: RenderFn<T>) {
      // Resolve any previous promises
      resolver.current?.();

      return new Promise<T | undefined>((resolve, reject) => {
        try {
          resolver.current = resolve as any;
          const content = fn(close);
          setData({ isOpen: true, content });
        } catch (error) {
          reject(error);
        }
      });
    },
    [close]
  );

  return (
    <ModalContext.Provider value={{ open, close, setTitle }}>
      <Modal onClose={close} content={content} isOpen={isOpen} title={title} description={description} />
      {children}
    </ModalContext.Provider>
  );
};

interface Options<TSchema extends z.ZodObject<ZodRawShape>> {
  defaultValues?: DefaultValues<z.infer<TSchema>>;
  title: React.ReactNode;
  description?: React.ReactNode;
}

export const useModal = () => {
  const { open, close, setTitle } = useContext(ModalContext);

  const openModal = useCallback(
    function <T>(openFn: RenderFn<T>, options?: { title: React.ReactNode; description?: React.ReactNode }) {
      if (options) {
        setTitle(options);
      }

      return open(openFn);
    },
    [open]
  );

  const openForm = useCallback(
    function <TSchema extends z.ZodObject<ZodRawShape>>(
      schema: TSchema,
      formFields: React.ReactNode,
      { defaultValues, title, description }: Options<TSchema>
    ) {
      setTitle({ title, description });
      return open<z.infer<TSchema>>((close) => (
        <>
          <Reform
            schema={schema}
            onSubmit={(_, v) => close(v)}
            onReset={() => close()}
            defaultValues={defaultValues}
            id="modalForm"
          >
            {formFields}
          </Reform>
          <AlertDialogFooter className="bottom-0 sticky">
            <AlertDialogCancel onClick={() => close()}>Cancel</AlertDialogCancel>
            <Button type="submit" form="modalForm">
              Submit
            </Button>
          </AlertDialogFooter>
        </>
      ));
    },
    [open]
  );

  const openConfirmation = useCallback(
    ({
      title,
      description,
      buttonProps,
    }: {
      title: React.ReactNode;
      description?: React.ReactNode;
      buttonProps?: ButtonProps;
    }) => {
      setTitle({ title, description });
      return open<boolean>((close) => (
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => close(false)}>Cancel</AlertDialogCancel>
          <Button {...buttonProps} onClick={() => close(true)}>
            Confirm
          </Button>
        </AlertDialogFooter>
      ));
    },
    [open]
  );

  return {
    openModal,
    closeModal: close,
    openConfirmation,
    openForm,
  };
};
