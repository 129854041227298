import { CSSProperties } from "react";

import { cn } from "src/utils";
import { Textarea, TextareaProps } from "./textarea";

export interface AutoresizingTextareaProps extends TextareaProps {
  children?: React.ReactNode;
  handleChange: (text: string) => void;
}

export const AutoresizingTextarea = ({
  children,
  className,
  value,
  rows = 1,
  handleChange,
  ...rest
}: AutoresizingTextareaProps) => (
  <div className="grid relative">
    <div className={cn(className, "pointer-events-none visually-hidden whitespace-pre-wrap")} style={style}>
      {value}
      <br />
    </div>
    <Textarea
      value={value}
      rows={rows}
      onChange={({ target }) => handleChange(target.value)}
      className={cn("resize-none", className)}
      style={style}
      {...rest}
    />
    {children}
  </div>
);

const style: CSSProperties = { gridArea: "1 / 1 / 2 / 2", overflowWrap: "anywhere" };
