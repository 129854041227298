import { createContext } from "react";
import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Card, CardFooter } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { InternalDropzone } from "@/files/internal-dropzone";
import { useMyAccount } from "@/hooks/use-my-account";
import { MARKETING_PLAN_STATE_METADATA } from "@/metadata";
import {
  File_Audience,
  MarketingPlanState,
  OpportunityRequirementsQuery,
  useOpportunityRequirementsQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { RequirementsActions } from "./actions/requirements-actions";
import { AddRequirements } from "./add-requirements";
import { RequirementsList } from "./requirements-list";

export interface RequirementsContextData {
  opportunity: OpportunityRequirementsQuery["opportunity"];
  refetch: () => void;
}

export const RequirementsContext = createContext<RequirementsContextData>({
  opportunity: null,
  refetch: () => {},
});

export const Requirements = () => {
  const { opportunityId } = useParams();
  const { data: user } = useMyAccount();

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityRequirementsQuery({
    variables: { id: opportunityId ?? "" },
    pollInterval: 2000,
  });

  if (
    !opportunity ||
    !user ||
    opportunity.state === MarketingPlanState.WaitingForCoverages ||
    opportunity.state === MarketingPlanState.Complete
  ) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  const hasRequirements = opportunity.agentActions.some((action) => !action.complete);

  return (
    <RequirementsContext.Provider value={{ opportunity, refetch }}>
      <Card className={cn(hasRequirements && "ring-1 ring-current text-destructive")}>
        <SectionHeader className="space-y-2">
          <SectionTitle>
            <Icon
              icon={hasRequirements ? "warning" : "check_circle"}
              className={cn("filled flex-none text-lg w-6", !hasRequirements && "text-success")}
            />
            <h3 className="flex-auto">Required Information</h3>
            <HasInternalRole>
              <div className="flex gap-2 items-center">
                <AddRequirements opportunityId={opportunity.id} />
                <RequirementsActions opportunity={opportunity} refetch={refetch} />
              </div>
            </HasInternalRole>
          </SectionTitle>
          {hasRequirements && (
            <SectionDescription className="ml-10">
              {MARKETING_PLAN_STATE_METADATA[MarketingPlanState.InformationGathering].agentDescription}
            </SectionDescription>
          )}
        </SectionHeader>
        <RequirementsList />
        <InternalDropzone audience={File_Audience.External} onDrop={() => refetch()}>
          <CardFooter className="border-t p-2">
            <div className="bg-accent border border-dashed cursor-pointer flex flex-auto gap-4 items-center p-4 rounded-md text-muted-foreground">
              <Icon icon="cloud_upload" className="flex-none text-lg w-6" />
              <div className="text-sm">Click here, or drag and drop files to start an upload.</div>
            </div>
          </CardFooter>
        </InternalDropzone>
      </Card>
    </RequirementsContext.Provider>
  );
};
