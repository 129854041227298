// Looking for an icon? Make sure Material Symbols and Rounded are selected and
// then snake case the names of the icons here:
//
// https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Symbols

import { MaterialSymbol } from "material-symbols";
import { cn } from "src/utils";

export const Icon = ({ icon, className }: { icon: MaterialSymbol; className?: string }) => (
  <span
    className={cn("icon inline-flex items-center justify-center aspect-square flex-none", className)}
    data-icon={icon}
  >
    {icon}
  </span>
);
