import { SectionContent, SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Separator } from "@/components/ui/separator";
import { QuoteFragment } from "src/generated/graphql";
import { formatDateString, formatTimezoneDateString } from "src/utils/date";
import { formatDollarsAndCents } from "src/utils/strings";

export const QuoteInfo = ({ quote }: { quote: QuoteFragment }) => {
  const {
    submission,
    premium,
    mep,
    agentCommission,
    carrierFee,
    brokerFee,
    inspectionFee,
    effectiveDate,
    expirationDate,
    stateTransitions,
    createdAt,
  } = quote;

  const lastTransition = stateTransitions.length > 0 ? stateTransitions[stateTransitions.length - 1] : { createdAt };

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>{submission.appetiteProduct.carrierName}</h1>
        </SectionTitle>
        <SectionDescription>{submission.appetiteProduct.carrierProductName}</SectionDescription>
      </SectionHeader>
      <Separator />
      <SectionContent className="bg-background gap-4 grid grid-cols-3">
        <dl>
          <dt>Premium</dt>
          <dd>{premium && formatDollarsAndCents(premium)}</dd>
        </dl>
        <dl>
          <dt>MEP</dt>
          <dd>{mep ?? "0"}%</dd>
        </dl>
        <dl>
          <dt>Commission</dt>
          <dd>{agentCommission}%</dd>
        </dl>
        <dl>
          <dt>Carrier Fee</dt>
          <dd>{carrierFee && formatDollarsAndCents(carrierFee)}</dd>
        </dl>
        <dl>
          <dt>Broker Fee</dt>
          <dd>{brokerFee && formatDollarsAndCents(brokerFee)}</dd>
        </dl>
        <dl>
          <dt>Inspection Fee</dt>
          <dd>{inspectionFee && formatDollarsAndCents(inspectionFee)}</dd>
        </dl>
        <dl>
          <dt>Effective Date</dt>
          <dd>{effectiveDate ? formatDateString(effectiveDate, "MMMM d, yyyy") : "Effective date not set"}</dd>
        </dl>
        <dl>
          <dt>Expiration Date</dt>
          <dd>{expirationDate ? formatDateString(expirationDate, "MMMM d, yyyy") : "Expiration date not set"}</dd>
        </dl>
        <dl>
          <dt>Date Created</dt>
          <dd>{formatTimezoneDateString(createdAt, "MMMM d, yyyy")}</dd>
        </dl>
        <dl>
          <dt>Date Updated</dt>
          <dd>{formatTimezoneDateString(lastTransition.createdAt, "MMMM d, yyyy")}</dd>
        </dl>
      </SectionContent>
    </>
  );
};
