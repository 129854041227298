import { Link, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { QuoteAction, QuoteFragment, useTransitionQuoteMutation } from "src/generated/graphql";

export const BindRequestReceived = ({ quote }: { quote: QuoteFragment }) => {
  const [transition] = useTransitionQuoteMutation();
  const { insuredId } = useParams<"insuredId">();

  const handleTransition = async (action: QuoteAction) => {
    await transition({
      variables: {
        input: {
          id: quote.id,
          expectedState: quote.state,
          action,
        },
      },
    });
  };

  return (
    <>
      <Button variant="outline" size="sm" display="flex" asChild>
        <Link to={`/insured/${insuredId}/quotes/${quote.id}/bind`}>Bind Policy</Link>
      </Button>
      <Button onClick={() => handleTransition(QuoteAction.Invalidate)} variant="outline" size="sm" display="flex">
        Invalidate
      </Button>
      <Button onClick={() => handleTransition(QuoteAction.ReceiveNotTaken)} variant="outline" size="sm" display="flex">
        Quote Not Taken
      </Button>
    </>
  );
};
