import { zodResolver } from "@hookform/resolvers/zod";
import { FormEvent } from "react";
import { DefaultValues, FormProvider, FormState, Mode, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

interface Props<TSchema extends z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>>
  extends Omit<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, "onSubmit"> {
  schema: TSchema;
  methods?: UseFormReturn<z.input<TSchema>, any, undefined>;
  onSubmit(e: FormEvent<HTMLFormElement>, value: z.output<TSchema>, formState: FormState<z.input<TSchema>>): void;
  // NOTE: react-hook-forms supports async. Add that type if needed.
  defaultValues?: DefaultValues<z.input<TSchema>>;
  clearFormState?: boolean;
  mode?: Mode;
}
/**
 * Combines React-router Form with react-hook-forms
 * @param param0
 * @returns
 */
export function Reform<TSchema extends z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>>({
  children,
  className,
  schema,
  methods: providedMethods,
  onSubmit,
  defaultValues,
  clearFormState,
  mode = "onBlur",
  ...props
}: Props<TSchema>) {
  const generatedMethods = useForm({ mode, resolver: zodResolver(schema), defaultValues });
  const methods = providedMethods ?? generatedMethods;

  return (
    <FormProvider {...methods}>
      <form
        {...props}
        className={className}
        onSubmit={(e) => {
          e.preventDefault();
          void methods.trigger();
          onSubmit?.(e, schema.parse(methods.getValues()), methods.formState);
          if (clearFormState) {
            methods.reset();
          }
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
}
