import { SectionContent } from "@/components/section";
import { useVertical } from "./components/use-vertical";
import { TemplateRules } from "./template-rules";

export const VerticalRules = () => {
  const { templates } = useVertical();
  return (
    <div className="divide-y">
      {templates.map((template) => (
        <SectionContent key={template.id}>
          <TemplateRules template={template} />
        </SectionContent>
      ))}
    </div>
  );
};
