import { MaterialSymbol } from "material-symbols";

import { cn } from "src/utils";
import { SectionTitle } from "./section";
import { Icon } from "./ui/icon";

export const EmptyState = ({
  children,
  hasData = false,
  icon = "unknown_document",
  loading,
  title,
}: {
  children?: React.ReactNode;
  hasData?: boolean;
  icon?: MaterialSymbol;
  loading: boolean;
  title: React.ReactNode;
}) => {
  if (loading) {
    return null;
  }

  if (hasData) {
    return null;
  }

  return (
    <div className={cn("bg-background border flex items-center m-6 rounded-lg")}>
      <div className="aspect-auto flex flex-none h-24 w-24 items-center justify-center p-4 text-4xl">
        <Icon icon={icon} />
      </div>
      <div className="flex-auto p-4 pl-0 space-y-2">
        <SectionTitle>{title}</SectionTitle>
        {children}
      </div>
    </div>
  );
};
