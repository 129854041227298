import { MaterialSymbol } from "material-symbols";
import { ReactNode } from "react";

import { Button, ButtonProps } from "./ui/button";
import { Icon } from "./ui/icon";
import { toast } from "./ui/use-toast";

export interface ClippyProps extends ButtonProps {
  content: string;
  icon?: MaterialSymbol;
  label?: ReactNode;
  title?: string;
  description?: ReactNode;
}

export const Clippy = ({
  content,
  icon = "copy_all",
  label,
  title = "Copied to clipboard",
  description,
  className,
  onClick,
  ...props
}: ClippyProps) => (
  <Button
    variant="outline"
    size="sm"
    className={className}
    onClick={async (e) => {
      e.preventDefault();
      await navigator.clipboard.writeText(content);
      toast({ title, description: description || content });
      onClick?.(e);
    }}
    {...props}
  >
    {label}
    <Icon icon={icon} />
  </Button>
);
