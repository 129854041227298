import { groupBy } from "lodash";
import { Fragment, useState } from "react";

import { EditorCount } from "@/components/editor";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { MoreMenu } from "@/components/more-menu";
import { Button } from "@/components/ui/button";
import { Card, CardFooter, CardHeader } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { VerticalMarketingPlanTemplateFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import { TemplateHeader } from "./components/template-header";
import { useTemplate } from "./components/use-template";
import { getRuleFromKey, getRuleKey, Rule, Rules } from "./rule";

export const TemplateRules = ({ template }: { template: VerticalMarketingPlanTemplateFragment }) => {
  const [selectedRule, setSelectedRule] = useState("");
  const [checkedProducts, setCheckedProducts] = useState<Set<string>>(new Set());
  const { addTemplateRule, editTemplateRule, deleteTemplateRule, editProductRules } = useTemplate({
    template,
  });

  const selectedRuleCheckedProducts = (ruleKey: string) =>
    rules[ruleKey].map((rule) => rule.product).filter((product) => checkedProducts.has(product.id));

  const checkProduct = (id: string) => {
    setCheckedProducts((checkedProducts) => new Set([...checkedProducts, id]));
  };

  const checkAllProducts = () => {
    const allProducts = rules[selectedRule].map((rule) => rule.product.id);
    setCheckedProducts(new Set(allProducts));
  };

  const uncheckProduct = (id: string) => {
    setCheckedProducts((checkedProducts) => {
      checkedProducts.delete(id);
      return new Set(checkedProducts);
    });
  };

  const uncheckAllProducts = () => setCheckedProducts(new Set([]));

  const rules = groupBy(
    template.products.flatMap((p) => {
      const rules = JSON.parse(p.rules) as Rules;

      return rules.map((rule) => ({
        id: getRuleKey(rule),
        product: p,
        rule,
      }));
    }),
    (rule) => rule.id
  );

  const numRules = Object.keys(rules).length;

  return (
    <Card className="grid grid-cols-[2fr_1px_3fr]">
      <div className="flex flex-col justify-between">
        <TemplateHeader template={template} />
        {numRules > 0 && (
          <Grid className="grid-cols-[7rem_1fr_1.5rem] mb-auto">
            <GridRowHeader>
              <GridCell>Type</GridCell>
              <GridCell>Key</GridCell>
              <GridCell />
            </GridRowHeader>
            {Object.entries(rules).map(([ruleKey]) => (
              <GridRow
                key={ruleKey}
                onClick={() => {
                  setSelectedRule(ruleKey);
                  uncheckAllProducts();
                }}
                className={cn("cursor-pointer", ruleKey === selectedRule && "bg-primary/5")}
              >
                <RuleKeyDisplay ruleKey={ruleKey} />
                <MoreMenu>
                  <DropdownMenuItem onClick={() => editTemplateRule(ruleKey, () => setSelectedRule(""))}>
                    <Icon icon="edit" />
                    Edit
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => deleteTemplateRule(ruleKey, () => setSelectedRule(""))}>
                    <Icon icon="delete" />
                    Delete
                  </DropdownMenuItem>
                </MoreMenu>
              </GridRow>
            ))}
          </Grid>
        )}
        <CardFooter className="bg-accent border-t py-3 rounded-bl-lg">
          <Button variant="outline" size="xs" onClick={addTemplateRule}>
            Add Rule
          </Button>
        </CardFooter>
      </div>
      <Separator orientation="vertical" />
      {selectedRule && (
        <div className="flex flex-col justify-between">
          <CardHeader className="font-semibold flex-row-reverse gap-2 h-16 items-center justify-between text-sm">
            <RuleKeyDisplay ruleKey={selectedRule} />
          </CardHeader>
          <Grid className="grid-cols-[1.5rem_1fr_3fr_1fr_1fr_1.5rem] mb-auto">
            <GridRowHeader>
              <Checkbox
                checked={checkedProducts.size === numRules}
                onCheckedChange={(checked) => (checked ? checkAllProducts() : uncheckAllProducts())}
              />
              <GridCell>Carrier</GridCell>
              <GridCell>Product</GridCell>
              <GridCell>Operator</GridCell>
              <GridCell>Value</GridCell>
              <GridCell />
            </GridRowHeader>
            {Object.entries(rules).map(([ruleKey, rules]) =>
              rules.map((rule, i) => (
                <GridRow key={i} className={cn(ruleKey !== selectedRule && "hidden")}>
                  <Checkbox
                    checked={checkedProducts.has(rule.product.id)}
                    onCheckedChange={(checked) =>
                      checked ? checkProduct(rule.product.id) : uncheckProduct(rule.product.id)
                    }
                  />
                  <GridCell>{rule.product.appetiteProduct.carrierName}</GridCell>
                  <GridCell>{rule.product.appetiteProduct.carrierProductName}</GridCell>
                  <RuleDisplay rule={rule.rule} />
                  <Button
                    variant="ghost"
                    size="sm"
                    display="icon"
                    onClick={() => editProductRules([rule.product], ruleKey)}
                  >
                    <Icon icon="edit" />
                  </Button>
                </GridRow>
              ))
            )}
          </Grid>
          <CardFooter className="bg-accent border-t py-3 rounded-br-lg">
            <span className="h-6" />
            {checkedProducts.size > 1 && (
              <Button
                variant="outline"
                size="xs"
                className="ml-auto"
                onClick={() => editProductRules(selectedRuleCheckedProducts(selectedRule), selectedRule)}
              >
                Edit {checkedProducts.size} Rules
              </Button>
            )}
          </CardFooter>
        </div>
      )}
    </Card>
  );
};

const RuleKeyDisplay = ({ ruleKey }: { ruleKey: string }) => {
  const rule = getRuleFromKey(ruleKey);
  return (
    <>
      {rule.map((r, i) => (
        <Fragment key={i}>
          <GridCell>
            <EditorCount>{r.type}</EditorCount>
          </GridCell>
          <GridCell>{r.key || (r.type === "minimum-premium" && "Minimum Premium")}</GridCell>
        </Fragment>
      ))}
    </>
  );
};

const RuleDisplay = ({ rule }: { rule: Rule }) => (
  <>
    {rule.map((r, i) => (
      <Fragment key={i}>
        <GridCell>{"operator" in r ? r.operator : ""}</GridCell>
        <GridCell>{r.value}</GridCell>
      </Fragment>
    ))}
  </>
);
