import { Maps } from "@cp/toolkit";
import { SentryLogger } from "@qw/sentry";
import { FormProvider, useForm } from "react-hook-form";

import { ClientDataContext } from "@/client-data/client-data-context";
import { useToast } from "@/components/ui/use-toast";
import { SubmissionTemplate } from "@/supplementals/pdfs/submission-template";
import { useClientDataLazyQuery } from "src/generated/graphql";
import { useSubmissionTemplate } from "./use-submission-template";

export const SubmissionTemplateForm = () => {
  const { insuredId } = useSubmissionTemplate();
  const { toast } = useToast();
  const [clientData] = useClientDataLazyQuery();

  const methods = useForm({
    defaultValues: async () => {
      if (!insuredId) {
        console.warn("No insuredId present. Data won't be saved.");
        return {};
      }

      const { data: supplementalResult, error } = await clientData({
        variables: { input: { insuredId } },
      });

      if (!supplementalResult) {
        SentryLogger.warn(`Supplemental result was undefined for Insured (${insuredId})`);
        return {};
      }

      if (error) {
        SentryLogger.exception(error);
        toast({
          title: "Error",
          description: "There was an error loading your supplemental data.",
          variant: "destructive",
        });
        return {};
      }

      const supplementalData = Maps.collectBy(
        supplementalResult.clientData,
        (val) => `${val.key}${val.index === null ? "" : `--${val.index}`}`,
        (val) => val.value
      );

      return Object.fromEntries(supplementalData.entries());
    },
  });

  return (
    <form id="pdf-input" onSubmit={(e) => e.preventDefault()}>
      <ClientDataContext.Provider value={{ insuredId }}>
        <FormProvider {...methods}>
          <SubmissionTemplate />
        </FormProvider>
      </ClientDataContext.Provider>
    </form>
  );
};
