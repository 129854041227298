import { SectionContent } from "@/components/section";
import { useVertical } from "./components/use-vertical";
import { TemplateHierarchy } from "./template-hierarchy";

export const VerticalHierarchy = () => {
  const { templates } = useVertical();
  return (
    <div className="divide-y">
      {templates.map((template) => (
        <SectionContent key={template.id}>
          <TemplateHierarchy template={template} />
        </SectionContent>
      ))}
    </div>
  );
};
