import { Checkbox } from "@/components/ui/checkbox";
import { FormField } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { cn } from "src/utils";

export const LabelledCheckbox = ({ label, value, name }: { label?: string; value: string; name: string }) => (
  <FormField
    name={name}
    render={({ field }) => {
      const checked = field.value?.includes(value);
      return (
        <Label
          className={cn(
            "flex font-normal gap-3 items-center text-sm",
            checked ? "text-foreground" : "text-muted-foreground"
          )}
        >
          <Checkbox
            checked={checked}
            className={cn(checked ? "border-primary" : "border-muted-foreground")}
            onCheckedChange={(checked) => {
              return checked
                ? field.onChange([...field.value, value])
                : field.onChange(field.value?.filter((fieldValue: string) => fieldValue !== value));
            }}
          />
          <span className="flex-auto">{label ?? value}</span>
        </Label>
      );
    }}
  />
);
