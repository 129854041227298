import { useEdges } from "@xyflow/react";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { FC } from "react";
import { useParams } from "react-router";

import { Clippy } from "@/components/clippy";
import {
  SidePanel,
  SidePanelAction,
  SidePanelDetails,
  SidePanelHeader,
  SidePanelLoading,
  SidePanelSection,
} from "@/components/side-panel";
import { FileProcessorCategory, FileProcessorQuery, useFileProcessorQuery } from "src/generated/graphql";

import { DetailsFilesList, FileUpload } from "../components/details-files-list";
import { DetailsIssuesList } from "../components/details-issues-list";
import { DetailsLinksList } from "../components/details-links-list";
import { DetailsProcessorVersionsList } from "../components/details-processor-versions-list";
import { useEditProcessorFormModal } from "../components/forms/edit-processor-form";
import { useRemoveProcessorFormModal } from "../components/forms/remove-processor-form";
import { useRerunProcessorFormModal } from "../components/forms/rerun-processor-form";
import { processorCategoryToIconMap } from "../file-processing-pipeline.constants";
import { isRootNode } from "../file-processing-pipeline.helpers";
import { useHasDownStreamProcessors } from "../file-processing-pipeline.hooks";

export interface FileProcessorDetailsProps {
  data: FileProcessorQuery["fileProcessor"];
}

export const FileProcessorDetails: FC<FileProcessorDetailsProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      {!!data.issues?.length && (
        <SidePanelSection hasDivider={false} className="py-0">
          <SidePanelDetails>
            <DetailsIssuesList issues={data.issues} />
          </SidePanelDetails>
        </SidePanelSection>
      )}

      <SidePanelSection hasDivider={!data.issues?.length}>
        <SidePanelDetails label="Processor type">
          <p>{data?.category}</p>
        </SidePanelDetails>
      </SidePanelSection>

      <SidePanelSection>
        <SidePanelDetails label="Total files processed">
          <p>{Intl.NumberFormat("en-us").format(data?.totalProcessedFiles || 0)}</p>
        </SidePanelDetails>
      </SidePanelSection>

      <SidePanelSection>
        <SidePanelDetails label="Processor ID">
          <div className="-mx-2 -mt-0.5 -mb-1">
            {data?.id && (
              <Clippy
                variant="ghost"
                size="default"
                className="flex max-w-full truncate px-2 h-8"
                content={data.id}
                label={<span className="truncate text-foreground font-normal">{data.id}</span>}
              />
            )}
          </div>
        </SidePanelDetails>
      </SidePanelSection>

      {!!data.processorVersions?.length && (
        <SidePanelSection>
          <SidePanelDetails>
            <DetailsProcessorVersionsList
              processorId={data.id}
              processorVersions={data.processorVersions}
              defaultProcessorVersionId={data.defaultProcessorVersion}
            />
          </SidePanelDetails>
        </SidePanelSection>
      )}

      <SidePanelSection>
        <SidePanelDetails label="Links">
          <DetailsLinksList
            links={[
              {
                label: "Document AI",
                url: `https://console.cloud.google.com/ai/document-ai/locations/us/processors/${data?.id}`,
              },
              {
                label: "Google Cloud Storage Bucket",
                url: `https://console.cloud.google.com/storage/browser/qw-document-ai-training/training/${data?.name}`,
              },
            ]}
          />
        </SidePanelDetails>
      </SidePanelSection>

      {!!data?.files.length && (
        <SidePanelSection>
          <SidePanelDetails label="Recent files">
            <DetailsFilesList files={data?.files as unknown as FileUpload[]} />
          </SidePanelDetails>
        </SidePanelSection>
      )}
    </>
  );
};

export const FileProcessorDetailsSidePanel = () => {
  const { fileProcessorId } = useParams();
  const edges = useEdges();
  const { data, loading } = useFileProcessorQuery({ variables: { id: fileProcessorId! } });
  const { openRerunProcessorForm } = useRerunProcessorFormModal({ processorId: data?.fileProcessor?.id });
  const { openEditProcessorForm } = useEditProcessorFormModal({ processorId: data?.fileProcessor?.id });
  const { openRemoveProcessorForm } = useRemoveProcessorFormModal({ processorId: data?.fileProcessor?.id });

  const isLoading = loading && !data?.fileProcessor;
  const isEmpty = !loading && !data?.fileProcessor;

  const icon = processorCategoryToIconMap[data?.fileProcessor?.category as FileProcessorCategory];

  const hasDownstreamProcessors = useHasDownStreamProcessors(data?.fileProcessor?.name);

  const actions: SidePanelAction[] = [
    {
      id: "rerunProcessor",
      icon: "replay",
      label: "Rerun processor",
      onClick: openRerunProcessorForm,
    },
    {
      id: "editProcessor",
      icon: "edit",
      label: "Edit processor",
      onClick: openEditProcessorForm,
    },
    {
      id: "removeProcessor",
      icon: "delete",
      label: "Remove processor",
      onClick: openRemoveProcessorForm,
      isHidden: isRootNode(data?.fileProcessor?.id as string, edges) || hasDownstreamProcessors,
    },
  ];

  return (
    <SidePanel>
      <SidePanelHeader
        icon={icon}
        label="File Processor"
        title={startCase(toLower(data?.fileProcessor?.name.replaceAll("_", " ")))}
        actions={actions}
        onCloseNavigatePath="/file-processing-pipeline"
        isLoading={isLoading}
        isEmpty={isEmpty}
      />

      {isLoading && <SidePanelLoading />}

      {!isLoading && isEmpty && (
        <SidePanelSection className="space-y-2">No matching file processor found.</SidePanelSection>
      )}

      {!isLoading && !isEmpty && <FileProcessorDetails data={data?.fileProcessor} />}
    </SidePanel>
  );
};
