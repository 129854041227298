import { UUID } from "@cp/toolkit";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { RelativeDate } from "@/components/ui/relative-date";
import { toast } from "@/components/ui/use-toast";
import { useCreateOpportunityNoteMutation, useOpportunityDetailsQuery } from "src/generated/graphql";
import { AutoresizingTextarea, AutoresizingTextareaProps } from "./ui/autoresizing-textarea";

export const Notes = ({ opportunityId }: { opportunityId: UUID }) => {
  const [text, setText] = useState("");
  const [saving, setSaving] = useState(false);

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityDetailsQuery({
    variables: {
      id: opportunityId ?? "",
    },
    fetchPolicy: "cache-and-network",
    skip: !opportunityId,
  });

  const [createNote] = useCreateOpportunityNoteMutation();

  if (!opportunity) {
    return null;
  }

  const onSubmit = async () => {
    setSaving(true);
    await createNote({
      variables: {
        input: {
          opportunityId: opportunity.id,
          text,
        },
      },
      onCompleted() {
        setSaving(false);
        setText("");
        return refetch();
      },
      onError: () => {
        setSaving(false);
        toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
      },
    });
  };

  return (
    <Card>
      {opportunity.notes[0] && (
        <>
          <CardHeader>
            <CardTitle>Notes</CardTitle>
          </CardHeader>
          <CardContent className="pb-3 space-y-6">
            {opportunity.notes.map((note) => (
              <div key={note.id} className="space-y-1.5">
                <div className="text-xs whitespace-pre-wrap" style={{ overflowWrap: "anywhere" }}>
                  {note.text}
                </div>
                <footer className="flex gap-1 items-center text-2xs text-muted-foreground">
                  <strong>
                    {note.creator.firstName} {note.creator.lastName}
                  </strong>
                  <RelativeDate date={note.createdAt} />
                </footer>
              </div>
            ))}
          </CardContent>
        </>
      )}
      <footer className="p-3 relative">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onSubmit();
          }}
        >
          <NoteInput
            name="note"
            value={text}
            placeholder="Add a Note"
            spinner={saving || loading}
            handleChange={setText}
          />
        </form>
      </footer>
    </Card>
  );
};

export const NoteInput = ({ spinner, handleChange, ...rest }: AutoresizingTextareaProps & { spinner: boolean }) => (
  <AutoresizingTextarea
    className="bg-accent border-none focus-visible:ring-0 focus-visible:ring-offset-0 min-h-10 p-3 pr-14 rounded-md shadow-inner text-xs"
    handleChange={handleChange}
    {...rest}
  >
    <Button
      type="submit"
      variant="ghost"
      size="sm"
      display="icon"
      className="absolute bottom-1 h-8 right-1 !text-primary w-8"
    >
      {spinner ? <Spinner /> : <Icon icon="send" />}
    </Button>
  </AutoresizingTextarea>
);
