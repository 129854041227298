import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { VerticalMarketingPlanTemplateProductFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import {
  CglRuleItem,
  CglRuleOperator,
  ClientDataRuleBooleanOperator,
  ClientDataRuleItem,
  ClientDataRuleNumberOperator,
  ClientDataRuleTextOperator,
  ClientDataRuleValueType,
  MinimumPremiumRuleItem,
  operatorString,
  RuleItem,
  RuleType,
  StateRuleItem,
  StateRuleOperator,
  validRule,
} from "../rule";

export function ProductRuleForm({
  products,
  rule,
}: {
  products: VerticalMarketingPlanTemplateProductFragment[];
  rule: RuleItem;
}) {
  const { setValue } = useFormContext();
  const [currentRule, setCurrentRule] = useState(rule);

  useEffect(() => {
    setValue("rule", currentRule);
  }, [currentRule]);

  const valid = validRule(currentRule);

  const productName =
    products.length > 1
      ? `${products.length} products`
      : `${products[0].appetiteProduct.carrierName} ${products[0].appetiteProduct.carrierProductName}`;

  return (
    <>
      <div className="space-y-3">
        <RuleEditor rule={currentRule} onRuleChange={setCurrentRule} />
      </div>
      <aside
        className={cn(
          "flex gap-4 items-start mt-4 px-4 py-3 rounded-md",
          valid ? "bg-muted" : "bg-destructive text-destructive-foreground"
        )}
      >
        <Icon icon={valid ? "help" : "error"} className="mt-1" />
        <span className="leading-relaxed text-sm">
          {valid ? <RuleStatement productName={productName} rule={currentRule} /> : "Invalid Rule"}
        </span>
      </aside>
    </>
  );
}

const RuleStatement = ({ productName, rule }: { productName: string; rule: RuleItem }) => {
  let summary = <></>;

  switch (rule.type) {
    case RuleType.MinimumPremium:
      summary = (
        <>
          <strong>{productName}</strong>
          <span> will be </span>
          <strong>included</strong>
          <span> if premium is above </span>
          <strong>${rule.value ?? 0}</strong>.
        </>
      );
      break;
    case RuleType.State:
      summary = (
        <>
          <strong>{productName}</strong>
          <span> will be </span>
          <strong>{operatorString[rule.operator!]}</strong>
          <span> if primary state is </span>
          <strong>{STRICT_STATE_OPTIONS.find((state) => state.value === rule.value)?.label}</strong>.
        </>
      );
      break;
    case RuleType.CGL:
      summary = (
        <>
          <strong>{productName}</strong>
          <span> will be </span>
          <strong>{operatorString[rule.operator!]}</strong>
          <span> if CGL Code is </span>
          <strong>{rule.value}</strong>.
        </>
      );
      break;
    case RuleType.ClientData:
      summary =
        rule.valueType === ClientDataRuleValueType.Boolean ? (
          <>
            <strong>{productName}</strong>
            <span> will be </span>
            <strong>{operatorString[rule.operator!]}</strong>
            <span> if </span>
            <strong>{rule.key}</strong>
            <span> is </span>
            <strong>{rule.value}</strong>.
          </>
        ) : (
          <>
            <strong>{productName}</strong>
            <span> will be included if </span>
            <strong>{rule.key}</strong>
            <span> {operatorString[rule.operator!]} </span>
            <strong>{rule.value}</strong>.
          </>
        );
      break;
  }

  return summary;
};

export function RuleEditor({ rule, onRuleChange }: { rule?: RuleItem; onRuleChange: (rule: RuleItem) => void }) {
  switch (rule?.type) {
    case RuleType.MinimumPremium:
      return <MinimumPremiumRule rule={rule} onRuleChange={onRuleChange} />;
    case RuleType.State:
      return <StateRule rule={rule} onRuleChange={onRuleChange} />;
    case RuleType.CGL:
      return <CglRule rule={rule} onRuleChange={onRuleChange} />;
    case RuleType.ClientData:
      return <ClientDataRule rule={rule} onRuleChange={onRuleChange} />;
    default:
      return null;
  }
}

function MinimumPremiumRule({
  rule,
  onRuleChange,
}: {
  rule?: MinimumPremiumRuleItem;
  onRuleChange: (rule: RuleItem) => void;
}) {
  return (
    <div className="space-y-1.5">
      <h5>Minimum Premium</h5>
      <Input
        type="number"
        value={rule?.value}
        onChange={({ target }) => onRuleChange({ type: RuleType.MinimumPremium, value: Number(target.value) })}
      />
    </div>
  );
}

function StateRule({ rule, onRuleChange }: { rule?: StateRuleItem; onRuleChange: (rule: RuleItem) => void }) {
  return (
    <>
      <div className="space-y-1.5">
        <h5>Operator</h5>
        <RadioGroup
          value={rule?.operator}
          className="flex gap"
          onValueChange={(v: StateRuleOperator) =>
            onRuleChange({ type: RuleType.State, operator: v, value: rule?.value })
          }
        >
          {Object.values(StateRuleOperator).map((v) => (
            <RadioToggle key={v} v={v} />
          ))}
        </RadioGroup>
      </div>
      <div className="space-y-1.5">
        <h5>State</h5>
        <select
          className="block p-2 bg-background border rounded w-full"
          value={rule?.value}
          onChange={({ target }) =>
            onRuleChange({ type: RuleType.State, operator: rule?.operator, value: target.value })
          }
        >
          <option></option>
          {STRICT_STATE_OPTIONS.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

function CglRule({ rule, onRuleChange }: { rule?: CglRuleItem; onRuleChange: (rule: RuleItem) => void }) {
  return (
    <>
      <div className="space-y-1.5">
        <h5>Operator</h5>
        <RadioGroup
          value={rule?.operator}
          className="flex gap"
          onValueChange={(v: CglRuleOperator) => onRuleChange({ type: RuleType.CGL, operator: v, value: rule?.value })}
        >
          {Object.values(CglRuleOperator).map((v) => (
            <RadioToggle key={v} v={v} />
          ))}
        </RadioGroup>
      </div>
      <div className="space-y-1.5">
        <h5>CGL</h5>
        <Input
          value={rule?.value}
          onChange={({ target }) => onRuleChange({ type: RuleType.CGL, operator: rule?.operator, value: target.value })}
        />
      </div>
    </>
  );
}

function ClientDataRule({ rule, onRuleChange }: { rule?: ClientDataRuleItem; onRuleChange: (rule: RuleItem) => void }) {
  return (
    <>
      <div className="space-y-1.5">
        <h5>Key</h5>
        <Input
          disabled
          type="text"
          value={rule?.key}
          onChange={({ target }) =>
            onRuleChange({
              type: RuleType.ClientData,
              key: target.value,
              operator: rule?.operator,
              valueType: rule?.valueType ?? ClientDataRuleValueType.Text,
              value: rule?.value,
            })
          }
        />
      </div>
      <div className="space-y-1.5">
        <h5>Operator</h5>
        <RadioGroup
          value={rule?.operator}
          className="flex gap"
          onValueChange={(
            v: ClientDataRuleBooleanOperator | ClientDataRuleNumberOperator | ClientDataRuleTextOperator
          ) =>
            onRuleChange({
              type: RuleType.ClientData,
              operator: v,
              key: rule?.key ?? "",
              valueType: rule?.valueType ?? ClientDataRuleValueType.Text,
              value: rule?.value,
            })
          }
        >
          <ClientDataRuleOperatorItems valueType={rule?.valueType ?? ClientDataRuleValueType.Text} />
        </RadioGroup>
      </div>
      <div className="space-y-1.5">
        <h5>Client Data Value</h5>
        {rule?.valueType === ClientDataRuleValueType.Boolean ? (
          <RadioGroup
            value={rule?.value}
            className="flex gap"
            onValueChange={(value: string) =>
              onRuleChange({
                type: RuleType.ClientData,
                operator: rule?.operator,
                key: rule?.key ?? "",
                valueType: ClientDataRuleValueType.Boolean,
                value,
              })
            }
          >
            <RadioToggle v="Yes" />
            <RadioToggle v="No" />
          </RadioGroup>
        ) : (
          <Input
            type="text"
            value={rule?.value}
            onChange={({ target }) =>
              onRuleChange({
                type: RuleType.ClientData,
                value: target.value,
                key: rule?.key ?? "",
                valueType: rule?.valueType ?? ClientDataRuleValueType.Text,
                operator: rule?.operator,
              })
            }
          />
        )}
      </div>
    </>
  );
}

function ClientDataRuleOperatorItems({ valueType }: { valueType: ClientDataRuleValueType }) {
  switch (valueType) {
    case ClientDataRuleValueType.Text:
      return (
        <>
          {Object.values(ClientDataRuleTextOperator).map((v) => (
            <RadioToggle key={v} v={v} />
          ))}
        </>
      );
    case ClientDataRuleValueType.Number:
      return (
        <>
          {Object.values(ClientDataRuleNumberOperator).map((v) => (
            <RadioToggle key={v} v={v} />
          ))}
        </>
      );
    case ClientDataRuleValueType.Boolean:
      return (
        <>
          {Object.values(ClientDataRuleBooleanOperator).map((v) => (
            <RadioToggle key={v} v={v} />
          ))}
        </>
      );
  }
}

const RadioToggle = ({ v }: { v: string }) => (
  <Label className="bg-accent border cursor-pointer flex-auto p-2 rounded text-center text-muted-foreground text-xs has-[:checked]:bg-primary/10 has-[:checked]:border-primary has-[:checked]:text-primary">
    <RadioGroupItem value={v} className="hidden" />
    {v}
  </Label>
);
