import { format } from "date-fns";
import { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useModal } from "@/components/modal-provider";
import { SidePanelDetailsHeader, SidePanelList } from "@/components/side-panel";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertDialogAction, AlertDialogCancel, AlertDialogFooter } from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Icon } from "@/components/ui/icon";
import { Progress } from "@/components/ui/progress";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import {
  FileProcessorVersionState,
  useDeleteFileProcessorVersionMutation,
  useDeployFileProcessorVersionMutation,
  useSetDefaultFileProcessorVersionMutation,
  useUndeployFileProcessorVersionMutation,
} from "src/generated/graphql";
import { cn } from "src/utils";

export interface FileProcessorVersion {
  id: string;
  displayName: string;
  state: FileProcessorVersionState;
  createdAt?: Date;
  f1Score?: number | null;
}

export interface DetailsProcessorVersionsListProps {
  processorId: string;
  processorVersions?: FileProcessorVersion[] | null;
  defaultProcessorVersionId?: string | null;
}

export const DetailsProcessorVersionsList: FC<DetailsProcessorVersionsListProps> = ({
  processorId,
  processorVersions,
  defaultProcessorVersionId,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (!processorId || !processorVersions?.length) {
    return null;
  }

  const defaultVersion = processorVersions?.find(
    (processorVersion) => processorVersion.id === defaultProcessorVersionId
  );

  const versionsUrl = `https://console.cloud.google.com/ai/document-ai/locations/us/processors/${processorId}/v2/deploy-and-use/versions`;
  const versionsCount = processorVersions.length;

  return (
    <Collapsible open={isExpanded} onOpenChange={setIsExpanded} asChild>
      <div className="flex flex-col gap-2">
        <SidePanelDetailsHeader label="Processor versions">
          {versionsCount > 1 && (
            <>
              <Badge variant="outline" className="-my-2 px-1.5">
                {versionsCount >= 20 ? "20+" : versionsCount}
              </Badge>

              <span className="flex-1" />

              <div className="flex items-center -my-2 -mr-1.5">
                {!isExpanded && versionsCount >= 20 && (
                  <Button variant="ghost" size="xs" asChild>
                    <Link to={versionsUrl} target="_blank">
                      View all
                    </Link>
                  </Button>
                )}

                <CollapsibleTrigger asChild>
                  <Button variant="ghost" size="sm" display="icon" className="-mr-0.5">
                    <Icon icon={isExpanded ? "unfold_less" : "unfold_more"} />
                  </Button>
                </CollapsibleTrigger>
              </div>
            </>
          )}
        </SidePanelDetailsHeader>

        <div className="-mx-2 -mt-0.5 -mb-1">
          {!isExpanded && (
            <DetailsProcessorVersionsListItem
              processorVersion={{
                ...(defaultVersion || processorVersions[0]),
                processorId,
                isDefault: !!defaultVersion,
              }}
              hideActions
            />
          )}

          {!isExpanded && !defaultVersion && (
            <div className="mt-2 px-2">
              <Alert className="pb-3.5">
                <div className="flex gap-2">
                  <div>
                    <Icon icon="error" className="text-base" />
                  </div>
                  <div>
                    <AlertTitle>Default processor version not set</AlertTitle>
                    <AlertDescription className="text-muted-foreground">
                      Showing most recent version instead.
                    </AlertDescription>
                  </div>
                </div>
              </Alert>
            </div>
          )}

          <CollapsibleContent>
            <SidePanelList className="gap-0">
              {processorVersions.map?.((processorVersion) => (
                <DetailsProcessorVersionsListItem
                  key={processorVersion.id}
                  processorVersion={{
                    ...processorVersion,
                    processorId,
                    isDefault: processorVersion.id === defaultProcessorVersionId,
                  }}
                />
              ))}

              {versionsCount >= 20 && (
                <Button variant="ghost" size="sm" className="-mb-2" asChild>
                  <Link to={versionsUrl} target="_blank">
                    View all versions
                  </Link>
                </Button>
              )}
            </SidePanelList>
          </CollapsibleContent>
        </div>
      </div>
    </Collapsible>
  );
};

export interface DetailsProcessorVersionsListItemProps {
  processorVersion: FileProcessorVersion & {
    isDefault: boolean;
    processorId: string;
  };
  hideActions?: boolean;
}

export const DetailsProcessorVersionsListItem: FC<DetailsProcessorVersionsListItemProps> = ({
  processorVersion,
  hideActions,
}) => {
  const versionUrl = `https://console.cloud.google.com/ai/document-ai/locations/us/processors/${processorVersion.processorId}/v2/evaluate;processorVersionId=${processorVersion.id}`;

  return (
    <HoverCard openDelay={300}>
      <div
        key={processorVersion.id}
        className="-ml-2 flex flex-row items-center gap-2 px-2 h-9 text-xs text-muted-foreground"
      >
        <HoverCardTrigger asChild>
          <Button
            size="sm"
            variant="ghost"
            className={cn("block text-xs px-2 text-foreground max-w-[50%] truncate", {
              "max-w-[42%]": processorVersion.isDefault,
            })}
          >
            <Link to={versionUrl} target="_blank" rel="noreferrer">
              {processorVersion.displayName}
            </Link>
          </Button>
        </HoverCardTrigger>

        {processorVersion.isDefault && (
          <Badge variant="secondary" className="-ml-1">
            default
          </Badge>
        )}

        <div className="flex-1" />

        {processorVersion.createdAt && <span>{format(new Date(processorVersion.createdAt), "M/d/yy")}</span>}

        <div className="text-xs flex items-center gap-1 text-muted-foreground">
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <div>
                <Progress value={Number(processorVersion.f1Score) * 100} className="h-2 w-8" />
              </div>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>
                <span className="text-muted-foreground">F1 score:</span>{" "}
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 3,
                }).format(Number(processorVersion.f1Score))}{" "}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </div>

        <div className="leading-none">
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <span>
                <Icon
                  icon="circle"
                  className={cn("cursor-default mt-[2px] filled text-amber-500 text-xs", {
                    "text-amber-500/25": processorVersion.state === FileProcessorVersionState.Undeploying,
                    "text-green-500": processorVersion.state === FileProcessorVersionState.Deployed,
                    "text-green-500/25": processorVersion.state === FileProcessorVersionState.Deploying,
                    "text-destructive": processorVersion.state === FileProcessorVersionState.Failed,
                  })}
                />
              </span>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>{processorVersion.state?.toLocaleLowerCase()}</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </div>

        {/* TODO: Implement actions for processor versions */}
        {!hideActions && <ProcessorVersionActionsMenu processorVersion={processorVersion} />}
      </div>

      <HoverCardContent>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between gap-2">
            <h3
              className={cn("text-sm font-semibold leading-tight tracking-tight", {
                "max-w-[72%]": processorVersion.isDefault,
              })}
            >
              <Link to={versionUrl} target="_blank" rel="noreferrer" className="break-words text-wrap">
                {processorVersion.displayName}
              </Link>
            </h3>

            {processorVersion.isDefault && <Badge variant="secondary">default</Badge>}
          </div>

          <Separator />

          <div className="flex flex-col gap-3 text-xs">
            <div className="flex items-center justify-between gap-1">
              <span className="text-muted-foreground">State</span>
              <div>
                <Badge variant="outline" className="grow-0 inline-flex gap-1 pr-1 pl-2">
                  <span>{processorVersion.state?.toLocaleLowerCase()}</span>
                  <Icon
                    icon="circle"
                    className={cn("filled text-amber-500 text-xs", {
                      "text-amber-500/25": processorVersion.state === FileProcessorVersionState.Undeploying,
                      "text-green-500": processorVersion.state === FileProcessorVersionState.Deployed,
                      "text-green-500/50": processorVersion.state === FileProcessorVersionState.Deploying,
                      "text-destructive": processorVersion.state === FileProcessorVersionState.Failed,
                    })}
                  />
                </Badge>
              </div>
            </div>

            <div className="flex items-center justify-between gap-1">
              <span className="text-muted-foreground">F1 score</span>
              <div className="flex items-center gap-2">
                <div>
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 3,
                  }).format(Number(processorVersion.f1Score))}
                </div>
              </div>
            </div>

            {processorVersion.createdAt && (
              <div className="flex items-center justify-between gap-1">
                <span className="text-muted-foreground">Created date</span>
                <span>{format(new Date(processorVersion.createdAt), "M/d/yy")}</span>
              </div>
            )}
          </div>

          <Separator />

          <div className="flex flex-col">
            <span className="text-xs text-muted-foreground">Version ID</span>
            <span>
              <Button variant="link" size="sm" asChild className="p-0 h-auto break-words text-wrap">
                <Link to={versionUrl} target="_blank" rel="noreferrer">
                  {processorVersion.id}
                </Link>
              </Button>
            </span>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

export interface ProcessorVersionActionsMenuProps extends Omit<DetailsProcessorVersionsListItemProps, "hideActions"> {}

export const ProcessorVersionActionsMenu: FC<ProcessorVersionActionsMenuProps> = ({ processorVersion }) => {
  const { toast } = useToast();
  const { openModal, closeModal } = useModal();

  const defaultMutationOptions = {
    variables: { input: { processorVersionId: processorVersion.id, processorId: processorVersion.processorId } },
    refetchQueries: ["FileProcessor"],
  };

  const [deployProcessorVersion, { loading: deployLoading }] =
    useDeployFileProcessorVersionMutation(defaultMutationOptions);
  const [undeployProcessorVersion, { loading: undeployLoading }] =
    useUndeployFileProcessorVersionMutation(defaultMutationOptions);
  const [setDefaultProcessorVersion, { loading: setDefaultLoading }] =
    useSetDefaultFileProcessorVersionMutation(defaultMutationOptions);
  const [deleteProcessorVersion, { loading: deleteLoading }] =
    useDeleteFileProcessorVersionMutation(defaultMutationOptions);

  const isPretrained = useMemo(() => processorVersion.id.startsWith("pretrained-"), [processorVersion.id]);

  const isLoading = useMemo(
    () => deployLoading || undeployLoading || setDefaultLoading || deleteLoading,
    [deployLoading, undeployLoading, setDefaultLoading, deleteLoading]
  );

  const canDeploy = useMemo(
    () =>
      ![
        FileProcessorVersionState.Importing,
        FileProcessorVersionState.Deployed,
        FileProcessorVersionState.Deploying,
        FileProcessorVersionState.Undeploying,
        FileProcessorVersionState.Deleting,
        FileProcessorVersionState.Failed,
      ].includes(processorVersion.state) && !isPretrained,
    [processorVersion.state, isPretrained]
  );

  const canUndeploy = useMemo(
    () => processorVersion.state === FileProcessorVersionState.Deployed && !processorVersion.isDefault && !isPretrained,
    [processorVersion.state, processorVersion.isDefault, isPretrained]
  );

  const canSetAsDefault = useMemo(
    () => processorVersion.state === FileProcessorVersionState.Deployed && !processorVersion.isDefault,
    [processorVersion.state, processorVersion.isDefault]
  );

  const canDelete = useMemo(
    () =>
      ![FileProcessorVersionState.Deploying, FileProcessorVersionState.Undeploying].includes(processorVersion.state) &&
      !processorVersion.isDefault &&
      !isPretrained,
    [processorVersion.state, processorVersion.isDefault, isPretrained]
  );

  const isDisabled = useMemo(
    () => isLoading || (!canDeploy && !canUndeploy && !canSetAsDefault && !canDelete),
    [isLoading, canDeploy, canUndeploy, canSetAsDefault, canDelete]
  );

  const handleDeploy = () => {
    deployProcessorVersion({
      onCompleted: () =>
        toast({
          title: "Processor version deploying",
          description: "The processor version is deploying and will be available shortly",
        }),
      onError: (error: any) =>
        toast({
          variant: "destructive",
          title: "Failed to deploy processor version",
          description: error.message,
        }),
    });
  };

  const handleUndeploy = () => {
    undeployProcessorVersion({
      onCompleted: () =>
        toast({
          title: "Processor version undeployed",
          description: "The processor version has been undeployed successfully",
        }),
      onError: (error: any) =>
        toast({
          variant: "destructive",
          title: "Failed to undeploy processor version",
          description: error.message,
        }),
    });
  };

  const handleSetDefault = () => {
    setDefaultProcessorVersion({
      onCompleted: () =>
        toast({
          title: "Processor version default set",
          description: "The processor version has been set as the default successfully",
        }),
      onError: (error: any) =>
        toast({
          variant: "destructive",
          title: "Failed to set default processor version",
          description: error.message,
        }),
    });
  };

  const handleDelete = async () => {
    await openModal(
      () => (
        <>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={closeModal}>Cancel</AlertDialogCancel>
            <AlertDialogAction
              theme="destructive"
              onClick={() => {
                deleteProcessorVersion({
                  onCompleted: () => {
                    toast({
                      title: "Processor version deleted",
                      description: "The processor version has been deleted successfully",
                    });
                    closeModal();
                  },
                  onError: (error: any) =>
                    toast({
                      variant: "destructive",
                      title: "Failed to set delete processor version",
                      description: error.message,
                    }),
                });
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </>
      ),
      {
        title: "Delete processor version",
        description: `Are you sure you want to permanently delete the processor version "${processorVersion.displayName}?" This action cannot be undone.`,
      }
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={isDisabled} asChild>
        <Button variant="ghost" size="sm" display="icon" className="-ml-1 -mr-1.5">
          <Icon icon="more_horiz" className="font-bold" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {canDeploy && <DropdownMenuItem onClick={handleDeploy}>Deploy</DropdownMenuItem>}

        {canUndeploy && <DropdownMenuItem onClick={handleUndeploy}>Undeploy</DropdownMenuItem>}

        {canSetAsDefault && <DropdownMenuItem onClick={handleSetDefault}>Set as default</DropdownMenuItem>}

        {canDelete && (
          <DropdownMenuItem onClick={handleDelete}>
            <Icon icon="delete" />
            Delete
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
