import { Link } from "react-router-dom";
import { z } from "zod";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { useModal } from "@/components/modal-provider";
import { MoreMenu } from "@/components/more-menu";
import { SectionHeader, SectionTitle } from "@/components/section";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import {
  ProductExclusionStatus,
  useFindProductExclusionsQuery,
  useUpdateProductExclusionMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";
import { UpdateAppetiteForm } from "./update-appetite-form";

export function Appetite() {
  const { toast } = useToast();

  const {
    data: { productExclusionsFind } = { productExclusionsFind: [] },
    loading,
    refetch,
  } = useFindProductExclusionsQuery({
    variables: {
      where: {
        statuses: [ProductExclusionStatus.PendingReview],
      },
    },
    onError: (error) => {
      toast({ title: "Error", description: parseError(error), variant: "destructive" });
    },
  });

  const [updateProductExclusion] = useUpdateProductExclusionMutation({
    onCompleted: () => {
      void refetch();
      toast({ title: "Product Exclusion Updated" });
    },
    onError: (error) => {
      toast({ title: "Error", description: parseError(error), variant: "destructive" });
    },
  });

  const { openForm } = useModal();

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Appetite</h1>
          {loading && <Spinner />}
        </SectionTitle>
      </SectionHeader>
      {productExclusionsFind[0] ? (
        <Grid className="grid-cols-[2fr_2fr_1fr_1fr_4fr_1.5rem]">
          <GridRowHeader>
            <GridCell>Carrier</GridCell>
            <GridCell>Product</GridCell>
            <GridCell>State</GridCell>
            <GridCell>Naics</GridCell>
            <GridCell>Reason</GridCell>
            <GridCell />
          </GridRowHeader>
          {productExclusionsFind.map((exclusion) => (
            <GridRow key={exclusion.id}>
              <GridCell>{exclusion.appetiteProduct.carrierName}</GridCell>
              <GridCell>{exclusion.appetiteProduct.carrierProductName}</GridCell>
              <GridCell>{exclusion.state ?? "-"}</GridCell>
              <GridCell>{exclusion.naicsCode ?? "-"}</GridCell>
              <GridCell>{exclusion.reason ?? "-"}</GridCell>
              <MoreMenu>
                <DropdownMenuItem asChild>
                  <Link to={`/opportunity/${exclusion.submission.opportunityId}`}>
                    <Icon icon="open_in_new" />
                    View Opportunity
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={async () => {
                    const formData = await openForm(
                      z.object({
                        state: z.string().nullish(),
                        naicsCode: z.string().nullish(),
                        approvedReason: z.string(),
                      }),
                      <UpdateAppetiteForm />,
                      {
                        defaultValues: {
                          state: exclusion.state,
                          naicsCode: exclusion.naicsCode,
                          approvedReason: exclusion.reason ?? "",
                        },
                        title: "Update Appetite",
                      }
                    );

                    if (formData) {
                      void updateProductExclusion({
                        variables: {
                          input: {
                            id: exclusion.id,
                            status: ProductExclusionStatus.Approved,
                            approvedReason: formData.approvedReason,
                            state: formData.state ?? "",
                            naics: formData.naicsCode ?? "",
                          },
                        },
                      });
                    }
                  }}
                >
                  <Icon icon="edit" />
                  Modify & Approve
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() =>
                    updateProductExclusion({
                      variables: {
                        input: {
                          id: exclusion.id,
                          status: ProductExclusionStatus.Approved,
                        },
                      },
                    })
                  }
                >
                  <Icon className="text-success" icon="check" />
                  Approve
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() =>
                    updateProductExclusion({
                      variables: {
                        input: {
                          id: exclusion.id,
                          status: ProductExclusionStatus.Rejected,
                        },
                      },
                    })
                  }
                >
                  <Icon className="text-destructive" icon="delete" />
                  Reject
                </DropdownMenuItem>
              </MoreMenu>
            </GridRow>
          ))}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No exclusions found.</h2>} />
      )}
    </>
  );
}
