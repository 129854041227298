import { useState } from "react";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { SearchInput } from "@/components/ui/input";
import { LabelledCheckbox } from "@/forms/labelled-checkbox";
import {
  useAddProductToVerticalMarketingPlanTemplateMutation,
  useAppetiteProductsQuery,
  VerticalMarketingPlanTemplateFragment,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { useVertical } from "./use-vertical";

export const AddProducts = ({ template }: { template: VerticalMarketingPlanTemplateFragment }) => {
  const { openForm } = useModal();
  const { refetch } = useVertical();

  const [addProductToVerticalMarketingPlanTemplateTrigger] = useAddProductToVerticalMarketingPlanTemplateMutation({
    onCompleted() {
      void refetch();
    },
  });

  const addProductToVerticalMarketingPlan = async (verticalMarketingPlanTemplateId: string) => {
    const fd = await openForm(
      z.object({
        products: z.array(z.string()),
      }),
      <SelectProducts />,
      { defaultValues: { products: [] }, title: "Select Products" }
    );

    if (!fd) {
      return;
    }

    await addProductToVerticalMarketingPlanTemplateTrigger({
      variables: { input: { verticalMarketingPlanTemplateId, appetiteProductIds: fd.products } },
    });
  };

  return (
    <Button
      variant="outline"
      size="xs"
      className={cn(template.products.length === 0 && "!border-current !text-destructive")}
      onClick={() => addProductToVerticalMarketingPlan(template.id)}
    >
      Add Products
    </Button>
  );
};

function SelectProducts() {
  const { data } = useAppetiteProductsQuery();
  const [term, setTerm] = useState("");

  return (
    <div className="space-y-6">
      <SearchInput
        name="search"
        value={term}
        placeholder="Search Products"
        onChange={(event) => setTerm(event.currentTarget.value)}
      />
      <div className="space-y-3">
        {[...(data?.appetiteProducts ?? [])]
          .sort((a, b) => a.carrierName.localeCompare(b.carrierName))
          .filter((p) => {
            return (
              p.carrierName.toLowerCase().includes(term.toLowerCase()) ||
              p?.carrierProductName?.toLowerCase().includes(term.toLowerCase())
            );
          })
          .map((product) => (
            <LabelledCheckbox
              key={product.id}
              label={`${product.carrierName} ${product.carrierProductName}`}
              value={product.id}
              name="products"
            />
          ))}
      </div>
    </div>
  );
}
