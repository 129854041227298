import { Button } from "@/components/ui/button";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { SubmissionAction, SubmissionFragment } from "src/generated/graphql";
import { SubmissionMenu } from "./submission-menu";
import { useSubmissionActions } from "./use-submission-actions";

export const Referred = ({ submission }: { submission: SubmissionFragment }) => {
  const { handleConfirmation, handleTransition } = useSubmissionActions();
  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            display="icon"
            onClick={() => handleTransition(submission, SubmissionAction.Quote)}
          >
            <Icon icon="request_quote" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Quoted</TooltipContent>
      </Tooltip>
      <SubmissionMenu submission={submission}>
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionAction.BlockMarket)}>
          <Icon icon="front_hand" />
          Blocked
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleConfirmation(submission, SubmissionAction.Decline, true)}>
          <Icon icon="thumb_down" />
          Declined
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionAction.RuleOut)}>
          <Icon icon="block" />
          Rule Out
        </DropdownMenuItem>
      </SubmissionMenu>
    </>
  );
};
