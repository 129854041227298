import { ComponentProps, FC } from "react";
import { useFormContext } from "react-hook-form";

export interface FieldHiddenProps extends ComponentProps<"input"> {
  name: string;
}

export const FieldHidden: FC<FieldHiddenProps> = ({ name, ...props }) => {
  const { register } = useFormContext();

  return <input type="hidden" {...props} {...register(name)} />;
};
