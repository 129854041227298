import { Input, TextArea } from "@/forms/default";

export const UpdateAppetiteForm = () => (
  <div className="space-y-3">
    <div className="space-y-1.5">
      <h5>State</h5>
      <Input name="state" />
    </div>
    <div className="space-y-1.5">
      <h5>Naics</h5>
      <Input name="naicsCode" />
    </div>
    <div className="space-y-1.5">
      <h5>Reason</h5>
      <TextArea name="approvedReason" />
    </div>
  </div>
);
