import { Button } from "@/components/ui/button";
import { MarketingPlanAction, useTransitionMarketingPlanMutation } from "src/generated/graphql";
import { RequirementsContextData } from "../requirements";

export const InformationGathering = ({ opportunity }: RequirementsContextData) => {
  const [transition] = useTransitionMarketingPlanMutation();

  if (!opportunity) {
    return null;
  }

  const handleTransition = async (action: MarketingPlanAction) => {
    await transition({
      variables: {
        input: {
          id: opportunity.id,
          expectedState: opportunity.state,
          action,
        },
      },
    });
  };

  return (
    <Button variant="outline" size="xs" onClick={() => handleTransition(MarketingPlanAction.ReviewInformation)}>
      Info Gathering Complete
    </Button>
  );
};
